<template>
  <div>
  
    <tree-component></tree-component>

  </div>
</template>

<script>

import TreeComponent from './comuns/treeview/TreeComponent.vue'

export default {
  components: {
    TreeComponent
  },
  data() {
    return {
      
    }
  }
}
</script>