<template>
<div class="row justify-content-center">
    <b-overlay :show="show" rounded="sm">
  <card>

  <div class="row">    
    <div class="logo">
        <img src="img/logo_cvjl.png" alt="Logo" width="200" height="100">
      </div>    
      <div class="col-md-7" style="text-align:center;" >
        <h4 slot="header" style="margin-top: 30px;" class="card-title">:: Ficha de cadastro</h4>
    </div>
  </div>

    <form>
    
    </form>

  </card>
    </b-overlay>
</div>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  import axios from 'axios'
  import { BASE_CONSTANTS } from '../../service/config'

  export default {
    components: {
      Card,
    },
    data () {
      return {
        counter: 0,
        cabecalho: '',
        URL_PAGE: this.$route.query.page,
        show: false,
        msg: '',        
      }
    },
    mounted: function () {

      try{          

        let idForm = this.$route.params.idForm;
        this.$router.push('/forms/cadastro/' + idForm );
        
      }catch(e){
        this.$router.push('/login');
      }

    },
    methods: {
      toast(toaster, append = false, mensagem, cabecalho, tipoMensagem) {
        this.counter++
        this.$bvToast.toast(mensagem, {
            title: cabecalho,
            toaster: toaster,
            solid: true,
            variant: tipoMensagem,
            appendToast: append,

        })
      },            
    }
  }

</script>
<style>

</style>