<template>
  <div class="dados-vaga my-0 py-0">

    <b-modal ref="conf-candidatar" id="modal-conf-candidatar" size="lg" title="Confirmação" hide-footer>

      <div class="container-fluid">
        <b-row class="mb-3">
          <div class="col-md-12 mb-3">
            <h3>Confirma que deseja se candidatar a esta vaga?</h3>
          </div>
          <div class="col-md-12">
            <b-form-textarea id="textarea" v-model="obs"
              placeholder="Você pode informar uma observação para o coordenador desta vaga..." rows="3" max-rows="5"
              size="sm"></b-form-textarea>
          </div>
        </b-row>
        <b-row class="my-4">
          <div class="col-md-12">            
            <loading :show="showCandidatando">
            <b-button :disabled="showCandidatando" size="md" variant="danger" class="btn-fill float-right ml-2"
              @click="$bvModal.hide('modal-conf-candidatar')">
              Não
            </b-button>            
            <b-button :disabled="showCandidatando" size="md" variant="success" class=" btn-fill float-right" @click="confirmarCandidatar">
              Sim
            </b-button>
          </loading>
          </div>
        </b-row>
      </div>
    </b-modal>

    <b-jumbotron v-if="agenda" class="py-3 my-0" header-tag="p">

      <template #header>
        <div class="display-4 text-secondary meu-texto">Dados da vaga</div>
      </template>

      <template #lead>
        <div class="detalhe-dados-vaga text-secondary">
          {{ agenda.detalhe }}
        </div>
        <div class="detalhe-tipo-local-dados-vaga text-secondary">
          {{ agenda.detalheTipoLocal }}
        </div>
        <div class="obs-dados-vaga text-secondary">
          {{ agenda.obsPublica }}
        </div>
      </template>

      <hr class="mb-2 mt-0">

      <div class="dados-link-vaga">
        <b-row align-v="center" class="m-0">
          <b-col class="col-12 col-md-7 m-0">
            <b-row class="m-0 p-0">
              <b-col class="m-0 p-0">
                <p class="text-secondary"><span class="font-weight-bold">Município: </span> {{ agenda.cidade + ' - ' +
                  agenda.uf }} </p>
                <p class="text-secondary"><span class="font-weight-bold">Local: </span> {{ agenda.nomeLocal }} </p>
                <p class="text-secondary"><span class="font-weight-bold">Tipo de serviço: </span> {{ agenda.dscTipo }}</p>
              </b-col>
            </b-row>

            <b-row class="m-0 p-0">
              <b-col class="m-0 p-0 d-flex">
                <p class="text-secondary"><span class="font-weight-bold">Data: </span> {{ strDateFormat(agenda.dtAgenda,
                  'dd/MM/yyyy') }}</p>
                <p class="ml-5 text-primary text-secondary text-capitalize">( {{ agenda.diaSemana }} )</p>
              </b-col>
            </b-row>

            <b-row class="m-0 p-0">
              <b-col class="col-md-4 col-12 m-0 p-0">
                <p class="text-secondary"><span class="font-weight-bold">Horário: </span> {{ agenda.horarioExtenso }}</p>
              </b-col>
              <b-col class="col-md-4 col-12 m-0 p-0">
                <p v-if="agenda.quantidadeEscala" class="text-secondary"><span class="font-weight-bold">Quantidade:
                  </span> {{ agenda.quantidadeEscala }}</p>
              </b-col>
            </b-row>

          </b-col>

          <b-col class="col-12 col-md-5">
            <b-row class="m-0 p-0">
              <b-col class="d-flex" align-self="center">
                <p class="font-weight-bold my-auto text-secondary">Valor:</p>
                <p class="rounded bg-success text-white valor-plantao ml-3 my-auto">
                  {{ agenda.valorCompra.toLocaleString('pt-BR', {
                    style: 'currency', currency: 'BRL', maximumFractionDigits:
                      2
                  }) }}</p>
              </b-col>
            </b-row>
            <b-row class="pt-3" v-if="agenda.fixo === 'S'">
              <b-col>
                <h3 class="text-primary texto-vaga-fixa">Esta vaga de plantão é fixa</h3>
              </b-col>
            </b-row>
          </b-col>

        </b-row>
      </div>

      <hr class="mb-3 mt-0" />

      <div class="text-center text-md-left">        
          <b-button variant="primary btn-fill" size="lg" @click="candidatar">Tenho interesse</b-button>        
      </div>

    </b-jumbotron>
    <div class="row" v-else>
      <div class="col-md-12 text-center text-primary">Carregando...</div>       
    </div>
  </div>
</template>

<script>
import Card from 'src/components/Cards/Card.vue'
import appUtils from '@/composables/utils'
import AgendaService from '../../service/AgendaService'
import Box from '@caohenghu/vue-colorpicker/src/color/Box.vue'
import Loading from '../../layout/Loading'

export default {
  components: {
    Card,
    Box,
    Loading,
  },
  props: ['idAgenda'],
  data() {
    return {
      show: false,
      showCandidatando: false,
      showFrom: "",
      filaShow: [],
      agenda: null,
      empresa: null,
      obs: null,
    }
  },
  watch: {
    idAgenda(newVal) {
      this.buscarVaga()
    }
  },
  async mounted() {

    try {
      if (this.idAgenda) {
        this.buscarVaga()
      }
    } catch (e) {
      alert(e)
    }

  },
  methods: {
    buscarVaga() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("BuscarVaga");

      let dadosConsulta = { idAgenda: this.idAgenda };

      AgendaService.buscarVagaPlantao(dadosConsulta)
        .then((resposta) => {
          this.agenda = resposta.data.obj;
          if (this.agenda !== null) {
            this.empresa = this.agenda.identificadorEmpresa
          } else {
            toast('Agenda não encontrada', 'Falha', 'danger')
          }
          desativarShow("BuscarVaga");
        })
        .catch((error) => {
          desativarShow("BuscarVaga");
        });
    },
    candidatar() {

      if (this.loggedIn()) {
        this.$refs['conf-candidatar'].show()
      } else {
        this.$router.push({ name: 'CriarConta', params: { empresa: this.empresa }, query: { redirect: '/' + this.empresa + '/vaga/' + this.agenda.id } });
      }

    },
    confirmarCandidatar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      this.showCandidatando = true

      let dadosConsulta = {
        idProfissional: dadosUsuario.idPessoa,
        idAgenda: this.idAgenda,
        obs: this.obs
      };

      AgendaService.candidatarVagaPlantao(dadosConsulta)
        .then((resposta) => {

          let retorno = resposta.data;

          if (retorno.status === "SUCCESS") {

            toast(retorno.mensagens[0].descricao, 'Enviado', 'success')
            this.$refs['conf-candidatar'].hide()

            let ret = {
              "status": "success"
            }

            var v = this
            setTimeout(function (ret) {
              v.$emit('executou', ret)
            }, 2000);

          } else {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }
          this.showCandidatando = false

        })
        .catch((error) => {
          this.showCandidatando = false
        });
    },
    strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>
<style  lang="scss">
.dados-vaga {
  .texto-vaga-fixa {
    animation: blink 2s linear infinite;
  }

  .detalhe-dados-vaga,
  .detalhe-tipo-local-dados-vaga,
  .obs-dados-vaga {
    font-size: 0.8rem;
  }

}

@keyframes blink {
  0% {
    opacity: .1;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 600px) {

  /*.link-vaga .display-3{  
      font-size: 2.0rem;
    }

    .link-vaga .lead{  
      font-size: 1.0rem;
    }*/

}


@media (min-width: 991px) {

  /*.dados-link-vaga p{  
      font-size: 1.0 rem;
    }

    .dados-link-vaga .valor-plantao{  
      font-size: 2.0rem;
      padding: 5px 15px;
    }*/

}</style>