export default class User {
  constructor(username, email, password) {
    this.username = username;
    this.email = email;
    this.password = password;
  }
}

/*export const usuario = ({
    "id": null,
    "login": null,
    "senha": null,
    "ativo": false,
    pessoa: pessoa,
})*/