<template>
  <b-overlay :show="show" rounded="sm" z-index="1051">

    <template #overlay>
      <div class="text-center">
        <b-spinner variant="primary" small type="grow"></b-spinner>
        <span v-if="$slots.message" class="ml-3 text-primary"><slot name="message"></slot></span>
        <span v-else class="ml-3 text-primary">Aguarde...</span>
      </div>
    </template>

    <slot>
    </slot>
  </b-overlay>
</template>
<script>
export default {
  props: ['show'],
  data() {
    return {

    };
  },
  methods: {
    showOverlay(status) {
      this.show = status
    }
  }
}
</script>
<style></style>        