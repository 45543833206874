
const useAuthentication = () => {

  const isUnauthorized = (err) => {    
    if (err!== undefined && err.response !== undefined && err.response.status !== undefined) {      
      return err.response.status !== '401'
    }
    return false
  }
  
  return { isUnauthorized }

}

export default useAuthentication