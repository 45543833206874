<template>
  <div>
      <select-cor ref="selectCorSituacaoLocal" id="selectCorSituacaoLocal" @executou="(r) => { if (r.status === 'success') atualizarCor(r.color) }" />

      <b-modal header-bg-variant="primary" header-text-variant="light" footer-bg-variant="light"
        ref="modal-edit_situacao_local" id="modal-edit_situacao_local" size="lg" title="Configuração da situação no local"
        no-close-on-backdrop hide-footer>
  
        <loading :show="show">
        <div class="container-fluid" v-if="entity">
          <b-row class="my-1">
            <div class="col-md-12">
  
              <b-card-body class="p-0" style="min-height: 450px;">
  
                <div class="container-fluid">
  
                  <b-row>
                    <div class="col-md-9">
                      <b-form-group label="Local" label-for="input-local">
                        <b-form-input id="input-local" type="text" label="Local" placeholder="Local" readonly
                          v-model="entity.dscLocal" />
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group class="mb-0" label="Situação" label-for="input-situacao">
                        <b-form-select v-if="!entity.id" id="input-situacao" v-model="entity.idSituacao" value-field="id"
                          text-field="descricao" :options="itensSituacao" size="sm" @change="alterarSituacao(entity.idSituacao)">
                          <template #first>
                            <b-form-select-option :value="null">-- Selecione a situação --</b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-input v-else id="input-situacao" type="text" label="Situação" placeholder="Situação"
                          readonly v-model="entity.dscSituacao" />
                      </b-form-group>
                    </div>
                  </b-row>
  
                  <b-row>
                    <div class="col-md-12">
                      <h4 class="card-title text-primary m-0 pb-2">Configuração</h4>
                    </div>
                  </b-row>
  
                  <b-row>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.padrao" value="S" unchecked-value="N" switch>Padrão</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.executado" value="S" unchecked-value="N"
                        switch>Executado</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.previsto" value="S" unchecked-value="N"
                        switch>Previsto</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.possuiObs" value="S" unchecked-value="N" switch>Possui
                        Obs</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.possuiValCompl" value="S" unchecked-value="N" switch>Possui val
                        compl.</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.exportar" value="S" unchecked-value="N"
                        switch>Exportar</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.divulga" value="S" unchecked-value="N"
                        switch>Divulga</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.faturar" value="S" unchecked-value="N"
                        switch>Faturar</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.checkin" value="S" unchecked-value="N"
                        switch>Check-in</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.checkout" value="S" unchecked-value="N"
                        switch>Check-out</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.validarSimultaneo" value="S" unchecked-value="N"
                        switch>Validar simult.</b-form-checkbox>
                    </div>
                  </b-row>
  
                  <b-row>
                    <div class="col-md-3">
                      <b-form-group label="Aberto" label-for="select-aberto">
                        <b-form-select id="select-aberto" v-model="entity.aberto" :options="itensAberto">
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group label="Grupo" label-for="input-grupo">
                        <b-form-input id="input-grupo" type="text" label="Grupo" placeholder="Grupo" maxlength="50"
                          v-model="entity.grupo" />
                      </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group label="Ordem" label-for="input-ordem">
                        <b-form-spinbutton id="input-ordem" v-model="entity.ordem" inline></b-form-spinbutton>
                      </b-form-group>
                    </div>

                    <div class="col-md-3">
                      <b-form-group label="Cor" label-for="input-cor">
                      <div class="box-color" :style="{ background: entity.cor }" @click="abrirCor">
                        <div style="padding:2px; font-size: 12px; color:blue">{{ entity.cor === null || entity.cor === '' ?
                          'clique aqui para selecionar a cor' : '' }}</div>
                      </div>
                    </b-form-group>
                  </div>
  
                  </b-row>
  
                </div>
              </b-card-body>
            </div>
          </b-row>
        </div>
  
        <b-row class="mt-2 mb-0 mx-0 p-0">
          <div class="col-md-12 m-0 px-0 pt-2 pb-0 footer-js">
              <b-button size="md" variant="danger" class="btn-fill float-right" @click="fechar">Fechar</b-button>
              <b-button size="md" variant="primary" class="btn-fill float-right mr-1" @click="salvar()">Confirmar</b-button>
          </div>
        </b-row>
      </loading>
      </b-modal>
    </div>
  </template>
  
  <script>
  
  import AgendaService from "../../../service/AgendaService";
  import appUtils from '@/composables/utils'
  import Loading from '../../../layout/Loading'
  import SelectCor from '../../comuns/SelectCor.vue'
  
  export default {
    components: {
      Loading,
      SelectCor,
    },
    data() {
      return {
        show: false,
        showFrom: "",
        filaShow: [],
        idsEliminar: [],
        entity: null,
        itensAberto: [{ "value": "S", "text": "Sim" }, { "value": "N", "text": "Não" }, { "value": "A", "text": "Ambos" }],
        itensSituacao: []
      }
    },
    mounted() {
  
    },
    methods: {
      novo(idLocal) {  
        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("novaSituacaoLocal");
        AgendaService.dadosNovaSituacaoLocal(idLocal).then(resposta => {
          this.entity = resposta.data.obj.entity
          this.entity.id = null
          this.entity.idSituacao = null
          this.entity.cor = null
          this.itensSituacao = resposta.data.obj.situacoes
          desativarShow("novaSituacaoLocal");
        }).catch(error => {
          toast(error, 'Falha', 'danger')
          desativarShow("novaSituacaoLocal");
        })
  
        this.$bvModal.show('modal-edit_situacao_local')
      },
      edit(id) {
        const { toast } = appUtils(this)        
        AgendaService.editSituacaoLocal(id).then(resposta => {
          this.entity = resposta.data.obj
        }).catch(error => {
          toast(error, 'Falha', 'danger')        
        })
      },      
      salvar() {
  
        const { toast } = appUtils(this)
  
        this.show = true
  
        AgendaService.saveSituacaoLocal(this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
  
              let ret = {
                "status": "success",
                "entity": retorno.obj
              }
              this.$emit('executou', ret)
  
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
              var v = this
              setTimeout(function () {
                v.fechar()
              }, 2000);
  
            } else {
              if (retorno.mensagens.length > 0) {
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }
  
            this.show = false
          }).catch((error) => {
            this.show = false
            toast(error, 'Falha', 'danger')
          });
  
      },
      fechar() {
        this.hide()
      },
      async abrir(v) {
        this.edit(v)
        this.$bvModal.show('modal-edit_situacao_local')
      },
      hide() {
        this.$bvModal.hide('modal-edit_situacao_local')
      },
      carregarSituacoes() {
        const { ativarShow, desativarShow } = appUtils(this)
  
        ativarShow("carregarSituacoes")
  
        let dadosConsulta = {
          //idsEliminar: this.idsSituacaoEliminar
        }
  
        AgendaService.recuperaSituacoes(dadosConsulta)
          .then((resposta) => {
            this.itensSituacao = resposta.data.obj;
            desativarShow("carregarSituacoes")
          })
          .catch((error) => {
            desativarShow("carregarSituacoes")
          });
      },
      abrirCor() {
        this.$refs.selectCorSituacaoLocal.show(this.entity.cor)
      },
      atualizarCor(cor) {
        this.entity.cor = cor;
      },
      alterarSituacao(idSituacao) {

        let obj = this.itensSituacao.find((o) => o.id === idSituacao)
        this.entity.padrao = obj.padrao
        this.entity.executado = obj.executado
        this.entity.previsto = obj.previsto
        this.entity.possuiObs = obj.possuiObs
        this.entity.possuiValCompl = obj.possuiValCompl
        this.entity.exportar = obj.exportar
        this.entity.divulga = obj.divulga
        this.entity.validarSimultaneo = obj.validarSimultaneo
        this.entity.checkin = obj.identificador == 'CHECKIN' ? 'S' : 'N'
        this.entity.checkout = obj.identificador == 'CHECKOUT' ? 'S' : 'N'
      },
    }
  }
  </script>
  
  <style></style>