<template>
  <loading :show="show">
    <b-modal header-bg-variant="primary" header-text-variant="light" 
      footer-bg-variant="light"
      ref="modal-select-profissional" id="modal-select-profissional" size="lg" title="Selecionar profissional"
      no-close-on-backdrop>

      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">

            <b-card-body class="p-0" style="min-height: 450px;">

              <div class="container-fluid">

                <b-row class="mb-3">
                  <b-col lg="12" class="text-center">
                    <b-form-radio-group id="btn-radios-2" v-model="tipoBusca" button-variant="outline-primary" size="md"
                      name="radio-btn-outline" @change="mudaTipoBusca" buttons>
                      <b-form-radio v-model="tipoBusca" name="some-radios" value="escala">Minha rede</b-form-radio>
                      <b-form-radio v-model="tipoBusca" name="some-radios" value="busca">Busca</b-form-radio>
                      <b-form-radio v-model="tipoBusca" name="some-radios" value="cadastrar">Cadastrar</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>

                <b-row class="my-2" v-if="tipoBusca === 'escala'">
                  <b-col lg="12" class="my-1">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Digite um valor e presssione Enter" v-on:keydown.enter="buscaProfissionais">
                    </b-form-input>
                  </b-col>
                </b-row>

                <b-row v-else-if="tipoBusca === 'busca'" class="my-2">

                  <div class="col-md-2">
                    <b-form-select v-model="busca.ufCrm" :options="optionsUF">
                      <template #first>
                        <b-form-select-option :value="null">-- UF CRM --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>

                  <div class="col-md-3">
                    <b-form-input type="text" placeholder="CRM" v-model="busca.crm" />
                  </div>

                  <div class="col-md-4">
                    <b-form-input type="text" placeholder="CPF" maxlength="14" v-mask="'###.###.###-##'"
                      v-model="busca.cpf" />
                  </div>

                  <div class="col-md-12" v-if="false">
                    <b-form-input type="text" placeholder="Nome" v-model="busca.nome" />
                  </div>

                  <div class="col-md-3 pl-2">
                    <b-button size="sm" variant="primary" class="btn-fill" @click="buscar">
                      Buscar
                    </b-button>
                  </div>
                </b-row>

                <b-row v-else class="my-2">

                  <b-row class="mx-0">

                    <div class="col-md-3">
                      <base-input label="CPF">
                        <b-form-input type="text" maxlength="14" placeholder="CPF" v-mask="'###.###.###-##'" v-model="dados.cpf" />
                      </base-input>
                    </div>
                    
                    <div class="col-md-9">
                      <base-input label="Nome">
                        <b-form-input id="input-nome-profissional" type="text" label="Nome" placeholder="Nome" maxlength="100"
                          v-model="dados.nome" />
                      </base-input>
                    </div>

                    <div class="col-md-3">
                      <base-input label="UF CRM">
                        <b-form-select ref="ufCrm" v-model="dados.ufCrm" :options="optionsUF" value-field="value"
                          text-field="text" ></b-form-select>
                      </base-input>
                    </div>

                    <div class="col-md-6">
                      <base-input label="CRM">
                        <b-form-input type="text" ref="crm" placeholder="CRM" v-model="dados.crm" />
                      </base-input>
                    </div>

                    <div class="col-md-8">
                      <base-input label="E-mail">
                        <b-form-input role="presentation" autocomplete="off" type="email" ref="email" />
                      </base-input>
                    </div>

                    <div class="col-md-4">
                      <base-input label="Celular">
                        <b-form-input placeholder="Celular" maxlength="14" v-mask="'(##)#####-####'" />
                      </base-input>
                    </div>
                  </b-row>
                </b-row>

                <b-row>

                  <b-table v-if="tipoBusca !== 'cadastrar'" :items="itens" :fields="fields" :fixed="true"
                    sticky-header="300px" :hover="unico" :selectable="unico" @row-selected="onRowSelected">

                    <template #head(seleciona) v-if="!unico">
                      <b-form-checkbox v-model="todosSelecionados" value="S" unchecked-value="N"
                        @input="selecionarTodos"></b-form-checkbox>
                    </template>

                    <template v-slot:cell(seleciona)="row" v-if="!unico">
                      <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
                    </template>

                    <template #cell()="data">
                      {{ data.value }}
                    </template>

                  </b-table>

                </b-row>


              </div>
            </b-card-body>

          </div>
        </b-row>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <div v-if="tipoBusca !== 'cadastrar'">
            <b-button size="md" variant="danger" class="ml-1 btn-fill float-right" @click="fechar">Fechar</b-button>
            <b-button size="md" variant="success" class="btn-fill float-right" @click="confirmar" v-if="!unico">Confirmar
            </b-button>
          </div>
          <div v-else>
            <b-button v-if="tipoBusca === 'cadastrar'" size="md" variant="primary" class="btn-fill float-right"
              @click="salvar()">Confirmar</b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </loading>
</template>

<script>

import AgendaService from "../../service/AgendaService";
import { BASE_CONSTANTS } from "../../service/config";
import axios from "axios";
import appUtils from '@/composables/utils'
import Loading from '../../layout/Loading'
import getUF from '@/composables/getUF'

export default {
  components: {
    Loading,
  },
  props: ['unico'],
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      idsEliminar: [],
      itens: [],
      itensBusca: [],
      todosSelecionados: 'N',
      filter: null,
      tipoBusca: "escala",
      filterOn: ['nome'],
      tabIndex: 0,
      optionsUF: getUF(),
      busca: {
        ufCrm: null,
        crm: null,
        cpf: null,
        nome: null,
      },
      dados: {
        cpf: null,
        nome: null,
        ufCrm: null,
        crm: null,        
        email: null,
        celular: null,        
      },
      fields: [
        { key: 'seleciona', type: "check", thStyle: { width: "8%" } },
        { key: 'nome', label: 'Nome', sortable: true, sortDirection: 'asc', thStyle: { width: "92%" } },
      ],

    }
  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })
  },
  methods: {

    buscaProfissionais() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      if (this.filter) {

        ativarShow("buscaProfissionais");        

        let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

        let dadosConsulta = {
          idUsuario: dadosUsuario.idPessoa,
          idEmpresa: dadosUsuario.grupo.empresa.id,
          idsEliminar: this.idsEliminiar !== null ? this.idsEliminar : [],
          avulso: 'S',
          filtro: this.filter
        }
        return AgendaService.listaProfissionaisFilter(this.http, dadosConsulta).then(resposta => {
          this.itens = resposta.data.obj
          desativarShow("buscaProfissionais");
        }).catch(error => {
          toast(error, 'Falha', 'danger')
        })
      } else {
        toast('Informe um valor para pesquisar', 'Falha', 'danger')
      }

    },
    selecionarTodos(checked) {
      this.itens.forEach((item) => {
        item.seleciona = checked === 'S' ? 'S' : 'N';
      });
    },
    buscar() {
      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscar");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        idEmpresa: dadosUsuario.grupo.empresa.id,
        ufCrm: this.busca.ufCrm,
        crm: this.busca.crm,
        cpf: this.busca.cpf,
        nome: this.busca.nome,
      }

      return AgendaService.buscaProfissionaisFilter(this.http, dadosConsulta).then(resposta => {
        this.itens = resposta.data.obj
        desativarShow("buscar");
      }).catch(error => {
        desativarShow("buscar");
      })

    },
    mudaTipoBusca() {
      if (this.tipoBusca === 'escala') {
        this.buscaProfissionais()
      } else {
        this.itens.splice(0)
      }
    },
    confirmar() {

      let itensSelecionado = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      })

      let ret = {
        "status": "success",
        "ids": itensSelecionado
      }
      this.$emit('executou', ret)
      this.fechar()

    },
    salvar() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("salvarProfissional");
      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      this.dados.idEmpresa = dadosUsuario.grupo.empresa.id,
      this.dados.basico = 'S'
      AgendaService.salvarProfissional(this.dados)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {            
            let ret = {
              "status": "success",
              "profissional": retorno.obj
            }
            this.$emit('cadastrou', ret)

            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.fechar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("salvarProfissional");
        }).catch((error) => {
          desativarShow("salvarProfissional");
          toast(error, 'Falha', 'danger')
        });
    },
    fechar() {
      this.hide()
    },
    async abrir(v) {
      this.tipoBusca = 'escala'
      this.tabIndex = 0
      this.filter = null
      this.itens = []
      this.dados.cpf = ''
      this.dados.nome = ''
      this.dados.ufCrm = ''
      this.dados.crm = ''
      this.dados.email = ''
      this.dados.celular = ''
      this.idsEliminar = v
      //await this.buscaProfissionais()
      this.$bvModal.show('modal-select-profissional')
    },
    hide() {
      this.$bvModal.hide('modal-select-profissional')
    },
    onRowSelected(items) {

      let ret = {
        "status": "success",
        "profissional": items[0]
      }
      this.$emit('selecionou', ret)
      this.fechar()
    },
  }
}
</script>

<style>

</style>