<template>
  
    <card>
      <h4 slot="header" class="card-title">Conta</h4>

      <form>

        <b-row>
          <div class="col-md-3">
            <b-form-group label="cpf" label-for="input-cpf">
              <b-form-input v-mask="'###.###.###-##'" :readonly="disableCpf" id="input-cpf" type="text" placeholder="CPF" maxlength="11" v-model="entity.cpf" />
            </b-form-group>
          </div>
        </b-row>

        <b-row>
          <div class="col-md-6">
            <b-form-group label="Nome" label-for="input-nome">
              <b-form-input id="input-nome" type="text" placeholder="Nome" v-model="entity.nome" />
            </b-form-group>
          </div>
        </b-row>

        <b-row>

          <div class="col-md-2">
            <b-form-group label="UF CRM" label-for="input-uf-crm">              
              <b-form-select ref="ufCrm" v-model="entity.ufCrm" :options="optionsUF" value-field="value" text-field="text"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group label="CRM" label-for="input-crm">
              <b-form-input id="input-crm" type="text" placeholder="CRM" maxlength="100" v-model="entity.crm" />
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group label="E-mail" label-for="input-email">
              <b-form-input id="input-email" type="text" :readonly="disableEmail" placeholder="Email" maxlength="100"
                v-model="entity.email" />
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group label="Celular" label-for="input-telefone">
              <b-form-input id="input-telefone" type="text" placeholder="Telefone" maxlength="100"
                v-mask="'(##)#####-####'" v-model="entity.telefone" />
            </b-form-group>
          </div>

        </b-row>

        <b-row>
          <div class="col-md-1" v-if="statusSenha === 'editing'">
            <b-form-input placeholder="Senha atual" type="password" id="input-senha-atual" v-model="entity.senha"
              trim></b-form-input>
          </div>
          <div class="col-md-1" v-if="statusSenha === 'editing'">
            <b-form-input placeholder="Senha nova" type="password" id="input-senha-nova" v-model="entity.senhaNova"
              trim></b-form-input>
          </div>
          <div class="col-md-1" v-if="statusSenha === 'editing'">
            <b-form-input placeholder="Confirma senha" type="password" id="input-conf-senha-nova" v-model="entity.confirmarSenha" trim></b-form-input>
          </div>
          <div class="col-md-2" v-if="statusSenha === ''">
            <b-button variant="primary" class="btn-fill" @click="editarSenha">Alterar senha</b-button>
          </div>
          <div class="col-md-2" v-if="statusSenha === 'editing'">
            <b-button variant="primary" class="btn-fill" @click="alterarSenha">Alterar</b-button>
            <b-button variant="danger" class="ml-1 btn-fill" @click="cancelarEditarSenha">Cancelar</b-button>
          </div>
        </b-row>

        <b-row>
          <div class="col-md-12" v-if="errosRegra">
            <b-alert show variant="danger">
              <h6 class="alert-heading">Regras não atendidas</h6>
              <hr>
              <p class="mb-0">
              <ul>
                <li v-for="regra in errosRegra" :key="regra"> {{regra}} </li>
              </ul>
              </p>
            </b-alert>
          </div>
        </b-row>

        <div class="text-center">
          <b-button variant="primary" class="btn-fill float-right" @click="salvar">
            Salvar
          </b-button>
        </div>        

      </form>
    </card>
</template>
<script>

import AgendaService from '../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import appUtils from '@/composables/utils'
import getUF from '@/composables/getUF'

export default {
  components: {
    Card
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      statusSenha: '',
      errosRegra: null,
      optionsUF: [],
      disableCpf: false,
      disableEmail: false,
      entity: {
        id: null,
        cpf: '',
        email: '',
        ufCrm: '',
        crm: '',
        nome: '',
        senha: '',
        senhaNova: '',
        confirmarSenha: '',
      },
    }
  },
  mounted() {
    this.optionsUF = getUF()
    this.carregaDados();
  },
  methods: {

    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      ativarShow("carregaDados");

      let dadosBusca = {
        idUsuario: dadosUsuario.idPessoa
      }

      AgendaService.buscarDadosConta(dadosBusca)
        .then(resposta => {        

        let retorno = resposta.data;

        if (retorno.status === "SUCCESS") {
          this.entity = retorno.obj
          this.disableCpf = this.entity.cpf && this.entity.cpf.trim() !== ''
          this.disableEmail = this.entity.email && this.entity.email.trim() !== ''
          desativarShow("carregaDados");          
        } else {          
          desativarShow("carregaDados");
          toast(retorno.mensagens[0].descricao, 'Falha', 'danger')                    
        }

      }).catch(e => {
        toast("Não foi possível carregar os dados.", 'Falha', 'danger')
      });

    },
    editarSenha() {
      this.entity.senha = ''
      this.entity.senhaNova = ''
      this.entity.confirmarSenha = ''
      this.errosRegra = null
      this.statusSenha = 'editing'
    },
    cancelarEditarSenha() {      
      this.errosRegra = null
      this.statusSenha = ''
    },
    alterarSenha() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("alterarSenha");

      this.errosRegra = null

      AgendaService.updatePassword(this.entity)
        .then(resposta => {

          let retorno = resposta.data;
          desativarShow("alterarSenha");
          if (retorno.status === "SUCCESS") {
            this.statusSenha = ''         
            toast(retorno.mensagens[0].descricao, 'Sucesso', 'success')
          } else {            
            if (retorno.mensagens.length > 0 && retorno.mensagens[0].descricao.startsWith("Falha regra")) {
              this.errosRegra = []
              retorno.mensagens.slice(1).forEach(element => {
                this.errosRegra.push(element.descricao)
              });
            } else {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')              
            }     
          }

        }).catch(e => {
          desativarShow("alterarSenha");
          toast("Não foi possível atualizar a senha.", 'Falha', 'danger')
        });

    },
    salvar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("salvar");
      this.cancelarEditarSenha()

      AgendaService.atualizarConta(this.entity.id, this.entity)
        .then(resposta => {        

        let retorno = resposta.data;

        if (retorno.status === "SUCCESS") {          
          desativarShow("salvar");
          toast(retorno.mensagens[0].descricao, 'Sucesso', 'success')                    
        } else {          
          desativarShow("salvar");
          toast(retorno.mensagens[0].descricao, 'Falha', 'danger')                    
        }

      }).catch(e => {
        toast("Não foi possível atualizar a conta.", 'Falha', 'danger')
      });

    },    
  }
}

</script>
<style>

</style>
  