
const appUtils = (contexto) => {
  var ctx = contexto;

  const toast = (mensagem, cabecalho, tipoMensagem) => {        
    ctx.$bvToast.toast(mensagem, {
        title: cabecalho,
        toaster: 'b-toaster-top-right',
        solid: true,
        variant: tipoMensagem,
        appendToast: true
      });
    
  }

  const ativarShow = (from) => {
    if (ctx.showFrom === "") {
      ctx.showFrom = from;
      ctx.show = true;
      ctx.$emit('showOverlay', ctx.show)      
    } else {
      ctx.filaShow.push(from);
    }
  }

  const desativarShow = (from) => {
    if (from === ctx.showFrom) {
      if (ctx.filaShow.length > 0) {
        ctx.showFrom = ctx.filaShow[0];
        ctx.filaShow.splice(0, 1);
      } else {
        ctx.show = false;
        ctx.showFrom = "";
        ctx.$emit('showOverlay', ctx.show)
      }
    } else {
      let index = ctx.filaShow.indexOf(from);
      if (index >= 0) {
        ctx.filaShow.splice(index, 1);
      }
    }    
  }

  const convertHoraEmMinutos = (data) => {
    if(data!==null && data !== undefined){
      let f0 = data.split(":");
      let minutos = Number(f0[0]) * 60 + Number(f0[1]);
      return minutos;
    }      
  }
  
  const convertMinutosEmHora = (minutos) => {      
    const horas = Math.floor(minutos / 60);      
    const min = minutos % 60;      
    const textoHoras = `00${horas}`.slice(-2);
    const textoMinutos = `00${min}`.slice(-2);      
    return `${textoHoras}:${textoMinutos}`;
  }

  const formatarValorNumerico = (valor) => {    
    const tmp = valor.toString().replace(".", ",").split(",");
    let inteiro = tmp.length > 0 ? tmp[0] : '0';
    let decimal = tmp.length > 1 ? tmp[1].padEnd(2, '0') : '00';
    return inteiro + ',' + decimal;
  }

  const formatarCpf = (text) => {  
    const badchars = /[^\d]/g
    const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
    const cpf = new String(text).replace(badchars, "");
    return cpf.replace(mask, "$1.$2.$3-$4");
  }

  const maskPhone = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1")
  }

  const filterBy = (option, label, search) => {                   

    var t = ['aáàãâä','eéèêë','iíìîï','oóòõôö','uúùûü','cç']
    
    let normalizar = (texto) => {        
      texto = texto.toLocaleLowerCase()
      t.forEach((str) => {
        for (let i = 1; i < str.length; i++) {            
          texto = texto.replace(str[i], str[0])
        }
      });
      return texto
    };

    return normalizar(label).indexOf(normalizar(search)) > -1
    
  }

  const camelCase = (value) => {    
    return value.toLowerCase()
    .split(' ')
    .map((word) => word.length>1 ? word[0].toUpperCase() + word.slice(1) : word)
    .join(' ')
  }
  
  return { toast, ativarShow, desativarShow,convertHoraEmMinutos,convertMinutosEmHora, formatarValorNumerico, formatarCpf, filterBy, maskPhone, camelCase }

}

export default appUtils