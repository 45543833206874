<template>
  <div>
    <select-local ref="selectLocal" @executou="(r) => { if (r.status === 'success') adicionarLocal(r.ids) }" />

    <b-modal ref="modal-locais" id="modal-locais" title="Locais" size="xl">

      <div style="height: 450px;">

        <b-tabs content-class="mt-2">

          <b-tab title="Locais">

            <b-row>
              <div class="col-md-12">

                <b-table v-if="locais" :items="locais.lista" :fields="fieldsLocal" :fixed="true" sticky-header="350px"
                  :tbody-tr-class="rowClass">

                  <template #cell(actions)="row">
                    <b-button size="sm" @click="row.item.deleted = row.item.deleted == 'S' ? 'N' : 'S'" class="mr-1"
                      variant="danger" title="Excluir">
                      <b-icon icon="trash" aria-hidden="true"></b-icon>
                    </b-button>
                  </template>

                  <template #cell()="data">
                    {{ data.value }}
                  </template>

                </b-table>

              </div>
            </b-row>

            <b-row>
              <div class="col-md-12">

                <div class="w-100">
                  <b-button variant="info" size="sm" class="btn-fill" @click="abrirSelecionaLocal">
                    Adicionar
                  </b-button>
                </div>

              </div>
            </b-row>

          </b-tab>

        </b-tabs>

      </div>

      <template #modal-footer>

        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right btn-fill" @click="salvarLocais">
            Salvar
          </b-button>
        </div>
      </template>

    </b-modal>

    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir(0) }">
      <template v-slot:texto>Confirma exclusão do profisisonal?</template>
    </confirma>

    <div v-if="!edit">

      <card class="h-100 lista-profissional">

        <template slot="header">
          <h4 class="card-title">Profissionais</h4>
        </template>

        <b-row>

          <div class="col-md-2">
            <b-form-input type="text" placeholder="Nome/CRM/CPF" v-model="filter" />
          </div>

          <div class="col-md-2 filtro">
            <b-form-select v-model="alocado" :options="optionsFiltroAlocados"></b-form-select>
          </div>

          <div class="col-md-2 filtro">
            <b-form-select v-model="credenciamento" :options="optionsFiltroCredenciamento"></b-form-select>
          </div>

          <div class="col-md-2 text-center text-md-left filtro">
            <b-button variant="primary" size="sm" class="btn-fill float-left" @click="buscar">
              Pesquisar
            </b-button>

            <b-dropdown text="Ação" variant="primary" class="ml-2" size="sm">
              <b-dropdown-item @click="abrirAdicionarClassificacao">Classificar</b-dropdown-item>
              <b-dropdown-item @click="abrirRemoverClassificacao" href="#">Remover classificação</b-dropdown-item>
            </b-dropdown>

            <b-button class="ml-1 btn-fill" size="sm" variant="warning" @click="editar(0)" v-if="false">
              <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
            </b-button>
          </div>

        </b-row>

        <b-row>
          <div class="col-md-12 pt-2">

            <!-- Main table element -->
            <b-table empty-text="Nenhum registro" :items="itens" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty sticky-header="500px"
              small hover @row-clicked="editarProfissional">

              <template #head(seleciona)>
                <b-form-checkbox v-model="todosSelecionados" value="S" unchecked-value="N"
                  @input="selecionarTodos"></b-form-checkbox>
              </template>

              <template v-slot:cell(seleciona)="row">
                <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
              </template>

              <template v-slot:cell(classificacao)="row">
                <b-badge v-if="row.item.tags" class="ml-1" v-for="t in row.item.tags" v-bind:key="t.id"
                  variant="warning">{{ t.dscClassificacao }}</b-badge>
                <b-badge v-if="row.item.locais" class="ml-1" v-for="l in row.item.locais" v-bind:key="l.id"
                  variant="primary">{{ l.dscCompleto }}</b-badge>
              </template>

              <template #cell(actions)="row" v-if="false">
                <b-button size="sm" variant="primary" class="mr-1" @click="abrirLocais(row.item)" title="Locais">
                  <b-icon icon="house-fill" aria-hidden="true"></b-icon>
                </b-button>
              </template>

            </b-table>
          </div>

        </b-row>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
              size="sm"></b-pagination>
          </div>
        </div>
      </card>
    </div>
    <div v-else>
      <edit-profissional :idProfissional="idProfissional" @fecharedit="() => this.edit = false"
        @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.entity) }" />
    </div>

    <select-classificacao @showOverlay="(status) => this.$emit('showOverlay', status)" ref="selectClassificacao"
      @executou="(r) => { if (r.status === 'success') retornoSelecionaClassificacao(r.ids) }" />

    <div class="clearfix"></div>
  </div>
</template>

<script>
import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'
import Confirma from '../../comuns/Confirma'
import EditProfissional from './EditProfissional.vue'
import SelectLocal from '../../comuns/SelectLocal.vue'
import SelectClassificacao from '../../comuns/SelectClassificacao.vue'

export default {
  components: {
    Card,
    EditProfissional,
    Confirma,
    SelectLocal,
    SelectClassificacao,
  },
  data() {
    return {
      id: null,
      show: false,
      http: null,
      showFrom: "",
      filaShow: [],
      configuracao: null,
      itens: [],
      edit: false,
      idProfissional: null,
      todosSelecionados: 'N',
      addRemoveClassificacao: null,
      fields: [
        { key: 'seleciona', label: '', thStyle: { width: "3%" } },
        { key: 'cpf', label: 'CPF', sortable: true, sortDirection: 'asc', thStyle: { width: "15%" } },
        { key: 'crm', label: 'CRM', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
        { key: 'nome', label: 'Nome', sortable: true, sortDirection: 'asc', thStyle: { width: "30%" } },
        { key: 'classificacao', label: 'Classificações / Projetos', sortable: true, sortDirection: 'asc', thStyle: { width: "40%" } },
        //{ key: 'actions', label: 'Ação', thStyle: { width: "17%" } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      locais: null,
      alocado: null,
      credenciamento: null,
      optionsFiltroAlocados: [
        { value: null, text: 'Prof. alocados' },
        { value: 'S', text: 'Alocados' },
        { value: 'N', text: 'Não alocados' },
      ],
      optionsFiltroCredenciamento: [
        { value: null, text: 'Credenciamento' },
        { value: 'S', text: 'Credenciados' },
        { value: 'N', text: 'Não credenciados' },
      ],
      fieldsLocal: [
        { key: 'dscLocal', label: 'Local', sortable: true, sortDirection: 'asc', thStyle: { width: "70%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "20%" } }
      ],
    }
  },
  computed: {

  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    this.carregaDados()

  },
  methods: {
    novo() {
      this.edit = true
      this.idUsuario = 0
    },
    editarProfissional(record, index) {
      this.editar(record.idProfissional)
    },
    editar(id) {
      this.edit = true
      this.idProfissional = id
    },
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarProfissionais");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        idEmpresa: dadosUsuario.grupo.empresa.id,
        filtro: this.filter,
        credenciamento: this.credenciamento,
        alocado: this.alocado,
        "comClassificacao": "S",
        "comLocais": "S"
      }

      AgendaService.listaProfissionaisFilter(this.http, dadosConsulta)
        .then((resposta) => {
          this.itens = resposta.data.obj
          this.totalRows = this.itens.length
          desativarShow("listarProfissionais");
        })
        .catch((err) => {
          desativarShow("listarProfissionais");
        });

    },
    buscar() {
      this.carregaDados()
    },
    abrirLocais(record) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("abrirLocais");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        idPessoa: record.id,
        idEmpresa: dadosUsuario.grupo.empresa.id
        }

      AgendaService.buscarPessoaLocal(dadosConsulta).then((resposta) => {
        this.locais = resposta.data.obj
        this.$bvModal.show("modal-locais")
        desativarShow("abrirLocais");
      }).catch((err) => {
        desativarShow("abrirLocais");
      });

    },
    salvarLocais() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("salvarLocais");
      AgendaService.atualizarPessoaLocal(this.http, this.locais)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            this.$bvModal.hide('modal-locais')
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("salvarLocais");
        }).catch((error) => {
          desativarShow("salvarLocais");
          toast(error, 'Falha', 'danger')
        });
    },

    confirmaExcluir(id) {
      this.id = id
      this.$refs.conf.show()
    },
    excluir() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("deleteUsuario");

      AgendaService.deleteUsuario(this.http, this.id)
        .then(resposta => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.itens = this.itens.filter((i) => { return i.id !== this.id })
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("deleteUsuario");
        }).catch(error => {
          desativarShow("deleteUsuario");
          toast(error, "Falha", "danger")
        })
    },
    atualizarRegistro(entity) {
      let index = this.itens.indexOf(this.itens.find((i) => i.id === entity.id));
      if (index >= 0) {
        this.itens.splice(index, 1, entity);
      } else {
        this.itens.push(entity);
      }
    },
    abrirSelecionaLocal() {
      const { toast } = appUtils(this)
      let idsEliminar = this.locais.lista.map(elem => elem.idLocal);
      this.$refs.selectLocal.abrir(idsEliminar)
    },
    adicionarLocal(locais) {
      locais.forEach((item) => {

        let reg = {
          idLocal: item.id,
          dscLocal: item.descricao,
        }

        this.locais.lista.push(reg);

      });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.deleted === 'S') return 'table-danger'
    },
    selecionarTodos(checked) {
      this.itens.forEach((item) => {
        item.seleciona = checked === 'S' ? 'S' : 'N';
      });
    },
    async retornoSelecionaClassificacao(classificacoes) {

      let idsRegistro = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => elem.idProfissional)

      let idsClassificacao = classificacoes.map(elem => elem.id)

      if (this.addRemoveClassificacao === 'add') {
        await this.$refs.selectClassificacao.adicionarClassificacao(idsRegistro, idsClassificacao, 'profissional')
      } else {
        await this.$refs.selectClassificacao.removerClassificacao(idsRegistro, idsClassificacao)
      }      

      this.buscar()
    },
    abrirAdicionarClassificacao() {
      this.addRemoveClassificacao = "add"
      this.$refs.selectClassificacao.abrir(null, ['TGP'])
    },
    /*adicionarClassificacao(classificacoes) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("adicionarClassificacao");

      let idsProfissional = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => elem.idProfissional);

      let idsClassificacao = classificacoes.map(elem => elem.id);

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idsProfissional": idsProfissional,
        "idsClassificacao": idsClassificacao
      }

      AgendaService.adicionarClassificacao(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data
          if (retorno.status === "SUCCESS") {
            this.buscar()
          } else if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          } else {
            toast('Operação não foi realizada. Entre em contato com o suporte', 'Falha', 'danger')
          }
          desativarShow("adicionarClassificacao");
        }).catch((err) => {
          desativarShow("adicionarClassificacao");
        });

    },*/
    abrirRemoverClassificacao() {
      this.addRemoveClassificacao = "remove"
      this.$refs.selectClassificacao.abrir(null, ['TGP'])
    },
    /*removerClassificacao(classificacoes) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("removerClassificacao");

      let idsProfissional = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => elem.idProfissional);

      let idsClassificacao = classificacoes.map(elem => elem.id);

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idsProfissional": idsProfissional,
        "idsClassificacao": idsClassificacao
      }

      AgendaService.removerClassificacaoProfisisonal(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data
          if (retorno.status === "SUCCESS") {
            this.buscar()
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          } else {
            toast('Operação não foi realizada. Entre em contato com o suporte', 'Falha', 'danger')
          }
          desativarShow("removerClassificacao");
        }).catch((err) => {
          desativarShow("removerClassificacao");
        });

    },*/
  }
}
</script>
<style>
@media (max-width: 768px) {

  .lista-profissional .filtro {
    padding-top: 5px;
  }


}
</style>