import axios from 'axios';
import authHeader from './auth-header';
import { BASE_CONSTANTS } from "./config";


/*axios.interceptors.request.use(
    (config) => {        
      console.log('envio sem erro')
      return config;
    },
    (error) => {
      console.log('envio com erro')
      return Promise.reject(error);
    }
);
  
axios.interceptors.response.use(    
    (response) => {
        console.log('resposta sem erro')
        return this.$router.push("/login");  
    },
    (error) => {
        console.log('resposta com erro')
        if (error.response.status === 401 && !originalRequest._retry) {          
            originalRequest._retry = true;
            this.$store.dispatch('auth/logout');
            this.$router.push('/login/acesso');            
        }      
        return Promise.reject(error);
    }
);*/

export default {

    listarSetoresDescricao: (http) => {
        return http.get('setor/listardistinct')
    },
    listarSetores: (http) => {
        return http.get('setor/listar')
    },    
    listarPessoas: (http) => {
        return http.get('pessoa')
    },
    listarPessoasNomes: (http) => {
        return http.get('pessoa/listanomes')
    },
    listarDiaSemana: (http) => {
        return http.get('diasemana')
    },
    verificaToken: (http) => {
        return http.get('/oauth/check_token/?token=' + localStorage.getItem('authorization'))
    },
    salvarEvento: (http, evento) => {
        return http.post('/agenda/salvar', evento)
    },
    confirmarEvento: (dados) => {
        return axios.post('agenda/confirmar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },
    createUser: (http, user) => {
        return http.post('/pessoa/create', user)
    },
    updateUser: (http, user) => {
        return http.post('/usuario/update', user)
    },
    updatePassword: (dados) => {        
        return axios.post('/pessoa/updatePassword', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    excluiPessoa: (http, id) => {
        return http.delete('/pessoa/' + id)
    },
    atualizaEvento: (http, evento) => {
        return http.post('/agenda/edit', evento)
    },
    excluiEvento: (http, id, replicarAlteracoes) => {
        return http.delete('/agenda/' + id + '/' + replicarAlteracoes)
    },
    listaEventos: (http) => {
        return http.get('agenda/listAll')
    },
    listaEventosFilter: (http, dadosFiltro) => {
        return http.post('agenda/listaAgendas/', dadosFiltro)
    },    
    listaLocaisFilter: (dados) => {
        return axios.post('local/lista/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
        //return http.post('local/lista/', dadosFiltro)
    },
    listaProfissionaisFilter: (http, dadosFiltro) => {
        return http.post('profissional/lista/', dadosFiltro)
    },    
    buscaProfissionaisFilter: (http, dadosFiltro) => {
        return http.post('profissional/busca/', dadosFiltro)
    },    
    editProfissional: (http, id) => {
        return http.get('profissional/'+id)
    },
    salvarProfissional: (dados) => {
        return axios.post('profissional/cadastrar/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },
    
    listaEmpresaPJFilter: (http, dadosFiltro) => {
        return http.post('empresa-pj/lista/', dadosFiltro)
    },    
    buscaEmpresaPJFilter: (http, dadosFiltro) => {
        return http.post('profissional/busca/', dadosFiltro)
    },    
    editEmpresaPJ: (id) => {        
        return axios.get('empresa-pj/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    salvarEmpresaPJ: (dados) => {
        return axios.post('profissional/cadastrar/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },

    listaEspecializacao: (http, dadosFiltro) => {
        return http.post('profissional/especializacao/', dadosFiltro)
    },    
    geraRelatorioFinanceiro: (dados) => {
        return axios.post('relatorio/financeiro/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },
    buscarTotaisFechamentoMes: (dados) => {
        return axios.post('fechamento/totais-mes/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    geraRelatorioPDF: (dados) => {
        return axios.post('relatorio/export/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    geraRelatorioGeral: (dados) => {
        return axios.post('relatorio/geral', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    exportarRelatorioFinanceiroExcel: (dados) => {
        return axios.post('relatorio/financeiro/export/excel', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    exportarRelatorioFinanceiroGeralExcel: (http, dadosFiltro) => {
        return http.post('relatorio/financeiro/export/geral/excel', dadosFiltro)
    },
    exportarRelatorioFinanceiroHmtj: (http, dadosFiltro) => {
        return http.post('relatorio/financeiro/export/hmtj', dadosFiltro)
    },
    exportarAgendaExcel: (http, dadosFiltro) => {
        return http.post('relatorio/agenda/excel', dadosFiltro)
    },
    exportarRelatorioFinanceiroConsolidado: (dados) => {
        return axios.post('relatorio/financeiro/export/consolidado', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    exportarRelatorioFinanceiroDetalhado: (dados) => {
        return axios.post('relatorio/financeiro/export/detalhado', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    exportarRelatorioPersonalizado: (dados) => {
        return axios.post('relatorio/personalizado', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },
    exportarRelatorioFolhaPonto: (dados) => {
        return axios.post('relatorio/financeiro/export/folha-ponto', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    listaUsuariosFilter: (http, dados) => {
        return http.post('usuario/listar', dados)
    },
    listaTiposFilter: (dados) => {
        return axios.post('tipo/lista/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },
    recuperaSituacoes: (dados) => {
        return axios.post('situacao/lista/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },
    recuperaSituacoesGeral: (dados) => {
        return axios.post('situacao/lista-geral', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    gravarFechamentoAgenda: (dados) => {
        return axios.post('fechamento/gravar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
        //return http.post('fechamento/gravar', dadosFiltro)
    },
    alterarSituacaoFechamentoAgenda: (http, dadosFiltro) => {
        return http.post('fechamento/alterar-situacao', dadosFiltro)
    },
    cancelarFechamentoAgenda: (dados) => {
        return axios.post('fechamento/cancela', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
        //return http.post('fechamento/cancela', dadosFiltro)
    },
    buscarFormulario: (http, dadosFiltro) => {
        return http.post('formulario/buscar', dadosFiltro)
    },
    buscarFormularioCredenciamento: (dados) => {        
        return axios.post('credenciamento/preencher', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    abrirCredenciamento: (http, dados) => {
        return http.post('credenciamento/abrir', dados)
    },
    excluirCredenciamento: (dados) => {
        return axios.post('credenciamento/excluir', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    cancelarCredenciamento: (dados) => {
        return axios.post('credenciamento/cancelar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    gerarDashboardCredenciamento: (dados) => {
        return axios.post('credenciamento/dashboard', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    listarFechamento: (http, dadosFiltro) => {
        return http.post('fechamento/listar/', dadosFiltro)
    },
    copiarAgenda: (http, dadosFiltro) => {
        return http.post('fechamento/copiar/', dadosFiltro)
    },    
    listarEscala: (dados) => {
        return axios.post('escala/lista/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    editEscala: (id) => {
        return axios.get('escala/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    salvarEscala: (http, escala) => {
        return http.post('/escala/salvar', escala)
    },
    excluirAgendas: (http, dadosFiltro) => {
        return http.post('agenda/escala/excluir/', dadosFiltro)
    },
    excluirLoteAgenda: (http, dados) => {
        return http.post('agenda/excluir/', dados)
    },
    excluirEscala: (id) => {
        return axios.delete('/escala/delete/' + id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })        
    },
    listaSituacoesFechamento: (http, dadosFiltro) => {
        return http.post('fechamento/listaSituacoes/', dadosFiltro)
    },
    listaSituacoesGeral: (dados) => {
        return axios.post('situacao/lista-geral', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })        
    },
    listaHistoricoAgenda: (http, dadosFiltro) => {
        return http.post('agenda/historico/', dadosFiltro)
    },
    listaHistoricoFechamento: (http, dadosFiltro) => {
        return http.post('fechamento/historico/', dadosFiltro)
    },
    listaHistoricoCredenciamento: (dados) => {
        return axios.post('credenciamento/historico', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })                
    },
    buscarFechamento: (http, dadosFiltro) => {
        return http.post('fechamento/buscar/', dadosFiltro)
    },
    copiarAgenda: (http, dadosFiltro) => {
        return http.post('agenda/copiar/', dadosFiltro)
    },
    gerarDashboard: (dados) => {        
        return axios.post('relatorio/dashboard/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })                
    },
    imprimirAgenda: (http, dadosFiltro) => {
        return http.post('relatorio/escala/', dadosFiltro)
    },
    recuperarSenha: (http, dados) => {
        return http.post('usuario/recuperarSenha/email', dados)
    },
    resetarSenha: (http, dados) => {
        return http.post('usuario/resetSenha', dados)
    },
    listaSituacoesLocal: (dados) => {        
        return axios.post('local/situacoes', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    listaSituacoesFechamentoLocal: (dados) => {        
        return axios.post('local/situacoes/fechamento', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    listaTiposLocal: (dados) => {        
        return axios.post('local/tipos', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    buscarConfiguracaoLocal: (http, dados) => {
        return http.post('local/configuracao', dados)
    },
    buscarProfissionaisLocal: (dados) => {
        return axios.post('local/profissional', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    editLocal: (id) => {
        return axios.get('local/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })                                
    },
    saveLocal: (dados) => {
        return axios.post('local/save', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })                                
    },
    deleteLocal: (http, id) => {
        return http.delete('local/'+id)
    },
    saveConfiguracaoLocal: (http, dados) => {
        return http.post('local/configuracao/save', dados)
    },
    recuperaGruposUsuario: (http, dadosFiltro) => {
        return http.post('grupoUsuario/lista/', dadosFiltro)
    },
    editUsuario: (http, id) => {
        return http.get('usuario/'+id)
    },
    saveUsuario: (http, dados) => {
        return http.post('usuario/save', dados)
    },    
    deleteUsuario: (http, id) => {
        return http.delete('usuario/'+id)
    },
    buscarPessoaLocal: (dados) => {        
        return axios.post('pessoa/local/', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },
    atualizarPessoaLocal: (http, dados) => {
        return http.post('pessoa/local/atualizar', dados)
    },
    atualizarLoteAgenda: (http, dados) => {
        return http.post('/agenda/salvar/lote', dados)
    },
    buscarDadosManutencaoAgenda: (http, dados) => {
        return http.post('agenda/dados/manutencao', dados)
    },
    buscarParametroCadastro: (dados) => {
        return axios.post('parametro/buscar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
    },
    listaEspecialidadeFilter: (http, dados) => {
        return http.post('tipo/especialidade/lista/', dados)
    },
    editTipo: (http, id) => {
        return http.get('tipo/'+id)
    },
    saveTipo: (dados) => {
        return axios.post('tipo/save', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },  
    editGrupoUsuario: (http, id) => {
        return http.get('grupoUsuario/'+id)
    },
    saveGrupoUsuario: (dados) => {        
        return axios.post('grupoUsuario/save', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },    
    deleteGrupoUsuario: (http, id) => {
        return http.delete('grupoUsuario/'+id)
    },
    recuperaEmpresa: (http, dados) => {
        return http.post('empresa/lista/', dados)
    },
    editEmpresa: (http, id) => {
        return http.get('empresa/'+id)
    },
    saveEmpresa: (http, dados) => {
        return http.post('empresa/save', dados)
    },    
    deleteEmpresa: (http, id) => {
        return http.delete('empresa/'+id)
    },
    buscarEmpresaPeloIdentificador: (dados) => {        
        return axios.post('empresa/buscar/identificador', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    listaCredenciamento: (dados) => {
        return axios.post('credenciamento/lista', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    listaCredenciamentoPJ: (dados) => {
        return axios.post('credenciamento/lista-pj', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    listaCredenciamentoPF: (dados) => {        
        return axios.post('credenciamento/lista-pf', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    exportarListaCredenciamento: (dados) => {
        return axios.post('credenciamento/lista/export', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    buscaEmpresaCredenciamento: (dados) => {
        return axios.post('credenciamento/busca/empresa', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    aprovarCredenciamento: (http, dados) => {
        return http.post('credenciamento/aprovar', dados)
    },
    reprovarCredenciamento: (http, dados) => {
        return http.post('credenciamento/reprovar', dados)
    },
    atualizarCredenciamento: (dados) => {
        return axios.post('credenciamento/atualizar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    downloadTermo: (dados) => {        
        return axios.post('credenciamento/download/termo', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    buscarVagasPlantao: (dados) => {        
        return axios.post('mobile/vaga/buscar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    buscarVagaPlantao: (dados) => {
        return axios.post('mobile/vaga/aceitar/abrir', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    candidatarVagaPlantao: (dados) => {        
        return axios.post('mobile/vaga/aceitar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    listaAprovacaoCandidato: (http, dados) => {
        return http.post('mobile/vaga/aprovar/lista', dados)
    },
    abrirAprovacaoCandidato: (http, dados) => {
        return http.post('mobile/vaga/aprovar/abrir', dados)
    },
    aprovarCandidato: (http, dados) => {
        return http.post('mobile/vaga/aprovar', dados)
    },    
    reprovarCandidato: (http, dados) => {
        return http.post('mobile/vaga/reprovar', dados)
    },
    listaCandidaturas: (dados) => {        
        return axios.post('mobile/vaga/candidatura/lista', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    abrirCandidatura: (dados) => {
        return axios.post('mobile/vaga/candidatura/abrir', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    cancelarCandidatura: (dados) => {
        return axios.post('mobile/vaga/candidatura/cancelar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    buscarDadosFiltroVagas: (dados) => {
        return axios.post('mobile/vaga/filtro', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    buscarBancos: () => {
        return axios.get('mobile/banco', { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
        //return http.get('mobile/banco')
    },
    listaTipoEspecializacao: (dados) => {
        return axios.post('cadastro/tipo-especializacao', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
        
    },
    listaEspecialidade: (dados) => {
        return axios.post('cadastro/especialidade',dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
        //return http.post('cadastro/especialidade', dados)
    },
    listaNivelEspecializacao: () => {
        return axios.get('cadastro/nivel-especializacao', { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });
        //return http.get('cadastro/nivel-especializacao')
    },
    saveEspecializacao: (http, dados) => {
        return http.post('profissional/especializacao/salvar', dados)
    },    
    deleteEspecializacao: (http, id) => {
        return http.delete('profissional/especializacao/excluir/'+id)
    },
    criarConta: (dados) => {
        return axios.post('mobile/user/create', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    posCriarConta: (dados) => {
        return axios.post('credenciamento/pos-criar-conta', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    enviarDadosCredenciamento: (dados) => {
        return axios.post('credenciamento/enviarDados', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    posEnviarCredenciamento: (dados) => {
        return axios.post('credenciamento/pos-enviar-credenciamento', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    download: (idDocumento) => {
        return axios.get('arquivo/download/'+idDocumento, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },  
    buscarPerfil: (dados) => {
        return axios.post('mobile/user/profile', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    buscarDadosConta: (dados) => {
        return axios.post('mobile/user/dados', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    atualizarConta: (idPessoa, dados) => {
        return axios.put('mobile/user/'+idPessoa, dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },    
    listarPagamentos: (dados) => {        
        return axios.post('pagamento/listar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    abrirPagamento: (id) => {
        return axios.get('pagamento/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    listaClassificacao: (dados) => {
        return axios.post('classificacao/lista', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },    
    editClassificacao: (id) => {
        return axios.get('classificacao/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    saveClassificacao: (dados) => {
        return axios.post('classificacao/save', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },        
    deleteClassificacao: (id) => {
        return axios.delete('classificacao/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    listaTipoGeral: (dados) => {
        return axios.post('cadastro/tipo-geral', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    adicionarClassificacao: (dados) => {
        return axios.post('tag/adicionar-classificacao', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    removerClassificacao: (dados) => {
        return axios.post('tag/remover-classificacao', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    anunciarVaga: (dados) => {
        return axios.post('agenda/vaga/divulgar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    listaDocumento: (dados) => {
        return axios.post('documento/termo', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    buscaTermoUso: (dados) => {
        return axios.post('documento/termo-uso', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    buscaPoliticaPrivacidade: (dados) => {
        return axios.post('documento/politica-privacidade', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    listaPermissaoGeral: (dados) => {
        return axios.post('usuario/permissao-geral', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });      
    },
    buscarVersao: () => {                        
        return axios.get('usuario/versao', { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    listaLiberacaoAgenda: (dados) => {
        return axios.post('liberacao/lista', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    listabuscarUsuariosAprovadores: (dados) => {
        return axios.post('usuario/aprovador', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },    
    editAprovacao: (dados) => {           
        return axios.post('liberacao/edit', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    aprovarAprovacao: (dados) => {        
        return axios.post('liberacao/aprovar/diretor', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    reprovarAprovacao: (dados) => {        
        return axios.post('liberacao/reprovar/diretor', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    aprovarAprovacaoFinanceiro: (dados) => {        
        return axios.post('liberacao/aprovar/financeiro', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    reprovarAprovacaoFinanceiro: (dados) => {        
        return axios.post('liberacao/reprovar/financeiro', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    cancelarAprovar: (dados) => {        
        return axios.post('liberacao/aprovar/diretor/cancelar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    cancelarReprovar: (dados) => {        
        return axios.post('liberacao/reprovar/diretor/cancelar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    cancelarAprovarFinanceiro: (dados) => {        
        return axios.post('liberacao/aprovar/financeiro/cancelar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    cancelarReprovarFinanceiro: (dados) => {        
        return axios.post('liberacao/reprovar/financeiro/cancelar', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    buscarPerfilAprovador: (dados) => {        
        return axios.post('liberacao/perfil', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    buscarRelatoriosAgenda: (dados) => {        
        return axios.post('agenda/relatorios', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });        
    },
    dadosNovaSituacaoLocal: (id) => {
        return axios.get('local/situacao/novo/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    editSituacaoLocal: (id) => {
        return axios.get('local/situacao/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    saveSituacaoLocal: (dados) => {
        return axios.post('local/situacao/save', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    deleteSituacaoLocal: (id) => {
        return axios.delete('local/situacao/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    dadosNovaSituacaoFechamentoLocal: (id) => {
        return axios.get('local/situacao/fechamento/novo/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    editSituacaoFechamentoLocal: (id) => {
        return axios.get('local/situacao/fechamento/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    saveSituacaoFechamentoLocal: (dados) => {
        return axios.post('local/situacao/fechamento/save', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    deleteSituacaoFechamentoLocal: (id) => {
        return axios.delete('local/situacao/fechamento/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    deleteProfissionalLocal: (dados) => {
        return axios.post('local/profissional/excluir', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() });                
    },
    saveProfissionalLocal: (dados) => {
        return axios.post('local/profissional/save', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    dadosNovoTipoLocal: (id) => {
        return axios.get('local/tipo/novo/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    editTipoLocal: (id) => {
        return axios.get('local/tipo/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    saveTipoLocal: (dados) => {
        return axios.post('local/tipo/save', dados, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
    deleteTipoLocal: (id) => {
        return axios.delete('local/tipo/'+id, { baseURL: BASE_CONSTANTS.urlBase, headers: authHeader() })
    },
}