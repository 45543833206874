<template>
  <loading :show="show">
    <b-modal header-bg-variant="primary" header-text-variant="light" 
      footer-bg-variant="light"
      ref="modal-select-empresa" id="modal-select-empresa" size="lg" :title="titulo ? titulo : 'Selecionar empresa'"
      no-close-on-backdrop>

      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">

            <b-card-body class="p-0" style="min-height: 450px;">

              <div class="container-fluid">

                <b-row>

                  <b-table :items="itens" :fields="fields" :fixed="true"
                    sticky-header="300px" :hover="true" :selectable="true" select-mode="single" @row-selected="onRowSelected">

                    <template #cell()="data">
                      {{ data.value }}
                    </template>

                  </b-table>

                </b-row>


              </div>
            </b-card-body>

          </div>
        </b-row>
      </div>

      <template #modal-footer>
        <div class="w-100">
            <b-button size="md" variant="primary" class="btn-fill float-right" @click="confirmar()">Confirmar</b-button>          
        </div>
      </template>

    </b-modal>
  </loading>
</template>

<script>

import { BASE_CONSTANTS } from "../../service/config";
import axios from "axios";
import Loading from '../../layout/Loading'

export default {
  components: {
    Loading,
  },
  props:['titulo'],
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],      
      itens: [],
      fields: [        
        { key: 'nome', label: 'Nome', sortable: true, sortDirection: 'asc', thStyle: { width: "100%" } },
      ],
    }
  },
  mounted() {
    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })
  },
  methods: {    
    confirmar() {

      let itensSelecionado = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      })

      let ret = {
        "status": "success",
        "ids": itensSelecionado
      }
      this.$emit('executou', ret)
      this.fechar()

    },    
    fechar() {
      this.hide()
    },
    async abrir(v) {      
      this.itens = v
      this.$bvModal.show('modal-select-empresa')
    },
    hide() {
      this.$bvModal.hide('modal-select-empresa')
    },
    onRowSelected(itens) {
      let ret = {
        "status": "success",
        "empresa": itens[0]
      }
      this.$emit('selecionou', ret)
      this.fechar()
    },
  }
}
</script>

<style>

</style>