<template>
  <div>

    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir() }">
      <template v-slot:texto>Confirma exclusão ?</template>
    </confirma>


    <div v-if="!edit">

      <card class="h-100 lista-escala">

        <template slot="header">
          <h4 class="card-title">Escala</h4>
        </template>

        <b-row>


          <div class="col-md-5">
            <v-select v-model="itemLocal" :options="itensLocal" label="descricao" placeholder="Selecione o local"
              @input="pesquisar">
              <span slot="no-options" @click="$refs.select.open = false">
                Nenhum registro encontrado
              </span>
            </v-select>
          </div>

        </b-row>

        <b-row>
          <div class="col-md-12 pt-2">

            <!-- Main table element -->
            <b-table empty-text="Nenhum registro" :items="itens" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty sticky-header="500px"
              small hover @row-clicked="editarEscala">

              <template #cell(actions)="row">
                <b-button size="sm" @click="editar(row.item.id)" class="mr-1" variant="success" title="Editar">
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
                <b-button size="sm" @click="confirmaExcluir(row.item.id)" class="mr-1" variant="danger" title="Excluir">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>
              </template>

            </b-table>
          </div>

        </b-row>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm">
            </b-pagination>
          </div>
        </div>
      </card>
    </div>
    <div v-else>
      <edit-escala :idEscala="idEscala" @fecharedit="() => this.edit = false"
        @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.entity) }" />
    </div>

  </div>

</template>

<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../service/config'
import AgendaService from '../../service/AgendaService'
import Confirma from '../comuns/Confirma'
import appUtils from '@/composables/utils'
import EditEscala from './EditEscala.vue'

export default {
  components: {
    LTable,
    Card,
    Confirma,
    EditEscala,
  },
  mounted() {

    const { ativarShow, desativarShow } = appUtils(this)

    this.fields = [
      { label: 'Local', key: 'dscLocal', sortable: true, type: "select-local-disabled", thStyle: { width: '25%' } },
      { label: 'Tipo', key: 'dscTipo', sortable: true, type: "select-tipo", thStyle: { width: '25%' } },
      { label: 'Lim. dia', key: 'limiteDiaStr', sortable: true, type: "duracao", thStyle: { width: '8%' } },
      { label: 'Lim. mês', key: 'limiteMesStr', sortable: true, type: "duracao", thStyle: { width: '8%' } },
      { label: 'Duração', key: 'duracaoStr', sortable: true, type: "duracao", thStyle: { width: '8%' } },
      { label: 'Hora início', key: 'horaInicio', sortable: true, type: "time", thStyle: { width: '8%' } },
      { label: 'Unidade', key: 'unidadeStr', sortable: true, type: "duracao", thStyle: { width: '8%' } },
      { label: 'Ação', key: 'actions', class: 'text-center', thStyle: { width: '10%' } }
    ]


    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    ativarShow("mounted");

    let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))
    let dadosConsulta = {
      "idUsuario": dadosUsuario.idPessoa
    }

    AgendaService.listaLocaisFilter(dadosConsulta).then(resposta => {
      this.itensLocal = resposta.data.obj
      desativarShow("mounted");
    }).catch(error => {
      desativarShow("mounted");
    })



  },
  methods: {

    pesquisar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("pesquisar")

      let idsLocal = [];
      if (this.itemLocal != null) {
        idsLocal.push(this.itemLocal.id);
      }

      let filter = {
        "idsLocal": idsLocal
      }

      AgendaService.listarEscala(filter)
        .then(resposta => {
          desativarShow("pesquisar")
          let retorno = resposta.data;
          if (retorno.status === 'SUCCESS') {
            this.itens = retorno.obj.lista
            this.totalRows = this.itens.length
          } else {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }

        }).catch(error => {
          desativarShow("pesquisar")
        })

    },
    selectAllRows() {
      this.$refs.tbLista.selectAllRows()
    },
    clearSelected() {
      this.$refs.tbLista.clearSelected()
    },
    onRowSelected(itens) {
      this.selected = itens
    },
    editTipoLocal(tipoLocal) {
      let doEdit = true;
      if (this.editingRow && !confirm("Existe registro que ainda não foi gravado, deseja continuar?")) {
        doEdit = false;
      }
      if (doEdit) {
        this.editingRow = { ...tipoLocal };
        this.itensTipoEdit = [{ id: tipoLocal.idTipo, descricao: tipoLocal.dscTipo }]
      }
    },
    saveEdit() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let tipoLocal = this.editingRow;

      tipoLocal.seg = tipoLocal.bseg ? "S" : "N";
      tipoLocal.ter = tipoLocal.bter ? "S" : "N";
      tipoLocal.qua = tipoLocal.bqua ? "S" : "N";
      tipoLocal.qui = tipoLocal.bqui ? "S" : "N";
      tipoLocal.sex = tipoLocal.bsex ? "S" : "N";
      tipoLocal.sab = tipoLocal.bsab ? "S" : "N";
      tipoLocal.dom = tipoLocal.bdom ? "S" : "N";

      ativarShow("saveEdit");

      AgendaService.salvarEscala(this.http, tipoLocal).then((resposta) => {

        let retorno = resposta.data;

        if (retorno.status === "SUCCESS") {
          desativarShow("saveEdit");
          var index = this.dadosRelatorio.lista.indexOf(this.dadosRelatorio.lista.find(l => l.id === this.editingRow.id))
          this.dadosRelatorio.lista.splice(index, 1, this.editingRow)
          toast(retorno.mensagens[0].descricao, "Sucesso", "success")
        } else {
          if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }
          desativarShow("saveEdit");
        }

        this.resetEdit()

      })
        .catch((error) => {
          desativarShow("saveEdit");
        });

    },
    excluir() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("deleteTipoLocal");

      AgendaService.excluirEscala(this.id)
        .then((resposta) => {

          let retorno = resposta.data;

          if (retorno.status === "SUCCESS") {
            desativarShow("deleteTipoLocal");
            var index = this.itens.indexOf(this.itens.find(l => l.id === this.id))
            this.itens.splice(index, 1)
            toast("Registro excluído com sucesso.", "Sucesso", "success")
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
            desativarShow("deleteTipoLocal");
          }
        })
        .catch((error) => {
          desativarShow("deleteTipoLocal");
        });

    },
    resetEdit() {
      this.editingRow = null;
    },
    confirmaExcluir(id) {
      this.id = id
      this.$refs.conf.show()
    },
    editarEscala(record, index) {
      this.editar(record.id)
    },
    editar(id) {
      this.edit = true
      this.idEscala = id
    },
    atualizarRegistro(entity) {
      let index = this.itens.indexOf(this.items.find((i) => i.id === entity.id));
      if (index >= 0) {
        this.itens.splice(index, 1, entity);
      } else {
        this.itens.push(entity);
      }
    },
  },
  data() {
    return {
      survey: null,
      http: null,
      msg: null,
      show: false,
      showFrom: "",
      filaShow: [],
      id: null,
      itensLocal: [],
      itensLocalEdit: [],
      itensTipoEdit: [],
      itemLocal: null,
      idEscala: 0,
      edit: false,
      dtInicio: null,
      dtFim: null,
      editingRow: null,
      fields: [],
      itens: [],
      showConfirma: false,
      msgConfirma: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 15, { value: 100, text: "Mostrar todos" }],
      currentPage: 1,
      selected: [],
      itemExcluir: null,
      mensagemConfirmacao: "",
      showModal: false
    }
  },
  computed: {
    rows() {
      return this.dadosRelatorio.itens === undefined ? 0 : this.dadosRelatorio.itens.length
    },
    editableFields() {
      return this.fields.filter(field => { return field.editable === true })
    }
  }
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format.replace('yyyy', date.getFullYear())
      .replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'))
      .replace('dd', date.getDate().toString().padStart(2, '0'))
      .replace('HH', date.getHours().toString().padStart(2, '0'))
      .replace('mm', date.getMinutes().toString().padStart(2, '0'))
      .replace('ss', date.getSeconds().toString().padStart(2, '0'));
    return strDate;
  }
}

</script>
<style>
.tableCustom {
  padding: 12px 8px;
  font-size: 12px;
  vertical-align: middle;
  width: 20% !important;
  ;
  height: 100%;
}
</style>
