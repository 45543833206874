<template>
  <div>    
    <card v-if="entity">
    <h4 slot="header" class="card-title"> {{ this.idUsuario > 0 ? 'Atualizar Usuário' : 'Novo Usuário' }}  </h4>
    <form>

      <div class="row">        
        <div class="col-md-3">          
          <b-form-group label="cpf" label-for="input-cpf">
            <b-form-input id="input-cpf" :readonly="!isUsuarioEmpresa" v-mask="'###.###.###-##'" atype="text" placeholder="CPF" maxlength = "14" v-model="entity.cpf" />
          </b-form-group>        
        </div>

        <div class="col-md-4">          
          <b-form-group label="Nome" label-for="input-nome">
            <b-form-input :readonly="!isUsuarioEmpresa" id="input-nome" type="text" placeholder="Nome" maxlength = "100" v-model="entity.nome" />
          </b-form-group>        
        </div>
      </div>
      <div class="row">                

        <div class="col-md-3" v-if="false">   
          <b-form-group label="Login" label-for="input-login">
            <b-form-input id="input-login" type="text" label="Login" placeholder="Login" maxlength = "100" v-model="entity.login" />
          </b-form-group>                        
        </div>

        <div class="col-md-3">   
          <b-form-group label="E-mail" label-for="input-email">
            <b-form-input id="input-email" type="text" label="E-mail" placeholder="E-mail" maxlength = "200" v-model="entity.email" />
          </b-form-group>                        
        </div>

        <div class="col-md-4" v-if="isUsuarioEmpresa">
          <b-form-group label="Grupo de usuário" label-for="input-grupo-usuario">
            <v-select v-model="entity.idGrupoUsuario" :reduce="grupo => grupo.id" label="descricao" :options="gruposUsuario" placeholder="Grupo de usuário" >
              <span slot="no-options" @click="$refs.select.open = false">Nenhum registro encontrado</span>
            </v-select>
          </b-form-group>                 
        </div>
        
      </div>

      <div class="row">
        <div class="col-md-2" v-if="isUsuarioEmpresa">
          <b-form-group label="Ativo" label-for="input-ativo">
            <b-form-checkbox v-model="entity.ativo" value="S" unchecked-value="N" switch></b-form-checkbox>
          </b-form-group>        
        </div>
      </div>

      <div class="text-center">        
        <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="salvar">
          Salvar
        </button>
        <button type="submit" class="btn btn-danger btn-fill float-right mr-1" @click.prevent="voltar">
          Fechar
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>

</div>
</template>

<script>

import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'

export default {    
    props: ['idUsuario'],
    components: {
      Card    
    },
    data() {
        return {
            http: null,
            show: false,
            showFrom: "",
            filaShow: [],            
            entity: null,    
            gruposUsuario: null,
        }
    },
  async mounted() {    

      this.http = axios.create({
        baseURL: BASE_CONSTANTS.urlBase,
        headers: {
            'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
            'Content-Type': 'application/json',
            'Accept': '*/*'
        }
      })    

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = '{ ' +
        '"idUsuario": ' + dadosUsuario.idPessoa +
        ',"idEmpresa": ' + dadosUsuario.grupo.empresa.id +        
        '}';

      ativarShow("carregarGruposUsuario");
      await AgendaService.recuperaGruposUsuario(this.http, dadosConsulta)
          .then((resposta) => {           
            this.gruposUsuario = resposta.data.obj                                        
          desativarShow("carregarGruposUsuario");
        })
        .catch((err) => {          
          desativarShow("carregarGruposUsuario");
      });
      

      if (this.idUsuario > 0) {        
        this.carregaDados();
      } else {
        this.entity = {
          "cpf": "",
          "nome": "",
          "login": "",
          "email": "",
          "senha": "",
          "validarSimultaneo": "S",
          "idGrupoUsuario": null,
          "ativo": "S"
        }
      }

    },
    methods: {
      carregaDados(){

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("carregarDados");
     
        this.show = true

      AgendaService.editUsuario(this.http, this.idUsuario)
          .then((resposta) => {           
            this.entity = resposta.data.obj                     
          this.show = false          
          desativarShow("carregarDados");
        })
        .catch((err) => {          
          this.show = false
          desativarShow("carregarDados");
        });
      },  
      voltar() {
        this.$emit('fecharedit')
      },
      salvar() {
        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("salvarUsuario");              
        AgendaService.saveUsuario(this.http, this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {            
              let ret = {
                "status": "success",
                "entity": retorno.obj
              }              
              this.$emit('executou', ret)
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
                var v = this
                setTimeout(function () {
                  v.voltar()
                }, 2000);            
            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }  
            desativarShow("salvarUsuario");
          }).catch((error) => {          
            desativarShow("salvarUsuario");
            toast(error, 'Falha', 'danger')
          });
      },       
  },
  computed: {
    isUsuarioEmpresa() {
      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      return !this.entity.id || (dadosUsuario.grupo.empresa.id === this.entity.idEmpresa)
    },
  }
    
}
</script>



<style>

</style>