<template>
  <div style="min-height: 500px;">

    <card>

      <template slot="header">
        <h4 class="card-title">Criar conta</h4>
      </template>

      <b-container class="c-form">

        <b-row>

          <div class="col-md-7">

            <form autocomplete="off">

              <b-row class="mx-0">

                <div class="col-md-3 pl-1">
                  <base-input label="CPF">
                    <b-form-input type="text" maxlength="14" placeholder="CPF" v-mask="'###.###.###-##'"
                      v-model="profissional.cpf" />
                  </base-input>
                </div>

                <div class="col-md-9">
                  <base-input label="Nome">
                    <b-form-input type="text" label="Nome" placeholder="Nome" maxlength="100"
                      v-model="profissional.nome" />
                  </base-input>
                </div>

                <div class="col-md-6">
                  <base-input label="UF CRM">
                    <b-form-select ref="ufCrm" v-model="profissional.ufCrm" :options="optionsUF" value-field="value"
                      text-field="text"></b-form-select>
                  </base-input>
                </div>

                <div class="col-md-6">
                  <base-input label="CRM">
                    <b-form-input maxlength="10" v-mask="'##########'" ref="crm" placeholder="CRM" v-model="profissional.crm" />
                  </base-input>
                </div>

                <div class="col-md-8">
                  <base-input label="E-mail pessoal">
                    <b-form-input role="presentation" autocomplete="off" id="input-email" type="email" ref="email"
                      placeholder="E-mail" v-model="profissional.email" />
                  </base-input>
                </div>

                <div class="col-md-4">
                  <base-input label="Celular">
                    <b-form-input placeholder="Celular" maxlength="14" v-mask="'(##)#####-####'" v-model="profissional.celular" />
                  </base-input>
                </div>


                <div class="col-md-3">
                  <base-input label="Senha">
                    <b-form-input type="password" autocomplete="off" placeholder="Digite sua senha"
                      v-model="profissional.senha" />
                  </base-input>
                </div>

                <div class="col-md-3">
                  <base-input label="Confirmar senha">
                    <b-form-input type="password" autocomplete="off" placeholder="Confirmar senha"
                      v-model="profissional.confirmarSenha" />
                  </base-input>
                </div>

                <div class="col-md-5">

                </div>

                <div class="col-md-12" v-if="false">

                  <p class="text-center titulo-termo">TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS</p>

                  <p>JSoftware, pessoa jurídica de direito privado, inscrita no CNPJ 22.562.787/0001-18, com sede na Av.
                    Fleming, nº 900, Salas 23 e 24, bairro Ouro Preto, Belo Horizonte/MG, CEP: 31.310-490 neste ato
                    representada na forma de seu contrato social, doravante denominada CONTROLADORA.</p>

                  <p>Este termo de consentimento foi elaborado em conformidade com a Lei Geral de Proteção de Dados.
                    Consoante ao artigo 5º inciso XII da Lei 13.709, este documento viabiliza a manifestação livre,
                    informada e inequívoca, pela qual o titular/ responsável concorda com o tratamento de seus dados
                    pessoais e os dados do menor sob os seus cuidados, para as finalidades a seguir determinadas:</p>

                  <p class="paragrafo">PARÁGRAFO PRIMEIRO - DO CONSENTIMENTO</p>

                  <p>Ao assinar ou anuir com este termo o TITULAR concorda que a CONTROLADORA, proceda com o tratamento de
                    seus dados. Entende-se por tratamento de acordo com o artigo 5º inciso X, a coleta, produção,
                    recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento,
                    arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação,
                    comunicação, transferência, difusão ou extração.</p>

                  <p class="paragrafo">PARÁGRAFO SEGUNDO - DADOS PESSOAIS</p>

                  <p>Poderão ser tratados mediante anuência expressa do titular/ responsável os seguintes dados pessoais,
                    pelo(a) controlador(a): * Nome, endereço, status civil, e-mail, endereço, comprovante de residência,
                    telefone, histórico profissional, documentos profissionais como também informações técnicas a serem
                    desenvolvidas ao longo da relação negocial.</p>

                  <p class="paragrafo">PARÁGRAFO TERCEIRO - FINALIDADE DO TRATAMENTO</p>

                  <p>Em atendimento ao artigo 8º §4 este termo guarda finalidade determinada, sendo que os dados serão
                    utilizados especificamente para fins de: * Qualificação Contratual e Análise de Dados (Relatórios
                    Gerenciais)</p>

                  <p class="paragrafo">PARÁGRAFO QUARTO - SEGURANÇA DOS DADOS</p>

                  <p>A Controladora responsabiliza-se pela manutenção de medidas de segurança, técnicas e administrativas
                    aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de
                    destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.</p>
                </div>

                <div class="col-md-12">

                  <router-link v-if="false" target="_blank" to="/termouso">Ler o termo de consentimento para tratamento de
                    dados pessoais</router-link>

                  <b-form-checkbox v-model="aceite" value="true" unchecked-value="false" size="sm" class="mt-2">
                    <div style="text-transform: none;">
                      Ao selecionar este ícone e prosseguir você está aceitando nosso
                      <a href='#/termouso/em' target='_blank'>Termo de Uso</a> e nossa
                      <a href='#/politicaprivacidade/em' target='_blank'>Política de Privacidade</a>.
                    </div>
                  </b-form-checkbox>
                </div>


                <div class="col-md-12 btn-criar text-center text-md-right">
                  <loading :show="show">
                    <b-button variant="primary" class="mr-2" size="lg" @click="login">Já sou cadastrado</b-button>
                    <b-button variant="primary" class="btn-fill" size="lg" @click.prevent="criar">Criar conta</b-button>
                  </loading>
                </div>

              </b-row>
            </form>
          </div>
        </b-row>
      </b-container>
    </card>
  </div>
</template>

<script>
import Card from 'src/components/Cards/Card.vue'
import getUF from '@/composables/getUF'
import appUtils from '@/composables/utils'
import AgendaService from '../../service/AgendaService'
import Loading from '../../layout/Loading'

export default {
  components: {
    Card,
    Loading
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      empresa: '',
      destino: null,
      possuiConta: 'N',      
      profissional: {
        cpf: null,
        email: null,
        celular: null,
        nome: null,
        ufCrm: null,
        crm: null,
        perfilProfissional: 'S',
        validaPossuiEmpresa: 'N',
        senha: null,
        confirmarSenha: null,
        possuiEmpresa: null,
        idEmpresa: null,
        credenciamento: 'N',
      },
      aceite: false,
      optionsUF: [],
    }
  },
  async mounted() {

    const { toast, ativarShow, desativarShow } = appUtils(this)

    try {

      this.destino = this.$route.query
      this.empresa = this.$route.params.empresa;
            
      this.optionsUF = getUF()

      let dadosConsulta = {
        identificador: this.empresa
      }

      ativarShow("buscarEmpresa");

      await AgendaService.buscarEmpresaPeloIdentificador(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.profissional.idEmpresa = retorno.obj.id            
            this.$store.dispatch('auth/loadEmpresa', retorno.obj);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("buscarEmpresa");
        }).catch((error) => {
          desativarShow("buscarEmpresa");
          toast(error, 'Falha', 'danger')
        });
    } catch (e) {
      toast(error, 'Falha', 'danger')
    }

  },
  methods: {
    criar() {

      const { toast } = appUtils(this)

      if (!this.aceite) {
        toast('É necessário ler e aceitar os termos', 'Falha', 'danger')
        return
      }

      this.show = true

      AgendaService.criarConta(this.profissional)
        .then((resposta) => {

          let retorno = resposta.data;

          if (retorno.status === "SUCCESS") {

            toast(retorno.mensagens[0].descricao, "Sucesso", "success")

            toast('Logando no sistema', 'Autenticando', 'info')

            let user = {
              "username": this.profissional.email,
              "password": this.profissional.senha,
            }

            this.$store.dispatch('auth/login', user).then((user) => {

              let credenciamento = this.destino && this.destino.redirect && this.destino.redirect.indexOf('credenciamento') >= 0
              let url = ''
              if (credenciamento) {
                url = { name: 'PosCriarConta', params: { empresa: this.empresa } }
              } else if (this.destino && this.destino.redirect) {
                url = this.destino.redirect;
              } else {
                url = '/admin/agenda'  
              }

              var v = this
              setTimeout(function () {
                v.$router.push(url);
              }, 2000);
            },
              error => {
                toast('Falha ao autenticar. Tente realizar o login novamente.', 'Falha', 'danger')
              }
            );

          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }

          this.show = false

        }).catch((error) => {
          this.show = false
          toast(error, 'Falha', 'danger')
        });
    },
    login() {
      if (this.destino != null) {
        this.$router.push({ name: 'Acesso', query: this.destino });
      } else {
        this.$router.push({ name: 'Acesso' });
      }
    }
  },
  limpar() {
    this.profissional.cpf = null
    this.profissional.email = null
    this.profissional.celular = null
    this.profissional.nome = null
    this.profissional.ufCrm = null
    this.profissional.crm = null
  },
}

</script>
<style>
.c-form {
  padding-bottom: 60px;
}

.link-termo {}
</style>