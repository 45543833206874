<template>
  <div>
    
    <card>
      <h4 slot="header" class="card-title"> Candidatura </h4>

      <form>

        <b-container class="c-form px-2" v-if="agenda">

          <b-row md="auto">
            <b-col>

              <b-button class="btn btn-primary btn-fill float-right" @click.prevent="voltar">
                Voltar
              </b-button>

            </b-col>
          </b-row>

          <b-row md="auto">
            <b-col>
              <p class="text-secondary"><span class="font-weight-bold">Município: </span> {{ agenda.cidade + ' - ' +
                agenda.uf }} </p>
              <p class="text-secondary"><span class="font-weight-bold">Local: </span> {{ agenda.nomeLocal }} </p>
              <p class="text-secondary"><span class="font-weight-bold">Tipo de serviço: </span> {{ agenda.dscTipo }}</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="d-flex">
              <p class="text-secondary"><span class="font-weight-bold">Data: </span> {{ strDateFormat(agenda.dtAgenda,
                'dd/MM/yyyy') }}</p>
              <p class="ml-5 text-primary text-secondary text-capitalize">( {{ agenda.diaSemana }} )</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <p class="text-secondary"><span class="font-weight-bold">Horário: </span> {{ agenda.horarioDe }}</p>
              <p class="text-secondary"><span class="font-weight-bold">Duração: </span> {{ agenda.duracaoHoraMinuto }}</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <p class="text-secondary"><span class="font-weight-bold">Valor: </span> {{ agenda.valorCompra.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL', maximumFractionDigits:2}) }}</p>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col class="text-center">              
              <b-button class="btn btn-danger btn-fill ml-1" @click.prevent="cancelar">
                Cancelar candidatura
              </b-button>
            </b-col>
          </b-row>
        </b-container>

      </form>

    </card>

  </div>
</template>

<script>

import AgendaService from '../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import appUtils from '@/composables/utils'
import PerfilProfissional from '../comuns/PerfilProfissional'

export default {
  props: ['idAgenda'],
  components: {
    Card,
    PerfilProfissional
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      agenda: null,
      idProfissionalPerfil: 0
    }
  },
  mounted() {

    if (this.idAgenda > 0) {
      this.carregaDados();
    } else {

    }
  },
  methods: {
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("abrirCandidatura");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        idAgenda: this.idAgenda
      }

      AgendaService.abrirCandidatura(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.agenda = retorno.obj;
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("abrirCandidatura");
        }).catch((error) => {
          desativarShow("abrirCandidatura");
          toast(error, 'Falha', 'danger')
        });


    },
    voltar() {
      this.$emit('fecharedit')
    },    
    cancelar() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("cancelar");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idAgenda": this.agenda.id,
        "idProfissional": dadosUsuario.idPessoa,
      }

      AgendaService.cancelarCandidatura(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "id": this.agenda.id
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("cancelar");
        }).catch((error) => {
          desativarShow("cancelar");
          toast(error, 'Falha', 'danger')
        });
    },
    strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
  },
  computed: {

  }


}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>

<style></style>