<template>
  <div class="content">
    <div class="container termo">
      <div v-if="identificadorEmpresa" class="col-md-12 p-4">
        <div v-html="texto"></div>
      </div>
    </div>
  </div>
</template>
<script>

import AgendaService from '../../service/AgendaService'
import appUtils from '@/composables/utils'

export default {
  components: {
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      identificadorEmpresa: null,
      texto: null
    }
  },
  async mounted() {

    this.identificadorEmpresa = this.$route.params.identificadorEmpresa

    if (this.identificadorEmpresa) {
      this.buscarEmpresa()
      this.buscarTermo()
    }

  },
  methods: {
    buscarTermo() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarTermo");

      let dadosConsulta = {
        identificadorEmpresa: this.identificadorEmpresa
        //"identificador": 'TCR'
      }

      AgendaService.buscaTermoUso(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data
          if (retorno.status === "SUCCESS") {
            this.texto = retorno.obj[0].arquivo;
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("buscarTermo");
        }).catch((error) => {
          desativarShow("buscarTermo");
          toast(error, 'Falha', 'danger')
        });

    },
    buscarEmpresa() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosConsulta = {
        identificador: this.identificadorEmpresa
      }

      ativarShow("buscarEmpresa");

      AgendaService.buscarEmpresaPeloIdentificador(dadosConsulta)
        .then((resposta) => {

          let retorno = resposta.data;

          if (retorno.status === "SUCCESS") {
            this.idEmpresa = resposta.data.obj.id
            this.$store.dispatch('auth/loadEmpresa', retorno.obj);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }

          desativarShow("buscarEmpresa");

        }).catch((error) => {
          desativarShow("buscarEmpresa");
          toast(error, 'Falha', 'danger')
        });
    }
  }
}
</script>
<style lang="scss">
.termo {
  background-color: white;

  .titulo-termo {
    font-size: 1.2em;
    color: #3f7bb7;
    font-weight: bold;
  }

  .paragrafo {
    color: #3f7bb7;
    font-weight: bold;
  }

}
</style>
