<template>
<div>  
    <select-profissional :unico="true" ref="selectProfissional"
      @selecionou="(r) => { selecionarProfissional(r.profissional) }"
      @cadastrou="(r) => { selecionarProfissional(r.profissional) }" />

    <editar-dados-profissional ref="editarDadosProfissional" @executou="(r) => { confirmarDadosProfissional(r) }" />

    <b-modal ref="modal-edit-agendas" id="modal-edit-agendas" size="lg" title="Alterar agendas" hide-footer no-close-on-backdrop>

      <loading :show="show">
      <div class="container-fluid">

        <b-row class="my-1">
          <div class="col-md-12">

            <b-card-body class="p-0" style="min-height: 370px;">

              <div class="container-fluid">

                <form>

                  <b-row>
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editLocal" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Local do plantão:</div>
                    <div class="col-md-8">
                      <v-select :disabled="editLocal === 'N'" v-model="agenda.idLocal" :options="listaLocal"
                        @open="buscaLocais" :on-change="localAlterado" @input="localAlterado"
                        :reduce="local => local.id" label="descricao" placeholder="Local do plantão">
                        <template #no-options>
                          Nenhum local encontrado
                        </template>
                      </v-select>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editTipo" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Tipo de serviço:</div>
                    <div class="col-md-8">
                      <v-select :disabled="editTipo === 'N'" v-model="agenda.idTipo" :options="listaTipo"
                        @open="buscaTipos" :reduce="tipo => tipo.id" label="descricao" placeholder="Tipo de serviço">
                        <template #no-options>
                          Nenhum tipo de serviço encontrado
                        </template>
                      </v-select>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editData" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Data:</div>
                    <div class="col-md-8">
                      <b-form-input type="date" :disabled="editData === 'N'" label="Data Início"
                        placeholder="Data Evento" v-model="agenda.dtAgenda" size="sm">
                      </b-form-input>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editDuracao" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Duração:</div>
                    <div class="col-md-8">
                      <b-form-input v-mask="'##:##'" :disabled="editDuracao === 'N'" label="Duração" placeholder="hh:mm"
                        v-model="agenda.duracao" size="sm">
                      </b-form-input>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editHorario" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Hora:</div>
                    <div class="col-md-8">
                      <b-form-input type="time" :disabled="editHorario === 'N'" label="Horário Início"
                        placeholder="hh:mm" v-model="agenda.horario" size="sm">
                      </b-form-input>
                    </div>
                  </b-row>

                  <b-row class="mt-2">
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editMotivoTroca" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Motivo troca:</div>
                    <div class="col-md-8">
                      <v-select v-model="agenda.idMotivoTroca" :disabled="editMotivoTroca === 'N'" :options="listaMotivoTroca"
                        :reduce="m => m.id" label="descricao" @open="preencheMotivoTroca"
                        placeholder="Selecione o motivo da troca">
                        <template #no-options>
                          Nenhum motivo de troca encontrado encontrado
                        </template>
                      </v-select>
                    </div>
                  </b-row>

                  <b-row class="mt-2">
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editProfissionalEfetivo" value="S" unchecked-value="N"
                        switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Prof. alocado:</div>
                    <div class="col-md-8">
                      <b-row class="m-0 p-0">
                        <div class="col-11 m-0 p-0">
                          <v-select v-model="agenda.idProfissionalEfetivo" :disabled="editProfissionalEfetivo === 'N'"
                            :options="listaProfissional" :reduce="prof => prof.id" label="nome"
                            @open="buscaProfissionais" placeholder="Selecione o profissional">
                            <template #no-options>
                              Nenhum profissional encontrado
                            </template>
                          </v-select>
                        </div>
                        <div class="col-1 m-0 p-0">
                          <b-button type="button" :disabled="editProfissionalEfetivo === 'N'" size="sm" class="ml-1 btn btn-info btn-fill"
                            @click="abrirSelecionaProfissional('alocado')">
                            <b-icon icon="person-plus" aria-hidden="true"></b-icon>
                          </b-button>
                        </div>
                      </b-row>

                      <b-row class="m-0 pt-1" v-if="isPncEfetivo">
                        <div class="col-11 m-0 p-0">
                          <span v-if="agenda.idProfissionalEfetivoPnc" id="input-profissional-efetivo-pnc">{{
                              agenda.nomeProfissionalEfetivoPnc
                          }}</span>
                        </div>
                      </b-row>

                    </div>
                  </b-row>

                  <b-row class="mt-2">
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editProfissional" value="S" unchecked-value="N"
                        switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Prof. do plantão:</div>
                    <div class="col-md-8">
                      <b-row class="m-0 p-0">
                        <div class="col-11 m-0 p-0">
                          <v-select v-model="agenda.idProfissional" :disabled="editProfissional === 'N'"
                            :options="listaProfissional" :reduce="prof => prof.id" label="nome"
                            @open="buscaProfissionais" placeholder="Selecione o profissional">
                            <template #no-options>
                              Nenhum profissional encontrado
                            </template>
                          </v-select>
                        </div>
                        <div class="col-1 m-0 p-0">
                          <b-button type="button" size="sm"
                            class="ml-1 btn btn-info btn-fill" :disabled="editProfissional === 'N'" @click="abrirSelecionaProfissional('plantao')">
                            <b-icon icon="person-plus" aria-hidden="true"></b-icon>
                          </b-button>
                        </div>
                      </b-row>

                      <b-row class="m-0 pt-1" v-if="isPnc">
                        <div class="col-11 m-0 p-0">
                          <span v-if="agenda.idProfissionalPnc" id="input-profissional-pnc">{{
                              agenda.nomeProfissionalPnc
                          }}</span>
                        </div>
                      </b-row>

                    </div>
                  </b-row>

                  <b-row class="mt-2">
                    <div class="col-md-1">
                      <b-form-checkbox v-model="editSituacao" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </div>
                    <div class="col-md-3 text-right pr-3 font-weight-bold">Situação:</div>
                    <div class="col-md-8">
                      <v-select v-model="agenda.idSituacao" :disabled="editSituacao === 'N'" :options="listaSituacao"
                        :reduce="sit => sit.id" label="descricao" @open="buscaSituacoes"
                        @input="alterarSituacao(agenda.idSituacao)" placeholder="Selecione a situação">
                        <template #no-options>
                          Nenhuma situação encontrada
                        </template>
                      </v-select>
                    </div>
                  </b-row>

                  <b-row class="mt-2">
                    <b-col class="col-md-1">
                      <b-form-checkbox v-model="editObs" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </b-col>
                    <b-col class="col-md-3 text-right pr-3 font-weight-bold">
                      Observação:
                    </b-col>
                    <b-col class="col-md-8">
                      <b-form-textarea id="textarea" :disabled="editObs === 'N'" v-model="agenda.obs"
                        placeholder="Informe a justificativa..." rows="1" max-rows="3" size="sm"></b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row class="mt-2">
                        <b-col class=" col-md-1">
                    <b-form-checkbox v-model="editValCompl" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </b-col>
                    <b-col class="col-md-3 text-right pr-3 font-weight-bold">
                      Valor compl.:
                    </b-col>
                    <b-col class="col-md-8">
                      <b-form-input :disabled="editValCompl === 'N'" v-model="agenda.valorCompl" v-money="money"
                        placeholder="Valor compl." size="sm" @click="$event.target.select()"></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="mt-2">
                   <b-col class=" col-md-1">
                    <b-form-checkbox v-model="editAdiantado" value="S" unchecked-value="N" switch></b-form-checkbox>
                    </b-col>
                    <b-col class="col-md-3 text-right pr-3 font-weight-bold">
                      Adiantado:
                    </b-col>
                    <b-col class="col-md-8">
                      <v-select v-model="agenda.avista" :disabled="editAdiantado === 'N'" :options="optionsAdiantado" :reduce="o => o.value" label="text">
                      </v-select>
                    </b-col>
                  </b-row>

                </form>

              </div>

            </b-card-body>

          </div>
        </b-row>
        <b-row class="my-1">
          <div class="col-md-12">            
            <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right" @click="fechar">
              Fechar
            </button>            
            <button size="sm" class="btn btn-success btn-fill float-right" @click="atualizar">
              Atualizar
            </button>            
          </div>
        </b-row>
      
      </div>
      </loading>

    </b-modal>  
</div>  
</template>

<script>

import AgendaService from "../service/AgendaService";
import { BASE_CONSTANTS } from "../service/config";
import axios from "axios";
import SelectProfissional from './comuns/SelectProfissional.vue'
import appUtils from '@/composables/utils'
import Loading from '../layout/Loading'
import EditarDadosProfissional from './comuns/EditarDadosProfissional.vue'

export default {
  components: {
    Loading,
    EditarDadosProfissional,
    SelectProfissional,
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      idsAgenda: [],
      editLocal: "N",
      editTipo: "N",
      editData: "N",
      editHorario: "N",
      editDuracao: "N",
      editProfissional: "N",
      editProfissionalEfetivo: "N",
      editSituacao: "N",
      editMotivoTroca: "N",
      editObs: "N",
      editValCompl: "N",
      editAdiantado: "N",
      mostrarProfissionalEfetivo: false,
      campoSelectProfissional: '',
      vincularProfLocal: null,
      agenda: {
        idLocal: null,
        idTipo: null,
        dtAgenda: null,
        horario: null,
        duracao: null,
        idProfissional: null,
        nomeProfissional: null,
        idProfissionalEfetivo: null,
        nomeProfissionalEfetivo: null,
        idProfissionalPnc: null,
        nomeProfissionalPnc: null,
        idProfissionalEfetivoPnc: null,
        nomeProfissionalEfetivoPnc: null,
        idSituacao: null,
        obs: null,
        valorCompl: null,
        idMotivoTroca: null,
        avista: null,
      },
      listaLocal: [],
      listaTipo: [],
      listaProfissional: [],
      listaSituacao: [],
      listaMotivoTroca: [],
      optionsAdiantado: [{text:'-', value: null}, {text:'Sim', value: 'S'}, {text:'Não', value: 'N'}],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
    }
  },
  mounted() {
    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })
  },
  methods: {
    buscaDadosManutencaoAgenda() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscaDadosAgenda");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa +
        ',"ids": [' + this.idsAgenda + "] }";

      AgendaService.buscarDadosManutencaoAgenda(this.http, dadosConsulta)
        .then((resposta) => {

          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let dados = retorno.obj

            if (dados.idLocal !== null && this.listaLocal.filter(f => f.id === dados.idLocal).length == 0) {
              this.listaLocal.push({ "id": dados.idLocal, "descricao": dados.dscLocal })
            }
            if (dados.idTipo !== null && this.listaTipo.filter(f => f.id === dados.idTipo).length == 0) {
              this.listaTipo.push({ "id": dados.idTipo, "descricao": dados.dscTipo })
            }
            if (dados.idProfissionalEfetivo !== null && this.listaProfissional.filter(f => f.id === dados.idProfissionalEfetivo).length == 0) {
              this.listaProfissional.push({ "id": dados.idProfissionalEfetivo, "nome": dados.nomeProfissionalEfetivo, "pnc": dados.efetivoPnc, "efetivo": dados.efetivoEfetivo })              
            }
            if (dados.idProfissional !== null && this.listaProfissional.filter(f => f.id === dados.idProfissional).length == 0) {
              this.listaProfissional.push({ "id": dados.idProfissional, "nome": dados.nomeProfissional, "pnc": dados.pnc, "efetivo": dados.efetivo })
            }
            if (dados.idSituacao !== null && this.listaSituacao.filter(f => f.id === dados.idSituacao).length == 0) {
              this.listaSituacao.push({ "id": dados.idSituacao, "descricao": dados.dscSituacao })
            }
            if (dados.idMotivoTroca !== null && this.listaMotivoTroca.filter(f => f.id === dados.idMotivoTroca).length == 0) {
              this.listaMotivoTroca.push({ "id": dados.idMotivoTroca, "descricao": dados.dscMotivoTroca })
            }

            this.agenda.idLocal = dados.idLocal

            this.agenda.idProfissional = dados.idProfissional
            this.agenda.idProfissionalEfetivo = dados.idProfissionalEfetivo
            this.agenda.idMotivoTroca = dados.idMotivoTroca
            this.agenda.idProfissionalPnc = dados.idProfissionalPnc
            this.agenda.nomeProfissionalPnc = dados.nomeProfissionalPnc
            this.agenda.idProfissionalEfetivoPnc = dados.idProfissionalEfetivoPnc
            this.agenda.nomeProfissionalEfetivoPnc = dados.nomeProfissionalEfetivoPnc

            this.agenda.idTipo = dados.idTipo
            this.agenda.idSituacao = dados.idSituacao
            this.agenda.dtAgenda = dados.dtAgendaStr
            this.agenda.horario = dados.horarioDe
            this.agenda.duracao = dados.duracaoHoraMinuto

          }
          desativarShow("buscaDadosAgenda");
        })
        .catch(() => {
          desativarShow("buscaDadosAgenda");
        });

    },

    atualizar() {

      const { toast, ativarShow, desativarShow, convertHoraEmMinutos } = appUtils(this)

      //ativarShow("atualizar")
      this.show = true

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = '{ ' +
        '"idUsuario": ' + dadosUsuario.idPessoa +
        ',"idLocal": ' + (this.editLocal === 'S' && this.agenda.idLocal !== null ? this.agenda.idLocal : 0) +
        ',"idTipo": ' + (this.editTipo === 'S' && this.agenda.idTipo !== null ? this.agenda.idTipo : 0) +
        ',"idProfissional": ' + (this.editProfissional === 'S' ? (this.agenda.idProfissional !== null ? this.agenda.idProfissional : -1) : 0 ) +
        ',"idProfissionalEfetivo": ' + (this.editProfissionalEfetivo === 'S' ? (this.agenda.idProfissionalEfetivo !== null ? this.agenda.idProfissionalEfetivo : -1) : 0) +
        ',"nomeProfissional": "' + (this.editProfissional === 'S' && this.agenda.nomeProfissional !== null ? this.agenda.nomeProfissional : '') + '"' +
        ',"idProfissionalPnc": ' + (this.editProfissional === 'S' ? (this.agenda.idProfissionalPnc !== null ? this.agenda.idProfissionalPnc : -1) : 0) +
        ',"idProfissionalEfetivoPnc": ' + (this.editProfissionalEfetivo === 'S' ? (this.agenda.idProfissionalEfetivoPnc !== null ? this.agenda.idProfissionalEfetivoPnc : -1) : 0) +
        ',"idSituacao": ' + (this.editSituacao === 'S' && this.agenda.idSituacao !== null ? this.agenda.idSituacao : 0) +
        ',"idMotivoTroca": ' + (this.editMotivoTroca === 'S' ? (this.agenda.idMotivoTroca !== null ? this.agenda.idMotivoTroca : -1) : 0) +
        ',"duracao": ' + (this.editDuracao === 'S' && this.agenda.duracao !== undefined ? convertHoraEmMinutos(this.agenda.duracao) : 0) +
        ',"horarioDe": "' + (this.editHorario === 'S' && this.agenda.horario !== undefined ? this.agenda.horario : '') + '"' +
        ',"dtAgendaStr": "' + (this.editData === 'S' && this.agenda.dtAgenda !== null ? this.agenda.dtAgenda : '') + '"' +
        ',"obs": "' + (this.editObs === 'S' && this.agenda.obs !== null ? this.agenda.obs : '') + '"' +
        ',"valorCompl": ' + (this.editValCompl === 'S' && this.agenda.valorCompl !== null ? parseFloat(this.agenda.valorCompl.toString().replace("R$ ", "").replace(".", "").replace(",", ".")) : -1) +
        ',"avista": "' + (this.editAdiantado === 'S' && this.agenda.avista !== null ? this.agenda.avista : '') + '"'+
        ',"idsAgenda": [' + this.idsAgenda + ']' +
        ',"confDuracao": "S"' +
        ',"confEfetivo": "S"' +
        '}'

      AgendaService.atualizarLoteAgenda(this.http, dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.fechar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          //desativarShow("atualizar");
          this.show = false
        }).catch((error) => {
          //desativarShow("atualizar");
          this.show = false
          toast(error, 'Falha', 'danger')
        });

    },
    localAlterado() {
      this.listaProfissional = this.listaProfissional.filter(f => f.id === (this.agenda.idProfissional !== null ? this.agenda.idProfissional : 0));
      this.listaTipo = this.listaTipo.filter(f => f.id === (this.agenda.idTipo !== null ? this.agenda.idTipo : 0));
      this.listaSituacao = this.listaSituacao.filter(f => f.id === (this.agenda.idSituacao !== null ? this.agenda.idSituacao : 0));
    },
    buscaLocais() {
      if (this.listaLocal.length <= 1) {

        let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

        let dadosConsulta = dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa + '}'

        AgendaService.listaLocaisFilter(dadosConsulta).then(resposta => {
          this.listaLocal = resposta.data.obj
        }).catch(error => {

        })
      }
    },
    buscaProfissionais() {

      if (this.listaProfissional.length <= 1) {

        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscaProfissionais");

        let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

        let idsLocal = [];
        if (this.agenda.idLocal != null) {
          idsLocal.push(this.agenda.idLocal);
        }

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idsLocal": idsLocal,
          "idEmpresa": dadosUsuario.grupo.empresa.id
        };

        AgendaService.listaProfissionaisFilter(this.http, dadosConsulta)
          .then((resposta) => {
            this.listaProfissional = resposta.data.obj;
            desativarShow("buscaProfissionais");
          })
          .catch(() => {
            desativarShow("buscaProfissionais");
          });
      }

    },
    buscaTipos() {

      if (this.listaTipo.length <= 1) {
        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscaTipos");

        let idsLocal = [];
        if (this.agenda.idLocal != null) {
          idsLocal.push(this.agenda.idLocal);
        }

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta =
          '{ "idUsuario": ' + dadosUsuario.idPessoa +
          ',"idsLocal": [' + idsLocal + ']' +
          ' }';

        AgendaService.listaTiposFilter(dadosConsulta)
          .then((resposta) => {
            this.listaTipo = resposta.data.obj;
            desativarShow("buscaTipos");
          })
          .catch((error) => {
            desativarShow("buscaTipos");
          });
      }
    },
    buscaSituacoes() {

      if (this.listaSituacao.length <= 1) {

        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscaSituacoes")

        let idsLocal = [];
        if (this.agenda.idLocal != null) {
          idsLocal.push(this.agenda.idLocal);
        }

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa +
          ',"idsLocal": [' + idsLocal + '] }';

        AgendaService.recuperaSituacoes(dadosConsulta)
          .then((resposta) => {
            this.listaSituacao = resposta.data.obj;
            desativarShow("buscaSituacoes");
          })
          .catch((error) => {
            desativarShow("buscaSituacoes");
          });
      }
    },
    fechar() {
      this.hide()
    },
    abrirEdicao(v) {
      this.idsAgenda = v
      this.agenda.idLocal = null
      this.agenda.idTipo = null
      this.agenda.idProfissional = null
      this.agenda.idSituacao = null
      this.agenda.nomeProfissional = null
      this.agenda.idProfissionalEfetivo = null
      this.agenda.nomeProfissionalEfetivo = null
      this.agenda.avista = null
      this.mostrarProfissionalEfetivo = false
      this.editLocal = "N"
      this.editTipo = "N"
      this.editData = "N"
      this.editHorario = "N"
      this.editDuracao = "N"
      this.editProfissional = "N"
      this.editProfissionalEfetivo = "N"
      this.editSituacao = "N"
      this.editMotivoTroca = "N"
      this.editObs = "N"
      this.editValCompl = "N"
      this.editAdiantado = "N"
      this. campoSelectProfissional = ''
      this. vincularProfLocal = null
      this.listaLocal = []
      this.listaTipo = []
      this.listaProfissional = []
      this.listaSituacao = []
      this.listaMotivoTroca = []
      this.buscaDadosManutencaoAgenda()
      this.$bvModal.show('modal-edit-agendas')      

    },
    abrirSelecionaProfissional(origem) {
      this.campoSelectProfissional = origem
      let idsEliminar = [];
      this.$refs.selectProfissional.abrir(idsEliminar)
    },
    confirmarDadosProfissional(v) {
      this.agenda.nomeProfissional = v.nomeProfissional
    },
    alterarSituacao(idSituacao) {
      let obj = this.listaSituacao.find((o) => o.id === idSituacao);
      this.mostrarProfissionalEfetivo = obj !== undefined ? obj.troca === "S" : false;
      if (this.mostrarProfissionalEfetivo) {
        if (this.agenda.idProfissionalEfetivo === null && this.agenda.idProfissional !== null) {
          let objProf = this.listaProfissional.find((o) => o.id === this.agenda.idProfissional)
          this.agenda.idProfissionalEfetivo = this.agenda.idProfissional
          this.agenda.nomeProfissionalEfetivo = objProf.nome
          this.agenda.idProfissional = null
          this.editProfissional = 'S'
        }
      } else {
        this.agenda.idProfissionalEfetivo = null
        this.agenda.nomeProfissionalEfetivo = ""
      }

    },
    selecionarProfissional(p) {

      if (this.campoSelectProfissional === 'alocado') {
        this.vincularProfLocal = null
        let prof = this.profissionalEfetivoInformado        
        if (prof && prof.pnc === 'S') {
          this.agenda.idProfissionalEfetivoPnc = p.id
          this.agenda.nomeProfissionalEfetivoPnc = p.nome
        } else {
          var profLista = this.listaProfissional.find(pl => pl.id === p.id)
          if (profLista === undefined) {
            this.listaProfissional.push({
              id: p.id,
              nome: p.nome,
              crm: p.crm,
              ufCrm: p.ufCrm,
              cpf: p.cpf
            })
            this.vincularProfLocal = 'S'
          }

          this.agenda.idProfissionalEfetivo = p.id

        }

        this.alterarProfissionalEfetivo(this.agenda.idProfissionalEfetivo)

      } else {

        this.vincularProfLocal = null
        let prof = this.profissionalInformado

        if (prof && prof.pnc === 'S') {
          this.agenda.idProfissionalPnc = p.id
          this.agenda.nomeProfissionalPnc = p.nome
        } else {
          var profLista = this.listaProfissional.find(pl => pl.id === p.id)
          if (profLista === undefined) {
            this.listaProfissional.push({
              id: p.id,
              nome: p.nome,
              crm: p.crm,
              ufCrm: p.ufCrm,
              cpf: p.cpf
            })
            this.vincularProfLocal = 'S'
          }
          
          this.agenda.idProfissional = p.id
          
        }
      }
    },
    alterarProfissionalPlantao(id) {
      this.vincularProfLocal = null
      if (!this.isPnc) {        
        this.agenda.idProfissionalPnc = null
        this.agenda.nomeProfissionalPnc = null
      }      
    },
    alterarProfissionalEfetivo(id) {
      let alterar = !this.agenda.idMotivoTroca || this.profissionalInformado === null
      
      if (alterar) {
        this.agenda.idProfissional = id;
        if (!this.agenda.idProfissionalEfetivoPnc) {
          this.agenda.idProfissionalEfetivoPnc = this.agenda.idProfissionalEfetivoPnc
          this.agenda.nomeProfissionalEfetivoPnc = this.agenda.nomeProfissionalEfetivoPnc
        }
      }
    },
    preencheMotivoTroca() {
      if (this.listaMotivoTroca.length <= 1) {

        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("preencheMotivoTroca")

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          idUsuario: dadosUsuario.idPessoa,
          idGrupoUsuario: dadosUsuario.grupo.id,
          idTipo: 4
        }

        AgendaService.listaSituacoesGeral(dadosConsulta)
          .then((resposta) => {
            this.listaMotivoTroca = resposta.data.obj;
            desativarShow("preencheMotivoTroca")
          })
          .catch((error) => {
            desativarShow("preencheMotivoTroca")
          });
      }
    },
    hide() {
      this.$bvModal.hide('modal-edit-agendas')
    }
  },
  computed: {
    profissionalEfetivoInformado() {
      let prof = null
      if (this.agenda.idProfissionalEfetivo !== null) {
        prof = this.listaProfissional.find(p => p.id === this.agenda.idProfissionalEfetivo);
      }
      return prof;
    },
    profissionalInformado() {
      let prof = null
      if (this.agenda.idProfissional !== null) {
        prof = this.listaProfissional.find(p => p.id === this.agenda.idProfissional);
      }
      return prof;
    },
    isPncEfetivo() {
      let prof = this.profissionalEfetivoInformado
      return prof != null ? prof.pnc === 'S' : false
    },
    isPnc() {
      let prof = this.profissionalInformado
      return prof != null ? prof.pnc === 'S' : false
    },
  }
}
</script>

<style>

</style>