<template>
<div>  

    <confirma ref="conf" @respondeu="(r) => { if(r === 'S') excluir(0) }" >
      <template v-slot:texto>Confirma exclusão do local?</template>
    </confirma>
 
    <div v-if="!edit">

    <card class="h-100" >

      <template slot="header">
        <h4 class="card-title">Candidaturas</h4>
      </template>
    
      <b-row>
          <div class="col-md-12">
    
    <b-table
      empty-text="Nenhum registro"
      :items="items"
      :fields="fields"
       :fixed="true" 
       :bordered="true" 
       responsive="true"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      sticky-header="500px"
      small
      hover      
      @row-clicked="abrirAgenda"
    >
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>      

      <template v-slot:cell(dtAgenda)="row">
        {{'(' + row.item.diaSemana + ') ' + strDateFormat(row.item.dtAgenda, 'dd/MM/yyyy') + ' às ' + row.item.horarioDe}}
      </template>

      <template v-slot:cell(valorCompra)="data">
        {{ data.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }) }}
      </template>

      <template #cell(actions)="row">
        
        <b-button size="sm" @click="editar(row.item.id)" class="mr-1" variant="success" title="Editar">
          <b-icon icon="pencil-square" aria-hidden="true" ></b-icon>
        </b-button>

      </template>

    </b-table>
          </div>
          </b-row>

    <div class="row">
      
        <div class="col-md-2 pl-2">
          
          <b-form-group
            label="Mostrar:"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="left"
            label-size="sm"
            
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>

      </div>
      
      <div class="col-md-10 pr-2" >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </div>

  </div>
  </card>

  </div>
  

  <div v-else>
  <edit-candidatura @showOverlay="(status) => this.$emit('showOverlay', status)" :idAgenda="idAgenda" @fecharedit="() => this.edit = false" 
  @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.id) }" />
  </div>    

</div>

</template>

<script>
  import AgendaService from '../../service/AgendaService'
  import Card from 'src/components/Cards/Card.vue'
  import appUtils from '@/composables/utils'  
  import Confirma from '../comuns/Confirma'
  import EditCandidatura from './EditCandidatura.vue'
  
  export default {
    components: {
      Card,      
      Confirma,
      EditCandidatura,
    },
    data() {
      return {
        id: null,
        mensagemEvento: null,
        show: false,
        http: null,
        showFrom: "",
        filaShow: [],
        configuracao: null,
        items: [],                
        edit: false,
        idAgenda: null,        
        itensLocal: [],
        idLocal: null,        
        dtInicio: null,
        dtFim: null,
        fields: [          
          { key: 'nomeLocal', label: 'Local', sortable: true, sortDirection: 'asc', thStyle: { width: "30%" } },
          { key: 'dtAgenda', label: 'Data/Hora', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },
          { key: 'duracaoHoraMinuto', label: 'Duração', sortable: true, sortDirection: 'asc', thStyle: { width: "8%" } },
          { key: 'valorCompra', label: 'Valor', sortable: true, sortDirection: 'asc', thStyle: { width: "8%" } },
          { key: 'dscTipo', label: 'Tipo', sortable: true, sortDirection: 'asc', thStyle: { width: "24%" } },
          { key: 'actions', label: 'Ação', thStyle: { width: "10%" } }
        ],        
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',                        
      }
    },
    computed: {
      
    },
  mounted() {               
      this.inicializar()
      this.carregaDados()
    },
  methods: {        
    inicializar() {

    
    },    
    editar(id) {        
        this.edit = true
        this.idAgenda = id        
      },
      carregaDados(){

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("listarCandidaturas");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
      }      
      
      AgendaService.listaCandidaturas(dadosConsulta)
        .then((resposta) => {           
          this.items = resposta.data.obj
          this.totalRows = this.items.length                    
          desativarShow("listarCandidaturas");
        })
        .catch((err) => {          
          desativarShow("listarCandidaturas");
        });
      },            
      confirmaExcluir(id) {
        this.id = id
        this.$refs.conf.show()        
      },
      excluir() {
        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("deleteLocal");

        AgendaService.deleteLocal(this.http, this.id).then(resposta =>
        {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {            
            this.items = this.items.filter((i) => { return i.id !== this.id })
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }  
            desativarShow("deleteLocal");
        }).catch(error =>{        
          desativarShow("deleteLocal");
          toast(error, "Ações do usuário", "danger")          
        })
      },
      atualizarRegistro(id) {
        let index = this.items.indexOf(this.items.find((i) => i.id === id));
        if (index >= 0) {
          this.items = this.items.filter((e) => { return e.id !== id })
        }
    },    
    rowClass(item, type) {
         if (!item || type !== 'row') return
        if (item.deleted === 'S') return 'table-danger'
      },
      abrirAgenda(record, index) {
        this.editar(record.id)
    },
      strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
    }
}

  function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>