<template>
  <div class="container">
    <div @click="toggleSubMenu" class="menu">
        <span>
            <i :class="icon"></i>
            {{ name }}
        </span>
        <div v-if="totalAccessCounter>0">
            <i class="bi bi-chevron-down" v-if="!showSubMenu"></i>
            <i class="bi bi-chevron-up" v-else></i>
        </div>
    </div>
    <div v-if="showSubMenu && totalAccessCounter>0">
        <template v-for="(item, index) in submenu"> 
            <div class="submenu" v-if="hasAccess(item.access)"> 
                <router-link :to="item.url" @click.native="$emit('close')"  :key="index">
                    {{ item.title }}
                </router-link>
            </div>
        </template>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            showSubMenu: false,
        }
    },

    props: {
        icon: null,
        name: null,        
        submenu: {
            type: Array
        },
    },
    methods: {
        toggleSubMenu() {
            this.showSubMenu = !this.showSubMenu;
        },
        hasAccess(menu) {
            return this.$store.getters['auth/hasAccess'](menu)
        },
    },
    computed: { 
        totalAccessCounter() {
            let counter = 0;
            for (let index = 0; index < this.submenu.length; index++) {
                if(this.hasAccess(this.submenu[index].access)) {
                    counter++;
                }
            }
            return counter;
        }
    },
}
</script>

<style scoped>
.container {
    width: 100%;
    padding: 1em;
    color: white;
}

.menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
}

.menu span {
    display: flex;
    align-items: center;
}

.menu span i {
    font-size: 1.5em;
    padding: 0 0.2em;
}

.submenu {
    width: 100%;
    padding: 1em 2em;
}

.submenu a {
    text-decoration: none;
    color: white;
}
</style>