<template>
    <b-overlay :show="show" rounded="sm">      
<b-card>

  <template slot="header">
    <h4 class="card-title">Credenciamentos</h4>
  </template>

      <b-container>        
      </b-container>
  </b-card>
  </b-overlay>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  import axios from 'axios'
  import { BASE_CONSTANTS } from '../../../service/config'
  import appUtils from '@/composables/utils'  
  import UsuarioService from '../../../service/UsuarioService'
  
  export default {
    components: {
      Card,
    },
    data () {
      return {
        http: null,
        show: false,
        showFrom: "",
        filaShow: [],                      
      }
    },    
    async mounted() {

      try {          

        this.http = axios.create({
          baseURL: BASE_CONSTANTS.urlBase,
          headers: {            
              'Content-Type': 'application/json',            
          }
        })

        this.$router.push({ name: 'Credenciamento', params: { empresa: 'rcs' } });          

        /*  let dadosConsulta = '{ "identificador": "' + this.empresa + '"}';

          const { toast, ativarShow, desativarShow } = appUtils(this)

          ativarShow("buscarEmpresa");

          await AgendaService.buscarEmpresaPeloIdentificador(this.http, dadosConsulta)
              .then((resposta) => {

                let retorno = resposta.data;
                
                if (retorno.status === "SUCCESS") {            
                  this.$store.dispatch('auth/loadEmpresa', retorno.obj);                
                } else {                        
                  if (retorno.mensagens.length > 0) {              
                    toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                  }
                }

                desativarShow("buscarEmpresa");

            }).catch((error) => {          
                desativarShow("buscarEmpresa");
                toast(error, 'Falha', 'danger')
            });

          let idEmpresa = this.$store.getters['auth/empresa'] ? this.$store.getters['auth/empresa'].id : 0

          if (idEmpresa > 0) {
            ativarShow("buscarFormularioCredenciamento");
              
              dadosConsulta = '{ "idEmpresa":' + idEmpresa  + '}';

              AgendaService.buscarFormularioCredenciamento(this.http, dadosConsulta)
                .then((resposta) => {

                  let retorno = resposta.data;
                                  
                  if (retorno.status === "SUCCESS") {            
                    this.formulario = retorno.obj;
                  } else {                        
                    if (retorno.mensagens.length > 0) {              
                      toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                    }
                  }

                  desativarShow("buscarFormularioCredenciamento");

              }).catch((error) => {          
                  desativarShow("buscarFormularioCredenciamento");
                  toast(error, 'Falha', 'danger')
              });
            }

         */

      }catch(e){
        alert(e)
      }
    },
    methods: {            
      enviarDados () {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        this.formulario.campos.filter(c => c.tipo == 'ARQUIVO')
        .forEach((c) => {
          console.log(c)
        });

        ativarShow("enviarDadosCredenciamento");              

        UsuarioService.enviarDadosCredenciamento(this.http, this.formulario)
          .then((resposta) => {

            let retorno = resposta.data;

            if (retorno.status === "SUCCESS") {            
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")              
            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }

            desativarShow("enviarDadosCredenciamento");
        }).catch((error) => {          
            desativarShow("enviarDadosCredenciamento");
            toast(error, 'Falha', 'danger')
        });        

      },
      submitFile(event) {                        
        var campo = this.formulario.campos.find(c => c.nome === event.target.id);
        var file = event.target.files[0];
        var reader = new FileReader();        
        reader.onload = (e) => {
          campo.valor = e.target.result;          
        };                
        reader.readAsDataURL(file)
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      },      
    buscarCep(event){      
      var campo = this.formulario.campos.find(c => c.nome === event.target.id);      
      var self = this;
      var cep = event.target.value.replace('-', '')
      
      let valit = /^[0-9]{5}-[0-9]{3}$/.test(cep)
      let tamanhoString = cep.length
      if(tamanhoString == 8){
        let urlBusca = "https://viacep.com.br/ws/" + cep + "/json/"
        axios.get(urlBusca).then(endereco => {          
          this.$set(campo.valores, 0, endereco.data.cep)          
          campo.valores[1] = endereco.data.logradouro
          campo.valores[4] = endereco.data.bairro          
          campo.valores[5] = endereco.data.localidade
          campo.valores[6] = endereco.data.uf
          //campo.valor = campo.valores.reduce((a, b) => { return a + '|' + b })          
        }).catch(e => {
          console.log('Cep não encontrato')          
        })
      }
      },
      changeMascara: function(e) {
        this.mascara = e.length <= 14 ? '(##) ####-####' : '(##) #####-####'        
      },      
  },
  computed: {

  }    
 
}
  
</script>
<style>
</style>