<template>
  <b-modal ref="modal-select-local" id="modal-select-local" size="lg" title="Selecionar local" hide-footer
    no-close-on-backdrop>
    <div class="container-fluid">

      <b-row class="my-1">
        <div class="col-md-12">

          <b-card-body class="p-0" style="min-height: 370px;">

            <div class="container-fluid">

              <b-row>
                <b-col lg="12" class="my-1">
                  <b-form-group label="Filtrar" label-for="filter-input" label-cols-sm="2" label-align-sm="right"
                    label-size="sm" class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input id="filter-input" v-model="filter" type="search"
                        placeholder="Digite para filtrar"></b-form-input>
                      <b-input-group-append v-if="filter">
                        <b-button @click="filter = ''">Limpar</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>

                <b-table :items="itens" :fields="fields" :fixed="true" sticky-header="400px" :filter="filter"
                  :filter-included-fields="filterOn">

                  <template #head(seleciona)>
                    <b-form-checkbox v-model="todosSelecionados" value="S" unchecked-value="N"
                      @input="selecionarTodos"></b-form-checkbox>
                  </template>

                  <template v-slot:cell(seleciona)="row">
                    <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
                  </template>

                  <template #cell()="data">
                    {{ data.value }}
                  </template>

                </b-table>
              </b-row>

            </div>

          </b-card-body>

        </div>
      </b-row>

      <b-row class="my-1">
        <div class="col-md-12">
          <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right" @click="fechar">
            Fechar
          </button>
          <button size="sm" class="btn btn-success btn-fill float-right" @click="confirmar">
            Confirmar
          </button>
        </div>
      </b-row>
    </div>

  </b-modal>
</template>

<script>

import AgendaService from "../../service/AgendaService";
import { BASE_CONSTANTS } from "../../service/config";
import axios from "axios";
import appUtils from '@/composables/utils'

export default {
  data() {
    return {
      http: null,
      show: false,
      filaShow: [],
      showFrom: "",
      idsEliminar: [],
      itens: [],
      todosSelecionados: 'N',
      filter: null,
      filterOn: ['descricao'],
      fields: [
        { key: 'seleciona', type: "check", thStyle: { width: "8%" } },
        { key: 'descricao', label: 'Descrição', sortable: true, sortDirection: 'asc', thStyle: { width: "92%" } },
      ],
    }
  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })
  },
  methods: {

    async buscaLocais() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = '{ ' +
        '"idUsuario": ' + dadosUsuario.idPessoa +
        (this.idsEliminiar !== null ? ',"idsEliminar": [' + this.idsEliminar + ']' : '') +
        '}';

      return AgendaService.listaLocaisFilter(dadosConsulta).then(resposta => {
        this.itens = resposta.data.obj
      }).catch(error => {

      })

    },
    selecionarTodos(checked) {
      this.itens.forEach((item) => {
        item.seleciona = checked === 'S' ? 'S' : 'N';
      });
    },
    confirmar() {

      let idsSelecionado = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => ({ id: elem.id, descricao: elem.descricao }));

      let ret = {
        "status": "success",
        "ids": idsSelecionado
      }
      this.$emit('executou', ret)
      this.fechar()
    },
    fechar() {
      this.hide()
    },
    async abrir(v) {
      this.itens = []
      this.idsEliminar = v
      await this.buscaLocais()
      this.$bvModal.show('modal-select-local')
    },
    hide() {
      this.$bvModal.hide('modal-select-local')
    }
  }
}
</script>

<style></style>