<template>
<div>  

    <confirma ref="conf" @respondeu="(r) => { if(r === 'S') excluir(0) }" >
      <template v-slot:texto>Confirma exclusão do local?</template>
    </confirma>
 
    <div v-if="!edit">

    <card class="h-100" >

      <template slot="header">
        <h4 class="card-title">Aprovação de candidatos</h4>
      </template>
    
    <div class="row">
                  <div class="col-md-4">                    
                    
                    <v-select v-model="idLocal"
                      ref="select"
                      :options="itensLocal"
                      :reduce="local => local.id"
                      label="descricao"                      
                      placeholder="Local do plantão" >
                      <span slot="no-options" @click="$refs.select.open = false">
                        Nenhum registro encontrado
                      </span>
                    </v-select>

                  </div>
                  
                  <div class="col-md-4">                    
                    <b-input-group>             
                      <template #prepend>
                        
                      </template>
                      

                      <b-form-datepicker id="dtInicio" 
                      v-model="dtInicio" 
                      size="sm" 
                      class="mb-2"
                      placeholder="Início do período"
                      today-button
                      reset-button                    
                      close-button               
                      today-button-variant="primary"
                      reset-button-variant="danger"
                      close-button-variant="success"
                      label-no-date-selected="Nenhuma data selecionada"
                      label-today-button="Hoje"
                      label-reset-button="Limpar"
                      label-close-button="Fechar"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }">
                      </b-form-datepicker>

                      <b-input-group-text class="mb-2">até</b-input-group-text>                       

                        <b-form-datepicker id="dtFim" 
                    v-model="dtFim" 
                    size="sm" 
                    class="mb-2"
                    placeholder="Início do período"
                    today-button
                    reset-button                    
                    close-button               
                    today-button-variant="primary"
                    reset-button-variant="danger"
                    close-button-variant="success"
                    label-no-date-selected="Nenhuma data selecionada"
                    label-today-button="Hoje"
                    label-reset-button="Limpar"
                    label-close-button="Fechar"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    >
                    </b-form-datepicker>                     

                    </b-input-group>
                  </div>

                  <div class="col-md-3 h-100">                    
                    <b-button size="md" variant="primary" class="btn btn-primary btn-fill" @click="carregaDados">                    
                      Buscar
                    </b-button>                                        
                  </div>
                </div>
     

     <b-row >
       <b-col cols="12" class="pt-2">

    <!-- Main table element -->
    <b-table
      empty-text="Nenhum registro"
      :items="items"
      :fields="fields"
       :fixed="true" 
       :bordered="true" 
       responsive="true"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      sticky-header="500px"
      small
      hover      
      @row-clicked="abrirAgenda"
    >
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>      

      <template v-slot:cell(dtAgenda)="row">
        {{strDateFormat(row.item.dtAgenda, 'dd/MM/yyyy')}}
      </template>

      <template #cell(actions)="row">
        
        <b-button size="sm" @click="editar(row.item.id)" class="mr-1" variant="success" title="Editar">
          <b-icon icon="pencil-square" aria-hidden="true" ></b-icon>
        </b-button>

      </template>

    </b-table>
       </b-col>

    </b-row>

    <div class="row">
      
        <div class="col-md-2 pl-2">
          
          <b-form-group
            label="Mostrar:"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="left"
            label-size="sm"
            
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>

      </div>
      
      <div class="col-md-10 pr-2" >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </div>

  </div>
  </card>

  </div>
  

  <div v-else>
  <edit-candidato @showOverlay="(status) => this.$emit('showOverlay', status)" :idAgenda="idAgenda" @fecharedit="() => this.edit = false" 
  @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.id) }" />
  </div>    

  <div class="clearfix"></div>
</div>

</template>

<script>
  import AgendaService from '../../service/AgendaService'
  import Card from 'src/components/Cards/Card.vue'
  import axios from 'axios'
  import { BASE_CONSTANTS } from '../../service/config'
  import appUtils from '@/composables/utils'  
  import Confirma from '../comuns/Confirma'
  import EditCandidato from './EditCandidato.vue'
  
  export default {
    components: {
      Card,      
      Confirma,
      EditCandidato,
    },
    data() {
      return {
        id: null,
        mensagemEvento: null,
        show: false,
        http: null,
        showFrom: "",
        filaShow: [],
        configuracao: null,
        items: [],                
        edit: false,
        idAgenda: null,        
        itensLocal: [],
        idLocal: null,        
        dtInicio: null,
        dtFim: null,
        fields: [          
          { key: 'nomeLocal', label: 'Local', sortable: true, sortDirection: 'asc', thStyle: { width: "50%" } },
          { key: 'dtAgenda', label: 'Dt. agenda', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
          { key: 'dscTipo', label: 'Tipo', sortable: true, sortDirection: 'asc', thStyle: { width: "25%" } },          
          { key: 'actions', label: 'Ação', thStyle: { width: "25%" } }
        ],        
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',                        
      }
    },
    computed: {
      
    },
    mounted() {
      this.http = axios.create({
        baseURL: BASE_CONSTANTS.urlBase,
        headers: {
            'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
            'Content-Type': 'application/json',
            'Accept': '*/*'
        }
      })      
      this.inicializar()
      this.carregaDados()
    },
  methods: {        
    inicializar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("inicializar");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))
            
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "coordenador": "S"
      }      
      

      AgendaService.listaLocaisFilter(dadosConsulta)
        .then(resposta => {
          this.itensLocal = resposta.data.obj
          desativarShow("inicializar");
      }).catch(error => {
          desativarShow("inicializar");          
      })
    
    },    
    editar(id) {        
        this.edit = true
        this.idAgenda = id        
      },
      carregaDados(){

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("listarCandidatos");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id
      }      
      
      AgendaService.listaAprovacaoCandidato(this.http, dadosConsulta)
        .then((resposta) => {           
          this.items = resposta.data.obj
          this.totalRows = this.items.length                    
          desativarShow("listarCandidatos");
        })
        .catch((err) => {          
          desativarShow("listarCandidatos");
        });
      },      
      salvarConfiguracao() {        
        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("salvarConfiguracao");              
        AgendaService.saveConfiguracaoLocal(this.http, this.configuracao)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {            
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
              this.$bvModal.hide('modal-configuracao')
            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }              
            desativarShow("salvarConfiguracao");
          }).catch((error) => {          
            desativarShow("salvarConfiguracao");
            toast(error, 'Falha', 'danger')
          });
      },
      confirmaExcluir(id) {
        this.id = id
        this.$refs.conf.show()        
      },
      excluir() {
        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("deleteLocal");

        AgendaService.deleteLocal(this.http, this.id).then(resposta =>
        {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {            
            this.items = this.items.filter((i) => { return i.id !== this.id })
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }  
            desativarShow("deleteLocal");
        }).catch(error =>{        
          desativarShow("deleteLocal");
          toast(error, "Ações do usuário", "danger")          
        })
      },
      atualizarRegistro(id) {
        let index = this.items.indexOf(this.items.find((i) => i.id === id));
        if (index >= 0) {
          this.items = this.items.filter((e) => { return e.id !== id })
        }
    },
    abrirSelecionaProfissional() {    
        const { toast } = appUtils(this)
        let idsEliminar = this.locais.locais.map(elem => elem.idLocal);
        this.$refs.selectLocal.abrir(idsEliminar)      
      },    
    adicionarProfissional(locais) {
        locais.forEach((item) => {              
            
          let reg = {
            idLocal:  item.id,
            dscLocal: item.descricao,            
          }

          this.locais.locais.push(reg);

        });          
    },
    rowClass(item, type) {
         if (!item || type !== 'row') return
        if (item.deleted === 'S') return 'table-danger'
      },
      abrirAgenda(record, index) {
        this.editar(record.id)
    },
      strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
    }
}

  function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>