<template>
  <div>

    <!-- b-modal ref="modal-configuracao" id="modal-configuracao" title="Configurações" size="xl">

      <loading :show="show">
        <div style="height: 450px;">
          <b-tabs content-class="mt-2">
            <b-tab title="Situações">
              <b-table v-if="configuracao" :items="configuracao.situacoes" :fields="fieldsSit" sticky-header="true" >
                <template v-slot:cell(seleciona)="row">
                  <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
                </template>
                <template v-slot:cell(padrao)="row">
                  <b-form-checkbox v-model="row.item.padrao" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(furo)="row">
                  <b-form-checkbox v-model="row.item.furo" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(previsto)="row">
                  <b-form-checkbox v-model="row.item.previsto" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(executado)="row">
                  <b-form-checkbox v-model="row.item.executado" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(troca)="row">
                  <b-form-checkbox v-model="row.item.troca" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(possuiObs)="row">
                  <b-form-checkbox v-model="row.item.possuiObs" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(possuiValCompl)="row">
                  <b-form-checkbox v-model="row.item.possuiValCompl" value="S" unchecked-value="N"
                    switch></b-form-checkbox>
                </template>
                <template v-slot:cell(exportar)="row">
                  <b-form-checkbox v-model="row.item.exportar" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(divulga)="row">
                  <b-form-checkbox v-model="row.item.divulga" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(avista)="row">
                  <b-form-checkbox v-model="row.item.avista" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(validarSimultaneo)="row">
                  <b-form-checkbox v-model="row.item.validarSimultaneo" value="S" unchecked-value="N"
                    switch></b-form-checkbox>
                </template>
                <template v-slot:cell(pago)="row">
                  <b-form-checkbox v-model="row.item.pago" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(faturar)="row">
                  <b-form-checkbox v-model="row.item.faturar" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>                
                <template v-slot:cell(aberto)="row">
                  <b-form-select v-model="row.item.aberto" :options="itensAberto" size="sm" style="width: 80px;">
                  </b-form-select>
                </template>                
                <template v-slot:cell(checkin)="row">
                  <b-form-checkbox v-model="row.item.checkin" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(checkout)="row">
                  <b-form-checkbox v-model="row.item.checkout" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(pedidoDesistencia)="row">
                  <b-form-checkbox v-model="row.item.pedidoDesiste" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
            </b-tab>

            <b-tab title="Situações de fechamento">

              <b-table v-if="configuracao" :items="configuracao.situacoesFechamento" :fields="fieldsSitFat" :fixed="true"
                sticky-header="400px">

                <template v-slot:cell(seleciona)="row">
                  <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
                </template>

                <template v-slot:cell(padrao)="row">
                  <b-form-checkbox v-model="row.item.padrao" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(faturar)="row">
                  <b-form-checkbox v-model="row.item.faturar" value="S" unchecked-value="N" switch></b-form-checkbox>
                </template>
                <template v-slot:cell(atualizarPreco)="row">
                  <b-form-checkbox v-model="row.item.atualizarPreco" value="S" unchecked-value="N"
                    switch></b-form-checkbox>
                </template>

                <template #cell()="data">
                  {{ data.value }}
                </template>

              </b-table>

            </b-tab>

            <b-tab title="Profissionais">

              <b-table v-if="configuracao" :items="configuracao.profissionais" :fields="fieldsProfissional" :fixed="true"
                sticky-header="350px" :tbody-tr-class="rowClass">

                <template #cell()="data">
                  {{ data.value }}
                </template>

                <template #cell(actions)="row">
                  <b-button size="sm" @click="row.item.deleted = row.item.deleted == 'S' ? 'N' : 'S'" class="mr-1"
                    variant="danger" title="Excluir">
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>

              </b-table>

              <b-row>
                <div class="col-md-12">

                  <div class="w-100">
                    <b-button variant="info" size="sm" class="btn-fill" @click="abrirSelecionaProfissional">
                      Adicionar
                    </b-button>
                  </div>

                </div>

              </b-row>

            </b-tab>

          </b-tabs>
        </div>
      </loading>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right btn-fill" @click="salvarConfiguracao" :disabled="show">
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal -->

    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir(0) }">
      <template v-slot:texto>Confirma exclusão do local?</template>
    </confirma>

    <div v-if="!edit">

      <card class="h-100">

        <template slot="header">
          <h4 class="card-title">Locais</h4>
        </template>

        <div class="row">

          <b-col class="col-md-6">
            <b-form-group label="Filtrar: " label-for="filter-input" label-cols-sm="1" label-align-sm="left"
              label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Digite aqui sua pesquisa"
                  v-on:keydown.enter="buscar"></b-form-input>

              </b-input-group>
            </b-form-group>

          </b-col>

          <b-col class="col-md-6 px-3">
            <b-button class="mr-1" size="sm" variant="warning" @click="editar(0)">
              <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
            </b-button>
          </b-col>

        </div>

        <div class="row" v-if="dscPai.length > 0">
          <div class="col-md-12 pt-2">
            <b-breadcrumb>
              <b-breadcrumb-item v-for="(id, i) in idPai" :key="id"
                @click.prevent="voltar(id)">{{ dscPai[i] }}</b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>

        <b-row>
          <b-col cols="12" class="pt-2">

            <!-- Main table element -->
            <b-table empty-text="Nenhum registro" :items="items" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty sticky-header="500px"
              small hover @row-clicked="abrirLocalFilho">
              <template #cell(name)="row">
                {{ row.value.first }} {{ row.value.last }}
              </template>

              <template #cell(actions)="row">

                <b-button size="sm" @click="editar(row.item.id)" class="mr-1" variant="success" title="Editar">
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
                <b-button size="sm" @click="confirmaExcluir(row.item.id)" class="mr-1" variant="danger" title="Excluir">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>
                <b-button size="sm" variant="primary" class="mr-1" @click="abrirConfiguracao(row.item)" title="Configuração" v-if="false">
                  <b-icon icon="gear-wide-connected" aria-hidden="true"></b-icon>
                </b-button>

              </template>

            </b-table>
          </b-col>

        </b-row>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
              size="sm"></b-pagination>
          </div>

        </div>
      </card>

    </div>

    <div v-else>
      <edit-local @showOverlay="(status) => this.$emit('showOverlay', status)" :idLocal="idLocal" :idPai="idLocalPai"
        @fecharedit="() => this.edit = false"
        @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.entity) }" />
    </div>

    <div class="clearfix"></div>
  </div>
</template>

<script>
import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'
import EditLocal from './EditLocal'
import Confirma from '../../comuns/Confirma'
import Loading from '../../../layout/Loading'

export default {
  components: {
    Card,
    EditLocal,
    Confirma,
    Loading,
  },
  data() {
    return {
      id: null,
      mensagemEvento: null,
      show: false,
      http: null,
      showFrom: "",
      filaShow: [],
      configuracao: null,
      items: [],
      idPai: [],
      dscPai: [],
      idLocalPai: null,
      edit: false,
      idLocal: null,
      fields: [
        { key: 'descricao', label: 'Descrição', sortable: true, sortDirection: 'asc', thStyle: { width: "80%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "20%" } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      itensAberto: [{ "value": "S", "text": "Sim" }, { "value": "N", "text": "Não" }, { "value": "A", "text": "Ambos" }],
      fieldsSit: [
      { key: 'dscSituacao', label: 'Situação', sortable: true, stickyColumn: true, variant: 'primary', sortDirection: 'asc', thStyle: { width: "30%" } },
        { key: 'seleciona', type: "check", thStyle: { width: "8%" } },        
        { key: 'padrao', label: 'Padrão', type: "check", thStyle: { width: "10%" } },
        { key: 'furo', label: 'Furo', type: "check", thStyle: { width: "10%" } },
        { key: 'previsto', label: 'Previsto', type: "check", thStyle: { width: "10%" } },
        { key: 'executado', label: 'Executado', type: "check", thStyle: { width: "10%" } },
        { key: 'troca', label: 'Troca', type: "check", thStyle: { width: "10%" } },
        { key: 'possuiObs', label: 'Obs', type: "check", thStyle: { width: "10%" } },
        { key: 'possuiValCompl', label: 'V. compl.', type: "check", thStyle: { width: "12%" } },
        { key: 'exportar', label: 'Exportar', type: "check", thStyle: { width: "10%" } },
        { key: 'avista', label: 'Avista', type: "check", thStyle: { width: "10%" } },
        { key: 'divulga', label: 'Divulga', type: "check", thStyle: { width: "10%" } },
        { key: 'pago', label: 'Pago', type: "check", thStyle: { width: "10%" } },
        { key: 'faturar', label: 'Faturar', type: "check", thStyle: { width: "10%" } },        
        { key: 'validarSimultaneo', label: 'Simult.', type: "check", thStyle: { width: "10%" } },
        { key: 'aberto', label: 'Aberto', type: "check", thStyle: { width: "40%" } },
        { key: 'checkin', label: 'Checkin', type: "check", thStyle: { width: "10%" } },
        { key: 'checkout', label: 'Checkout', type: "check", thStyle: { width: "10%" } },
        { key: 'pedidoDesistencia', label: 'Desiste', type: "check", thStyle: { width: "10%" } },        
      ],
      fieldsSitFat: [
        { key: 'seleciona', type: "check", thStyle: { width: "8%" } },
        { key: 'dscSituacao', label: 'Situação', sortable: true, sortDirection: 'asc', thStyle: { width: "68%" } },
        { key: 'padrao', label: 'Padrão', type: "check", thStyle: { width: "8%" } },
        { key: 'faturar', label: 'Faturar', type: "check", thStyle: { width: "8%" } },
        { key: 'atualizarPreco', label: 'Preço', type: "check", thStyle: { width: "8%" } },
      ],
      fieldsUsuario: [
        { key: 'seleciona', type: "check" },
        { key: 'nome', label: 'Situação', sortable: true, sortDirection: 'asc' },
      ],
      fieldsProfissional: [
        { key: 'nome', label: 'Nome', sortable: true, thStyle: { width: "40%" } },
        { key: 'crm', label: 'Crm', sortable: true, thStyle: { width: "20%" } },
        { key: 'cpf', label: 'cpf', sortable: true, thStyle: { width: "20%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "20%" } }
      ],
    }
  },
  computed: {
    hierarquia() {
      var path = ''
      this.dscPai.forEach((desc) => {
        path += (path != '' ? '/' : '') + desc
      })
      return path
    }
  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    this.carregaDados()

  },
  methods: {
    abrirLocalFilho(record, index) {
      this.idPai.push(record.id);
      this.dscPai.push(record.descricao);
      this.carregaDados();
    },
    voltar(id) {
      console.log(id)
      var index = this.idPai.indexOf(id)
      this.idPai.splice(index, this.idPai.length - index)
      this.dscPai.splice(index, this.idPai.length - index);
      this.carregaDados();
    },
    editar(id) {
      this.edit = true
      this.idLocal = id
      this.idLocalPai = this.idPai.length > 0 ? this.idPai[this.idPai.length - 1] : null
    },
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarLocais");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = '{ ' +
        '"idUsuario": ' + dadosUsuario.idPessoa +
        (this.idPai.length > 0 ? ',"idPai": ' + this.idPai[this.idPai.length - 1] : '') +
        ',"descricao": "' + this.filter + '"' +
        '}';


      AgendaService.listaLocaisFilter(dadosConsulta)
        .then((resposta) => {
          this.items = resposta.data.obj
          this.totalRows = this.items.length
          dadosConsulta = '{}'
          this.show = false
          desativarShow("listarLocais");
        })
        .catch((err) => {
          desativarShow("listarLocais");
        });

    },
    buscar() {
      this.idPai = [];
      this.carregaDados();
    },
    abrirConfiguracao(record) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("abrirConfiguracao");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      this.dados = []
      this.show = true

      let dadosConsulta = '{ ' +
        '"idUsuario": ' + dadosUsuario.idPessoa +
        ',"idEmpresa": ' + dadosUsuario.grupo.empresa.id +
        ',"idLocal": ' + record.id +
        '}';

      AgendaService.buscarConfiguracaoLocal(this.http, dadosConsulta)
        .then((resposta) => {

          this.configuracao = resposta.data.obj

          this.optionsSituacao = this.configuracao.situacoes.map(s => {
            return { text: s.dscSituacao, value: s.id }
          })

          this.$bvModal.show("modal-configuracao")

          desativarShow("abrirConfiguracao");

        }).catch((err) => {
          this.show = false
          desativarShow("abrirConfiguracao");
        });

    },
    salvarConfiguracao() {

      const { toast } = appUtils(this)
      this.show = true
      AgendaService.saveConfiguracaoLocal(this.http, this.configuracao)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            this.$bvModal.hide('modal-configuracao')
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          this.show = false
        }).catch((error) => {
          this.show = false
          toast(error, 'Falha', 'danger')
        });
    },
    confirmaExcluir(id) {
      this.id = id
      this.$refs.conf.show()
    },
    excluir() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("deleteLocal");

      AgendaService.deleteLocal(this.http, this.id).then(resposta => {
        let retorno = resposta.data;
        if (retorno.status === "SUCCESS") {
          this.items = this.items.filter((i) => { return i.id !== this.id })
          toast(retorno.mensagens[0].descricao, "Sucesso", "success")
        } else {
          if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }
        }
        desativarShow("deleteLocal");
      }).catch(error => {
        desativarShow("deleteLocal");
        toast(error, "Ações do usuário", "danger")
      })
    },
    atualizarRegistro(entity) {
      let index = this.items.indexOf(this.items.find((i) => i.id === entity.id));
      if (index >= 0) {
        this.items.splice(index, 1, entity);
      } else {
        this.items.push(entity);
      }
    },
    abrirSelecionaProfissional() {
      const { toast } = appUtils(this)
      let idsEliminar = this.configuracao.profissionais.map(elem => elem.id);
      this.$refs.selectProfissional.abrir(idsEliminar)
    },
    adicionarProfissional(regs) {
      regs.forEach((item) => {

        let reg = {
          idPessoa: item.id,
          crm: item.crm,
          cpf: item.cpf,
          nome: item.nome,
          deleted: 'N'
        }

        this.configuracao.profissionais.push(reg);

      });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.deleted === 'S') return 'table-danger'
    }
  }
}
</script>