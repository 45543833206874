<template>
<div>
    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir(0) }">
      <template v-slot:texto>Confirma exclusão do usuário?</template>
    </confirma>

    <div v-if="!edit">

      <card class="h-100">

        <template slot="header">
          <h4 class="card-title">Classificação</h4>
        </template>

        <div class="row">

          <b-col class="col-md-6">
            <b-form-group label="Filtrar: " label-for="filter-input" label-cols-sm="1" label-align-sm="left"
              label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Digite aqui sua pesquisa"
                  v-on:keydown.enter="buscar"></b-form-input>

              </b-input-group>
            </b-form-group>

          </b-col>

          <b-col class="col-md-6 px-3">
            <b-button class="mr-1" size="sm" variant="primary" @click="novo">
              <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
            </b-button>
          </b-col>

        </div>

        <div class="row">
          <div class="col-md-12 pt-2">

            <!-- Main table element -->
            <b-table empty-text="Nenhum registro" :items="items" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty small hover
              @row-clicked="editarClassificacao">

              <template #cell(actions)="row">

                <b-button size="sm" @click="editar(row.item.id)" class="mr-1" variant="success" title="Editar">
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
                <b-button size="sm" @click="confirmaExcluir(row.item.id)" class="mr-1" variant="danger" title="Excluir">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>
              </template>
            </b-table>
          </div>

        </div>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
              size="sm"></b-pagination>
          </div>
        </div>
      </card>
    </div>

    <div v-else>
      <edit-tag @showOverlay="(status) => this.$emit('showOverlay', status)" :id="id" @fecharedit="() => this.edit = false"
        @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.entity) }" />
    </div>
    <div class="clearfix"></div>
</div>
</template>

<script>
import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'
import Confirma from '../../comuns/Confirma'
import EditTag from './EditClassificacao.vue'

export default {
  components: {
    Card,
    EditTag,
    Confirma
  },
  data() {
    return {
      id: null,
      show: false,
      http: null,
      showFrom: "",
      filaShow: [],
      configuracao: null,
      items: [],
      edit: false,
      fields: [
        { key: 'descricao', label: 'Descrição', sortable: true, sortDirection: 'asc', thStyle: { width: "80%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "20%" } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      locais: null,
      fieldsLocal: [
        { key: 'seleciona', type: "check", thStyle: { width: "10%" } },
        { key: 'dscLocal', label: 'Local', sortable: true, sortDirection: 'asc', thStyle: { width: "70%" } },
        { key: 'coordenador', label: 'Coordenador', type: "check", thStyle: { width: "20%" } },
      ],
    }
  },
  computed: {

  },
  mounted() {
    this.carregaDados()
  },
  methods: {
    novo() {
      this.edit = true
      this.id = 0
    },
    editarClassificacao(record, index) {
      this.editar(record.id)
    },
    editar(id) {
      this.edit = true
      this.id = id
    },
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarClassificacao");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "descricao": this.filter
      }

      AgendaService.listaClassificacao(dadosConsulta)
        .then((resposta) => {
          this.items = resposta.data.obj
          this.totalRows = this.items.length
          desativarShow("listarClassificacao");
        })
        .catch((err) => {
          desativarShow("listarClassificacao");
        });
    },
    buscar() {
      this.carregaDados();
    },
    confirmaExcluir(id) {
      this.id = id
      this.$refs.conf.show()
    },
    excluir() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("deleteClassificacao");

      AgendaService.deleteClassificacao(this.id)
        .then(resposta => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.items = this.items.filter((i) => { return i.id !== this.id })
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("deleteClassificacao");
        }).catch(error => {
          desativarShow("deleteClassificacao");
          toast(error, "Falha", "danger")
        })
    },
    atualizarRegistro(entity) {
      let index = this.items.indexOf(this.items.find((i) => i.id === entity.id));
      if (index >= 0) {
        this.items.splice(index, 1, entity);
      } else {
        this.items.push(entity);
      }
    }
  }
}
</script>