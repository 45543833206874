<template>

    <card v-if="entity">
    <h4 slot="header" class="card-title"> {{ this.id > 0 ? 'Atualizar Tag' : 'Nova Tag' }}  </h4>
    <form>

      <div class="row" >                
        <div class="col-md-5">
          <b-form-group label="Tipo" label-for="input-tipo">
            <v-select v-model="entity.idTipo" :reduce="tipo => tipo.id" label="descricao" :options="tipos" placeholder="Tipo" >
              <span slot="no-options" @click="$refs.select.open = false">Nenhum registro encontrado</span>
            </v-select>
          </b-form-group>                 
        </div>
      </div>

      <div class="row">        
        <div class="col-md-5">          
          <b-form-group label="descricao" label-for="input-descricao">
            <b-form-input id="input-descricao" type="text" placeholder="Descrição" maxlength = "100" v-model="entity.descricao" />
          </b-form-group>        
        </div>
      </div>  

      <div class="text-center">        
        <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="salvar">
          Salvar
        </button>
        <button type="submit" class="btn btn-danger btn-fill float-right mr-1" @click.prevent="voltar">
          Fechar
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>

</template>

<script>

import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import appUtils from '@/composables/utils'

export default {    
    props: ['id'],
    components: {
      Card    
    },
    data() {
        return {
            show: false,
            showFrom: "",
            filaShow: [],            
            entity: null,    
            tipos: [],
        }
    },
  async mounted() {    

    await this.buscarTipos()

      if (this.id > 0) {        
        this.carregaDados();
      } else {

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        this.entity = {
          "descricao": "",   
          "idTipo": null,
          "idEmpresa": dadosUsuario.grupo.empresa.id,
        }

        if (this.tipos.length == 1) {
          this.entity.idTipo = this.tipos[0].id
        }

      }

    },
    methods: {
      carregaDados(){

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("carregarDados");

      AgendaService.editClassificacao(this.id)
          .then((resposta) => {           
          this.entity = resposta.data.obj          
          desativarShow("carregarDados");
        })
        .catch((err) => {                    
          desativarShow("carregarDados");
        });
      },
      async buscarTipos() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscarTipos");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "identificadores": ['TGP']
      }

      await AgendaService.listaTipoGeral(dadosConsulta)
        .then((resposta) => {
          this.tipos = resposta.data.obj
          desativarShow("buscarTipos");
        })
        .catch((err) => {
          desativarShow("buscarTipos");
        });

    },
      voltar() {
        this.$emit('fecharedit')
      },
      salvar() {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("salvarClassificacao"); 

        AgendaService.saveClassificacao(this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {            
              let ret = {
                "status": "success",
                "entity": retorno.obj
              }              
              this.$emit('executou', ret)
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
                var v = this
                setTimeout(function () {
                  v.voltar()
                }, 2000);            
            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }  
            desativarShow("salvarClassificacao");
          }).catch((error) => {          
            desativarShow("salvarClassificacao");
            toast(error, 'Falha', 'danger')
          });
      },                
      hasAccess(menu) {
        return this.$store.getters['auth/hasAccess'](menu)
      },
  }
    
}
</script>



<style>

</style>