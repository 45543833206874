<template>
<b-overlay :show="show" rounded="sm">
  <card>
    <h4 slot="header" class="card-title">Atualizar Usuário</h4>
    <form>
      <div class="row">
        <div class="col-md-3">
          <base-input label="Grupo usuário">
            <b-form-select ref="grupoUsuario" v-model="user.grupoUsuario" :options="optionsGrupoUsuario"></b-form-select>
          </base-input>
        </div>
        <div class="col-md-5">
          <base-input label="E-mail">
            <b-form-input type="email"
                    ref="email"
                    placeholder="E-mail"
                    v-model="user.email" />
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input label="Nome">
              <b-form-input
                    ref="nome"
                    type="text"
                    label="Nome"
                    placeholder="Nome"
                    maxlength = "100"
                    v-model="user.nome" />
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
            <base-input label="CPF">
              <b-form-input type="text"
                    ref="cpf"
                    maxlength = "14"
                    placeholder="CPF"
                    v-mask="'###.###.###-##'"
                    v-model="user.cpf" />
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input label="RG">
            <b-form-input
                    type="text"
                    maxlength = "10"
                    ref="rg"
                    v-mask="'##.###.###'"
                    placeholder="RG"
                    v-model="user.rg" />
          </base-input>
        </div>
        <div class="col-md-3">
           <base-input label="Estado Cívil">
            <b-form-select ref="estadoCivil" v-model="user.estadoCivil" :options="optionsEstadoCivil"></b-form-select>
          </base-input>
        </div>

         <div class="col-md-3">
         <base-input label="Nacionalidade">
             <b-form-input 
                    ref="nacionalidade"
                    type="text"
                    placeholder="Nacionalidade"
                    v-model="user.nacionalidade" />
          </base-input>
        </div>
        
      </div>
       <div class="row">
            <div class="col-md-12">
                Dados de contato:
            </div>
      </div>      
      <!-- CONTATO -->
        <div class="row">
            <div class="col-md-3">
               <base-input label="Telefone Fixo">
                <b-form-input
                          type="text"
                          ref="telefone"
                          placeholder="Telefone Fixo"
                          maxlength = "13"
                          v-mask="'(##)####-####'"
                          v-model="user.telefone" />
                </base-input>
            </div>
            <div class="col-md-3">
                 <base-input label="Telefone Celular">
                  <b-form-input
                      type="text"
                      ref="celular"
                      placeholder="Telefone Celular"
                      maxlength = "14"
                      v-mask="'(##)#####-####'"
                      v-model="user.celular" />
            </base-input>
            </div>
        </div>
      <!-- FIM CONTATO -->
      <div class="row">
            <div class="col-md-12">
                Endereço:
            </div>
      </div>      
      <!-- ENDERECO -->
        <div class="row">
            <div class="col-md-2">
             <!-- 
            https://viacep.com.br/ws/32900000/json/

          -->
          <base-input label="CEP">
              <b-form-input
                    type="text"
                    ref="cep"
                    maxlength = "9"
                    placeholder="CEP"
                    v-mask="'#####-###'"
                    v-model="user.endereco.cep"
                    v-on:keyup="buscar" />
          </base-input>
            </div>
            <div class="col-md-4">
            <base-input label="Endereço">
                <b-form-input
                    id="inputLogradouro"
                    type="text"
                    ref="logradouro"
                    placeholder="Endereço"
                    v-model="user.endereco.logradouro" />
          </base-input>
            </div>
            <div class="col-md-1">
                <base-input label="Numero">

                <b-form-input
                    type="text"
                    ref="numero"
                    maxlength = "6"
                    placeholder="num."
                    v-model="user.endereco.numero" />
          </base-input>
            </div>
            <div class="col-md-2">
                <base-input label="Complemento">
                <b-form-input
                    type="text"
                    ref="complemento"
                    placeholder="Complemento"
                    v-model="user.endereco.complemento" />
          </base-input>
            </div>

            <div class="col-md-3">
                <base-input label="Bairro">
                <b-form-input
                    id="inputBairro"
                    type="text"
                    ref="bairro"
                    placeholder="Bairro"
                    v-model="user.endereco.bairro" />
          </base-input>
            </div>
        </div>

         <div class="row">
            <div class="col-md-1">
             <base-input label="UF">

              <b-form-input
                    id="inputUF"
                    type="text"
                    ref="uf"
                    maxlength = "2"
                    placeholder="UF"
                    v-model="user.endereco.uf" />
          </base-input>
            </div>
            <div class="col-md-4">
           <base-input label="Cidade">
                <b-form-input
                    id="inputCidade"
                    type="text"
                    maxlength="100"
                    ref="cidade"
                    placeholder="Cidade"
                    v-model="user.endereco.cidade" />
          </base-input>
            </div>
         </div>
      <!-- FIM ENDERECO -->

      <div class="row">
        <div class="col-md-12">
         <base-input label="Senha">
            <b-form-checkbox
              id="zerarSenha"
              v-model="user.zerarSenha"
              name="zerarSenha"
              value="true"
              unchecked-value="false"
            >
              Marque para zerar a senha do usuário
            </b-form-checkbox>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
         <base-input label="Ativar">
            <b-form-checkbox
              id="ativar"
              v-model="user.ativar"
              name="ativar"
              value="true"
              unchecked-value="false"
            >
              Marque para ativar usuário
            </b-form-checkbox>
          </base-input>
        </div>
      </div>
      <div>
         <b-button id="exibeMensagem" hidden @click="toast('b-toaster-bottom-right', true)" class="mb-2">b-toaster</b-button>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="salvar">
          Salvar
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>
</b-overlay>
</template>
<script>
  import AgendaService from '../../service/AgendaService'
  import Card from 'src/components/Cards/Card.vue'
  import axios from 'axios'
  import { BASE_CONSTANTS } from '../../service/config'

  export default {
    components: {
      Card
    },
    data () {
      return {
        http: null,
        confirmsenha: null,
        counter: 0,
        cabecalho: '',
        URL_PAGE: this.$route.query.page,
        show: false,
        msg: '',
        cep: '',
        endereco: {},
        naoLocalizado: false,
        file1: null,
        file2: null,
        file3: null,
        user: {
          cpf: null,
          rg: null,
          estadoCivil: null,
          email: null,
          telefone: null,
          celular: null,
          idEmpresa: null,
          nome: null,
          nacionalidade: null,
          senha: null,
          grupoUsuario: null,
          profissional:{
           crm: null
          },
          endereco: {
            cep: '',
            logradouro: null,
            numero: null,
            complemento: null,
            bairro: null,
            uf: null,
            cidade: null
          },
          contaBancaria: {
            codBanco: null,
            agencia: null,
            numero: null
          },
          anexo1: null,
          anexo2: null,
          anexo3: null,

        },
        optionsEstadoCivil:[
          { value: null, text: 'Estado Cívil', disabled: true  },
          { value: 'S', text: 'Solteiro(a)' },
          { value: 'C', text: 'Casado(a)' },
          { value: 'D', text: 'Divorciado(a)' },
          { value: 'V', text: 'Viúvo(a)'},
        ],
        optionsGrupoUsuario:[
          { value: null, text: 'Grupo Usuário', disabled: true  },
        ]
      }
    },
    mounted() {
      this.http = axios.create({
        baseURL: BASE_CONSTANTS.urlBase,
        headers: {
            'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
            'Content-Type': 'application/json',
            'Accept': '*/*'
        }
      })

      // Set the initial number of items
      const token = String(localStorage.getItem('authorization')).replaceAll('"','')
      if (token === null || token === undefined || token === '' || token === 'null') {
        this.$route.router.go('/login');
      } else {
        axios.get(BASE_CONSTANTS.urlBase + 'oauth/check_token/?token=' + token).then(resposta =>
        {
            this.carregaDados()
        }).catch(error =>{
          if(error === false){
            this.$router.push('/login');
          }
        })
      }
    },
    methods: {
      carregaDadosUser(){
        this.dados = []
        this.show = true
        let baseUrl = BASE_CONSTANTS.urlBase

        let idUsuario = this.$route.query.user
         axios.create({
          baseURL: baseUrl,
          headers: {
              'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
              'Content-Type': 'application/json',
          }
        }).get("usuario/" + idUsuario).then(resposta =>
        {          
          this.user = resposta.data          
        //  this.user.endereco.cep = (this.user.endereco !== undefined && this.user.endereco !== null && this.user.endereco.cep !== null) ? this.user.endereco.cep : '' 
          this.user.login = resposta.data.login
          this.user.ativar = resposta.data.ativo
          this.user.email  = resposta.data.login           
          dadosConsulta = '{}'
          this.show = false
        }).catch(error =>{
          this.show = false
        })

        this.show = false
      },
      carregaDados(){
        // /grupousuario/listar

        this.dados = []
        this.show = true
        let baseUrl = BASE_CONSTANTS.urlBase
        axios.create({
          baseURL: baseUrl,
          headers: {
              'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
              'Content-Type': 'application/json',
          }
        }).get("grupousuario/combo").then(resposta =>
        {
          this.optionsGrupoUsuario = resposta.data
          this.show = false
          this.carregaDadosUser()
        }).catch(error =>{
          this.show = false
        })
      },
      verificarCpf(cpf) {      
        return validar(cpf)
      },
      buscar: function(){
       var self = this;
      var cep = (this.user.endereco.cep).replace('-','')
      self.naoLocalizado = false;
      let valit = /^[0-9]{5}-[0-9]{3}$/.test(cep)
      let tamanhoString = cep.length
      if(tamanhoString == 8){
        let urlBusca = "https://viacep.com.br/ws/" + cep + "/json/"
         axios.get(urlBusca).then(endereco =>
        {
          this.user.endereco.logradouro = endereco.data.logradouro
          this.user.endereco.bairro = endereco.data.bairro
          this.user.endereco.uf     = endereco.data.uf
          this.user.endereco.cidade = endereco.data.localidade
        }).catch(e => {
          this.user.endereco.logradouro = null
          this.user.endereco.bairro = null
          this.user.endereco.uf     = null
          this.user.endereco.cidade = null
        })
      }
      },
      validaCampos(evento){

        if(this.user.grupoUsuario === undefined || this.user.grupoUsuario === null || this.user.grupoUsuario === ''){
           this.msg = 'Informe o Grupo!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.grupoUsuario.focus();
           return false
        }

        if(this.user.email === undefined || this.user.email === null || this.user.email === ''){
           this.msg = 'Informe o E-mail!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.email.focus();
           return false
        }
        
        if(this.user.nome === undefined || this.user.nome === null || this.user.nome === ''){
           this.msg = 'Informe seu nome!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.nome.focus();
           return false
        }

        

 /*     if(this.user.profissional.crm === undefined || this.user.profissional.crm === null){
           this.msg = 'Informe o CRM!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.crm.focus();
           return false
        }*/

        if(this.user.cpf === undefined || this.user.cpf === null || this.user.cpf === ''){
           this.msg = 'Informe o CPF!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.cpf.focus();
           return false
        }else if(!this.verificarCpf(this.user.cpf)){
           this.msg = 'CPF Inválido!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.cpf.focus();
           return false
        }

        if(this.user.rg === undefined || this.user.rg === null || this.user.rg === ''){
           this.msg = 'Informe o RG!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.rg.focus();
           return false
        }

        if(this.user.estadoCivil === undefined || this.user.estadoCivil === null || this.user.estadoCivil === ''){
           this.msg = 'Informe o Estado Civíl!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.estadoCivil.focus();
           return false
        }

        if(this.user.nacionalidade === undefined || this.user.nacionalidade === null || this.user.nacionalidade === ''){
           this.msg = 'Informe sua nacionalidade!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.nacionalidade.focus();
           return false
        }

        

        if(this.user.celular === undefined || this.user.celular === null || this.user.celular ===''){
           this.msg = 'Informe o Celular!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.celular.focus();
           return false
        }

        if(this.user.endereco.cep === undefined || this.user.endereco.cep === null || this.user.endereco.cep ===''){
           this.msg = 'Informe o CEP!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           return false
        }

        if(this.user.endereco.logradouro === undefined || this.user.endereco.logradouro === null || this.user.endereco.logradouro === ''){
           this.msg = 'Informe o logradouro!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.logradouro.focus();
           return false
        }

        if(this.user.endereco.numero === undefined || this.user.endereco.numero === null || this.user.endereco.numero === ''){
           this.msg = 'Informe o número do endereço!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           return false
        }

        if(this.user.endereco.bairro === undefined || this.user.endereco.bairro === null || this.user.endereco.bairro === ''){
           this.msg = 'Informe o Bairro!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.bairro.focus();
           return false
        }

        if(this.user.endereco.uf === undefined || this.user.endereco.uf === null || this.user.endereco.uf === ''){
           this.msg = 'Informe o estado!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.uf.focus();
           return false
        }

        if(this.user.endereco.cidade === undefined || this.user.endereco.cidade === null || this.user.endereco.cidade === ''){
           this.msg = 'Informe a Cidade!'
           this.tipoMensagem = 'info'
           this.cabecalho = 'Campo obrigatório!'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
           this.$refs.cidade.focus();
           return false
        }

        return true
      },
      toast(toaster, append = false, mensagem, cabecalho, tipoMensagem) {
        this.counter++
        this.$bvToast.toast(mensagem, {
            title: cabecalho,
            toaster: toaster,
            solid: true,
            variant: tipoMensagem,
            appendToast: append,

        })
      },
      salvar () {
         var usuario = this.user

        if(this.validaCampos(this.user)){
                 
            usuario.cpf.replaceAll('-','').replaceAll('.','')
            usuario.rg.replaceAll('-','').replaceAll('.','')
            usuario.endereco.cep.replaceAll('-','').replaceAll('.','')
            usuario.idEmpresa = this.$route.query.origem
            let usuarioJson = JSON.stringify(usuario)
            //alert('Your data: ' + usuarioJson)
            this.show = true;
           AgendaService.updateUser(this.http, this.user).then(resposta =>
          {
            this.show = false;
          //  this.limpar()
            this.msg = 'Dados salvos com sucesso!'
            this.tipoMensagem = 'success'
            this.cabecalho = 'Aviso:'
            this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
            
          }).catch(e =>{
            this.show = false;
            this.msg = 'Não foi possível atualizar os dados!'
            this.tipoMensagem = 'danger'
           this.cabecalho = 'Aviso:'
           this.toast('b-toaster-bottom-right', true, this.msg, this.cabecalho, this.tipoMensagem)
          });
        }
      },
      submitFile1 () {
        var file = document.getElementById('file1').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.user.anexo1 = reader.result
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      },submitFile2 () {
       var file = document.getElementById('file2').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.user.anexo2 = reader.result
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      },submitFile3 () {
       var file = document.getElementById('file3').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.user.anexo3 = reader.result
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      },
      registrar: function(){
      // processar dados
    }
    }
  }


  const validar = cpf => checkAll(prepare(cpf))

  const notDig = i => !['.', '-', ' '].includes(i)
  const prepare = cpf => cpf.trim().split('').filter(notDig).map(Number)
  const is11Len = cpf => cpf.length === 11
  const notAllEquals = cpf => !cpf.every(i => cpf[0] === i)
  const onlyNum = cpf => cpf.every(i => !isNaN(i))

  const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx)
  const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0)
  const resto11 = somaDig => 11 - (somaDig % 11)
  const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11

  const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)))
  const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos]

  const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)]
  const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r)

</script>
<style>

</style>