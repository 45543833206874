import { sliceEvents, createPlugin } from '@fullcalendar/core';

const CalendarioEmpty = {

  classNames: [ 'custom-view' ],
  duration: { days: 1 },
  content: function(props) {        
    return ''
  }
}

export default createPlugin({
  views: {
    customEmpty: CalendarioEmpty
  }
});