<template>
  <b-modal ref="modal-select-classificacao" id="modal-select-classificacao" size="lg" title="Selecionar classificação"
    hide-footer no-close-on-backdrop>
    <div class="container-fluid">

      <b-row class="my-1">
        <div class="col-md-12">

          <b-card-body class="p-0" style="min-height: 370px;">

            <div class="container-fluid">

              <b-row>
                <b-col lg="12" class="my-1">
                  <b-form-group label="Filtrar" label-for="filter-input" label-cols-sm="2" label-align-sm="right"
                    label-size="sm" class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input id="filter-input" v-model="filter" type="search"
                        placeholder="Digite para filtrar"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>

                <b-table :items="itens" :fields="fields" :fixed="true" sticky-header="400px" :filter="filter"
                  :filter-included-fields="filterOn">

                  <template #head(seleciona)>
                    <b-form-checkbox v-model="todosSelecionados" value="S" unchecked-value="N"
                      @input="selecionarTodos"></b-form-checkbox>
                  </template>

                  <template v-slot:cell(seleciona)="row">
                    <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
                  </template>

                  <template #cell()="data">
                    {{ data.value }}
                  </template>

                </b-table>
              </b-row>

            </div>

          </b-card-body>

        </div>
      </b-row>

      <b-row class="my-1">
        <div class="col-md-12">
          <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right" @click="fechar">
            Fechar
          </button>
          <button size="sm" class="btn btn-success btn-fill float-right" @click="confirmar">
            Confirmar
          </button>
        </div>
      </b-row>
    </div>

  </b-modal>
</template>

<script>

import AgendaService from "../../service/AgendaService"
import appUtils from '@/composables/utils'

export default {
  data() {
    return {
      show: false,
      filaShow: [],
      showFrom: "",
      idsEliminar: [],
      itens: [],
      tipos: null,
      todosSelecionados: 'N',
      filter: null,
      filterOn: ['descricao'],
      fields: [
        { key: 'seleciona', type: "check", thStyle: { width: "8%" } },
        { key: 'descricao', label: 'Descrição', sortable: true, sortDirection: 'asc', thStyle: { width: "92%" } },
      ],
    }
  },
  mounted() {

  },

  methods: {
    async buscaClassificacoes() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscaClassificacoes")

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idsEliminar": this.idsEliminiar !== null ? this.idsEliminar : null,
        "identificadoresTipoGeral": this.tipos
      }

      return AgendaService.listaClassificacao(dadosConsulta)
        .then(resposta => {
          this.itens = resposta.data.obj
          desativarShow("buscaClassificacoes")
        }).catch(error => {
          desativarShow("buscaClassificacoes")
        })

    },
    async adicionarClassificacao(idsRegistro, idsClassificacao, tipo) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("adicionarClassificacao");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "ids": idsRegistro,
        "idsClassificacao": idsClassificacao,
        "tipo": tipo
      }

      await AgendaService.adicionarClassificacao(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data
          if (retorno.status === "SUCCESS") {
            this.buscar()
          } else if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          } else {
            toast('Operação não foi realizada. Entre em contato com o suporte', 'Falha', 'danger')
          }
          desativarShow("adicionarClassificacao");
        }).catch((err) => {
          desativarShow("adicionarClassificacao");
        });

    },
    async removerClassificacao(idsRegistro, idsClassificacao) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("removerClassificacao");

      /*let idsProfissional = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => elem.idProfissional);

      let idsClassificacao = classificacoes.map(elem => elem.id);*/

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "ids": idsRegistro,
        "idsClassificacao": idsClassificacao
      }

      await AgendaService.removerClassificacao(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data
          if (retorno.status === "SUCCESS") {
            this.buscar()
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          } else {
            toast('Operação não foi realizada. Entre em contato com o suporte', 'Falha', 'danger')
          }
          desativarShow("removerClassificacao");
        }).catch((err) => {
          desativarShow("removerClassificacao");
        });

    },
    selecionarTodos(checked) {
      this.itens.forEach((item) => {
        item.seleciona = checked === 'S' ? 'S' : 'N';
      });
    },
    confirmar() {
      let idsSelecionado = this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => ({ id: elem.id, idProfissional: elem.idProfissional, descricao: elem.descricao }));

      let ret = {
        "status": "success",
        "ids": idsSelecionado
      }
      this.$emit('executou', ret)
      this.fechar()
    },
    fechar() {
      this.hide()
    },
    async abrir(idsEliminar, tipos) {
      this.itens = []
      this.idsEliminar = idsEliminar
      this.tipos = tipos
      await this.buscaClassificacoes()
      this.$bvModal.show('modal-select-classificacao')
    },
    hide() {
      this.$bvModal.hide('modal-select-classificacao')
    }
  }
}
</script>

<style></style>