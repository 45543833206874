<template>  
    <card v-if="entity">

      <h4 v-if="false" slot="header" class="card-title"> {{ this.idEmpresaPJ > 0 ? 'Atualizar Empresa PJ' : 'Nova
      Empresa PJ' }} </h4>
      <h4 slot="header" class="card-title">Empresa PJ</h4>

      <form>

        <div class="mx-1">

          <b-tabs content-class="mt-3" style="min-height: 500px;">
            <b-tab title="Empresa" active>

              <b-row>
                <div class="col-md-2">
                  <b-form-group label="cpf" label-for="input-cpf">
                    <b-form-input v-mask="'###.###.###-##'" :readonly="leitura" id="input-cnpj" type="text"
                      placeholder="CNPJ" maxlength="11" v-model="entity.cnpj" />
                  </b-form-group>
                </div>
              </b-row>

              <b-row>
                <div class="col-md-3">
                  <b-form-group label="Nome" label-for="input-nome">
                    <b-form-input :readonly="leitura" id="input-nome" type="text" placeholder="Nome" maxlength="100"
                      v-model="entity.nome" />
                  </b-form-group>
                </div>

                <div class="col-md-2">
                  <b-form-group label="E-mail" label-for="input-email">
                    <b-form-input :readonly="leitura" id="input-email" type="text" placeholder="Email" maxlength="100"
                      v-model="entity.email" />
                  </b-form-group>
                </div>

                <div class="col-md-2">
                  <b-form-group label="Telefone" label-for="input-telefone">
                    <b-form-input :readonly="leitura" id="input-telefone" type="text" placeholder="Telefone"
                      maxlength="100" @cnahge="changeMascaraProf($event)" v-mask="mascaraTelefoneProf"
                      v-model="entity.telefone" />
                  </b-form-group>
                </div>
              </b-row>

              <div v-if="entity.contaBancaria">

                <b-row>
                  <div class="col-md-12">
                    <h4 class="card-title text-primary">Conta bancária</h4>
                  </div>
                </b-row>

                <b-row>
                  <div class="col-md-3">
                    <b-form-group label="Banco" label-for="input-conta-banco">
                      <b-form-input :readonly="leitura" id="input-conta-banco" type="text" placeholder="Banco"
                        maxlength="100" v-model="entity.contaBancaria.banco.descricao" />
                    </b-form-group>
                  </div>
                </b-row>

                <b-row>
                  <div class="col-md-1">
                    <b-form-group label="Agência" label-for="input-conta-agencia">
                      <b-form-input :readonly="leitura" id="input-conta-agencia" type="text" placeholder="Agência"
                        maxlength="100" v-model="entity.contaBancaria.agencia" />
                    </b-form-group>
                  </div>

                  <div class="col-md-1">
                    <b-form-group label="DV" label-for="input-conta-dvAgencia">
                      <b-form-input :readonly="leitura" id="input-conta-dvAgencia" type="text" placeholder="DV"
                        maxlength="100" v-model="entity.contaBancaria.dvAgencia" />
                    </b-form-group>
                  </div>

                  <div class="col-md-2">
                    <b-form-group label="Número" label-for="input-conta-numero">
                      <b-form-input :readonly="leitura" id="input-conta-numero" type="text" placeholder="Número"
                        maxlength="100" v-model="entity.contaBancaria.numero" />
                    </b-form-group>
                  </div>

                  <div class="col-md-1">
                    <b-form-group label="DV" label-for="input-conta-dvnumero">
                      <b-form-input :readonly="leitura" id="input-conta-dvnumero" type="text" placeholder="DV"
                        maxlength="100" v-model="entity.contaBancaria.dvNumero" />
                    </b-form-group>
                  </div>
                </b-row>

              </div>

              <b-row>
                <div class="col-md-12">
                  <h4 class="card-title text-primary">Endereço</h4>
                </div>
              </b-row>

              <b-row v-if="entity.endereco">
                <div class="col-md-2">
                  <b-form-group label="cep" label-for="input-cep">
                    <b-form-input :readonly="leitura" id="input-cep" type="text" placeholder="Cep" maxlength="100"
                      v-model="entity.endereco.cep" />
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group label="Logradouro" label-for="input-logradouro">
                    <b-form-input :readonly="leitura" id="input-logradouro" type="text" placeholder="Logradouro"
                      maxlength="100" v-model="entity.endereco.logradouro" />
                  </b-form-group>
                </div>

                <div class="col-md-1">
                  <b-form-group label="Número" label-for="input-numero">
                    <b-form-input :readonly="leitura" id="input-numero" type="text" placeholder="Número" maxlength="100"
                      v-model="entity.endereco.numero" />
                  </b-form-group>
                </div>

                <div class="col-md-2">
                  <b-form-group label="Bairro" label-for="input-bairro">
                    <b-form-input :readonly="leitura" id="input-bairro" type="text" placeholder="Bairro" maxlength="100"
                      v-model="entity.endereco.bairro" />
                  </b-form-group>
                </div>

                <div class="col-md-1">
                  <b-form-group label="UF" label-for="input-uf">
                    <b-form-input :readonly="leitura" id="input-uf" type="text" placeholder="UF" maxlength="100"
                      v-model="entity.endereco.uf" />
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group label="Cidade" label-for="input-cidade">
                    <b-form-input :readonly="leitura" id="input-cidade" type="text" placeholder="Cidade" maxlength="100"
                      v-model="entity.endereco.cidade" />
                  </b-form-group>
                </div>
              </b-row>

            </b-tab>
            <b-tab title="Documentos">

              <b-table v-if="entity.documentos" :items="entity.documentos" :fields="fieldsDocumento" :fixed="true"
                sticky-header="350px">

                <template #cell(actions)="row">
                  <b-button size="sm" @click="abrirDocumento(row.item.id)" class="mr-1" variant="danger" title="Abrir">
                    <b-icon icon="file-arrow-down" aria-hidden="true"></b-icon>
                  </b-button>
                </template>

                <template #cell()="data">
                  {{ data.value }}
                </template>

              </b-table>
            </b-tab>
          </b-tabs>
        </div>

        <div class="text-center">

          <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="salvar" v-if="false">
            Salvar
          </button>

          <button type="submit" class="btn btn-danger btn-fill float-right mr-1" @click.prevent="voltar">
            Fechar
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </card>
</template>

<script>

import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'
import funcao from "../../../components/Funcao"

export default {
  props: ['idEmpresaPJ'],
  components: {
    Card,
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      mascaraTelefoneProf: '(##)#####-####',
      entity: null,
      leitura: true,
      fieldsDocumento: [
      { key: 'id', label: 'id', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
        { key: 'dscTipo', label: 'Tipo', sortable: true, sortDirection: 'asc', thStyle: { width: "80%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "20%" } }
      ],
    }
  },
  async mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    if (this.idEmpresaPJ > 0) {
      this.carregaDados();
    } else {
      this.entity = {
        "cpf": "",
        "nome": "",
        "login": "",
        "email": "",
        "senha": "",
        "validarSimultaneo": "S",
        "idGrupoUsuario": null
      }
    }

  },
  methods: {
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("carregarDados");

      this.show = true

      AgendaService.editEmpresaPJ(this.idEmpresaPJ)
        .then((resposta) => {
          this.entity = resposta.data.obj
          changeMascaraProf(this.entity.telefone)
          this.show = false
          desativarShow("carregarDados");
        })
        .catch((err) => {
          this.show = false
          desativarShow("carregarDados");
        });
    },
    voltar() {
      this.$emit('fecharedit')
    },
    async abrirDocumento(v) {

      const { toast } = appUtils(this)

      var doc = this.entity.documentos.find(d => d.id === v);
      let ret = await this.download(v)
      let data = ret.data
      if (data.status === 'SUCCESS') {
        funcao.methods.download(ret.data.obj.conteudo, "documento." + ret.data.obj.extensao);
      } else {
        toast(data.mensagens[0].descricao, 'Falha', 'danger')
      }
    },
    async download(idDocumento) {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      let ret = AgendaService.download(idDocumento)
      return await ret;
    },
    changeMascaraProf: function (e) {
      this.mascaraTelefoneProf = e && e.length <= 14 ? '(##) ####-####' : '(##) #####-####'
    },
  }

}
</script>



<style>

</style>