<template>
    <div>
        <button type="button" 
                class="navbar-toggler navbar-toggler-left"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="toggleSidebar">
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
        </button>

    <div class="menu-background" v-if="showMenu" @click="toggleSidebar"></div>

            <transition name="master" v-if="showMenu">
                <div class="menu" >
                    <div class="btn-container" >
                        <button class="btn-logout" @click="logout">
                            <i class="bi bi-box-arrow-right"></i>
                            Logout
                        </button>
                    </div>

                    <hr >

                    <MobileSideBarSubMenu v-if="hasAccess('AGENDA')" :name="'Agenda'" :icon="'bi bi-calendar-week'" :submenu="itemsSubMenuAgenda" @close="toggleSidebar" />

                    <MobileSideBarSubMenu v-if="hasAccess('RELATORIO_FINANCEIRO') || hasAccess('FECHAMENTOS') || hasAccess('PAGAMENTOS')" :name="'Financeiro'" :icon="'bi bi-cash-coin'" :submenu="itemsSubMenuFinanceiro" @close="toggleSidebar" />

                    <MobileSideBarSubMenu v-if="hasMenu('ADM')" :name="'Cadastros'" :icon="'bi bi-person-plus'" :submenu="itemsSubMenuCadastro" @close="toggleSidebar" />

                    <MobileSideBarSubMenu v-if="hasAccess('LISTA_CREDENCIAMENTO') || hasAccess('CREDENCIAMENTO') || hasAccess('LISTA_CREDENCIAMENTO_PJ')" :name="'Credenciamento'" :icon="'bi bi-file-earmark-text'" :submenu="itemsSubMenuCredenciamento" @close="toggleSidebar" />

                    <MobileSideBarSubMenu v-if="hasAccess('LISTA_CANDIDATO') || hasAccess('MURAL_VAGA')" :name="'Vagas'" :icon="'bi bi-person-workspace'" :submenu="itemsSubMenuVagas" @close="toggleSidebar" />

                    <MobileSideBarSubMenu v-if="hasAccess('DASHBOARD')" :name="'Dashboard'" :icon="'bi bi-graph-up-arrow'" :submenu="itemsSubMenuDashboard" @close="toggleSidebar" />

                    <MobileSideBarSubMenu v-if="hasAccess('RELATORIO') || hasAccess('RELATORIO_GERAL')" :name="'Relatórios'" :icon="'bi bi-file-earmark-bar-graph'" :submenu="itemsSubMenuRelatorios" @close="toggleSidebar" />
            
                    <router-link @click.native="toggleSidebar" to="/admin/agenda" v-if="false && hasAccess('AGENDA')" >
                        <div class="container_menu">
                            <i class="bi bi-calendar-week"></i>
                            Agenda
                        </div>
                    </router-link>
                    
                    <router-link @click.native="toggleSidebar" to="/acesso" v-if="!loggedIn && !isTela('Acesso')">
                        <div class="container_menu">
                            <i class="bi bi-window-plus"></i>
                            Acesso
                        </div>
                    </router-link>      

                </div>
            </transition>
    </div>
</template>

<script>
import MobileSideBarSubMenu from './MobileSideBarSubMenu.vue';

export default {
    data() {
        return {
            showMenu: false,
            itemsSubMenuAgenda: [
                {
                    title: 'Agenda',
                    url: '/admin/agenda',
                    access: 'AGENDA'
                },
                {
                    title: 'Manutenção',
                    url: '/admin/manutencao-agenda',
                    access: 'MANUTENCAO_AGENDA'
                }
            ],            
            itemsSubMenuFinanceiro: [
                {
                    title: 'Relatório',
                    url: '/admin/relatorio-financeiro',
                    access: 'RELATORIO_FINANCEIRO'
                },
                {
                    title: 'Fechamentos',
                    url: '/admin/fechamentos',
                    access: 'FECHAMENTOS'
                },
                {
                    title: 'Pagamentos',
                    url: '/prof/pagamento',
                    access: 'PAGAMENTOS'
                }                                
            ],
            itemsSubMenuCadastro: [                
                {
                    title: 'Local',
                    url: '/admin/cad/local',
                    access: 'CAD_LOCAL'
                },
                {
                    title: 'Usuário',
                    url: '/admin/cad/usuario',
                    access: 'CAD_USUARIO'
                },
                {
                    title: 'Profissional',
                    url: '/admin/cad/profissional',
                    access: 'CAD_PROFISSIONAL'
                },
                {
                    title: 'Tipos de serviço',
                    url: '/admin/cad/tipo',
                    access: 'CAD_TIPO'
                },
                {
                    title: 'Grupo de usuário',
                    url: '/admin/cad/grupo-usuario',
                    access: 'CAD_GRUPO_USUARIO'
                },
                {
                    title: 'Empresa',
                    url: '/admin/cad/empresa',
                    access: 'CAD_EMPRESA'
                },
                {
                    title: 'Empresa PJ',
                    url: '/admin/cad/empresa-pj',
                    access: 'CAD_EMPRESA_PJ'
                },
                {
                    title: 'Situação',
                    url: '/admin/cad/situacao',
                    access: 'CAD_SITUACAO'
                },
                {
                    title: 'Escala',
                    url: '/admin/escala',
                    access: 'CAD_SITUACAO'
                }
            ],

            itemsSubMenuCredenciamento: [
                {
                    title: 'Lista de credenciamentos',
                    url: '/admin/list-credenciamento',
                    access: 'LISTA_CREDENCIAMENTO'
                },
                {
                    title: 'Credenciamento',
                    url: '/admin/credenciamentoi',
                    access: 'CREDENCIAMENTO'
                },
                {
                    title: 'Empresas',
                    url: '/admin/list-credenciamento-pj',
                    access: 'LISTA_CREDENCIAMENTO_PJ'
                }
            ],
            itemsSubMenuVagas: [
                {
                    title: 'Aprovar candidato',
                    url: '/admin/list-candidato',
                    access: 'LISTA_CANDIDATO'
                },
                {
                    title: 'Mural de vagas',
                    url: '/muralVaga',
                    access: 'MURAL_VAGA'
                },
                {
                    title: 'Candidaturas',
                    url: '/list-candidatura',
                    access: 'LISTA_CANDIDATURA'
                }
            ],
            itemsSubMenuDashboard: [
                {
                    title: 'Geral',
                    url: '/admin/dashboard',
                    access: 'DASHBOARD'
                }
            ],
            itemsSubMenuRelatorios: [
                {
                    title: 'Geral',
                    url: '/admin/relatorio',
                    access: 'RELATORIO_GERAL'
                }
            ],
        }
    },
    components: {
        MobileSideBarSubMenu,
    },
    methods: {
        toggleSidebar() {
            this.showMenu = !this.showMenu;
        },
        hasAccess(menu) {
            return this.$store.getters['auth/hasAccess'](menu)
        },
        hasMenu(posicao) {        
            return this.$store.getters['auth/hasMenu'](posicao)
        },
        isTela(v) {
            return this.$route.name === v
        },
        logout(){
            this.$store.dispatch('auth/logout')
            this.toggleSidebar()
            this.$router.push('/login/acesso')
      },
    },
    computed: {
        loggedIn() {      
            return this.$store.state.auth.status.loggedIn
        },    
  } ,
}
</script>

<style scoped>
.menu-background {
  position: fixed; 
  z-index: 9991; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

.menu {
    position: fixed;
    top: 3.7em;
    left: 0;
    height: 90%;
    width: 15em;
    box-shadow: 10px 10px 5px rgba(0,0,0,0.4);
    z-index: 99999;
    background-color: #3f7bb7;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
}

.menu a {
    width: 100%;
    padding: 1em 1em;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu i {
    font-size: 1.5em;
    padding: 0 0.2em;
}

.container_menu {
    display: flex;
    align-items: center;
}

.container_submenu {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1em;
}

.btn-logout {
    padding: 0.5em 3em;
    color: #fff;
    background-color: red;
    border: none;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-container {
    width: 100%;
    padding: 1em;
    border-bottom: 1px solid #fff;
}

.master-enter-active {
    animation: enter .3s ease-in  ;
}
.master-leave-active {
    animation: enter 0.3s ease-in reverse;
}

@keyframes enter {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}

.dropbtn {
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
  border: none;
}

.dropdown {
  position: relative ;
  margin: 0;
  display: flex;
  transition: 0.2s;
  z-index: 999 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 4em;
  top: -0.6em;
  background-color: #4781ff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999 !important;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  left: 50px;
}

.dropdown-content a:hover {
  background-color: #2f6aeb;
}

.dropdown:hover .dropdown-content {
  display: block;
  
}
</style>