<template>
<b-container class="wrapper" fluid 

>  
    <b-row class="justify-content-center">
      <b-col sm="6">
        <router-view></router-view>
      </b-col>
    </b-row>  
</b-container>
</template>

<script>

  export default {
    components: {

    }
  }

</script>
<style lang="scss">

</style>
