<template>
  <div class="content home">
    <div class="container-fluid">
      <h2 class="p-2">Bem vindo!</h2>
    </div>
  </div>
</template>

<script>

  export default {
    components: {
    },
    data () {
      return {
        
      }
    }
  }
</script>
<style>

.home .boasvindas{

}

</style>
