<template>
  <b-modal ref="modal-divulgar-vaga" id="modal-divulgar-vaga" size="lg" title="Divulgar vaga de plantão" hide-footer
    no-close-on-backdrop>
    <div class="container-fluid">

      <b-tabs content-class="mt-2">

        <b-tab title="Classificação">

          <b-row class="my-1">
            <div class="col-md-12">

              <b-card-body class="p-0" style="min-height: 370px;">

                <div class="container-fluid">

                  <b-row>
                    <b-col lg="12" class="my-1">
                      <b-form-group label="Filtrar" label-for="filter-input" label-cols-sm="2" label-align-sm="right"
                        label-size="sm" class="mb-0">
                        <b-input-group size="sm">
                          <b-form-input id="filter-input" v-model="filterClassificacao" type="search"
                            placeholder="Digite para filtrar"></b-form-input>
                          <b-input-group-append>
                            <b-button :disabled="!filterClassificacao" @click="filterClassificacao = ''">Limpar</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>

                    <b-table :items="classificacoes" :fields="fieldsClassificacao" :fixed="true" sticky-header="400px"
                      :filter="filterClassificacao" :filter-included-fields="filterClassificacaoOn">

                      <template #head(seleciona)>
                        <b-form-checkbox v-model="todosSelecionadosClassificacao" value="S" unchecked-value="N"
                          @input="selecionarTodosClassificacao"></b-form-checkbox>
                      </template>

                      <template v-slot:cell(seleciona)="row">
                        <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
                      </template>

                      <template #cell()="data">
                        {{ data.value }}
                      </template>

                    </b-table>
                  </b-row>

                </div>

              </b-card-body>

            </div>
          </b-row>

        </b-tab>

        <b-tab title="Escala">

          <b-row class="my-1">
            <div class="col-md-12">

              <b-card-body class="p-0" style="min-height: 370px;">

                <div class="container-fluid">

                  <b-row>
                    <b-col lg="12" class="my-1">
                      <b-form-group label="Filtrar" label-for="filter-input" label-cols-sm="2" label-align-sm="right"
                        label-size="sm" class="mb-0">
                        <b-input-group size="sm">
                          <b-form-input id="filter-input" v-model="filterLocal" type="search"
                            placeholder="Digite para filtrar"></b-form-input>
                          <b-input-group-append>
                            <b-button :disabled="!filterLocal" @click="filterLocal = ''">Limpar</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>

                    <b-table :items="locais" :fields="fieldsLocal" :fixed="true" sticky-header="400px"
                      :filter="filterLocal" :filter-included-fields="filterLocalOn">

                      <template #head(seleciona)>
                        <b-form-checkbox v-model="todosSelecionadosLocal" value="S" unchecked-value="N"
                          @input="selecionarTodosLocal"></b-form-checkbox>
                      </template>

                      <template v-slot:cell(seleciona)="row">
                        <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
                      </template>

                      <template #cell()="data">
                        {{ data.value }}
                      </template>

                    </b-table>
                  </b-row>

                </div>

              </b-card-body>

            </div>
          </b-row>

        </b-tab>
      </b-tabs>

      <b-row class="my-1">
        <div class="col-md-12">
          <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right" @click="fechar">
            Fechar
          </button>
          <button size="sm" class="btn btn-success btn-fill float-right" @click="confirmar">
            Confirmar
          </button>
        </div>
      </b-row>

    </div>

  </b-modal>
</template>

<script>

import AgendaService from "../../service/AgendaService";
import appUtils from '@/composables/utils'

export default {
  data() {
    return {
      show: false,
      filaShow: [],      
      showFrom: "",
      classificacoes: [],
      locais: [],
      todosSelecionadosClassificacao: 'N',
      todosSelecionadosLocal: 'N',
      filterClassificacao: null,
      filterClassificacaoOn: ['descricao'],
      filterLocal: null,
      filterLocalOn: ['descricao'],
      fieldsClassificacao: [
        { key: 'seleciona', type: "check", thStyle: { width: "8%" } },
        { key: 'descricao', label: 'Descrição', sortable: true, sortDirection: 'asc', thStyle: { width: "92%" } },
      ],
      fieldsLocal: [
        { key: 'seleciona', type: "check", thStyle: { width: "8%" } },
        { key: 'descricao', label: 'Descrição', sortable: true, sortDirection: 'asc', thStyle: { width: "92%" } },
      ],
    }
  },
  mounted() {

  },

  methods: {

    async buscaLocais() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscaLocais")

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa
      }

      return AgendaService.listaLocaisFilter(dadosConsulta).then(resposta => {
        this.locais = resposta.data.obj
        desativarShow("buscaLocais")
      }).catch(error => {
        desativarShow("buscaLocais")
      })

    },
    async buscaClassificacoes() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscaClassificacoes")

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "identificadoresTipoGeral": ['TGP']
      }

      return AgendaService.listaClassificacao(dadosConsulta)
        .then(resposta => {
          this.classificacoes = resposta.data.obj
          desativarShow("buscaClassificacoes")
        }).catch(error => {
          desativarShow("buscaClassificacoes")
        })

    },
    selecionarTodosClassificacao(checked) {
      this.classificacoes.forEach((item) => {
        item.seleciona = checked === 'S' ? 'S' : 'N';
      });
    },
    selecionarTodosLocal(checked) {
      this.locais.forEach((item) => {
        item.seleciona = checked === 'S' ? 'S' : 'N';
      });
    },
    confirmar() {
      let classificacoes = this.classificacoes.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => ({ id: elem.id, descricao: elem.descricao }))

      let locais = this.locais.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => ({ id: elem.id, descricao: elem.descricao }))

      let ret = {
        "status": "success",
        "filtro": {
          "classificacoes": classificacoes,
          "locais": locais
        }
      }
      this.$emit('executou', ret)
      this.fechar()
    },
    fechar() {
      this.hide()
    },
    async abrir(v) {
      this.classificacoes = []
      this.locais = []
      this.idsEliminar = v
      await this.buscaClassificacoes()
      await this.buscaLocais()
      this.$bvModal.show('modal-divulgar-vaga')
    },
    hide() {
      this.$bvModal.hide('modal-divulgar-vaga')
    }
  }
}
</script>

<style></style>