<template>
  <b-modal ref="modal-conf-excluir" 
  id="modal-conf-excluir" 
  size="lg" title="Confirmação" hide-footer >

        <div class="container-fluid">
          <b-row class="my-1">
            <div class="col-md-12">
              <slot name="texto"></slot>              
            </div>
          </b-row>          
          <b-row class="my-1">
            <div class="col-md-12">
              <button
                size="sm"
                style="margin-left: 4px"
                class="btn btn-danger btn-fill float-right"
                @click="nao"
              >
                Não
              </button>
              <button
                size="sm"
                class="btn btn-success btn-fill float-right"
                @click="sim"
              >
                Sim
              </button>
            </div>
          </b-row>
        </div>
      </b-modal>      
</template>

<script>
export default {    
    data() {
        return {
        
        }
    },
    methods: {
        sim() {
            this.hide()
            this.$emit('respondeu', 'S')
        },
        nao() {
            this.hide()
            this.$emit('respondeu', 'N')
        },
        show() {
            this.$bvModal.show('modal-conf-excluir')
        },
        hide() {
            this.$bvModal.hide('modal-conf-excluir')
        } 
    }
}
</script>

<style>

</style>