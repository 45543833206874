import { sliceEvents, createPlugin } from '@fullcalendar/core';

const convertDateToBR = function(data){

  var dia  = data.getDate().toString();
  var diaF = (dia.length == 1) ? '0'+dia : dia;

  var mes  = (data.getMonth()+1).toString();
  var mesF = (mes.length == 1) ? '0'+mes : mes;

  var anoF = data.getFullYear();
  return diaF+"/"+mesF+"/"+anoF;

}

const criaEvento = function (event){
  console.log('olá');
  //alert('entrou');
}

const convertDateTODay = function(objDate){
  var meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  var days = ['dom','seg','ter','qua','qui','sex','sab'];
  var formatData = objDate.replace(/(\d{2})(\/)(\d{2})/, "$3$2$1");  
  var newData = new Date(formatData);
 // console.log(newData.getDate() + ' ' + meses[newData.getMonth()] + ' (' +  days[newData.getDay()]+ ')');
  return days[newData.getDay()];
}


const convertMinutosEmHora = function(minutos){
  const horas = Math.floor(minutos/ 60);          
  const min = minutos % 60;
  const textoHoras = (`00${horas}`).slice(-2);
  const textoMinutos = (`00${min}`).slice(-2);
  
  return `${textoHoras }:${textoMinutos}`;
}

const CalendarioPorProfissional = {

  classNames: [ 'custom-view' ],
  duration: { weeks: 1 },
  content: function(props) {
    let html = ''
    let segs = sliceEvents(props, false).filter(f => f.def.title !== '[NOVO_REGISTRO]')
    let furo = 'border-left: #d35400 solid 3px;'
    let naoFuro = 'border-left: #27ae60 solid 3px;'
    let criaProfissional = false
    let itemBotaoInserir = ''

    html += '<div class="container-fluid">'
   

    if(segs.length > 0){
        let idProfissionalAnt = null
        let initialView = true
        let itemDom = ''
        let itemSeg = ''
        let itemTer = ''
        let itemQua = ''
        let itemQui = ''
        let itemSex = ''
        let itemSab = ''
        itemBotaoInserir = ''
        
        for (let i = 0; i < segs.length; i++) {
            const evento = segs[i].def;
            let duracao = segs[i].range.start
            let dataInicio = segs[i].range.end
          let idProfissional = evento.extendedProps.idProfissional
          let nomeProfissional = evento.extendedProps.nomeProfissional
          let idLocal = evento.extendedProps.idLocal
          let nomeLocal = evento.extendedProps.nomeLocal
            let itemDiv = ''
            var data = convertDateToBR(dataInicio)
            let diaSemana = convertDateTODay(data)

            itemBotaoInserir = '<buttom id="botaoadd" class="botaoadd" onclick="criaEvento()">+ nova agenda</buttom>'


            // INICIO MONTA ITEM PROFISSIONAL
            itemDiv +=  '<div class"row" style="border:1px solid red;">'
            itemDiv +=  '<div class="container-fluid centro" style="margin-bottom:5px;'
            if (idProfissional === null || idProfissional === undefined){
             itemDiv += furo
            }else{
             itemDiv += naoFuro
            } 
            itemDiv +=  '">' 

            itemDiv += '<div class="row view-events">'
            itemDiv += '<div class="col-md-8 titulo">'
            itemDiv +=  nomeLocal
            itemDiv +=  '</div>'

            itemDiv +=  '<div class="col-md-3" style="border-bottom: 3px solid '
            itemDiv +=  evento.extendedProps.cor 
            itemDiv +=  '; font-size:12px;">'
            itemDiv +=  '<label>'
            itemDiv +=  evento.extendedProps.horarioDe +'<br />'+ convertMinutosEmHora(evento.extendedProps.duracao)
            itemDiv +=  '</label>'
            itemDiv +=  '</div>'
            itemDiv +=  '<div class="col-md-1 top">'
            if(idProfissional === null || idProfissional === undefined){
             itemDiv +=  '<i class="nc-icon nc-send "></i>'
            }  
            itemDiv +=  '</div>'
            itemDiv +=  '</div>'
            itemDiv +=  '</div>'
            itemDiv +=  '</div>'
            // FIM MONTA ITEM SETOR

            // MONTA HTML

             if(idProfissionalAnt != null && idProfissionalAnt !== undefined && idProfissional !== null && idProfissional !== undefined && idProfissionalAnt != idProfissional){   
              html +=  '<tbody>'
              html +=  '<tr>'
              html +=  '<td style="vertical-align:top">'
              html += itemDom + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemSeg + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemTer + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemQua + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemQui + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemSex + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemSab + itemBotaoInserir
              html +=  '</td>'

              html +=  '<td style="vertical-align:top">'
              html +=  '</td>'

              html +=  '</tr>'
              html +=  '<tbody>'        

              html += '          </table>'
              html += '        </div>'
              html += '       </div>'
              html += '       </div>'
             } 

            if(initialView == true || (idProfissionalAnt != null && idProfissionalAnt !== undefined && idProfissional !== null && idProfissional !== undefined && idProfissionalAnt != idProfissional)){
              itemDom = ''
              itemSeg = ''
              itemTer = ''
              itemQua = ''
              itemQui = ''
              itemSex = ''
              itemSab = ''

              html += '<div class="row">'
              html += '<div class="col-md-2 " style="vertical-align: middle; height:100%">'
              if(initialView == true){
                html += '<p style="font-size:12px; word-wrap: normal; margin-top: 50px;  padding-right:5px; ">'
                html +=  (idProfissional !== undefined && idProfissional != null) ? nomeProfissional : ''
                html += '</p>'
              }else{
                html += '<p style="font-size:12px; word-wrap: normal;  padding-right:5px; ">'
                html +=  (idProfissional !== undefined && idProfissional != null) ? nomeProfissional : ''
                html += '</p>'
              }
              html += '     </div>'
              html += '      <div class="col-md-10">'
              html += '        <div class="row">'
              html += '           <table class="table table-bordered">'
              if(initialView == true){
                html += '              <thead>'
                html += '                  <tr>'
                html += '                    <th scope="col" class="diaSemanaAgenda">dom.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">seg.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">ter.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">qua.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">qui.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">sex.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">sab.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda"></th>'
                html += '                  </tr>'
                html += '              </thead>'
            }
          }

          diaSemana === 'dom' ? (itemDom += itemDiv ) : ''
          diaSemana === 'seg' ? (itemSeg += itemDiv ) : ''
          diaSemana === 'ter' ? (itemTer += itemDiv ) : ''
          diaSemana === 'qua' ? (itemQua += itemDiv ) : ''
          diaSemana === 'qui' ? (itemQui += itemDiv ) : ''
          diaSemana === 'sex' ? (itemSex += itemDiv ) : ''
          diaSemana === 'sab' ? (itemSab += itemDiv ) : ''

            criaProfissional = false
            idProfissionalAnt = (idProfissional !== undefined && idProfissional != null) ? idProfissional : ''
            initialView = false
      }  
      
      html +=  '<tbody>'
      html +=  '<tr>'
      html +=  '<td style="vertical-align:top">'
      html += itemDom + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemSeg + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemTer + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemQua + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemQui + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemSex + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemSab + itemBotaoInserir
      html +=  '</td>'

      html +=  '<td style="vertical-align:top">'

      html +=  '</td>'


      html +=  '</tr>'
      html +=  '</tbody>'         

      html += '          </table>'
      html += '        </div>'
      html += '       </div>'
    html += '</div>'

    return { html: html }
  }else{
    return { html: html }
  }
}

}

export default createPlugin({
  views: {
    customPorProfissionais: CalendarioPorProfissional
  },
  provides() {
    return {
      criaEvento(event) {
        alert('ok')
        return null
      }
    };
  }  
})

