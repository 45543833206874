<template>
<b-container fluid class="redefinir-senha-page">
    <div class="content">

      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-5 col-md-8 col-sm-12">
          <span>
            <form>

              <b-card header-bg-variant="primary" border-variant="primary" class="mb-0 pb-0">

                <template #header>
                  <h3 class="card-title text-center text-white">Redefinição de senha</h3>
                </template>

                <b-row class="m-0 p-0">
                  <div class="col-md-12">
                    <b-form-group class="mt-2"                  
                    label="Informe a nova Senha"
                    label-for="input-senha"      >
                    <b-form-input type="password" placeholder="Nova senha" id="input-senha" v-model="senha" trim></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group 
                    label="Confirmar senha"
                    label-for="input-conf-senha"      >
                    <b-form-input type="password" placeholder="Confirmar senha" id="input-conf-senha" v-model="confirmarSenha" trim></b-form-input>
                    </b-form-group>
                  </div>                  
                  
                </b-row>

                <b-row class="m-0 p-0">
                  <div class="col-md-12">
                    <div class="text-center mt-4">
                      <button type="submit" class="btn btn-info btn-fill" @click.prevent="confirmar">Confirmar</button>
                    </div>
                  </div>
                </b-row>

                <b-row class="px-3 pt-3">
                  <div class="col-md-12" v-if="errosRegra">
                    <b-alert show variant="danger">
                      <h6 class="alert-heading">Regras não atendidas</h6>
                      <hr>
                      <p class="mb-0">
                      <ul>
                        <li v-for="regra in errosRegra" :key="regra"> {{regra}} </li>
                      </ul>
                      </p>
                    </b-alert>
                  </div>
                </b-row>

              </b-card>
              <div class="clearfix"></div>
            </form>
          </span>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
  import AgendaService from "../../service/AgendaService";
  import Card from 'src/components/Cards/Card.vue'
  import axios from "axios";
  import { BASE_CONSTANTS } from "@/service/config";
  import appUtils from '@/composables/utils'
  
  export default {
    components: {
      Card
    },
    data () {
      return {        
        http: null,
        senha: '',
        confirmarSenha: '',
        token: '',
        show: false,
        showFrom: "",
        filaShow: [], 
        errosRegra: null,
      }
    },
    mounted(){
      this.token = this.$route.params.token;      

      this.http = axios.create({baseURL: BASE_CONSTANTS.urlBase,
        headers: { "Content-Type": "application/json",
          Accept: "*/*",
        }
      });

    },
    methods: {      
      confirmar () {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("confirmar");

        this.errosRegra = null

        let dadosEnvio =
        { "senha": this.senha,
          "confirmarSenha": this.confirmarSenha,
          "token": this.token
        }

        AgendaService.resetarSenha(this.http, dadosEnvio)
          .then((resposta) => {            
          desativarShow("confirmar");
          let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
                        
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");            

            toast('Logando no sistema', 'Autenticando', 'info')

            let user = {
              "username": retorno.obj.login,
              "password": this.senha,
              }

            this.senha = ''
            this.confirmarSenha = '';

            this.$store.dispatch('auth/login', user)
              .then((user) => {                
                var v = this
                setTimeout(function () {
                  v.$router.push({ name: 'Agenda' });
                }, 2000);
              },
                error => {
                  toast('Falha ao autenticar. Tente realizar o login novamente.', 'Falha', 'danger')
                }
              );          

          } else {

            desativarShow("alterarSenha");            
            if (retorno.mensagens.length > 0 && retorno.mensagens[0].descricao.startsWith("Falha regra")) {
              this.errosRegra = []
                retorno.mensagens.slice(1).forEach(element => {
                this.errosRegra.push(element.descricao)
              });
            } else {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')              
            }
          }
        })
        .catch((err) => {
          this.showRegraSenha = false;
          desativarShow("confirmar");
          toast(err,"Falha","danger");
        });            
      }   
    }
  }

</script>
<style>

.redefinir-senha-page .card{
    border-radius: 10px;
    /*padding-top: 30px;*/
    padding-bottom: 30px;
  }

  .redefinir-senha-page .content{
    padding-top: 12vh;
  }

</style>
