<template>
  <div>
      
      <b-modal header-bg-variant="primary" header-text-variant="light" footer-bg-variant="light"
        ref="modal-edit_tipo_local" id="modal-edit_tipo_local" size="lg" title="Configuração do tipo de serviço no local"
        no-close-on-backdrop hide-footer body-class="pt-0">
  
        <loading :show="show">
        <div class="container-fluid edit-local" v-if="entity">
          <b-row class="my-1">
            <div class="col-md-12">
  
              <b-card-body class="p-0" style="min-height: 450px;">
  
                <div class="container-fluid">  
                  <b-row>
                    <div class="col-md-12">
                      <b-form-group class="mb-0" label="Local" label-for="input-local">
                        <b-form-input id="input-local" type="text" label="Local" placeholder="Local" readonly v-model="entity.dscLocal" />
                      </b-form-group>
                    </div>
                    <div class="col-md-12">
                      <b-form-group class="mb-0" label="Tipo" label-for="input-tipo">
                        <b-form-select v-if="!entity.id" id="input-tipo" v-model="entity.idTipo" value-field="id"
                          text-field="descricao" :options="itensTipo" size="sm" @change="alterarTipo(entity.idTipo)">
                          <template #first>
                            <b-form-select-option :value="null">-- Selecione o tipo --</b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-input v-else id="input-tipo" type="text" label="Tipo" placeholder="Tipo" readonly v-model="entity.dscTipo" />
                      </b-form-group>
                    </div>
                  </b-row>
  
                  <b-row>
                    <div class="col-md-12">
                      <h4 class="card-title text-primary mt-2">Configuração</h4>
                    </div>
                  </b-row>
  
                  <b-row>
                    <div class="col-md-3">
                    <b-form-group class="mb-0" label="Duração" label-for="input-duracao">
                      <b-form-input id="input-duracao" type="time" label="Duração" placeholder="hh:mm" v-model="entity.duracaoStr" size="sm"></b-form-input>                      
                    </b-form-group>
                    </div>
                    <div class="col-md-3">
                    <b-form-group class="mb-0" label="Unidade" label-for="input-unidade">
                      <b-form-input id="input-unidade" type="time" label="Unidade" placeholder="hh:mm" v-model="entity.unidadeStr" size="sm"></b-form-input>                      
                    </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group class="mb-0" label="Limite dia" label-for="input-limitedia">
                      <b-form-input id="input-limitedia" type="number" label="Limite dia" v-model="entity.limiteDiaReal" size="sm">
                      </b-form-input>                      
                    </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group class="mb-0" label="Limite mês" label-for="input-limitemes">
                      <b-form-input id="input-limitemes" type="number" label="Limite mês" v-model="entity.limiteMesReal" size="sm">
                      </b-form-input>                      
                    </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group class="mb-0" label="Valor venda" label-for="input-valor-venda">
                      <b-form-input v-model="valorVenda" id="input-valor-venda" style="font-size: 0.7rem;" v-money="money" placeholder="Valor venda" size="sm" @click="$event.target.select()">
                      </b-form-input>
                    </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <b-form-group class="mb-0" label="Valor compra" label-for="input-valor-compra">
                      <b-form-input v-model="valorCompra" id="input-valor-compra" style="font-size: 0.7rem;" v-money="money" placeholder="Valor compra" size="sm" @click="$event.target.select()">
                      </b-form-input>
                    </b-form-group>
                    </div>                    
                    <div class="col-md-3">
                      <b-form-group label="Modo troca" label-for="select-modo-troca">
                        <b-form-select id="select-modo-troca" v-model="entity.modoTroca" :options="itensModoTroca">
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-3 mt-2">
                        <b-form-checkbox id="check-validar-simultaneo" v-model="entity.validarSimultaneo" value="S" unchecked-value="N" switch>Validar simultâneo</b-form-checkbox>                      
                        <b-form-checkbox v-model="entity.exibeDuracao" value="S" unchecked-value="N" switch>Exibir duração</b-form-checkbox>
                    </div>  
                  </b-row>  

                </div>
              </b-card-body>
            </div>
          </b-row>
        </div>
  
        <b-row class="mt-2 mb-0 mx-0 p-0">
          <div class="col-md-12 m-0 px-0 pt-2 pb-0 footer-js">
              <b-button size="md" variant="danger" class="btn-fill float-right" @click="fechar">Fechar</b-button>
              <b-button size="md" variant="primary" class="btn-fill float-right mr-1" @click="salvar()">Confirmar</b-button>
          </div>
        </b-row>
      </loading>
      </b-modal>
    </div>
  </template>
  
  <script>
  
  import AgendaService from "../../../service/AgendaService";
  import appUtils from '@/composables/utils'
  import Loading from '../../../layout/Loading'
  //import { VMoney } from 'v-money'
    
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        show: false,
        showFrom: "",
        filaShow: [],
        idsEliminar: [],
        entity: null,
        valorVenda: 0,
        valorCompra: 0,
        itensModoTroca: [{ "value": "L", "text": "Livre" }, { "value": "C", "text": "Autorização" }],
        itensTipo: [],
        money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      }
    },
    mounted() {
  
    },
    methods: {
      novo(idLocal) {  
        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("novoTipoLocal");
        AgendaService.dadosNovoTipoLocal(idLocal).then(resposta => {          
          this.entity = resposta.data.obj.entity
          this.entity.id = null
          this.entity.idTipo = null          
          this.itensTipo = resposta.data.obj.tipos          
          this.valorVenda = this.entity.valorVenda
          this.valorCompra = this.entity.valorCompra
          desativarShow("novoTipoLocal")
        }).catch(error => {
          toast(error, 'Falha', 'danger')
          desativarShow("novoTipoLocal")
        })
  
        this.$bvModal.show('modal-edit_tipo_local')
      },
      edit(id) {
        const { toast } = appUtils(this)        
        AgendaService.editTipoLocal(id).then(resposta => {
          this.entity = resposta.data.obj          
          this.valorVenda = formatarValorNumerico(this.entity.valorVenda)
          this.valorCompra = formatarValorNumerico(this.entity.valorCompra)
        }).catch(error => {
          toast(error, 'Falha', 'danger')        
        })
      },      
      salvar() {
  
        const { toast } = appUtils(this)
  
        this.show = true

        this.entity.valorVenda = parseFloat(this.valorVenda.toString().replace("R$ ", "").replace(".", "").replace(",", "."))
        this.entity.valorCompra = parseFloat(this.valorCompra.toString().replace("R$ ", "").replace(".", "").replace(",", "."))
        AgendaService.saveTipoLocal(this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
              let ret = {
                "status": "success",
                "entity": retorno.obj
              }
              this.$emit('executou', ret)  
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
              this.fechar()
              /*var v = this
              setTimeout(function () {
                v.fechar()
              }, 2000);*/  
            } else {
              if (retorno.mensagens.length > 0) {
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }
  
            this.show = false
          }).catch((error) => {
            this.show = false
            toast(error, 'Falha', 'danger')
          });
  
      },
      fechar() {
        this.hide()
      },
      async abrir(v) {
        this.edit(v)
        this.$bvModal.show('modal-edit_tipo_local')
      },
      hide() {
        this.$bvModal.hide('modal-edit_tipo_local')
      },
      carregarTipos() {
        const { ativarShow, desativarShow } = appUtils(this)
  
        ativarShow("carregarTipos")
  
        let dadosConsulta = {
          simples: "S"
          //idsEliminar: this.idsSituacaoEliminar
        }
  
        AgendaService.listaTiposFilter(dadosConsulta)
          .then((resposta) => {
            this.itensTipo = resposta.data.obj;
            desativarShow("carregarTipos")
          })
          .catch((error) => {
            desativarShow("carregarTipos")
          });
      },
      alterarTipo(idTipo) {
        let obj = this.itensTipo.find((o) => o.id === idTipo)        
      },
    }
  }

  function formatarValorNumerico(valor) {
  const tmp = valor.toString().replace(".", ",").split(",");
  let inteiro = tmp.length > 0 ? tmp[0] : '0';
  let decimal = tmp.length > 1 ? tmp[1].padEnd(2, '0').substr(0, 2) : '00';
  return inteiro + ',' + decimal;
}

  </script>
  
  <style lang="scss">

  .edit-local {    
    label, .btn-radio {
      font-size: 9.5px;
      color: #9a9a9a;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .custom-radio label:after, .custom-radio label:before {
      top: 0;
    }
  }

</style>