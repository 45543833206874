<template>

    <b-modal header-bg-variant="primary" header-text-variant="light" 
      footer-bg-variant="light"
      ref="modal-dados-profissional" id="modal-dados-profissional" size="lg" title="Dados do profissional">

        <div class="container-fluid">

            <b-row class="mx-0">
                <div class="col-md-12">
                    <base-input label="Nome">
                    <b-form-input id="input-nome-profissional" type="text" label="Nome" placeholder="Nome" maxlength="100" v-model="dados.nome" />
                    </base-input>                    
                </div>                
            </b-row>
        </div>

        <template #modal-footer="{ cancel }">
            <div class="w-100">
                <b-button size="md" variant="primary" class="btn-fill float-right" @click="confirmar()">
                    Confirmar
                </b-button>
            </div>
        </template>

    </b-modal>
</template>

<script>

import getUF from '@/composables/getUF'

export default {

    data() {
        return {
            show: false,
            showFrom: "",
            filaShow: [],
            dados: {
                crm: null,
                ufCrm: null,
                email: null,
                celular: null,
                nome: null,
            },
        }
    },
    mounted() {
        
    },
    methods: {        
        confirmar() {          
            this.$emit('executou', this.dados)
            this.fechar()              
        },             
        fechar() {
            this.hide()
        },
        async abrir(v) {
            this.dados = v
            this.$bvModal.show('modal-dados-profissional')
        },
        hide() {
            this.$bvModal.hide('modal-dados-profissional')
        }
    }

}
</script>

<style>

</style>