<template>
  <div style="min-height: 500px;" class="pos-enviar-credenciamento">

    <card>

      <template slot="header">
        <h4 class="card-title">Credenciamento</h4>
      </template>

      <b-container class="c-form">

        <b-row>

          <div class="col-md-12">
            <b-jumbotron>

              <template #header>
                <p class="display-4">Preenchimento dos dados concluído</p>
              </template>

              <template #lead>
                {{ conf ? conf.header : '' }}
              </template>

              <hr class="my-4">

              <div v-if="conf" v-html="conf.body" style="max-height: 1000px; overflow-y:auto;"></div>

              <hr class="my-4">

              <div v-if="conf" v-html="conf.footer" style="max-height: 1000px; overflow-y:auto;"></div>

            </b-jumbotron>
          </div>

          <div class="col-md-7">

          </div>

        </b-row>

      </b-container>
    </card>


  </div>
</template>

<script>
import Card from 'src/components/Cards/Card.vue'
import appUtils from '@/composables/utils'
import AgendaService from '../../service/AgendaService'

export default {
  components: {
    Card,
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      empresa: '',
      idEmpresa: null,
      conf: null,
    }
  },
  async mounted() {

    const { toast } = appUtils(this)

    try {

      this.empresa = this.$route.params.empresa
      //this.empresa = 'em'

      await this.buscarIdEmpresa()
      
      this.posEnviarCredenciamento()

      this.credenciamento = true
    } catch (e) {
      toast(e, 'Falha', 'danger')
    }

  },
  methods: {

    async buscarIdEmpresa() {

      try {

        if (this.empresa) {
          let dadosConsulta = {
            "identificador": this.empresa
          }

          const { toast, ativarShow, desativarShow } = appUtils(this)

          ativarShow("buscarEmpresa");

          await AgendaService.buscarEmpresaPeloIdentificador(dadosConsulta)
            .then((resposta) => {
              let retorno = resposta.data;
              desativarShow("buscarEmpresa");
              if (retorno.status === "SUCCESS") {
                this.idEmpresa = retorno.obj.id
              } else {
                if (retorno.mensagens.length > 0) {
                  toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                }
              }
            }).catch((error) => {
              desativarShow("buscarEmpresa");
              toast(error, 'Falha', 'danger')
            });
        }

      } catch (e) {
        alert(e)
      }

    },
    posEnviarCredenciamento() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("posEnviarCredenciamento")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": this.idEmpresa
      }

      AgendaService.posEnviarCredenciamento(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data.obj
          if (retorno.destino === 'credenciamento') {
            this.conf = retorno.conf
            this.credenciamento = true
          } else {
            this.$router.push({ name: retorno.destino })
          }

          desativarShow("posEnviarCredenciamento")
        }).catch((err) => {
          desativarShow("posEnviarCredenciamento")
          toast(err, 'Falha', 'danger')
        });

    },

  }
}

</script>
<style lang="scss">
.pos-enviar-credenciamento {

  .jumbotron {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

}
</style>