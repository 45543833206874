<template>
  <nav class="navbar navbar-expand-lg">
    <div class="navbar-container" >    
      <!-- container-fluid -->

          <!-- <button type="button" 
              class="navbar-toggler navbar-toggler-left"
              :class="{toggled: $sidebar.showSidebar}"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button> -->

      <MobileSideBar/>

      <div v-if="$store.getters['auth/logoEmpresa'] !== null" class="logo" >
        <a href="#" class="simple-text logo__container" >
            <div class="logo-emp">
                <img @click="escondeMenu" :src="$store.getters['auth/logoEmpresa']" alt="" >
            </div>
        </a>
      </div>

      <a v-if="loggedIn" class="info-topo-grupo-usuario navbar-brand text-truncate" 
      style="margin-right:5px;" href="#" >Painel do Usuário | <span style=" color: #7f8c8d; margin-left:5px;" >{{ $store.getters['auth/userGroupName'] }}</span></a>

      <span v-if="loggedIn && !versaoAtualizada()" class="span-msg-atualizar" style=" color: red; margin-left:5px;" >{{ 'Versão Desatualizada. Pressione CTRL+SHIFT+R para atualizar.' }}</span>

      <div class="collapse navbar-collapse justify-content-end" >

        <ul class="navbar-nav ml-auto">
          
          <li class="nav-item" v-if="false">
            <a href="#" class="nav-link">
              <i class="nc-icon nc-zoom-split"></i>
              <span class="d-lg-block">&nbsp; Pesquisar</span>
            </a>
          </li>
          
          <base-dropdown title="Administrativo" v-if="false && loggedIn && hasMenu('ADM')">            
            <a class="dropdown-item" href="#/admin/cad/local" v-if="hasAccess('CAD_LOCAL')">Locais</a>
            <a class="dropdown-item" href="#/admin/cad/usuario" v-if="hasAccess('CAD_USUARIO')">Usuários</a>            
            <a class="dropdown-item" href="#/admin/cad/profissional" v-if="hasAccess('CAD_PROFISSIONAL')">Profissionais</a>
            <a class="dropdown-item" href="#/admin/cad/tipo" v-if="hasAccess('CAD_TIPO')">Tipos de serviço</a>            
            <a class="dropdown-item" href="#/admin/cad/grupo-usuario" v-if="hasAccess('CAD_GRUPO_USUARIO')">Grupos de usuário</a>            
            <a class="dropdown-item" href="#/admin/cad/empresa" v-if="hasAccess('CAD_EMPRESA')">Empresa</a>
            <div class="divider"></div>            
            <a class="dropdown-item" href="#/admin/cad/situacao" v-if="hasAccess('CAD_SITUACAO')">Situações</a>
          </base-dropdown>
          
          <li class="nav-item" v-if="false">
            <a href="#" @click="logout" size="sm" class="nav-link">
              Sair
            </a>
          </li>

          <base-dropdown class="nav-user-perfil" tag="li" v-if="loggedIn">
            <template slot="title">
              <i class="nc-icon nc-circle-09"></i>
              <b class="info-topo-usuario caret ml-1 text-truncate" >{{$store.getters['auth/userName']}}</b>
              <span class="notification" v-if="false">5</span>
            </template>
           <!--  <a class="dropdown-item" href="#/admin/agenda" v-if="agendaAtivar">Agenda</a> -->
            <a class="dropdown-item" href="#/admin/minhaConta" >Minha conta</a>
            <div class="divider"></div>
            <a class="dropdown-item" href="#" @click="logout">Sair</a>
          </base-dropdown>

          <!-- li class="nav-item" v-if="!loggedIn && !isTelaLogin">            
            <b-button class="btn btn-primary btn-fill" size="sm" @click="login()">Login</b-button>
          </li !-->

        </ul>
      </div>

    </div>
  </nav>
</template>
<script>

import axios from 'axios'
import { BASE_CONSTANTS } from '../service/config'
import MobileSideBar from './MobileSideBar.vue';

export default {
  mounted() {
      this.http = axios.create({
        baseURL: BASE_CONSTANTS.urlBase, headers: {Authorization:"bearer " +String(localStorage.getItem("authorization")).replaceAll('"', ""),
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      });

      //this.startTimer()
    },
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },    
    data () {
      return {
        http: null
      }
    },
    components: {
      MobileSideBar
    },
  methods: {
      login() {        
        this.$router.push('/login/acesso')          
      },
      logout() {
        
        /*let token = localStorage.getItem('authorization')
        if (token !== null && token !== undefined && token !== '' || token !== 'null') {
          this.http.get(BASE_CONSTANTS.urlBase + 'usuario/logout?access_token='+String(token).replaceAll('"',''))
            .then((response) => {
              
            }).catch(error =>{
              
          });
        }
          localStorage.setItem('authorization', null)
          localStorage.setItem('eventosArmazenados', null)
          localStorage.setItem('itensSetor', null)
          localStorage.setItem('itensProfissionais', null)
          localStorage.setItem('itensLocal', null)
          localStorage.setItem('dadosUsuario', null)
        localStorage.setItem('refresh_token', null) */         
          
        this.$store.dispatch('auth/logout');
        this.$router.push('/login/acesso')
          
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },      
      hasAccess(menu) {
        return this.$store.getters['auth/hasAccess'](menu)
      },
      hasMenu(posicao) {        
        return this.$store.getters['auth/hasMenu'](posicao)
      },
      versao() {
        return this.$store.getters['auth/versao']
      },
    versaoAtualizada() {        
      return this.versao() === BASE_CONSTANTS.versao        
    },            
    escondeMenu(){
      this.mostraMenu = !this.mostraMenu
    },
    /*formattedTimeLeft() {
      const timeLeft = this.$store.state.auth.timer
      if (timeLeft) { 
        const minutes = Math.floor(timeLeft / 60)
        let seconds = timeLeft % 60
        if (seconds < 10) {
          seconds = `0${seconds}`
        }
        return `${minutes}:${seconds}`
      } else {
        return ""
      }
    },*/     
  },
  computed: {
    loggedIn() {      
      return this.$store.state.auth.status.loggedIn
    }
  }
}

</script>
<style>
@media (min-width: 990px) {
  .navbar-expand-lg {
    padding: 0 !important;
  }
}

@media (min-width: 0px) and (max-width:989px) {
  .logo,
  .span-msg-atualizar {
    display: none;
  }

}
  .navbar-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  }
  .nav-user-perfil .dropdown-menu{
    margin-left: -60px;
  }

  @media (max-width: 576px){
  .info-topo-grupo-usuario{
    max-width: 85%;
  }
  
}

</style>
