<template>
  <footer class="footer fixed-bottom">
    <div class="container-fluid">
      <nav>
        <ul class="footer-menu">
          <li>
            <!-- router-link :to="{path:'/admin'}">Dashboard</router-link -->
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; 2024 
        <a href="https://escalamedica.com.br" target="_blank">Escala Médica.</a>
        <a href="https://www.escalamedica.com.br" target="_blank"> Todos os direitos reservados.</a>
        <span> - Versão: {{ versao }} </span>
      </div>
    </div>
  </footer>
</template>
<script>

import { BASE_CONSTANTS } from "../service/config";

export default {
  data() {
    return {
      versao: BASE_CONSTANTS.versao
    }
  },
  methods: {
      
  }

}
</script>
<style>
.fixed-bottom {
  z-index: 99;
}
</style>
