<template>
  <div>
    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir() }">
      <template v-slot:texto>Confirma exclusão das agendas selecionadas?</template>
    </confirma>

    <card>

      <template slot="header">
        <h4 class="card-title">Relatórios</h4>
      </template>

      <div class="container-fluid c-form manutencao-agenda">

        <div class="row">

          <div class="col-md-2 filtro">
            <b-form-select v-model="modelo" :options="optionsFiltroModelo"></b-form-select>
          </div>

          <div class="col-md-3">
            <b-input-group>
              <b-button variant="primary" size="sm" class="mb-2  mr-1" @click="goMesAnterior">
                <b-icon icon="arrow-left-circle"></b-icon>
              </b-button>
              <b-form-datepicker id="dtInicio" v-model="dtInicio" size="sm" class="mb-2" placeholder="Início do período"
                today-button reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                close-button-variant="success" label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
                label-reset-button="Limpar" label-close-button="Fechar"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
              </b-form-datepicker>

              <b-input-group-text class="mb-2">até</b-input-group-text>

              <b-form-datepicker id="dtFim" v-model="dtFim" size="sm" class="mb-2" placeholder="Início do período"
                today-button reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                close-button-variant="success" label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
                label-reset-button="Limpar" label-close-button="Fechar"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
              </b-form-datepicker>

              <b-button variant="primary" size="sm" class="mb-2 ml-1" @click="goMesSeguinte">
                <b-icon icon="arrow-right-circle"></b-icon>
              </b-button>

            </b-input-group>
          </div>

          <div class="col-md-1">
            <b-form-select ref="input-uf" v-model="uf" :options="optionsUF" value-field="value" text-field="value">
              <template #first>
                <b-form-select-option :value="null">UF do CRM</b-form-select-option>
              </template>
            </b-form-select>
          </div>

          <div class="col-md-2">
            <v-select id="input-especialidade" v-model="idEspecialidade" :options="listaEspecialidade"
              :reduce="especialidade => especialidade.id" label="descricao" placeholder="Especialidade">
              <template #no-options>
                Nenhuma especialidade encontrada
              </template>
            </v-select>
          </div>

          <div class="col-md-2">
            <v-select v-model="idSituacao" :options="listaSituacao" :reduce="situacao => situacao.id" label="descricao"
              ref="select" placeholder="Selecione a situação">
              <span slot="no-options" @click="$refs.select.open = false">
                Nenhum registro encontrado
              </span>
            </v-select>
          </div>

          <div class="col-md-2">
            <button type="button" class="float-right btn btn-secondary btn-fill btn-sm px-4 ml-1" @click="exportar">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Exportar
            </button>
            <button type="button" class="float-right btn btn-primary btn-fill btn-sm px-4" @click="buscar">
              <b-icon icon="bar-chart-steps" aria-hidden="true"></b-icon>
              Gerar
            </button>
          </div>
        </div>

        <b-row>
          <b-col cols="12" class="pt-2">

            <b-table empty-text="Nenhum registro" :items="items" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty sticky-header="500px"
              small >
              <template #cell(name)="row">
                {{ row.value.first }} {{ row.value.last }}
              </template>

              <template v-slot:cell(dtCredenciamento)="row">
                {{ strDateFormat(row.item.dtCredenciamento, 'dd/MM/yyyy') }}
              </template>
            </b-table>
          </b-col>

        </b-row>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"></b-pagination>
          </div>

        </div>

      </div>
    </card>
  </div>
</template>

<script>
import Card from 'src/components/Cards/Card.vue'
import AgendaService from '../../service/AgendaService'
import appUtils from '@/composables/utils'
import Confirma from '../comuns/Confirma'
import FiltroLocal from '../comuns/FiltroLocal'
import funcao from "src/components/Funcao";
import getUF from '@/composables/getUF'

export default {
  components: {
    Card,
    Confirma,
    FiltroLocal,
  },
  data() {
    return {
      survey: null,
      http: null,
      msg: null,
      show: false,
      showFrom: "",
      filaShow: [],
      loading: false,
      dtInicio: null,
      dtFim: null,
      fields: [
        { key: 'dtCredenciamento', label: 'Data do credenciamento', sortable: true, sortDirection: 'asc', thStyle: { width: "14%" } },
        { key: 'nomeProfissional', label: 'Profissional', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },
        { key: 'crm', label: 'CRM', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
        { key: 'ufCrm', label: 'UF CRM', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
      ],
      items: [],
      showConfirma: false,
      msgConfirma: null,
      perPage: 10,
      pageOptions: [5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      modelo: null,
      idEspecialidade: null,
      idSituacao: null,
      listaSituacao: [],
      listaEspecialidade: [],      
      uf: null,
      optionsUF: [],
      optionsFiltroModelo: [
        { value: null, text: 'Selecione o modelo' },
      ],
    }
  },
  mounted() {

    const dataAtual = new Date()
    const anoAtual = dataAtual.getFullYear()
    this.anos = [anoAtual - 1, anoAtual]

    this.mes = dataAtual.getMonth() + 1
    this.ano = anoAtual

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
    this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")

    this.exibirValorVenda = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_VENDA', 'E')
    this.exibirValorCompra = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_COMPRA', 'E')

    this.isRelEscalaPdf = this.hasPermissionWith('RELATORIO', 'ESCALA_EM_PDF', 'E')
    this.isRelEscalaPlanilha = this.hasPermissionWith('RELATORIO', 'ESCALA_EM_PLANILHA', 'E')
    this.isRelConsPorProf = this.hasPermissionWith('RELATORIO', 'CONSOLIDADO_POR_PROFISSIONAL', 'E')
    this.isRelDetPorProf = this.hasPermissionWith('RELATORIO', 'DETALHADO_POR_PROFISSIONAL', 'E')
    this.isRelPrevReal = this.hasPermissionWith('RELATORIO', 'PREVISTO_X_REALIZADO', 'E')

    this.optionsFiltroModelo.push({ value: 'listagem', text: 'Listagem' })
    this.modelo = 'listagem'

    this.optionsUF = getUF()

    this.buscarEspecialidades()
    this.buscarSituacoes()

  },

  methods: {
    goMesAnterior() {
      const dataAtual = new Date(this.dtInicio + "T00:00:00")
      dataAtual.setMonth(dataAtual.getMonth() - 1);
      var firstDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1)
      var lastDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0)
      this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
      this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")
    },
    goMesSeguinte() {
      const dataAtual = new Date(this.dtInicio + "T00:00:00")
      dataAtual.setMonth(dataAtual.getMonth() + 1);
      var firstDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1)
      var lastDay = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0)
      this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
      this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")
    },
    filterBy(option, label, search) {
      const { filterBy } = appUtils(this)
      return filterBy(option, label, search)
    },
    buscar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarCredenciamentos");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "idSituacao": this.idSituacao,
        "uf": this.uf,
        "idEspecialidade": this.idEspecialidade,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
      }

      AgendaService.listaCredenciamento(dadosConsulta)
        .then((resposta) => {
          this.items = resposta.data.obj
          this.totalRows = this.items.length
          desativarShow("listarCredenciamentos");
        })
        .catch((err) => {
          desativarShow("listarCredenciamentos");
        });      
    },
    buscarEspecialidades() {

      const { ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa
      }

      AgendaService.listaEspecialidade(dadosConsulta)
        .then(resposta => {
          this.listaEspecialidade = resposta.data.obj
        }).catch(error => {
        })

    },
    buscarSituacoes() {

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTipo": 2
      }

      AgendaService.recuperaSituacoesGeral(dadosConsulta)
        .then(resposta => {
          this.listaSituacao = resposta.data.obj
          let s = this.listaSituacao.find((s) => s.identificador == 'CCD')
          if (s) {
            this.idSituacao = s.id
          }
        }).catch(error => {

        })

    },
    exportar() {
      
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportar");

      let dadosConsulta = this.montarFiltro()
      dadosConsulta.camposExportar = [
        { label: "Data do Credenciamento", campo: "dtCredenciamento", largura: 5, tipo: "D" },
        { label: "Profissional", campo: "nomeProfissional", largura: 10, tipo: "S" },
        { label: "CRM", campo: "crm", largura: 3, tipo: "S" },
        {label: "UF CRM", campo: "ufCrm", largura: 3, tipo: "S"}
      ]
      
      AgendaService.exportarListaCredenciamento(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;

          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj            
            funcao.methods.downloadExcel(base64)
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
          desativarShow("exportar");
        })
        .catch((err) => {
          desativarShow("exportar");
        });     

    },
    montarFiltro(){
      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "idSituacao": this.idSituacao,
        "idEspecialidade": this.idEspecialidade,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,        
      }
      return dadosConsulta
    },
    strDateFormat(dataStr, format) {
      if (dataStr !== null) {
        let data = new Date(dataStr + " (UTC-3)")
        return dateToStr(data, format);
      } else {
        return ""
      }
    },    
    hasPermission(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
    hasPermissionWith(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
  },
  computed: {
    rows() {
      return this.dadosRelatorio.itens === undefined ? 0 : this.dadosRelatorio.itens.length
    },
    existeAgendaSelecionada() {
      return this.itens.filter(function (elem) {
        return elem.seleciona === 'S'
      }).length > 0
    }

  }
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format.replace('yyyy', date.getFullYear())
      .replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'))
      .replace('dd', date.getDate().toString().padStart(2, '0'))
      .replace('HH', date.getHours().toString().padStart(2, '0'))
      .replace('mm', date.getMinutes().toString().padStart(2, '0'))
      .replace('ss', date.getSeconds().toString().padStart(2, '0'));
    return strDate;
  }
}

</script>
<style lang="scss">
.manutencao-agenda {

  .c-form {
    padding-bottom: 60px;
  }

  tbody {
    max-height: 300px;
  }

  .grid-td {
    width: 100%;
  }

  .grid-td .grid-line {
    padding-left: 10px;
    padding-top: 1px;
    /*border-left: 5px solid red !important;*/
    width: 100%;
    height: 50px;

  }

  .grid-line .div-grid-line {
    width: 100%;
  }

  .div-grid-line .div-grid-line-cor {
    width: 38px;
  }

  .cor-fim-semana {
    color: red;
  }

  .fonte-local {
    font-size: 0.7rem;
  }

}
</style>
