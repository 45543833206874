<template>
  <b-container fluid class="login-page" >
    
    <div class="row internal-content">

      <div class="col-9 left-side">

        <div class="row background-login">

          <div class="col-12">

          </div>

          <div class="col-12">
            <h1 class="main-text-background">Gestão Inteligente <br/>de <span style="color:#3f7bb7">Plantões</span> para <br/>Hospitais e <br/>Clínicas Médicas</h1>
          </div>

        </div>
        
      </div>

      <div class="col-3 right-side">
        
        <div class="row box-login m-0 p-0">

          <div class="col-12 logo-login">
            <img :src="$store.getters['auth/logoEmpresa']" alt="" >
          </div>

          <div class="col-12 mt-5">
            <b-form-group class="mt-2" label="E-mail" label-for="input-email" label-class="text-primary font-weight-bold">
              <b-form-input placeholder="Digite seu e-mail" id="input-email" v-model="user.username" trim></b-form-input>
            </b-form-group>                  
          </div>

          <div class="col-12">
            <b-form-group label="Senha" label-for="input-senha" label-class="text-primary font-weight-bold">
              <b-form-input placeholder="Digite sua senha" type="password" id="input-senha" v-model="user.password" trim></b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 text-center mt-3">
            <b-button type="submit" variant="primary" class="btn btn-primary btn-fill font-weight-bold px-5" size="lg" :disabled="logando" @click.prevent="handleLogin">Entrar</b-button>                
          </div>

          <div class="col-12 text-center mt-3">
            <router-link :to="{ name: 'RecuperarSenha' }" class="link-primary">Esqueceu sua senha?</router-link>                
          </div>

          <div class="col-12 text-center mt-3">
            <router-link :to="{name: 'CriarConta', params: {empresa: empresa} }" class="link-primary" v-if="empresa !== ''">Criar conta</router-link>
          </div>

        </div>

      </div>

    </div>

    <div class="content" v-if="false">

      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-8">
          <span>
            <form>

              <b-card header-bg-variant="header-modal-js" border-variant="primary" class="mb-0 pb-0">

                <template #header>
                  <h3 class="card-title text-center text-white">Login</h3>
                </template>

                <div>

                  <b-form-group class="mt-2" label="E-mail" label-for="input-email">
                    <b-form-input placeholder="Digite seu e-mail" id="input-email" v-model="user.username" trim></b-form-input>
                  </b-form-group>

                  <b-form-group label="Senha" label-for="input-senha">
                    <b-form-input placeholder="Digite sua senha" type="password" id="input-senha"
                      v-model="user.password" trim></b-form-input>
                  </b-form-group>

                </div>
                <div class="text-center pt-3">
                  <b-button type="submit" variant="primary" class="btn btn-primary btn-fill w-100" size="lg" :disabled="logando"
                    @click.prevent="handleLogin">Entrar</b-button>
                </div>
                <div class="text-center pt-3">
                  <router-link :to="{ name: 'RecuperarSenha' }" class="link-primary">Esqueceu sua senha?</router-link>
                </div>
                <div class="text-center pt-3">
                  <router-link :to="{name: 'CriarConta', params: {empresa: empresa} }" class="link-primary" v-if="false && empresa !== ''">Criar conta</router-link>
                </div>
              </b-card>
              <div class="clearfix"></div>
            </form>
          </span>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import AgendaService from "../../service/AgendaService";
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../service/config'
import appUtils from '@/composables/utils'
import User from "../../model/user"

export default {
  components: {
    Card
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      empresa: '',
      logando: false,
      user: new User('', ''),
      /*user: {
        email: '',
        senha: ''
      },*/
      msg: '',
      appVersion: process.env.VUE_APP_VERSION
    }
  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Content-Type': 'application/json',
      }
    })

    const regex = /^\/(.*)\/credenciamento$/
    const matches = regex.exec(this.$route.query.redirect);
    if (matches && matches.length == 2) {      
      this.empresa = matches[1]
    } else {
      this.empresa = 'em'
    }

    this.$store.dispatch('auth/logout')
  },
  methods: {
    handleLogin() {
      if (this.user.username && this.user.password) {

        this.logando = true

        const { toast, ativarShow, desativarShow } = appUtils(this)

        toast('Validando usuário...', 'Autenticando', 'info')

        this.$store.dispatch('auth/login', this.user)
          .then((user) => {
            this.validarVersao()

            toast('Carregando informações...', 'Autenticado', 'success')

            var nameRouter = null
            var params = null

            let redirect = this.$route.query.redirect
            let url = this.urlPadrao()//'/admin/agenda'
            if (redirect !== undefined) {
              url = redirect
            } else if (user.urlPadrao !== null && user.urlPadrao !== undefined) {
              url = user.urlPadrao
            }
            

            if (user.credenciamentosPendente && user.credenciamentosPendente.length) {              
              //nameRouter = 'Credenciamentoi';
              //params = { iniciarPreenchimento: true }          
              
              if(!this.empresa || user.credenciamentosPendente.filter(f => f.empresa == this.empresa).length){
                url = '/admin/credenciamentoi'
              }

            }

            toast('Configurando permissões...', 'Permissões', 'success')
            var v = this
            setTimeout(function () {
              if (nameRouter) {                
                v.$router.push({ name: nameRouter, params: params } );
              } else {                
                v.$router.push(url);
              }
            }, 1000);
            

          }, error => {
            this.logando = false
              toast('Usuário e/ou senha incorreto(s)!', 'Falha', 'danger')
            }
          );
      }
    },
    async carregarEmpresa() {
      if (this.empresa !== '') {

        try {

          let dadosConsulta = '{ "identificador": "' + this.empresa + '"}';

          const { toast, ativarShow, desativarShow } = appUtils(this)

          ativarShow("buscarEmpresa");

          await AgendaService.buscarEmpresaPeloIdentificador(dadosConsulta)
            .then((resposta) => {
              let retorno = resposta.data;

              if (retorno.status === "SUCCESS") {
                this.$store.dispatch('auth/loadEmpresa', retorno.obj);
              } else {
                if (retorno.mensagens.length > 0) {
                  toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                }
              }

              desativarShow("buscarEmpresa");

            }).catch((error) => {
              desativarShow("buscarEmpresa");
              toast(error, 'Falha', 'danger')
            });

        } catch (e) {
          alert(e)
        }

      }
    },
    validarVersao() {
      var isAtualizar = this.versao() !== BASE_CONSTANTS.versao
      if (isAtualizar) {
        //var url = window.location.origin        
        //window.location = url
        //window.location.reload(true)
      }
    },
    versao() {
      return this.$store.getters['auth/versao']
    },
    urlPadrao() {
      return this.$store.getters['auth/urlPadrao']
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/admin/agenda');
    }
  },
}

</script>
<style lang="scss">
.principal {
  margin-top: 200px;
}

.login-page .card {
  border-radius: 10px;
  /*padding-top: 30px;*/
  padding-bottom: 30px;
}

.login-page .content {
  padding-top: 12vh;
}

.login-page{

  height: 100%;

    .internal-content{

      height: 100%;

      .left-side{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #01406b;
        height: 100%;
      }

      .right-side{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fafafa;
        padding: 0 32px;
        height: 100%;
      }

    }

    .logo-login{

      max-width: 250px;
      text-align: center;
      margin: auto;

      img{
        max-width: 260px;
        max-height: 60px;
      }
    }

    .background-login{
      
      width: calc(100% - 400px);
      height: 80%;

      .main-text-background{
        color: white;
        width: 70%;
        font-size: 70px;
      }

    }

}

</style>