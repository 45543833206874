<template>

  <div>  
  
      <confirma ref="conf" @respondeu="(r) => { if(r === 'S') excluir() }" >
        <template v-slot:texto>Confirma exclusão da especialização</template>
      </confirma>
   
      <div>
  
      <card class="h-100" >
  
        <template slot="header">
          <h4 class="card-title">Graduação</h4>
        </template>      
  
      <b-container :fluid="fluid"> 
      
      <b-row class="graduacao" >
        <div class="box-especializacao col-12 px-2">          
            
            <b-row class="mx-0 px-0">

              <b-col class="col-12" >
                <b-form-group v-if="especializacao.editing == 'S'" label="Título" label-for="input-titulo">
                  <b-form-input id="input-titulo" type="text" label="Título" placeholder="Título" v-model="especializacao.titulo" />                                      
                </b-form-group>                
                <p v-else class="text-truncate"><span class="font-weight-bold">Títutlo: </span> {{especializacao.titulo}}</p> 
              </b-col>              

              <b-col class="col-12 pr-0" >
                <b-form-group v-if="especializacao.editing == 'S'" label="Instituição" label-for="input-instituicao">
                  <b-form-input :readonly="especializacao.editing == 'N'" id="input-instituicao" type="text" label="Instituição" placeholder="Instituição" v-model="especializacao.instituicao" />                    
                </b-form-group>
                <p v-else class="text-truncate"><span class="font-weight-bold">Instituição: </span> {{especializacao.instituicao}}</p> 
              </b-col>

            </b-row>

            <b-row class="mx-0">
              
              <b-col class="col-12 col-md-2" v-if="especializacao.idTipo == 4">

                <b-form-group v-if="especializacao.editing == 'S'" class="mb-0" label="Especialidade" label-for="input-especialidade">
                  <v-select 
                      id="input-especialidade"
                        v-model="especializacao.idEspecialidade"
                        :options="listaEspecialidade"
                        :reduce="especialidade => especialidade.id"
                        label="descricao"                  
                        placeholder="Especialidade">
                        <template #no-options>
                              Nenhuma especialidade encontrada
                        </template>                        
                  </v-select>                    
                  </b-form-group>
              <p v-else class="text-truncate"><span class="font-weight-bold">Especialidade: </span> {{especializacao.dscEspecialidade}}</p> 

              </b-col>

              <b-col class="col-12 col-md-2" v-if="especializacao.idTipo == 4">
                <b-form-group v-if="especializacao.editing == 'S'" label="RQE" label-for="input-rqe">
                  <b-form-input id="input-rqe" type="text" label="RQE" placeholder="RQE" v-model="especializacao.rqe" />                    
                </b-form-group>
                <p v-else class="text-truncate"><span class="font-weight-bold">RQE: </span> {{especializacao.rqe}}</p> 
              </b-col>

              <b-col class="col-12 col-md-2" v-if="especializacao.idTipo == 5">
                <b-form-group v-if="especializacao.editing == 'S'" class="mb-0" label="Nível" label-for="input-nivel">
                    <b-form-select 
                      id="input-nivel"                      
                        v-model="especializacao.nivel"                        
                        :options="niveisEspecializacao"
                        size="md">                      
                      </b-form-select>                                        
                  </b-form-group>
                  <p v-else class="text-truncate"><span class="font-weight-bold">Nível: </span> {{especializacao.nivel}}</p>
              </b-col>

              <b-col class="col-12 col-md-3">
                <b-form-group v-if="especializacao.editing == 'S'" class="mb-0" label="Data de obtenção" label-for="input-data-obtencao">
                    <b-form-input 
                    id="input-data-obtencao"
                    v-mask="'##/####'"
                    label="Obtenção"
                    placeholder="MM/yyyy"
                    v-model="especializacao.dtObtencao"
                    size="sm">
                    </b-form-input>
                  </b-form-group>
                  <p v-else class="text-truncate"><span class="font-weight-bold">Data obtenção: </span> {{especializacao.dtObtencao}}</p>
              </b-col>

              <b-col class="col-12 col-md-3">
                <b-form-group v-if="especializacao.editing == 'S'" class="mb-0" label="Situação" label-for="input-situacao">
                    <b-form-select 
                      id="input-situacao"                      
                        v-model="especializacao.idSituacao"
                        value-field="id"
                        text-field="descricao"
                        size="md">                      
                        <b-form-select-option v-for="s in getSituacoesEspecializacao(['CCA'])" v-bind:key="s.id" value="s.id">{{s.descricao}}</b-form-select-option>
                      </b-form-select>                    
                      
                      
                  </b-form-group>
                  <p v-else class="text-truncate"><span class="font-weight-bold">Situação: </span> {{especializacao.dscSituacao}}</p>
              </b-col>
              
            </b-row>
            
            <b-row class="mx-0">
              <b-col class="col-12">                    
                      
                      <base-input label="Documento de comprovação" >                      
                        <b-form-file v-if="especializacao.editing == 'S'" multiple capture size="sm"
                        browse-text="Procurar"              
                        :id="especializacao.id.toString()"
                          :state="Boolean(fileSelecionado)"
                          placeholder="Informe o documento ( Frente )"
                          drop-placeholder="Arraste o arquivo aqui..."
                          accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                          @change="submitFile">
                        </b-form-file>                

                        <b-form-file v-if="especializacao.editing == 'S'" multiple capture size="sm"
                        browse-text="Procurar"              
                        :id="especializacao.id.toString()"
                          :state="Boolean(fileSelecionado)"
                          placeholder="Informe o documento ( Verso )"
                          drop-placeholder="Arraste o arquivo aqui..."
                          accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                          @change="submitFile">
                        </b-form-file>
                        <div class="mt-1">
                          <b-list-group v-for="(doc, index) in especializacao.documentos" :key="index" >
                            <b-list-group-item button class="d-flex justify-content-between align-items-center" >        
                              <b-link class="text-primary text-truncate" @click="abrirArquivo(especializacao.id, doc.id)" >
                                {{ doc.nome }}
                              </b-link>
                              <b-icon v-if="especializacao.editing == 'S'" icon="trash" variant="danger" class="border m-2" scale="2.0" @click="removerDocumento(especializacao.id, doc.id)"></b-icon>
                            </b-list-group-item>
                          </b-list-group>                          
                        </div>
                      </base-input>          
                    </b-col>
            </b-row>
            

        </div>

      </b-row>      
          
    </b-container>                  
  
  
    </card>
  
    </div>
  
    <div class="clearfix"></div>
    
  </div>
  
  </template>
  
  <script>
    import AgendaService from '../../service/AgendaService'
    import Card from 'src/components/Cards/Card.vue'
    import axios from 'axios'
    import { BASE_CONSTANTS } from '../../service/config'
    import appUtils from '@/composables/utils'
import Confirma from './Confirma'
import funcao from "../../components/Funcao"
    
export default {
      props: ['especializacao','leitura','fluid'],
      components: {
        Card, 
        Confirma    
      },
      data() {           
        return {
          show: false,
          http: null,
          showFrom: "",
          filaShow: [],
          id: null,          
          fileSelecionado: null,
          tiposEspecializacao: [],
          listaEspecialidade: [],
          niveisEspecializacao: [],          
          situacoesEspecializacao: [],
          copia: null,
        }
      },
      computed: {
        
      },
      mounted() {
  
        this.http = axios.create({
          baseURL: BASE_CONSTANTS.urlBase,
          headers: {
              'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
              'Content-Type': 'application/json',
              'Accept': '*/*'
          }
        })      

        this.inicializar()
        this.carregaDados()

      },
    methods: {        
      inicializar() {
  
        const { ativarShow, desativarShow } = appUtils(this)

        this.buscarTiposEspecializacao()
        this.buscarEspecialidades()
        this.buscarNiveisEspecializacao()
        this.buscarSituacoesEspecializacao()
      
      },
      buscarTiposEspecializacao() {
  
        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscarTiposEspecializacao");

        AgendaService.listaTipoEspecializacao(this.http)
          .then(resposta => {
            this.tiposEspecializacao = resposta.data.obj            
            desativarShow("buscarTiposEspecializacao");
        }).catch(error => {
            desativarShow("buscarTiposEspecializacao");          
        })

      },
      buscarEspecialidades() {

        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscarEspecialidades");

        let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa
        }      

        AgendaService.listaEspecialidade(dadosConsulta)
          .then(resposta => {
            this.listaEspecialidade = resposta.data.obj            
            desativarShow("buscarEspecialidades");
        }).catch(error => {
            desativarShow("buscarEspecialidades");          
        })

      },
      buscarNiveisEspecializacao() {

        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscarNiveisEspecializacao");

        AgendaService.listaNivelEspecializacao(this.http)
          .then(resposta => {
            this.niveisEspecializacao = resposta.data.obj            
            desativarShow("buscarNiveisEspecializacao");
        }).catch(error => {
            desativarShow("buscarNiveisEspecializacao");          
        })

      },
      buscarSituacoesEspecializacao() {

        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("buscarSituacoesEspecializacao");

        let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idTipo": 3
        }      

        AgendaService.recuperaSituacoesGeral(dadosConsulta)
          .then(resposta => {
            this.situacoesEspecializacao = resposta.data.obj            
            desativarShow("buscarSituacoesEspecializacao");
        }).catch(error => {
            desativarShow("buscarSituacoesEspecializacao");          
        })

      },
      getSituacoesEspecializacao(i) {
        return this.situacoesEspecializacao.filter((s) => i.indexOf(s.identificador) > -1)
      },
      incluir() {             

        let minId = this.especializacoes.reduce(
          (min, prof) => (prof.id < min ? prof.id : min),
          0
        )

        minId -= 1;

        this.especializacoes.push({
          "id": minId,
            "idPessoa": null,
            "titulo": '',
            "nivel": '',
            "instituicao": '',
            "idEspecialidade": null,
            "dscEspecialidade": '',
            "idSituacao": null,
            "dscSituacao": '',
            "rqe": '',
            "dtObtencao": '',
            "documentos": [],
          "editing": 'N'
        })

        this.editar(minId)
        
        },
      editar(id) {

        if (this.showSave) {
          this.especializacoes.forEach((e) => {
            e.editing = 'N'
          })          
        }

          let item = this.especializacoes.find(e => e.id === id)
          this.copia = {...item}
          item.editing = 'S'          

        },
      cancelar(id) {          
        
        if (id < 0) {

          //this.especializacoes = this.especializacoes.filter((e) => { return e.id !== id })
          this.$emit('remove', id)

        } else {
          let item = this.especializacoes.find(e => e.id === id)        
          item.editing = 'N'
          let index = this.especializacoes.indexOf(item);        

          this.especializacoes.splice(index, 1, this.copia);
        }        

      },
      carregaDados(){
  
          const { toast, ativarShow, desativarShow } = appUtils(this)
  
          ativarShow("listarCredenciamentos");
  
        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
  
        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idSituacao": this.idSituacao,
          "tipoData": this.tipoFiltroData,
          "dtInicio": this.dtInicio,
          "dtFim": this.dtFim,
        }      
        
        AgendaService.listaCredenciamento(dadosConsulta)
          .then((resposta) => {           
            this.items = resposta.data.obj  
            this.totalRows = this.items.length                    
            desativarShow("listarCredenciamentos");
          })
          .catch((err) => {          
            desativarShow("listarCredenciamentos");
          });
  
        },      
        salvar(id) {
          
          const { toast, ativarShow, desativarShow } = appUtils(this)

          ativarShow("salvar");         

          let item = this.especializacoes.find(e => e.id === id)
          
          AgendaService.saveEspecializacao(this.http, item)
            .then((resposta) => {
              let retorno = resposta.data;
              if (retorno.status === "SUCCESS") {            
                let index = this.especializacoes.indexOf(item);     
                this.especializacoes.splice(index, 1, retorno.obj);
                toast(retorno.mensagens[0].descricao, "Sucesso", "success")                
              } else {                        
                if (retorno.mensagens.length > 0) {              
                  toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                }
              }              
              desativarShow("salvar");
            }).catch((error) => {          
              desativarShow("salvar");
              toast(error, 'Falha', 'danger')
            });

      },
      confirmaExcluir(id) {
          
        this.id = id
        this.$refs.conf.show()        
          
      },
      excluir() {
        if (this.id < 0) {
          this.cancelar(this.id)
        } else {

          const { toast, ativarShow, desativarShow } = appUtils(this)
          ativarShow("excluir");

          AgendaService.deleteEspecializacao(this.http, this.id).then(resposta => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
              this.especializacoes = this.especializacoes.filter((e) => { return e.id !== this.id })
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            } else {
              if (retorno.mensagens.length > 0) {
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }
            desativarShow("excluir");
          }).catch(error => {
            desativarShow("excluir");
            toast(error, "Falha", "danger")
          })

        }

      },
      atualizarRegistro(entity) {
          let index = this.items.indexOf(this.items.find((i) => i.id === entity.id));
          if (index >= 0) {
            this.items.splice(index, 1, entity);
          } else {          
            this.items.push(entity);
          }
      },
      abrirSelecionaProfissional() {    
          const { toast } = appUtils(this)
          let idsEliminar = this.locais.locais.map(elem => elem.idLocal);
          this.$refs.selectLocal.abrir(idsEliminar)      
        },    
      adicionarProfissional(locais) {
          locais.forEach((item) => {              
              
            let reg = {
              idLocal:  item.id,
              dscLocal: item.descricao,            
            }
  
            this.locais.locais.push(reg);

          });          
      },
      rowClass(item, type) {
           if (!item || type !== 'row') return
          if (item.deleted === 'S') return 'table-danger'
        },
        abrirCredenciamento(record, index) {
          this.editar(record.id)
      },
      strDateFormat(dataStr, format) {
        if (dataStr !== null) {
          let data = new Date(dataStr + " (UTC-3)")
          return dateToStr(data, format);
        } else {
          return ""
        }
      },      
      submitFile(event) {

        var espec = this.especializacoes.find(e => e.id.toString() === event.target.id);
        let index = 0;

        let lerArquivo = (file) => {

            var reader = new FileReader();
          reader.onload = (e) => {      
            var minId = espec.documentos.reduce((min, doc) => (doc.id < min ? doc.id : min), 0)            
            minId -= 1;      
              espec.documentos.push({
                "id": minId,
                "nome" : file.name,            
                "arquivo": e.target.result,
                "idTipo": null
              })                      
            };            
            reader.readAsDataURL(file)
            reader.onerror = function (error) {
              console.log('Error: ', error)
            }
        };

        for (index = 0; index < event.target.files.length; index++){
          var file = event.target.files[index];
          lerArquivo(file)
        }
      },
      alterarTipo(id, idTipo) {
        let item = this.especializacoes.find(e => e.id === id)          
        let tipo = this.tiposEspecializacao.find(t => t.id === idTipo)
        item.dscTipo = tipo ? tipo.descricao : ''
      },    
      abrirArquivo(idEspec, idDoc) {
        var espec = this.especializacoes.find(e => e.id = idEspec);        
        var doc = espec.documentos.find(d => d.id === idDoc);
        funcao.methods.downloadData(doc.arquivo, doc.nome);
      },  
      removerDocumento(idEspec, idDoc) {
        var espec = this.especializacoes.find(e => e.id = idEspec);  
        espec.documentos = espec.documentos.filter((d) => { return d.id !== idDoc })
      }
  },  
  /*watch: {
    especializacoes (newValue) {
      this.especializacoesInterna = newValue // Update when the prop changes
    },
    especializacoesInterna(newValue) {
      console.log(newValue)
      this.$emit('passData', this.especializacoesInterna)
    }
  }*/
    
  }
  
  function dateToStr(date, format) {  
    if (date !== undefined && date !== null) {
      let strDate = format
        .replace("yyyy", date.getFullYear())
        .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
        .replace("dd", date.getDate().toString().padStart(2, "0"))
        .replace("HH", date.getHours().toString().padStart(2, "0"))
        .replace("mm", date.getMinutes().toString().padStart(2, "0"))
        .replace("ss", date.getSeconds().toString().padStart(2, "0"));
      return strDate;
    }
    return "";
  }
  
  </script>
  <style>

    /*.card form [class*=col-]:first-child{
      padding-left: 0 !important;
    }

    .card form [class*=col-]:last-child{
      padding-right: 0 !important;
    }*/

    .lista-especializacao p{
      line-height: 1;
    }
  
    .c-form{
      padding-bottom: 60px;
    }

    .lista-especializacao .box-especializacao{
      background-color: rgba(213, 221, 249, 0.327);
    }
  
    </style>