<template>
  <div class="content">
    <div class="container-fluid">

      <div class="col-md-8 col-md-offset-2 text-center">
        <h2 class="title text-danger">Página não permitida</h2>      
        <h2 class="title">A página que tentou acessar não é permitida para o seu usuário.</h2>
      </div>
      
    </div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    data () {
      return {
        
      }
    }
  }
</script>
<style>

</style>
