<template>
  <b-container fluid class="recupera-senha-page">
    <div class="content">

      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-5 col-md-8 col-sm-12">
          <span>
            <form>

              <b-card header-bg-variant="header-modal-js" border-variant="primary" class="mb-0 pb-0">

                <template #header>
                  <h3 class="card-title text-center text-white">Recuperar senha</h3>
                </template>

                <div>
                  <b-form-group class="mt-2"
                  label="E-mail cadastrado"
                  label-for="input-email"      >
                  <b-form-input placeholder="Digite seu e-mail" id="input-email" v-model="email" trim></b-form-input>
                  </b-form-group>

                  <div class="text-center mt-4">                    
                    <button type="submit" class="btn btn-primary btn-fill" @click.prevent="recuperar">Recuperar</button>
                  </div>
                </div>
              </b-card>
              <div class="clearfix"></div>
            </form>
          </span>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
  import AgendaService from "../../service/AgendaService";
  import Card from 'src/components/Cards/Card.vue'
  import axios from "axios";
  import { BASE_CONSTANTS } from "@/service/config";
  import appUtils from '@/composables/utils'
  
  export default {
    components: {
      Card
    },
    data () {
      return {        
        email: '',
        http: null,          
        show: false,
        showFrom: "",
        filaShow: [],      
      }
    },
    mounted(){
      
      this.http = axios.create({baseURL: BASE_CONSTANTS.urlBase,
        headers: { "Content-Type": "application/json",
          Accept: "*/*",
        }
      });

    },
    methods: {      
      recuperar() {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("recuperar");

        let dadosEnvio =
        '{ "email": "' + this.email + '"' +
        "}";

        AgendaService.recuperarSenha(this.http, dadosEnvio)
        .then((resposta) => {
          desativarShow("recuperar");
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {         
            this.email = '';
            toast(retorno.mensagens[0].descricao, "Sucesso", "success");
          } else {                                    
            toast(retorno.mensagens[0].descricao,"Falha","danger");
          }
        })
        .catch((err) => {
          desativarShow("recuperar");
          toast(err,"Falha","danger");
        });            
      }   
    }
  }

</script>
<style>

.recupera-senha-page .card{
    border-radius: 10px;
    /*padding-top: 30px;*/
    padding-bottom: 30px;
  }

  .recupera-senha-page .content{
    padding-top: 12vh;
  }

</style>
