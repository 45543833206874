<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <edit-adm-profile-form>
          </edit-adm-profile-form>
        </div>
       <!-- <div class="col-md-4">
          <user-card>
          </user-card>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
  import EditAdmProfileForm from './UserProfile/EditAdmProfileForm.vue'
  import UserCard from './UserProfile/UserCard.vue'

  export default {
    components: {
      EditAdmProfileForm,
      UserCard
    }
  }

</script>
<style>

</style>
