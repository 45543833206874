<template>
  <div class="diario-view-calendar">
    <b-row v-if="!itens.length" class="m-0 p-0 text-center">
      <b-col cols="12" class="text-center">
        <h4 class="text-secondary">Nenhuma agenda encontrada</h4>
      </b-col>
    </b-row>
    <b-row v-else class="m-0 p-0">
      <b-col cols="12 m-0 p-0">
        <div class="row m-1 p-0 border-bottom bg-white rounded-left" v-for="(plantao, index) in itens" :key="index">
          <div class="col-md-1 m-0 p-0">
            <div class="row m-0 p-0">
              <div class="col-md-4 m-0 p-0">
                <div class="row m-0 p-0">
                  <div class="col-md-12">
                    {{ plantao.horarioDe }}
                  </div>
                  <div class="col-md-12">
                    {{ plantao.horarioAte }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 m-0 p-0 d-flex justify-content-center align-items-center">

                <div class="row m-0 p-0">
                  <div class="col-md-12">
                    <span class="font-weight-bold" style="font-size:0.6rem;">Duração:</span>
                  </div>
                  <div class="col-md-12">
                    {{ plantao.duracaoHoraMinuto }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 m-0 p-0 d-flex justify-content-center align-items-center">
                <b-icon
                  :icon="plantao.status === 'executado' ? 'hand-thumbs-up' : plantao.status === 'previsto' ? 'clock' : 'calendar-x'"
                  :variant="plantao.status === 'executado' ? 'success' : plantao.status === 'previsto' ? 'warning' : 'danger'"></b-icon>
              </div>
            </div>
          </div>

          <div class="col-md-1">
            <div class="row m-0 p-0">
              <div v-if="plantao.checkIn" class="col-md-12 text-success">
                Check-in: {{ strDateFormat(plantao.checkIn, 'HH:mm') }}
              </div>
              <div v-if="plantao.checkOut" class="col-md-12 text-success">
                Check-out: {{ strDateFormat(plantao.checkOut, 'HH:mm') }}
              </div>
            </div>
          </div>


          <div class="col-md-2">
            <div class="row m-0 p-0">
              <div class="col-md-12 font-weight-bold text-truncate">
                {{ plantao.nomeProfissionalComCrm ? camelCase(plantao.nomeProfissionalComCrm) : '-' }}
              </div>
              <div class="col-md-12 font-weight-light text-truncate" v-bind:style="'color:' + plantao.corTipo">
                {{ camelCase(plantao.dscTipo) }}
              </div>
            </div>

          </div>
          <div class="col-md-2 font-italic d-flex justify-content-center align-items-center text-truncate"
            v-bind:style="plantao.corLocal !== '#ffffff' ? 'color:' + plantao.corLocal : ''">
            {{ camelCase(plantao.nomeLocal) }}
          </div>
          <div class="col-md-1 d-flex justify-content-center align-items-center" v-bind:style="'color:' + plantao.cor">
            {{ camelCase(plantao.dscSituacao) }}
          </div>

          <div class="col-md-1 m-0 p-0">
            <div class="row m-0 p-0 h-100">
              <div v-if="plantao.idMotivoTroca" class="col-md-3 m-0 p-0 d-flex justify-content-center align-items-center">
                <b-icon icon="arrow-left-right" variant="info"></b-icon>
              </div>
              <div v-if="plantao.avista == 'S'" class="col-md-3 m-0 p-0 d-flex justify-content-center align-items-center">
                <b-icon icon="cash" variant="success"></b-icon>
              </div>
              <div v-if="plantao.valorCompl" class="col-md-3 m-0 p-0 d-flex justify-content-center align-items-center">
                <b-icon icon="plus-circle" variant="secondary"></b-icon>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="row m-0 p-0">
              <div v-if="plantao.emailProfissional" class="col-md-12">
                Email: {{ plantao.emailProfissional }}
              </div>
              <div v-if="plantao.telefoneProfissional" class="col-md-12">
                Telefone: {{ maskPhone(plantao.telefoneProfissional) }}
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <b-button size="sm" @click="abrirAgenda(plantao)" variant="primary" title="Abrir">
              <b-icon icon="calendar3-event"></b-icon>
            </b-button>
            <b-button size="sm" @click="confirmar(plantao.id)" variant="success" title="Confirmar" class="ml-1">
              <b-icon icon="calendar2-check"></b-icon>
            </b-button>
          </div>
        </div>

      </b-col>
    </b-row>

  </div>
</template>

<script>
import Card from 'src/components/Cards/Card.vue'
import getUF from '@/composables/getUF'
import { VMoney } from 'v-money'
import funcao from "../../components/Funcao"
import appUtils from '@/composables/utils'
import AgendaService from "../../service/AgendaService";

export default {
  components: {
    Card,
  },
  props: ['list', 'dia'],
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      optionsUF: getUF(),
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      mascaraTelefone: '(##)#####-####',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
    }
  },
  directives: { money: VMoney },
  mounted() {

  },

  methods: {
    acao() {

    },
    abrirAgenda(r) {
      const event = {
        id: r.id,
        dtAgenda: new Date(r.dtAgenda),
        idRecorrencia: r.idRecorrencia
      }
      this.$emit('abrirAgenda', event)
    },
    confirmar(id) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      //this.showLoad('Confirmando...')
      ativarShow("confirmar");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      const dadosConsulta = {
        id: id,
        idUsuario: dadosUsuario.idPessoa,
        idEmpresa: dadosUsuario.grupo.empresa.id,
        coordenador: dadosUsuario.perfilCoordenador,
      }

      AgendaService.confirmarEvento(dadosConsulta).then((resposta) => {
        let retorno = resposta.data;
        
        if (retorno.status === "SUCCESS") {

          let listRetorno = retorno.obj
          listRetorno.forEach((item) => {
            this.$emit('atualizar', item)
          });

        } else {          
          if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }          
        }
        desativarShow("confirmar")
      }).catch((err) => {        
        desativarShow("confirmar")
      });

    },
    colorLocal(cor) {
      if (cor) {
        return 'border-left: 5px solid ' + cor
      } else {
        return ''
      }
    },
    colorSituacao(cor) {
      if (cor) {
        return 'border-top: 5px solid ' + cor
      } else {
        return ''
      }
    },
    strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
    maskPhone(phone) {
      const { maskPhone } = appUtils(this)
      return maskPhone(phone)
    },
    camelCase(value) {
      const { camelCase } = appUtils(this)
      return camelCase(value)
    }
  },
  computed: {
    itens() {
      const dataAtual = this.strDateFormat(this.dia, 'yyyy-MM-dd')
      return this.list ? this.list.filter(f => f.title !== '[NOVO_REGISTRO]' && f.dtAgenda === dataAtual) : []
    }
  },
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }

}

</script>

<style lang="scss">
.diario-view-calendar {

  tbody {
    max-height: 300px;
  }

  .grid-td {
    width: 100%;
  }

  .grid-td .grid-line {
    padding-left: 10px;
    padding-top: 1px;
    width: 100%;
    height: 50px;

  }

  .grid-line .div-grid-line {
    width: 100%;
  }

  .div-grid-line .div-grid-line-cor {
    width: 38px;
  }

  .cor-fim-semana {
    color: red;
  }

  .fonte-local {
    font-size: 0.7rem;
  }

}
</style>