import { sliceEvents, createPlugin } from '@fullcalendar/core';

const convertDateToBR = function(data){

  var dia  = data.getDate().toString();
  var diaF = (dia.length == 1) ? '0'+dia : dia;

  var mes  = (data.getMonth()+1).toString();
  var mesF = (mes.length == 1) ? '0'+mes : mes;

  var anoF = data.getFullYear();
  return diaF+"/"+mesF+"/"+anoF;

}

const convertDateTODay = function(objDate){
  var meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  var days = ['dom','seg','ter','qua','qui','sex','sab'];
  var formatData = objDate.replace(/(\d{2})(\/)(\d{2})/, "$3$2$1");  
  var newData = new Date(formatData);
 // console.log(newData.getDate() + ' ' + meses[newData.getMonth()] + ' (' +  days[newData.getDay()]+ ')');
  return days[newData.getDay()];
}

const convertMinutosEmHora = function(minutos){
  const horas = Math.floor(minutos/ 60);          
  const min = minutos % 60;
  const textoHoras = (`00${horas}`).slice(-2);
  const textoMinutos = (`00${min}`).slice(-2);
  
  return `${textoHoras }:${textoMinutos}`;
}

const CalendarioPorLocal = {

  classNames: [ 'custom-view' ],
  duration: { weeks: 1 },
  content: function(props) {
    let html = ''
    let segs = sliceEvents(props, true).filter(f => f.def.title !== '[NOVO_REGISTRO]')
    
    let furo = 'border-left: #d35400 solid 3px;'
    let naoFuro = 'border-left: #27ae60 solid 3px;'

    html += '<div class="container-fluid">'
   
    if(segs.length > 0){
        let criaLocal = true
        let localAnt = null
        let initialView = true
        let itemDom = ''
        let itemSeg = ''
        let itemTer = ''
        let itemQua = ''
        let itemQui = ''
        let itemSex = ''
        let itemSab = ''
        let itemBotaoInserir = ''
        
      for (let i = 0; i < segs.length; i++) {
          
            const evento = segs[i].def;
            let duracao = segs[i].range.start
            let dataInicio = segs[i].range.end
            let idProfissional = evento.extendedProps.idProfissional
            let nomeProfissional = evento.extendedProps.nomeProfissional
            let nomeLocal = evento.extendedProps.nomeLocal
            let itemDiv = ''

            var data = convertDateToBR(dataInicio)
            let diaSemana = convertDateTODay(data)

            //itemBotaoInserir = '<buttom id="botaoadd" class="botaoadd">+ nova agenda</buttom>'
                        
            // INICIO MONTA ITEM
            itemDiv +=  '<div class"row">'
            itemDiv +=  '<div class="container-fluid centro" style="margin-bottom:5px; '
            if (localAnt !== undefined && idProfissional !== undefined && localAnt != null && idProfissional !== null && localAnt != nomeProfissional){
              itemDiv += furo
            }else{
              itemDiv += naoFuro
            } 
            itemDiv +=  '">' 

            itemDiv += '<div class="row" @click="handleEventClick">'
            itemDiv += '<div class="col-md-8 titulo" >'
            itemDiv +=  (idProfissional !== undefined && idProfissional != null) ? nomeProfissional : ''
            itemDiv +=  '</div>'

            itemDiv +=  '<div class="col-md-3" style="border-bottom: 3px solid '
            itemDiv +=  evento.extendedProps.cor 
            itemDiv +=  '; font-size:12px;">'
            itemDiv +=  '<label>'
            itemDiv +=  evento.extendedProps.horarioDe +'<br />'+ convertMinutosEmHora(evento.extendedProps.duracao)
            itemDiv +=  '</label>'
            itemDiv +=  '</div>'
            itemDiv +=  '<div class="col-md-1 top">'
            if(idProfissional === undefined || idProfissional === null){
              itemDiv +=  '<i class="nc-icon nc-send "></i>'
            }  
            itemDiv +=  '</div>'
            itemDiv +=  '</div>'
            itemDiv +=  '</div>'
            itemDiv +=  '</div>'
            // FIM MONTA ITEM

            // MONTA HTML

            if(localAnt != null && localAnt !== undefined && localAnt != nomeLocal){      
              html +=  '<tbody>'
              html +=  '<tr>'
              html +=  '<td style="vertical-align:top">'
              html += itemDom + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemSeg + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemTer + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemQua + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemQui + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemSex + itemBotaoInserir
              html +=  '</td>'
              html +=  '<td style="vertical-align:top">'
              html += itemSab + itemBotaoInserir
              html +=  '</td>'

              html +=  '<td style="vertical-align:top">'
              html +=  '</td>'

              html +=  '</tr>'
              html +=  '</tbody>'     
              
              
              html += '          </table>'
              html += '        </div>'
              html += '       </div>'
              html += '       </div>'
             } 

            if(initialView == true || (localAnt != null && localAnt != nomeLocal)){
              itemDom = ''
              itemSeg = ''
              itemTer = ''
              itemQua = ''
              itemQui = ''
              itemSex = ''
              itemSab = ''

              html += '<div class="row">'
              html += '<div class="col-md-2" style="font-size:12px; word-wrap: normal; ">'
              if(initialView == true){
                html += '<p style="font-size:12px; word-wrap: normal; margin-top: 50px;  padding-right:5px; ">'
                html +=  nomeLocal
                html += '</p>'
              }else{
                html += '<p style="font-size:12px; word-wrap: normal;  padding-right:5px; ">'
                html +=  nomeLocal
                html += '</p>'
              }
              html += '     </div>'
              html += '      <div class="col-md-10">'
              html += '        <div class="row" >'
              html += '           <table class="table table-bordered">'
              if(initialView == true){
                html += '              <thead>'
                html += '                  <tr>'
                html += '                    <th scope="col" class="diaSemanaAgenda">dom.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">seg.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">ter.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">qua.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">qui.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">sex.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda">sab.</th>'
                html += '                    <th scope="col" class="diaSemanaAgenda"></th>'                
                html += '                  </tr>'
                html += '              </thead>'
            }
          }
          diaSemana === 'dom' ? (itemDom += itemDiv ) : ''
          diaSemana === 'seg' ? (itemSeg += itemDiv ) : ''
          diaSemana === 'ter' ? (itemTer += itemDiv ) : ''
          diaSemana === 'qua' ? (itemQua += itemDiv ) : ''
          diaSemana === 'qui' ? (itemQui += itemDiv ) : ''
          diaSemana === 'sex' ? (itemSex += itemDiv ) : ''
          diaSemana === 'sab' ? (itemSab += itemDiv ) : ''

            criaLocal = false
            localAnt = nomeLocal
            initialView = false
      }  

      html +=  '<tbody>'
      html +=  '<tr>'
      html +=  '<td style="vertical-align:top">'
      html += itemDom + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemSeg + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemTer + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemQua + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemQui + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemSex + itemBotaoInserir
      html +=  '</td>'
      html +=  '<td style="vertical-align:top">'
      html += itemSab + itemBotaoInserir
      html +=  '</td>'

      html +=  '<td style="vertical-align:top">'
      html +=  '</td>'

      html +=  '</tr>'
      html +=  '</tbody>'   
    
      html += '          </table>'
      html += '        </div>'
      html += '       </div>'
    html += '</div><br /><br />'

    return { html: html }
  }else{
    return { html: html }
  }
}
}

export default createPlugin({
  views: {
    customPorLocais: CalendarioPorLocal
  }
});