<template>
  <card v-if="entity">
    <h4 slot="header" class="card-title"> {{ this.idGrupoUsuario > 0 ? 'Atualizar Grupo de Usuário' : 'Novo Grupo de
          Usuário' }} </h4>
    <form>

      <div class="row">
        <div class="col-md-5">
          <b-form-group label="descricao" label-for="input-descricao">
            <b-form-input id="input-descricao" type="text" placeholder="Descrição" maxlength="100"
              v-model="entity.descricao" />
          </b-form-group>
        </div>
      </div>

      <div class="row" v-if="hasAccess('ROOT')">
        <div class="col-md-5">
          <b-form-group label="Empresa" label-for="input-empresa">
            <v-select v-model="entity.idEmpresa" :reduce="empresa => empresa.id" label="nome" :options="empresas"
              placeholder="Empresa">
              <span slot="no-options" @click="$refs.select.open = false">Nenhum registro encontrado</span>
            </v-select>
          </b-form-group>
        </div>
      </div>

      <div class="row" style="height: 300px; overflow-y: scroll">
        <div class="col-md-12">          
            <tree-component :treeData="permissao.menu" ref="listaPermissao" id="tree-permissao"></tree-component>          
        </div>
      </div>

      <div class="text-center">
        <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="salvar">
          Salvar
        </button>
        <button type="submit" class="btn btn-danger btn-fill float-right mr-1" @click.prevent="voltar">
          Fechar
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>
</template>

<script>

import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'
import TreeComponent from '../../comuns/treeview/TreeComponent.vue'

export default {
  props: ['idGrupoUsuario'],
  components: {
    Card,
    TreeComponent,
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      entity: null,
      empresas: null,
      permissao: {menu: null},
    }
  },
  async mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    const { toast, ativarShow, desativarShow } = appUtils(this)

    let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

    if (this.hasAccess('ROOT')) {

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id
        }

      ativarShow("carregarEmpresa")

      await AgendaService.recuperaEmpresa(this.http, dadosConsulta)
        .then((resposta) => {
          this.empresas = resposta.data.obj
          desativarShow("carregarEmpresa");
        })
        .catch((err) => {
          desativarShow("carregarEmpresa");
        })

    }

    this.buscarPermissao()

    if (this.idGrupoUsuario > 0) {
      this.carregaDados();
    } else {
      this.entity = {
        "descricao": "",
        "idEmpresa": dadosUsuario.grupo.empresa.id,
      }
    }

  },
  methods: {
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("carregarDados");

      AgendaService.editGrupoUsuario(this.http, this.idGrupoUsuario)
        .then((resposta) => {
          this.entity = resposta.data.obj
          this.preencherPemissaoGrupo()
          desativarShow("carregarDados");
        })
        .catch((err) => {
          desativarShow("carregarDados");
        });

    },
    buscarPermissao() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarPermissao");

      let dadosConsulta = {}

      AgendaService.listaPermissaoGeral(dadosConsulta)
        .then((resposta) => {
          this.permissao = resposta.data.obj
          desativarShow("buscarPermissao");
        })
        .catch((err) => {
          desativarShow("buscarPermissao");
        });
    },
    preencherPemissaoGrupo() {

      this.entity.menus.forEach((m) => {        
    
        let menu = this.obterItem(this.permissao.menu, m.id, 'M')
        
        menu.select = true
        
        if (m.permissoes) {          
          m.permissoes.forEach((p) => {
            let permissao = this.obterItem(menu, p.idPermissao, 'P')
            permissao.select = true
            permissao.nivel = p.nivel
          })
        }
      })

    },
    obterItem(menu, id, tipo) {

      if(!menu.children) return null

      let item = menu.children.find(m => m.id == id && ((tipo == 'M' && m.nivel == null) || (tipo == 'P' && m.nivel != null)))

      if (!item) {
        let subMenus = menu.children.filter((m) => { return m.nivel == null })
        for (let i = 0; i < subMenus.length; i++) {
          item = this.obterItem(subMenus[i], id, tipo)
          if (item) break
        }
      }
      return item

    },
    obterMenusSelecionados() {
      return this.obterMenusSelecionadosRec(this.permissao.menu)
    },
    obterMenusSelecionadosRec(m) {

      let menus = []
      
      m.children.forEach((item) => {

        if (item.nivel == null) {
          let permissoes = this.permissoesMenuMarcada(item)
          if (item.select || permissoes.length > 0) {
            menus.push({
              id: item.id,
              permissoes: permissoes
            })
          }
        }

        if (item.children) {
          let menusItem = this.obterMenusSelecionadosRec(item)
          menus.push(...menusItem)          
        }       
        
      })

      return menus
      
    },
    permissoesMenuMarcada(item) {
      if (item.children) {
        return item.children.filter((i) => { return i.nivel != null && i.select }).map(i => ({ idPermissao: i.id, nivel: i.nivel }))
      }
      return []
    },
    voltar() {
      this.$emit('fecharedit')
    },
    salvar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      
      ativarShow("salvarGrupoUsuario");

      this.entity.menus = this.obterMenusSelecionados()

      AgendaService.saveGrupoUsuario(this.entity)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);

          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("salvarGrupoUsuario");
        }).catch((error) => {
          desativarShow("salvarGrupoUsuario");
          toast(error, 'Falha', 'danger')
        });
    },
    hasAccess(menu) {
      return this.$store.getters['auth/hasAccess'](menu)
    },
  }

}
</script>



<style></style>