<template>
  <div>

    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir(0) }">
      <template v-slot:texto>Confirma exclusão do profisisonal?</template>
    </confirma>

    <div v-if="!edit">

      <card class="h-100 lista-profissional">

        <template slot="header">
          <h4 class="card-title">Empresas PJ</h4>
        </template>

        <b-row>

          <div class="col-md-2">
            <b-form-input type="text" placeholder="Nome/Razão/CNPJ" v-model="filter" />
          </div>

          <div class="col-md-2 filtro">
            <b-form-select v-model="credenciamento" :options="optionsFiltroCredenciamento"></b-form-select>
          </div>

          <div class="col-md-2 text-center text-md-left filtro">
            <b-button variant="primary" size="sm" class="btn-fill float-left" @click="buscar">
              Pesquisar
            </b-button>

            <b-button class="ml-1 btn-fill" size="sm" variant="warning" @click="editar(0)" v-if="false">
              <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
            </b-button>

          </div>

        </b-row>

        <b-row>
          <div class="col-md-12 pt-2">

            <!-- Main table element -->
            <b-table empty-text="Nenhum registro" :items="items" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty sticky-header="500px"
              small hover @row-clicked="editarEmpresaPJ">

              <template v-slot:cell(cnpj)="data">
                {{ formatarCpf(data.value) }}
              </template>


              <template #cell(actions)="row">
                <b-button v-if="row.item.situacaoCredenciamento && row.item.situacaoCredenciamento === 'CCD'" size="sm" @click="abrirTermo(row.item.idCredenciamento)" class="mr-1"
                  variant="primary" title="Termo">
                  <b-icon icon="download" aria-hidden="true"></b-icon>
                </b-button>
              </template>

            </b-table>
          </div>

        </b-row>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
              size="sm"></b-pagination>
          </div>
        </div>
      </card>
    </div>
    <div v-else>
      <edit-empresa-p-j @showOverlay="(status) => this.$emit('showOverlay', status)" :idEmpresaPJ="idEmpresaPJ" @fecharedit="() => this.edit = false"
        @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.entity) }" />
    </div>

    <div class="clearfix"></div>

  </div>
</template>

<script>
import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'
import Confirma from '../../comuns/Confirma'
import EditEmpresaPJ from './EditEmpresaPJ.vue'
import SelectLocal from '../../comuns/SelectLocal.vue'
import funcao from "src/components/Funcao";

export default {
  components: {
    Card,
    EditEmpresaPJ,
    Confirma,
    SelectLocal
  },
  data() {
    return {
      id: null,
      show: false,
      http: null,
      showFrom: "",
      filaShow: [],
      configuracao: null,
      items: [],
      edit: false,
      idEmpresaPJ: null,
      fields: [
        { key: 'cnpj', label: 'CNPJ', sortable: true, sortDirection: 'asc', thStyle: { width: "15%" } },
        { key: 'nome', label: 'Nome', sortable: true, sortDirection: 'asc', thStyle: { width: "50%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "20%" } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      locais: null,
      alocado: null,
      credenciamento: null,
      optionsFiltroAlocados: [
        { value: null, text: 'Prof. alocados' },
        { value: 'S', text: 'Alocados' },
        { value: 'N', text: 'Não alocados' },
      ],
      optionsFiltroCredenciamento: [
        { value: null, text: 'Credenciamento' },
        { value: 'S', text: 'Credenciados' },
        { value: 'N', text: 'Não credenciados' },
      ],
      fieldsLocal: [
        { key: 'dscLocal', label: 'Local', sortable: true, sortDirection: 'asc', thStyle: { width: "70%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "20%" } }
      ],
    }
  },
  computed: {

  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    this.carregaDados()

  },
  methods: {
    novo() {
      this.edit = true
      this.idUsuario = 0
    },
    editarEmpresaPJ(record, index) {
      this.editar(record.id)
    },
    editar(id) {
      this.edit = true
      this.idEmpresaPJ = id
    },
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarEmpresasPJ");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        idEmpresa: dadosUsuario.grupo.empresa.id,
        filtro: this.filter,
      }

      AgendaService.listaEmpresaPJFilter(this.http, dadosConsulta)
        .then((resposta) => {
          this.items = resposta.data.obj
          this.totalRows = this.items.length
          desativarShow("listarEmpresasPJ");
        })
        .catch((err) => {
          desativarShow("listarEmpresasPJ");
        });

    },
    buscar() {
      this.carregaDados();
    },
    abrirLocais(record) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("abrirLocais");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = { 
        idUsuario: dadosUsuario.idPessoa,
        idPessoa: record.id,
        idEmpresa: dadosUsuario.grupo.empresa.id
      }

      AgendaService.buscarPessoaLocal(dadosConsulta).then((resposta) => {
        this.locais = resposta.data.obj
        this.$bvModal.show("modal-locais")
        desativarShow("abrirLocais");
      }).catch((err) => {
        desativarShow("abrirLocais");
      });

    },
    salvarLocais() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("salvarLocais");
      AgendaService.atualizarPessoaLocal(this.http, this.locais)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            this.$bvModal.hide('modal-locais')
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("salvarLocais");
        }).catch((error) => {
          desativarShow("salvarLocais");
          toast(error, 'Falha', 'danger')
        });
    },
    confirmaExcluir(id) {
      this.id = id
      this.$refs.conf.show()
    },
    excluir() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("deleteUsuario");

      AgendaService.deleteUsuario(this.http, this.id)
        .then(resposta => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.items = this.items.filter((i) => { return i.id !== this.id })
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("deleteUsuario");
        }).catch(error => {
          desativarShow("deleteUsuario");
          toast(error, "Falha", "danger")
        })
    },
    atualizarRegistro(entity) {
      let index = this.items.indexOf(this.items.find((i) => i.id === entity.id));
      if (index >= 0) {
        this.items.splice(index, 1, entity);
      } else {
        this.items.push(entity);
      }
    },
    abrirSelecionaLocal() {
      const { toast } = appUtils(this)
      let idsEliminar = this.locais.lista.map(elem => elem.idLocal);
      this.$refs.selectLocal.abrir(idsEliminar)
    },
    adicionarLocal(locais) {
      locais.forEach((item) => {

        let reg = {
          idLocal: item.id,
          dscLocal: item.descricao,
        }

        this.locais.lista.push(reg);

      });
    },
    formatarCpf(text) {
      const { formatarCpf } = appUtils(this)
      return formatarCpf(text)
    },
    abrirTermo(id) {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("abrirTermo");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": id
      }

      AgendaService.downloadTermo(dadosConsulta)
        .then((resposta) => {

          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadPdf(base64);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("abrirTermo");
        })
        .catch((err) => {
          desativarShow("abrirTermo");
        });

    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.deleted === 'S') return 'table-danger'
    }
  }
}

</script>
<style>
@media (max-width: 768px) {

  .lista-profissional .filtro {
    padding-top: 5px;
  }


}
</style>