<template>
  <div class="content dashboard">
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-4">
          <div class="row mx-0">
            <div class="col-md-6">
              <b-form-datepicker id="dtInicio" v-model="dtInicio" size="sm" placeholder="Período" today-button
                reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                close-button-variant="success" label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
                label-reset-button="Limpar" label-close-button="Fechar" :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }">
              </b-form-datepicker>
            </div>
            <div class="col-md-6">
              <b-form-datepicker id="dtFim" v-model="dtFim" size="sm" class="mb-2" placeholder="até" today-button
                reset-button close-button today-button-variant="primary" reset-button-variant="danger"
                close-button-variant="success" label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
                label-reset-button="Limpar" label-close-button="Fechar" :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }">
              </b-form-datepicker>
            </div>
          </div>
        </div>

        <div class="col-md-1">
          <b-overlay :show="show" rounded opacity="0.6" spinner-small spinner-variant="primary"
            class="d-inline-block w-100">
            <b-button size="sm" variant="primary" class="btn btn-fill btn-primary btn-block w-100"
              @click="gerarDashboard">
              <b-icon icon="bar-chart" aria-hidden="true" class="mr-1"></b-icon> Atualizar</b-button>
          </b-overlay>
        </div>
      </div>

      <div class="row">

        <div class="card col-md-2 px-0 border-0">

          <card class="mb-0 h-100">
            <template slot="header">
              <h4 class="card-title"></h4>
              <p class="card-category"></p>
            </template>

            <div class="row px-3">
              <div class="col-md-7 mb-1">
                <h2></h2>
              </div>
              <div class="col-md-5 mb-1"></div>

              <div class="col-md-7 mb-1">
                <h2></h2>
              </div>
              <div class="col-md-5 mb-1"></div>

              <div class="col-md-7 mb-1">
                <h2></h2>
              </div>
              <div class="col-md-5 mb-1"></div>

              <div class="col-md-7 mb-1">
                <h2></h2>
              </div>
              <div class="col-md-5 mb-1"></div>

              <div class="col-md-7 mb-0">
                <h2></h2>
              </div>
              <div class="col-md-5 mb-0"></div>
            </div>
          </card>

        </div>

        <div class="col-md-10">
          <div class="row m-0">

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-warning">
                  <i class="nc-icon nc-badge text-warning"></i>
                </div>
                <div slot="content" class="mr-1">
                  <p class="card-category">Dados básicos</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdDadosBasicos') }}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-success">
                  <i class="nc-icon nc-badge text-primary"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Preenchimento</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdEmPreenchimento') }}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-badge text-danger"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Aprovação</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdAguardAprovacao') }}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-badge text-dark"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Assinatura</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdAguardAssinatura') }}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-badge text-success"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Concluído</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdConcluido') }}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-badge text-primary"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category"></p>
                  <h4 class="card-title"></h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-badge text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category"></p>
                  <h4 class="card-title"></h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">
                  <i class="nc-icon nc-badge text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category"></p>
                  <h4 class="card-title"></h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">
                  <i class="nc-icon nc-badge text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category"></p>
                  <h4 class="card-title"></h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">
                  <i class="nc-icon nc-badge text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category"></p>
                  <h4 class="card-title"></h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">
                  <i class="nc-icon nc-badge text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category"></p>
                  <h4 class="card-title"></h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">
                  <i class="nc-icon nc-badge text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category"></p>
                  <h4 class="card-title"></h4>
                </div>
              </stats-card>
            </div>

          </div>
        </div>

      </div>

      <div class="row">

        <div class="col-md-3">
          <card>

            <chart-card v-if="pieChartSituacao !== null" :chart-data="pieChartSituacao.data"
              :chart-options="pieChartSituacao.options" chart-type="Pie" :key="'pieChartSituacao' + seqKeyGrafico">
            </chart-card>

            <div v-else><sem-dados-dashboard /></div>

            <template slot="header">
              <h4 class="card-title">Situações</h4>
              <p class="card-category">Status dos credenciamentos</p>
            </template>

            <template slot="footer">
              <div class="legend">
                <div ref="situacaoLegenda"></div>
              </div>
            </template>

          </card>

        </div>

        <div class="col-md-9">
          <card>
            <chart-card v-if="barChartEvolucao != null" :chart-data="barChartEvolucao.data"
              :chart-options="barChartEvolucao.options"
              :chart-responsive-options="barChartEvolucao.responsiveOptions" chart-type="Bar"
              :key="'barChartEvolucao' + seqKeyGrafico">
            </chart-card>

            <div v-else><sem-dados-dashboard /></div>

            <template slot="header">
              <h4 class="card-title">Credenciamentos</h4>
              <p class="card-category">Evolução do número de credenciamentos a cada mês</p>
            </template>

            <template slot="footer">
              <div class="legend">
                <div ref="barChartEvolucaoLegenda"></div>
              </div>
            </template>
          </card>
        </div>
      </div>

      <div class="row">
              <div class="col-md-3">

        <card>

        <chart-card v-if="pieChartPorEstado !== null" :chart-data="pieChartPorEstado.data" 
        :chart-options="pieChartPorEstado.options"
        :chart-responsive-options="pieChartPorEstado.responsiveOptions"
        chart-type="Pie" :key="'pieChartPorEstado' + seqKeyGrafico">
        </chart-card>

        <div v-else><sem-dados-dashboard/></div>

          <template slot="header">
        <h4 class="card-title">Credenciamentos por UF</h4>
            <p class="card-category">
              Quantidade por UF
            </p>
          </template>
        
          <template slot="footer" >
            <div class="legend">
              <div ref="porEstadoLegenda"></div>    
            </div>
          </template>

        </card>       
        </div>
        </div>

      <div>
      </div>
    </div>
  </div>
</template>

<script>

import ChartCard from "src/components/Cards/ChartCard.vue";
import StatsCard from "src/components/Cards/StatsCard.vue";
import LTable from "src/components/Table.vue";
import AgendaService from "../../service/AgendaService";
import axios from "axios";
import { BASE_CONSTANTS } from "../../service/config";
import Chartist from 'chartist';
import ChartistPluginLegend from 'chartist-plugin-legend';
import ChartistPluginPointLabels from 'chartist-plugin-pointlabels';
import ChartistPluginTooltip from 'chartist-plugin-tooltip';
import Card from '../../components/Cards/Card.vue';
import useAuthentication from '@/composables/useAuthentication'
import SemDadosDashboard from '../../components/SemDadosDashboard.vue';
import appUtils from '@/composables/utils'

export default {
  components: {
    LTable,
    ChartCard,
    StatsCard,
    Chartist,
    ChartistPluginLegend,
    ChartistPluginPointLabels,
    ChartistPluginTooltip,
    Card,
    SemDadosDashboard,
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],      
      dtInicio: null,
      dtFim: null,
      dashboard: null,
      fieldsQuantPorTipo: [],
      editTooltip: "Edit Task",
      deleteTooltip: "Remove",      
      pieChartSituacao: null,
      barChartEvolucao: null,      
      pieChartPorEstado: null,
      seqKeyGrafico: 1,
    };
  },
  async mounted() {
    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        Authorization:
          "bearer " +
          String(localStorage.getItem("authorization")).replaceAll('"', ""),
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });

    this.fieldsQuantPorTipo = [
      { label: "Descrição", key: "dscTipo", thStyle: { width: "60%" } },
      { label: "Quant", key: "quantidade", thStyle: { width: "25%" } },
      { key: "last" }
    ];

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), 0, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
    this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")

    this.limparDashboard()
    this.gerarDashboard()

  },
  methods: {
    getInfoDashBoard(field) {
      return this.dashboard != null ? this.dashboard[field] : '';
    },

    limparDashboard() {

    },

    gerarDashboard() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("gerarDashboard");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
          idUsuario: dadosUsuario.idPessoa,
          idEmpresa: dadosUsuario.grupo.empresa.id,
          dtInicio: this.dtInicio,
          dtFim: this.dtFim        
        }

      AgendaService.gerarDashboardCredenciamento(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.dashboard = retorno.obj;

            this.seqKeyGrafico++;

            this.gerarGraficoEvolucao()
            this.gerarGraficoPorSituacao()        
            this.gerarGraficoPorEstado()    

          } else {
            this.limparDashboard();
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
          desativarShow("gerarDashboard");
        }).catch((err) => {
          console.log(err)
          const { isUnauthorized } = useAuthentication()
          const isLogin = isUnauthorized(err)
          if (isLogin) this.$router.push("/login");
          toast(err, "Falha", "danger");
          this.limparDashboard();
          desativarShow("gerarDashboard");
        });
    },
    gerarGraficoEvolucao() {

      if (this.$refs['barChartEvolucaoLegenda']) {
        this.$refs['barChartEvolucaoLegenda'].innerHTML = '';
      }

      if (this.dashboard.evolucao != null && this.dashboard.evolucao.length > 0) {

        let labels = []
        let series = [[], []]
        let max = 0

        this.dashboard.evolucao.forEach(h => {
          labels.push(h.dscMes)
          series[0].push(h.quantidade)
          if (h.quantidade > max) max = h.quantidade
        })

        this.barChartEvolucao = {
          data: {
            labels: labels,
            series: series,
          },
          options: {
            low: 0,
            high: max,
            seriesBarDistance: 10,
            axisX: {
              showGrid: true,
            },
            stackBars: true,
            plugins: [
              Chartist.plugins.legend({
                legendNames: ['Mês'],
                position: this.$refs['barChartEvolucaoLegenda']
              }),
              Chartist.plugins.ctPointLabels({
                //textAnchor: 'middle',
                labelInterpolationFnc: function (value) {
                  return value !== undefined ? value : ''
                },
              }),
              Chartist.plugins.tooltip({
                valueTransform: function (value) {
                  return value;
                }
              })
            ]
          },
          responsiveOptions: [
            [
              "screen and (max-width: 640px)",
              {
                showPoint: true,
                seriesBarDistance: 5,
                axisX: {
                  labelInterpolationFnc(value) {
                    return value[0];
                  },
                },
              },
            ],
          ],
        }

      } else {
        this.barChart = null
      }
    },
    gerarGraficoPorSituacao() {

      this.$refs['situacaoLegenda'].innerHTML = '';

      if (this.dashboard.situacoes != null && this.dashboard.situacoes.length > 0) {

        let labels = []
        let series = []

        this.dashboard.situacoes.forEach(t => {
          labels.push(t.descricao)
          series.push(t.quantidade)
        })

        var sum = function (a, b) { return a + b };

        this.pieChartSituacao = {
          data: {
            labels: labels,
            series: series
          },
          options: {
            donut: true,
            labelInterpolationFnc: function (value, index) {
              return series[index];
            },
            plugins: [
              Chartist.plugins.legend({
                className: 'small-legend-dash',
                position: this.$refs['situacaoLegenda']
              })
            ]
          },
          responsiveOptions: [

          ],
        }

      } else {
        this.pieChartSituacao = null
      }
    }, 
    gerarGraficoPorEstado() {

      this.$refs['porEstadoLegenda'].innerHTML = '';

      if (this.dashboard.estados != null && this.dashboard.estados.length > 0) {

        let labels = []
        let series = []

        this.dashboard.estados.forEach(t => {
          labels.push(t.uf + ' : '+t.quantidade)
          series.push(t.quantidade)
        })

        var sum = function (a, b) { return a + b };

        this.pieChartPorEstado = {
          data: {
            labels: labels,
            series: series
          },
          options: {
            donut: true,
            labelInterpolationFnc: function (value, index) {
              return series[index];
            },
            plugins: [
              Chartist.plugins.legend({
                className: 'small-legend-dash',
                position: this.$refs['porEstadoLegenda']
              })
            ]
          },
          responsiveOptions: [

          ],
        }

      } else {
        this.pieChartPorEstado = null
      }
    },   
  },
  computed: {
    colorEficiencia: function () {
      var val = this.getInfoDashBoard('eficiencia');
      if (val < 50) {
        return 'color: #cd7f32'
      } else if (val > 50 && val < 70) {
        return 'color: #c0c0c0'
      } else {
        return 'color: #ffd700'
      }
    }
  }
};


function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format.replace('yyyy', date.getFullYear())
      .replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'))
      .replace('dd', date.getDate().toString().padStart(2, '0'))
      .replace('HH', date.getHours().toString().padStart(2, '0'))
      .replace('mm', date.getMinutes().toString().padStart(2, '0'))
      .replace('ss', date.getSeconds().toString().padStart(2, '0'));
    return strDate;
  }
}

</script>

<style lang="scss">
.dashboard .legend {
  ul {
    list-style-type: none;
  }

  li {
    display: inline;
  }
}

.dashboard {

  .card .ct-chart {
    margin: 0 0;
    height: 150px;
  }

  .hidden_header {
    display: none;
  }

  .tb-qtde-tipo tr {
    height: 10px;
  }

  .small-legend-dash {
    font-size: 10px;
  }

  .small-legend-dash li:before {
    margin-top: 2px;
  }

  .card .table tbody td:last-child,
  .card .table thead th:last-child {
    padding-right: 15px;
    height: 100%;
    display: none;
  }
}
</style>
