<template>
  <div class="c-form">

    <div v-if="!edit">

      <card class="h-100">

        <template slot="header">
          <h4 class="card-title">Pagamentos</h4>
        </template>

        <div class="row list-pagamentos">

          <div class="col-md-4 filtro">

            <v-select v-model="idSituacao" :options="itensSituacao" :reduce="situacao => situacao.id" label="descricao"
              ref="select" placeholder="Selecione a situação">
              <span slot="no-options" @click="$refs.select.open = false">
                Nenhum registro encontrado
              </span>
            </v-select>

          </div>

          <div class="col-md-4 filtro">

            <b-input-group>

              <b-button variant="primary" size="sm" class="mb-2 mr-1" @click="goMesAnterior">
                <b-icon icon="arrow-left-circle"></b-icon>
              </b-button>

              <b-form-input v-mask="'##/####'" label="Competência" placeholder="Competência" v-model="competenciaIni" size="sm"></b-form-input>
              <b-input-group-text class="mb-2">até</b-input-group-text>
              <b-form-input v-mask="'##/####'" label="Competência" placeholder="até" v-model="competenciaFim" size="sm"></b-form-input>

              <b-button variant="primary" size="sm" class="mb-2 ml-1" @click="goMesSeguinte">
                <b-icon icon="arrow-right-circle"></b-icon>
              </b-button>

            </b-input-group>

          </div>

          <div class="col-md-2 h-100 text-center text-md-left filtro">
            <b-button size="md" class="btn btn-primary btn-fill" @click="carregaDados">
              Buscar
            </b-button>
          </div>
        </div>

        <b-row>
          <b-col cols="12" class="pt-2">

            <b-table empty-text="Nenhum registro" :items="items" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty sticky-header="500px"
              small hover @row-clicked="abrir">

              <template v-slot:cell(valor)="data">
                {{ data.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 })}}
              </template>

              <template v-slot:cell(dtPagamento)="row">
                {{ strDateFormat(row.item.dtPagamento, 'dd/MM/yyyy') }}
              </template>

              <template #cell(actions)="row">
                <b-button size="sm" @click="editar(row.item.id)" class="mr-1" variant="success" title="Abrir">
                  <b-icon icon="file-text" ></b-icon>
                </b-button>
              </template>

            </b-table>
          </b-col>

        </b-row>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
              size="sm"></b-pagination>
          </div>

        </div>
      </card>

    </div>


    <div v-else>
      <detalhe-pagamento @showOverlay="(status) => this.$emit('showOverlay', status)" :idPagamento="idPagamento"
        @fecharedit="() => this.edit = false" />
    </div>

    <div class="clearfix"></div>

  </div>

</template>

<script>
import AgendaService from '../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import appUtils from '@/composables/utils'
import DetalhePagamento from './DetalhePagamento'

export default {
  components: {
    Card,
    DetalhePagamento
  },
  data() {
    return {
      id: null,
      show: false,
      showFrom: "",
      filaShow: [],
      items: [],
      edit: false,
      idSituacao: null,
      itensSituacao: [],
      idPagamento: null,
      competenciaIni: '',
      competenciaFim: '',
      fields: [
        { key: 'empresa', label: 'Gestora', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },
        { key: 'unidade', label: 'Unidade', sortable: true, sortDirection: 'asc', thStyle: { width: "25%" } },
        { key: 'competencia', label: 'competência', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
        { key: 'valor', label: 'Valor', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
        { key: 'situacao', label: 'Situação', sortable: true, sortDirection: 'asc', thStyle: { width: "15%" } },
        { key: 'dtPagamento', label: 'Pagamento', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "10%" } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
    }
  },
  computed: {

  },
  mounted() {
    this.inicializar()
    this.carregaDados()
  },
  methods: {
    inicializar() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("inicializar");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = '{ ' +
        '"idUsuario": ' + dadosUsuario.idPessoa +
        ',"idTipo": 5' +
        '}'

      AgendaService.recuperaSituacoesGeral(dadosConsulta)
        .then(resposta => {
          this.itensSituacao = resposta.data.obj
          desativarShow("inicializar");
        }).catch(error => {
          desativarShow("inicializar");
        })

        const dataAtual = new Date()
        this.competenciaIni = '01/'+dataAtual.getFullYear()
        this.competenciaFim = '12/'+dataAtual.getFullYear()

    },
    voltar(id) {
      var index = this.idPai.indexOf(id)
      this.idPai.splice(index, this.idPai.length - index)
      this.dscPai.splice(index, this.idPai.length - index);
      this.carregaDados();
    },
    editar(id) {
      this.edit = true
      this.idPagamento = id
    },
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarPagamentos");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idProfissional": dadosUsuario.idPessoa,
        "idSituacao": this.idSituacao,
        "competenciaIni": this.competenciaIni,
        "competenciaFim": this.competenciaFim
      }

      AgendaService.listarPagamentos(dadosConsulta)
        .then((resposta) => {

          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.items = resposta.data.obj
          this.totalRows = this.items.length
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("listarPagamentos");
        })
        .catch((err) => {
          desativarShow("listarPagamentos");
        });

    },
    abrir(record, index) {
      this.editar(record.id)
    },
    goMesAnterior() {

      const pattern = /^(0[1-9]|1[012])[\/]\d{4}$/i
      let result = this.competenciaIni.match(pattern)
      if (result) {

        const tmp = this.competenciaIni.split('/')
        const data = tmp[1] + '-' + tmp[0] + '-01'
        const dataAtual = new Date(data + "T00:00:00")
        dataAtual.setMonth(dataAtual.getMonth() - 1);

        this.competenciaIni = (dataAtual.getMonth()+1).toString().padStart(2,'0') +'/'+dataAtual.getFullYear()
        this.competenciaFim = this.competenciaIni

      }
    },
    goMesSeguinte() {            

      const pattern = /^(0[1-9]|1[012])[\/]\d{4}$/i
      let result = this.competenciaIni.match(pattern)
      if (result) {

        const tmp = this.competenciaIni.split('/')
        const data = tmp[1] + '-' + tmp[0] + '-01'

        const dataAtual = new Date(data + "T00:00:00")
        dataAtual.setMonth(dataAtual.getMonth() + 1);

        this.competenciaIni = (dataAtual.getMonth()+1).toString().padStart(2,'0') +'/'+dataAtual.getFullYear()
        this.competenciaFim = this.competenciaIni

      }
      
    },
    strDateFormat(dataStr, format) {
      if (dataStr !== null) {
        let data = new Date(dataStr + " (UTC-3)")
        return dateToStr(data, format);
      } else {
        return ""
      }
    },
  },

}

function dateToStr(date, format) {
  if (date !== undefined && date !== null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
  return "";
}

</script>
<style lang="scss">

  @media (max-width: 768px) {
    .list-pagamentos .filtro {
      padding-top: 5px;
    }
  }


.list-pagamentos {
  .filtro {
    font-size: 0.7rem;
  }
}

</style>