<template>
    <div v-if="warningZone">
        <!-- b-modal id="modal-1" title="BootstrapVue" v-model="warningZone">
            <p class="my-4">Hello from modal!</p>
        </b-modal !-->

        <b-alert style="z-index: 1000;" class="position-fixed fixed-top m-0 rounded-0 text-center" variant="warning" show>Olá <b>{{$store.getters['auth/userName']}}</b>, você ainda está ai?</b-alert>        
    </div>
</template>

<script>

export default {
    name: "AutoLogout",
    data: function () {
        return {
            events: ['click', 'mouseover', 'mousedown', 'scroll', 'keypress', 'load'],
            warningTimer: null,
            logoutTimer: null,
            warningZone: false,
            logoutWait: 120,
        }        
    },
    mounted() {
        this.events.forEach(function(event) {
            window.addEventListener(event, this.resetTimer);
        }, this);
        this.setTimers()
    },
    destroyed() {
        this.events.forEach(function(event) {
            window.removeEventListener(event, this.resetTimer);
        }, this);
        this.clearTimer()   
    },
    methods: {
        setTimers: function () {            
            this.warningTimer = setTimeout(this.warningMessage, (this.logoutWait-1) * 60 * 1000);
            this.logoutTimer = setTimeout(this.logoutUser, this.logoutWait * 60 * 1000);
            this.warningZone = false
        },
        warningMessage: function () {            
            this.warningZone = true
        },
        logoutUser: function () {                      
            this.$store.dispatch('auth/logout');              
            this.$router.push({ name: 'Acesso' });            
            window.location.reload(true)
        },
        resetTimer: function () {
            this.clearTimer()
            this.setTimers()
        },
        clearTimer: function () {
            clearTimeout(this.warningTimer)
            clearTimeout(this.logoutTimer)
        }
        
    }


}

</script>

<style scoped>

</style>