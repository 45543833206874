<template>
  <edit-aprovacao ref="editAprovacao" @showOverlay="(status) => this.$emit('showOverlay', status)"
    :idAprovacao="idLiberacao" @fecharedit="() => this.edit = true"
    @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.id) }" />
</template>

<script>
import Card from 'src/components/Cards/Card.vue'
import EditAprovacao from './EditAprovacao.vue'

export default {
  components: {
    Card,
    EditAprovacao
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      empresa: '',
      idLiberacao: null,
      edit: true,
      agenda: null,
      optionsUF: []
    }
  },
  async mounted() {

    try {
      var tmp = this.$route.params.idLiberacao
      if (tmp) {
        this.idLiberacao = atob(tmp)
        this.atualizarRegistro()
      }

    } catch (e) {
      alert(e)
    }

  },
  methods: {
    atualizarRegistro() {
      this.$refs.editAprovacao.carregaDados()
    },

  },
}

</script>
<style></style>