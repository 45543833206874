<template>

  <div class="previsto-realizado-fechamento">
    <b-table v-if="itens" :items="itens" :fields="fields" responsive="sm" :fixed="true" head-variant="light"
      sticky-header>

      <template v-for="field in fields" v-slot:[`cell(${field.key})`]="{ value, item, field }">
        <template>{{ value }}</template>
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: ['itens'],
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      fields: [
        { key: 'dscTipo', label: 'Tipo de serviço', sortable: true, sortDirection: 'asc', thStyle: { width: "60%" } },
        { key: 'duracaoPrevistaStr', label: 'Horas Prevista', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },
        { key: 'duracaoRealizadaStr', label: 'Horas realizada', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },        
      ],
    }
  },
  watch: {
    idAgenda(newVal) {
      this.buscarVaga()
    }
  },
  async mounted() {

  },
  methods: {

  },
}

</script>
<style>

</style>