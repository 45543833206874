<template>
  <div>
    <b-modal header-bg-variant="primary" header-text-variant="light" ref="modal-perfil-profissional"
      id="modal-perfil-profissional" title="Perfil do profissional" size="md">

      <perfil-profissional :idProfissional="idProfissionalPerfil"></perfil-profissional>

      <template #modal-footer="{ cancel }">
        <b-row class="w-100">
          <b-col>
            <b-button size="md" variant="danger" class="btn-fill float-right" @click="cancel()">
              Fechar
            </b-button>
          </b-col>
        </b-row>
      </template>

    </b-modal>

    <card>
      <h4 slot="header" class="card-title"> Aprovação </h4>

      <form>

        <b-container class="c-form px-2" v-if="agenda">

          <b-row md="auto">
            <b-col>

              <b-button class="btn btn-primary btn-fill float-right" @click.prevent="voltar">
                Voltar
              </b-button>

            </b-col>
          </b-row>

          <b-row md="auto">
            <b-col>
              <p class="text-secondary"><span class="font-weight-bold">Município: </span> {{ agenda.cidade + ' - ' +
                agenda.uf }} </p>
              <p class="text-secondary"><span class="font-weight-bold">Local: </span> {{ agenda.nomeLocal }} </p>
              <p class="text-secondary"><span class="font-weight-bold">Tipo de serviço: </span> {{ agenda.dscTipo }}</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="d-flex">
              <p class="text-secondary"><span class="font-weight-bold">Data: </span> {{ strDateFormat(agenda.dtAgenda,
                'dd/MM/yyyy') }}</p>
              <p class="ml-5 text-primary text-secondary text-capitalize">( {{ agenda.diaSemana }} )</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <p class="text-secondary"><span class="font-weight-bold">Horário: </span> {{ agenda.horarioDe }}</p>
              <p class="text-secondary"><span class="font-weight-bold">Duração: </span> {{ agenda.duracaoHoraMinuto }}</p>
            </b-col>
          </b-row>

          <b-row md="auto">

            <b-col cols="11">
              <b-form-select v-model="agenda.idProfissional" value-field="id" text-field="resumoProfissional"
                :options="agenda.candidatos" size="sm">
                <template #first>
                  <b-form-select-option :value="null">-- Selecione o Profissional --</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>

            <b-col cols="1" class="col-1">
              <b-button type="button" size="sm" class="ml-1 btn btn-info btn-fill" @click="abrirPerfil">
                <b-icon icon="person-badge" aria-hidden="true"></b-icon>
              </b-button>
            </b-col>

          </b-row>

          <b-row class="my-3">
            <b-col>
              <p class="font-weight-bold">Observação do profissional: </p>
              <p>{{ obsProfissional }}</p>
            </b-col>
          </b-row>

          <b-row class="mt-3">

            <b-col class="text-center">
              <b-button class="btn btn-success btn-fill " @click.prevent="aprovar">
                Aprovar
              </b-button>
              <b-button class="btn btn-danger btn-fill ml-1" @click.prevent="reprovar">
                Reprovar
              </b-button>
            </b-col>

          </b-row>

        </b-container>

      </form>

    </card>

  </div>
</template>

<script>

import AgendaService from '../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../service/config'
import appUtils from '@/composables/utils'
import PerfilProfissional from '../comuns/PerfilProfissional'

export default {
  props: ['idAgenda'],
  components: {
    Card,
    PerfilProfissional
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      agenda: null,
      idProfissionalPerfil: 0
    }
  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    if (this.idAgenda > 0) {
      this.carregaDados();
    } else {

    }
  },
  methods: {
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("abrirAprovacaoCandidato");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        idAgenda: this.idAgenda
      }

      AgendaService.abrirAprovacaoCandidato(this.http, dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.agenda = retorno.obj;
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("abrirAprovacaoCandidato");
        }).catch((error) => {
          desativarShow("abrirAprovacaoCandidato");
          toast(error, 'Falha', 'danger')
        });


    },
    voltar() {
      this.$emit('fecharedit')
    },
    aprovar() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("aprovarCandidato");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idAgenda": this.agenda.id,
        "idProfissional": this.agenda.idProfissional
      }

      AgendaService.aprovarCandidato(this.http, dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "id": this.agenda.id
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("aprovarCandidato");
        }).catch((error) => {
          desativarShow("aprovarCandidato");
          toast(error, 'Falha', 'danger')
        });
    },
    reprovar() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("reprovarCandidato");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idAgenda": this.agenda.id,
        "idProfissional": this.agenda.idProfissional
      }

      AgendaService.reprovarCandidato(this.http, dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "id": this.agenda.id
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("reprovarCandidato");
        }).catch((error) => {
          desativarShow("reprovarCandidato");
          toast(error, 'Falha', 'danger')
        });
    },
    strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
    abrirPerfil() {
      this.idProfissionalPerfil = this.agenda.idProfissional
      this.$bvModal.show("modal-perfil-profissional")
    }
  },
  computed: {

    obsProfissional: function () {
      let candidato = this.agenda && this.agenda.idProfissional ? this.agenda.candidatos.find((f) => f.id === this.agenda.idProfissional) : null
      return candidato !== null ? candidato.obsProfissional : ''
    }

  }


}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>

<style></style>