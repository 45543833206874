<template>
  <div v-if="entity">

    
    
        <form>

          <b-jumbotron fluid header-level="4" :header="entity.situacao" :lead="entity.profissional" class="px-2">

            <b-row class="mx-0">
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="Gestora" label-for="label-empresa">
                  <p id="label-empresa">{{ entity.empresa }}</p>
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label-class="font-weight-bold" label="Competência" label-for="label-competencia">
                  <p id="label-competencia">{{ entity.competencia }}</p>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label-class="font-weight-bold" label="Unidade" label-for="label-unidade">
                  <p id="label-unidade">{{ entity.unidade }}</p>
                </b-form-group>
              </div>
                        
              <div class="col-md-12" v-if="entity.procedimento">
                <b-form-group label-class="font-weight-bold" label="Procedimento" label-for="label-procedimento">
                  <p id="label-procedimento">{{ entity.procedimento }}</p>
                </b-form-group>
              </div>
            
              <div class="col-md-2">
                <b-form-group label-class="font-weight-bold" label="Valor" label-for="label-valor">
                  <p id="label-valor">{{ entity.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }) }}</p>
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label-class="font-weight-bold" label="Quantidade" label-for="label-quantidade">
                  <p id="label-quantidade">{{ entity.quantidade.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }) }}</p>
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label-class="font-weight-bold" label="Dt. solicita NF" label-for="label-profissional">
                  <p id="label-dt-solicita-nf">{{ strDateFormat(entity.dtSolicitaNf, 'dd/MM/yyyy') }}</p>                  
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label-class="font-weight-bold" label="Dt. emissão" label-for="label-dt-emissao">
                  <p id="label-dt-emissao-nf">{{ strDateFormat(entity.dtEmissaoNf, 'dd/MM/yyyy') }}</p>
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label-class="font-weight-bold" label="Dt. pagamento" label-for="label-dt-pagamento">
                  <p id="label-dt-pagamento">{{ strDateFormat(entity.dtPagamento, 'dd/MM/yyyy') }}</p>
                </b-form-group>
              </div>            

              <div class="col-md-4">
                <b-form-group label-class="font-weight-bold" label="Prestador" label-for="label-prestador">
                  <p id="label-prestador">{{ entity.prestador }}</p>
                </b-form-group>
              </div>

              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Nº nota" label-for="label-nota">
                  <p id="label-nota">{{ entity.numeroNota }}</p>
                </b-form-group>
              </div>

              <div class="col-md-12 text-center text-md-right">
                <b-button variant="primary" class="btn-fill" @click.prevent="voltar">
                  Fechar
                </b-button>
              </div>

            </b-row>

          </b-jumbotron>
          <div class="clearfix"></div>
        </form>
      

  </div>
</template>

<script>

import AgendaService from '../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import appUtils from '@/composables/utils'

export default {
  props: ['idPagamento'],
  components: {
    Card,
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      entity: null
    }
  },
  mounted() {
    if (this.idPagamento > 0) {
      this.carregaDados();
    }
  },
  methods: {
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("abrirPagamento")

      AgendaService.abrirPagamento(this.idPagamento)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.entity = retorno.obj;
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("abrirPagamento");
        }).catch((error) => {
          desativarShow("abrirPagamento");
          toast(error, 'Falha', 'danger')
        });


    },
    voltar() {
      this.$emit('fecharedit')
    },
    strDateFormat(dataStr, format) {
      if (!dataStr) return ""
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    }
  },
  computed: {

  }


}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>

<style>

</style>