<template>
  <div>    
    
    <select-cor ref="selectCor" @executou="(r) => { if(r.status === 'success') atualizarCor(r.color) }"/>

    <card v-if="entity">
    <h4 slot="header" class="card-title"> {{ this.idTipo > 0 ? 'Atualizar Tipo de Serviço' : 'Novo Tipo de Serviço' }}  </h4>
    <form>

      <div class="row">        
        <div class="col-md-12">          
          <b-form-group label="Descrição" label-for="input-descricao">
            <b-form-input id="input-descricao" type="text" placeholder="Descrição" maxlength = "200" v-model="entity.descricao" />
          </b-form-group>        
        </div>
      </div>

      <div class="row">                
        <div class="col-md-5">                    
          <b-form-group label="Especialidade" label-for="input-especialidade">
            <v-select v-model="entity.idEspecialidade" :reduce="especialidade => especialidade.id" label="descricao" :options="especialidades" 
            placeholder="Especialidade" >
              <span slot="no-options" @click="$refs.select.open = false">Nenhum registro encontrado</span>
            </v-select>
          </b-form-group>                 
        </div>

        <div class="col-md-4">   
            <b-form-group label="Cor" label-for="input-cor">
              <div class="box-color" :style="{background: entity.cor}" @click="abrirCor"> 
              <div style="padding:2px; font-size: 12px; color:blue">{{ entity.cor === null || entity.cor==='' ? 'clique aqui para selecionar a cor' : '' }}</div>
              </div>
            </b-form-group>                        
        </div>

      </div>        
      
      <div class="row">        
        <div class="col-md-4">          
          <b-form-group label="Ativo" label-for="input-ativo">
            <b-form-checkbox v-model="entity.ativo" value="S" unchecked-value="N" switch></b-form-checkbox>
          </b-form-group>        
        </div>
      </div>

      <div class="text-center">        
        <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="salvar">
          Salvar
        </button>
        <button type="submit" class="btn btn-danger btn-fill float-right mr-1" @click.prevent="voltar">
          Fechar
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>

</div>
</template>

<script>

import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'
import SelectCor from '../../comuns/SelectCor.vue'

export default {    
    props: ['idTipo'],
    components: {
      Card,
      SelectCor,    
    },
    data() {
        return {
            http: null,
            show: false,
            showFrom: "",
            filaShow: [],            
            entity: null,    
        }
    },
  async mounted() {    

      this.http = axios.create({
        baseURL: BASE_CONSTANTS.urlBase,
        headers: {
            'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
            'Content-Type': 'application/json',
            'Accept': '*/*'
        }
      })    

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = { 
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id
        }

      ativarShow("listaEspecialidade");
      await AgendaService.listaEspecialidadeFilter(this.http, dadosConsulta)
          .then((resposta) => {           
          this.especialidades = resposta.data.obj                              
          desativarShow("listaEspecialidade");
        })
        .catch((err) => {          
          desativarShow("listaEspecialidade");
      });
      

      if (this.idTipo > 0) {        
        this.carregaDados();
      } else {
        this.entity = {
          "descricao": "",
          "idEspecialidade": null,
          "ativo": "S",   
          "cor": null,
          "idEmpresa": dadosUsuario.grupo.empresa.id                 
        }
      }

    },
    methods: {
      carregaDados(){

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("carregarDados");

      AgendaService.editTipo(this.http, this.idTipo)
          .then((resposta) => {           
           this.entity = resposta.data.obj          
          this.show = false          
          desativarShow("carregarDados");
        })
        .catch((err) => {          
          this.show = false
          desativarShow("carregarDados");
        });
      },  
      voltar() {
        this.$emit('fecharedit')
      },
      salvar() {

        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("salvarTipo");              
        AgendaService.saveTipo(this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {            
              let ret = {
                "status": "success",
                "entity": retorno.obj
              }              
              this.$emit('executou', ret)
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
                var v = this
                setTimeout(function () {
                  v.voltar()
                }, 2000);            
            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }  
            desativarShow("salvarTipo");
          }).catch((error) => {          
            desativarShow("salvarTipo");
            toast(error, 'Falha', 'danger')
          });

      },     
      abrirCor() {
        this.$refs.selectCor.show(this.entity.cor)      
      },    
      atualizarCor(cor) {
        this.entity.cor = cor;
      }           
  }
    
}
</script>



<style>

</style>