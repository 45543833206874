<template>
  <b-modal :ref="'modal-conf-rel-fin-'+id" :id="'modal-conf-rel-fin-'+id" size="lg" title="Confirmação" 
    header-bg-variant="primary" header-text-variant="light" 
    footer-bg-variant="light" no-close-on-backdrop>
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">
            {{ msgConfirma }}
          </div>
        </b-row>
        <b-row class="my-1">

          <div class="col-md-12">

            <b-form-group label="Exibir as seguintes colunas" >
              <b-form-checkbox-group
                id="checkbox-group-colunas"
                v-model="escolha.outrasColunasSelecionadas"
                :options="listaOutrasColunas"
              ></b-form-checkbox-group>
            </b-form-group>

          </div>

          <div class="col-md-12" v-if="false">
            <b-form-checkbox v-if="exibirValorVenda" v-model="escolha.comPrecoVenda" value="S" unchecked-value="N">Exibir valor de venda</b-form-checkbox>
            <b-form-checkbox v-if="exibirValorCompra" v-model="escolha.comPrecoCompra" value="S" unchecked-value="N">Exibir valor de compra</b-form-checkbox>            
          </div>
        </b-row>
      </div>
        <template #modal-footer>
        <div class="w-100">
          <div>
            <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right"
              @click="$bvModal.hide('modal-conf-rel-fin-'+id)">
              Cancelar
            </button>
            <button size="sm" class="btn btn-success btn-fill float-right" @click="confirmar">
              Gerar
            </button>
          </div>          
        </div>
      </template>      
  </b-modal>
</template>
<script>

export default {  
  props: ['id'],
    data() {
      return {
        http: null,
        filaShow: [],
        msgConfirma: null,
        escolha: {
          //comPrecoVenda: 'N',
          //comPrecoCompra: 'N',
          outrasColunasSelecionadas: [] 
        },
        exibirValorVenda: false,
        exibirValorCompra: false,        
        listaOutrasColunas:[],
        /*listaOutrasColunas: [
          { text: 'Valor de venda', value: 'valorVenda' },
          { text: 'Valor de compra', value: 'valorCompra' },
          { text: 'Tipo de serviço', value: 'tipoServico' },
          { text: 'Valor unitário', value: 'valorUnitario' }
        ]*/
      }
  },
  mounted() {    
    this.exibirValorVenda = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_VENDA', 'E')
    this.exibirValorCompra = this.hasPermission('RELATORIO_FINANCEIRO', 'PRECO_COMPRA', 'E')
  },
  methods: {    
    confirmar() {

      /*this.escolha.comPrecoVenda = this.outrasColunasSelecionadas.filter((p) => { return p === 'valorVenda' }).length == 1 ? 'S' : 'N'
      this.escolha.comPrecoCompra = this.outrasColunasSelecionadas.filter((p) => { return p === 'valorCompra' }).length == 1 ? 'S' : 'N'
      this.escolha.comTipoServico = this.outrasColunasSelecionadas.filter((p) => { return p === 'tipoServico' }).length == 1 ? 'S' : 'N'
      this.escolha.comPrecoUnitario = this.outrasColunasSelecionadas.filter((p) => { return p === 'valorUnitario' }).length == 1 ? 'S' : 'N'*/

      let ret = {
        "status": "success",
        "escolha": this.escolha
      }              
      this.$emit('executou', ret)
      this.fechar()
    },                      
    fechar() {
      this.hide()
    },
    async show(colunas) {
      //this.escolha.comPrecoVenda = 'N'
      //this.escolha.comPrecoCompra = 'N'
      this.escolha.outrasColunasSelecionadas = []
      this.listaOutrasColunas = colunas
      this.$bvModal.show('modal-conf-rel-fin-'+this.id)
    },
    hide() {
      this.$bvModal.hide('modal-conf-rel-fin-'+this.id)
    },
    possuiOpcao() {
      return this.exibirValorVenda || this.exibirValorCompra
    },
    hasPermission(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    }, 
  }
}
</script>

<style>

</style>