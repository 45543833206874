<template>
  <div class="content dashboard">
    <div class="container-fluid">
      
      <div class="row">
        <div class="col-md-3">
          <div class="row mx-0">
            <div class="col-md-6">
              <b-form-datepicker
                id="dtInicio"
                v-model="dtInicio"
                size="sm"
                placeholder="Período"
                today-button
                reset-button
                close-button
                today-button-variant="primary"
                reset-button-variant="danger"
                close-button-variant="success"
                label-no-date-selected="Nenhuma data selecionada"
                label-today-button="Hoje"
                label-reset-button="Limpar"
                label-close-button="Fechar"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
              >
              </b-form-datepicker>
            </div>
            <div class="col-md-6">
              <b-form-datepicker
                id="dtFim"
                v-model="dtFim"
                size="sm"
                class="mb-2"
                placeholder="até"
                today-button
                reset-button
                close-button
                today-button-variant="primary"
                reset-button-variant="danger"
                close-button-variant="success"
                label-no-date-selected="Nenhuma data selecionada"
                label-today-button="Hoje"
                label-reset-button="Limpar"
                label-close-button="Fechar"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
              >
              </b-form-datepicker>
            </div>
          </div>
        </div>

        <div class="col-md-3">

          <v-select
            v-model="itemLocal"
            :options="itensLocal"
            label="descricao"
            ref="selectLocal"
            placeholder="Local do plantão">

            <span slot="no-options" @click="$refs.select.open = false">
              Nenhum registro encontrado
            </span>

          </v-select>

        </div>

        <div class="col-md-3">
          <v-select
            v-model="itemTipo"
            :options="itensTipo"
            @open="listarTipos"
            label="descricao"
            ref="select"
            placeholder="Tipo de serviço">
            <span slot="no-options" @click="$refs.select.open = false">
              Nenhum tipo de procedimento
            </span>
          </v-select>
        </div>

        <div class="col-md-2">
          <v-select
            v-model="itemSituacao"
            :options="itensSituacao"
            @open="listarSituacoes"
            label="descricao"
            ref="select"
            placeholder="Situação"
          >
            <span slot="no-options" @click="$refs.select.open = false">
              Nenhuma situação
            </span>
          </v-select>
        </div>

        <div class="col-md-1">
          <b-overlay
            :show="show"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block w-100" >            
            <b-button size="sm" variant="primary" class="btn btn-fill btn-primary btn-block w-100" @click="gerarDashboard" >
              <b-icon icon="bar-chart" aria-hidden="true" class="mr-1"></b-icon> Atualizar</b-button>              
          </b-overlay>
        </div>
      </div>

      <div class="row">
        
        <div class="card col-md-2 px-0 border-0">
          
          <card class="mb-0 h-100">
              <template slot="header">
                  <h4 class="card-title">Plantões de hoje</h4>
                  <p class="card-category"></p>
              </template>                

              <div class="row px-3">              
                  <div class="col-md-7 mb-1"><h2>Total:</h2></div>
                  <div class="col-md-5 mb-1">{{ this.getInfoDashBoard('qtdTotalHoje') }}</div>              
              
                  <div class="col-md-7 mb-1"><h2>Previsto:</h2></div>
                  <div class="col-md-5 mb-1">{{ this.getInfoDashBoard('qtdPrevistoHoje') }}</div>            
              
                  <div class="col-md-7 mb-1"><h2>Confirmado:</h2></div>
                  <div class="col-md-5 mb-1">{{ this.getInfoDashBoard('qtdConfirmadoHoje') }}</div>
                          
                  <div class="col-md-7 mb-1"><h2>Falta:</h2></div>
                  <div class="col-md-5 mb-1">{{ this.getInfoDashBoard('qtdFaltaHoje') }}</div>
                          
                  <div class="col-md-7 mb-0"><h2>Sem profissional:</h2></div>
                  <div class="col-md-5 mb-0">{{ this.getInfoDashBoard('qtdSemProfissionalHoje') }}</div>
              </div>
            </card>
          
        </div>

        <div class="col-md-10">
          <div class="row m-0">

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-warning">
                  <i class="nc-icon nc-circle-09 text-warning"></i>
                </div>
                <div slot="content" class="mr-1">
                  <p class="card-category">Prof. Escalados</p>
                  <h4 class="card-title">{{this.getInfoDashBoard('qtdProfEscalado')}}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-success">
                  <i class="nc-icon nc-badge text-primary"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Prof. efetivos</p>
                  <h4 class="card-title">{{this.getInfoDashBoard('qtdProfEfetivo')}}</h4>              
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-badge text-secondary"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Prof. PJ</p>
                  <h4 class="card-title">{{this.getInfoDashBoard('qtdProfPJ')}}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-refresh-02 text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Quant. trocas</p>
                  <h4 class="card-title">{{this.getInfoDashBoard('qtdTroca')}}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-money-coins text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Adiant. à vista</p>
                  <h4 class="card-title">R$ {{this.getInfoDashBoard('adiantAVista')}}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-money-coins text-primary"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Ad. à vista c/ compl.</p>
                  <h4 class="card-title">R$ {{this.getInfoDashBoard('adiantAVistaCompl')}}</h4>
                </div>
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-circle-09 text-info"></i>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Prof. novatos</p>
                  <h4 class="card-title">{{this.getInfoDashBoard('qtdProfNovato')}}</h4>
                </div>                
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">                             
                  <b-icon icon="award-fill" shift-v="2" font-scale="1.1" animation="throb" :style="colorEficiencia" ></b-icon>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Eficiência</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('eficiencia') }}%</h4>
                </div>                
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">                  
                  <b-icon icon="calendar3" shift-v="2" font-scale="1.1" class="text-warning" ></b-icon>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Escalas</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdEscala') }}</h4>
                </div>                
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">
                  <b-icon icon="calendar2-plus" shift-v="2" font-scale="1.1" class="text-success" ></b-icon>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Fechamentos</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdFechamento') }}</h4>
                </div>                
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">
                  <b-icon icon="calendar2-minus" shift-v="2" font-scale="1.1" class="text-danger" ></b-icon>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Fecha. pend.</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdFechamentoPendente') }}</h4>
                </div>                
              </stats-card>
            </div>

            <div class="col-md-2">
              <stats-card>
                <div slot="header" class="icon-info">                  
                  <b-icon icon="calendar2-event" shift-v="2" font-scale="1.1" class="text-warning" ></b-icon>
                </div>
                <div slot="content" class="mr-2">
                  <p class="card-category">Total plantões</p>
                  <h4 class="card-title">{{ this.getInfoDashBoard('qtdTotal') }}</h4>
                </div>                
              </stats-card>
            </div>

          </div>
        </div>

      </div>

      <div class="row">      

        <div class="col-md-6">          
          <card>
            <chart-card v-if="lineChartHistoricoPlantao != null"
              :chart-data="lineChartHistoricoPlantao.data"
              :chart-options="lineChartHistoricoPlantao.options"
              :responsive-options="lineChartHistoricoPlantao.responsiveOptions"
              :key="'lineChartHistoricoPlantao'+seqKeyGrafico">
            </chart-card>

            <div v-else><sem-dados-dashboard/></div>

              <template slot="header">
                <h4 class="card-title">Histórico de plantões</h4>
                <p class="card-category">
                  Histórico de plantões com o comparativo Plantão x Falta
                </p>
              </template>
              
              <template slot="footer">
                <div class="legend">
                  <div ref="lineChartHistoricoPlantaoLegenda"></div>
                </div>              
              </template>   

          </card>
        </div>

        <div class="card col-md-3 px-0 border-0" >

          <card class="mb-0 h-100" >

            <chart-card v-if="barChartPorSituacao!=null"
              :chart-data="barChartPorSituacao.data"
              :chart-options="barChartPorSituacao.options"
              :chart-responsive-options="barChartPorSituacao.responsiveOptions"
              chart-type="Bar" :key="'barChartPorSituacao'+seqKeyGrafico">
            </chart-card> 

            <div v-else><sem-dados-dashboard/></div>

            <template slot="header">
              <h4 class="card-title">Situações</h4>
              <p class="card-category">Quantidade por situação</p>
            </template>

            <template slot="footer">
              <div class="legend">
                <div ref="barChartPorSituacaoLegenda"></div>
              </div>              
            </template>

          </card>

        </div>

        <div class="card col-md-3 px-0 border-0">       

          <card class="mb-0 h-100" >

              <template slot="header">
                <h4 class="card-title">Tipos</h4>
                <p class="card-category">
                  Quantidade por tipo de serviço
                </p>
              </template>
              
              <b-table v-if="dashboard !== null && dashboard.quantPorTipo !== null && dashboard.quantPorTipo.length>0"
                  :fixed="true"                  
                  :items="dashboard.quantPorTipo"
                  :fields="fieldsQuantPorTipo"
                  responsive="sm"
                  head-variant="light"
                  sticky-header="187px"
                  thead-class="hidden_header"
                  small
                  class="tb-qtde-tipo">
                  
                  <template #cell()="data">
                    {{ data.value }}
                  </template>

              </b-table>

              <div v-else><sem-dados-dashboard/></div>              

          </card>

        </div>
        
      </div>

      <div class="row">

        <div class="col-md-3">          
          <card>
          
          <chart-card v-if="pieChartCobertura!==null" 
          :chart-data="pieChartCobertura.data" 
          :chart-options="pieChartCobertura.options"          
          chart-type="Pie" :key="'pieChartCobertura'+seqKeyGrafico">
          </chart-card>

          <div v-else><sem-dados-dashboard/></div>

          <template slot="header">
              <h4 class="card-title">Cobertura</h4>                            
              <p class="card-category">Coberto x Não coberto</p>
            </template>
            
            <template slot="footer" >
              <div class="legend">
                <div ref="coberturaLegend"></div>
              </div>
            </template>

          </card>

        </div>

        <div class="col-md-6">
          <card>
            <chart-card v-if="barChartHistoricoCobertura!=null"
              :chart-data="barChartHistoricoCobertura.data"
              :chart-options="barChartHistoricoCobertura.options"
              :chart-responsive-options="barChartHistoricoCobertura.responsiveOptions"
              chart-type="Bar" :key="'barChartHistoricoCobertura'+seqKeyGrafico">
            </chart-card> 

            <div v-else><sem-dados-dashboard/></div>

              <template slot="header">
                <h4 class="card-title">Histórico de coberturas</h4>
                <p class="card-category">Histórico de coberturas com o comparativo Coberto x Não coberto</p>
              </template>

              <template slot="footer">
                <div class="legend">
                  <div ref="barChartHistoricoCoberturaLegenda"></div>
                </div>              
              </template>
          </card>
        </div>

        <div class="card col-md-3 px-0 border-0" >

          <card class="mb-0 h-100" >
          
          <chart-card v-if="pieChartPorFuro!==null" :chart-data="pieChartPorFuro.data" 
          :chart-options="pieChartPorFuro.options"
          :chart-responsive-options="pieChartPorFuro.responsiveOptions"
          chart-type="Pie" :key="'pieChartPorFuro'+seqKeyGrafico">
          </chart-card>

          <div v-else><sem-dados-dashboard/></div>

            <template slot="header">
            <h4 class="card-title">Faltas</h4>
              <p class="card-category">
                Quantidade por tipo de falta
              </p>
            </template>
            
            <template slot="footer" >
              <div class="legend">
                <div ref="porFuroLegenda"></div>    
              </div>
            </template>

          </card>
                 
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>

import ChartCard from "src/components/Cards/ChartCard.vue";
import StatsCard from "src/components/Cards/StatsCard.vue";
import LTable from "src/components/Table.vue";
import AgendaService from "../service/AgendaService";
import axios from "axios";
import { BASE_CONSTANTS } from "../service/config";
import Chartist from 'chartist';
import ChartistPluginLegend from 'chartist-plugin-legend';
import ChartistPluginPointLabels from 'chartist-plugin-pointlabels';
import ChartistPluginTooltip from 'chartist-plugin-tooltip';
import Card from '../components/Cards/Card.vue';
import useAuthentication from '@/composables/useAuthentication'
import SemDadosDashboard from '../components/SemDadosDashboard.vue';
import appUtils from '@/composables/utils'

export default {
  components: {
    LTable,
    ChartCard,
    StatsCard,
    Chartist,
    ChartistPluginLegend,    
    ChartistPluginPointLabels,
    ChartistPluginTooltip,
    Card,
    SemDadosDashboard,
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      itensLocal: [],
      itemLocal: null,
      itemTipo: null,
      itemSituacao: null,
      itensLocal: [],
      itensTipo: [],
      itensSituacao: [],
      dtInicio: null,
      dtFim: null,
      dashboard: null,
      fieldsQuantPorTipo: [],
      editTooltip: "Edit Task",
      deleteTooltip: "Remove",
      //pieChart: null,
      pieChartCobertura: null,
      pieChartPorFuro: null,
      lineChartHistoricoPlantao: null,      
      barChartHistoricoCobertura: null,      
      barChartPorSituacao: null,
      seqKeyGrafico: 1,
    };
  },
  async mounted() {    
    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        Authorization:
          "bearer " +
          String(localStorage.getItem("authorization")).replaceAll('"', ""),
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });

    this.fieldsQuantPorTipo = [      
      {label: "Descrição", key: "dscTipo", thStyle: { width: "60%" } },
      {label: "Quant", key: "quantidade", thStyle: { width: "25%" }},
      { key: "last" }
    ];

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.dtInicio = dateToStr(firstDay, "yyyy-MM-dd")
    this.dtFim = dateToStr(lastDay, "yyyy-MM-dd")

    this.listarLocais()
    this.limparDashboard()
    this.gerarDashboard()

  },
  methods: {    
    getInfoDashBoard(field){      
      return this.dashboard!=null ? this.dashboard[field] : '';
    },    

    limparDashboard(){
      
    },

    gerarDashboard() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("gerarDashboard");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let idsLocal = [];
        let idsProfissional = [];
        let idsTipo = [];
        let idsSituacao = [];
        
        if (this.itemLocal != null) {          
          idsLocal.push(this.itemLocal.id)
        }
        if (this.itemProfissional != null) {          
          idsProfissional.push(thi.itemProfissional.id);
        }
        if (this.itemTipo != null) {          
          idsTipo.push(this.itemTipo.id);
        }
        if (this.itemSituacao != null) {        
          idsSituacao.push(this.itemSituacao.id);
        }

      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        dtInicio:  this.dtInicio,
        dtFim: this.dtFim,
        idsLocal: idsLocal,
        idsProfissional: idsProfissional,
        idsTipo: idsTipo,
        idsSituacao: idsSituacao
      }     

      AgendaService.gerarDashboard(dadosConsulta)
      .then((resposta) => {                  
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {            
            this.dashboard = retorno.obj;
            
            this.seqKeyGrafico++;

            this.gerarGraficoHistoricoPlantao()
            this.gerarGraficoHistoricoCobertura()            
            //this.gerarGraficoPorTipo()
            this.gerarGraficoPorSituacao()
            this.gerarGraficoPorFuro()
            this.gerarGraficoBarraPorSituacao();
            
          } else {
            this.limparDashboard();
            toast(retorno.mensagens[0].descricao,"Falha","danger");
          }
          desativarShow("gerarDashboard");
        }).catch((err) => {          
          console.log(err)
          const { isUnauthorized } = useAuthentication()          
          const isLogin = isUnauthorized(err)
          if(isLogin) this.$router.push("/login");
          toast(err,"Falha","danger");
          this.limparDashboard();
          desativarShow("gerarDashboard");
        });
    },

    gerarGraficoHistoricoPlantao(){

      if (this.$refs['lineChartHistoricoPlantaoLegenda']) {        
        this.$refs['lineChartHistoricoPlantaoLegenda'].innerHTML = '';
      }

      let labels = []
      let series = [[],[]]
      let max = 0      
      if(this.dashboard.coberturas!=null && this.dashboard.coberturas.length>0){
        this.dashboard.coberturas.forEach(c => {              
          labels.push(c.mes)
          //series[0].push(0)
          series[0].push(c.qtdPlantao)
          series[1].push(c.qtdFalta)
          //series[2].push(c.qtdAVista)          
          if(c.qtdPlantao> max) max = c.qtdPlantao
          if(c.qtdFalta> max) max = c.qtdFalta
          if(c.qtdAVista> max) max = c.qtdAVista
        })  
                    
        max += 500

        this.lineChartHistoricoPlantao = {
          data: {
            labels: labels,
             series: series                  
          },
          options: {
            low: 0,
            high: max,
            //showArea: false,
            //height: "150px",
            axisX: {
              showGrid: true,
            },            
            seriesBarDistance: 10,
            //axisY: {
            //offset: 5,
            //scaleMinSpace: 100,
            //showGrid: true,
            //showLabel: false
            //},
            //lineSmooth: true,
            //showLine: true,
            //showPoint: true,
            //fullWidth: true,
            /*chartPadding: {              
              right: 50,
            },*/            
            plugins: [                
              Chartist.plugins.legend({
                legendNames: ['Plantões','Falta'],
                 position: this.$refs['lineChartHistoricoPlantaoLegenda']
              }),
              Chartist.plugins.ctPointLabels({                                   
                  labelInterpolationFnc: function(value) {
                        return value !== undefined ? value.toFixed(2) : ''
                    },                    
                }),
                Chartist.plugins.tooltip({                  
                  valueTransform: function (value) {                    
                      return value;
                  }
                })
            ]
          },
          responsiveOptions: [
            [
            "screen and (max-width: 1024px)",
              {
                axisX: {
                  labelInterpolationFnc(value) {
                    return value[0];
                  },
                },
              },
            ],
          ],
        }
      }else{
        this.lineChartHistoricoPlantaoLegenda = null
      }
    },

    gerarGraficoHistoricoCobertura(){

      if (this.$refs['barChartHistoricoCoberturaLegenda']) {        
        this.$refs['barChartHistoricoCoberturaLegenda'].innerHTML = '';
      }

      if(this.dashboard.historicoMedicao!=null && this.dashboard.historicoMedicao.length > 0){

        let labels = []
        let series = [[],[]]
        let max = 0

        this.dashboard.historicoMedicao.forEach(h => {              
                  labels.push(h.mes)
                  series[0].push(h.percCoberto)
                  series[1].push(h.percNaoCoberto)
                  if(h.percCoberto> max) max = h.percCoberto
                  if(h.percNaoCoberto> max) max = h.percNaoCoberto
              })          

        this.barChartHistoricoCobertura =  {
          data: {
            labels: labels,
            series: series,
          },
          options: {            
            low: 0,
            high: max,
            seriesBarDistance: 10,
            axisX: {
              showGrid: true,
            },
            //height: "245px",           
            stackBars: true,
            /*labelInterpolationFnc: function(value) {              
              return value;
            },*/
            //labelInterpolationFnc: function(value, index) {              
              //console.log(value)
              //return (series[index] / series.reduce(sum) * 100).toFixed(2) + '%';
              //return series[index].toFixed(2) + '%';
            //},  
            plugins: [
                Chartist.plugins.legend({
                  legendNames: ['Coberto','Não coberto'],
                  position: this.$refs['barChartHistoricoCoberturaLegenda']
                }),                                
                Chartist.plugins.ctPointLabels({                  
                  textAnchor: 'middle',
                  labelInterpolationFnc: function(value) {
                        return value !== undefined ? value.toFixed(2)+'%' : ''
                    },                    
                }),
                Chartist.plugins.tooltip({                  
                  valueTransform: function (value) {                    
                      return value + '%';
                  }
                })
            ]
          },
          responsiveOptions: [
            [
              "screen and (max-width: 640px)",
              {                
                showPoint: true,
                seriesBarDistance: 5,
                axisX: {
                  labelInterpolationFnc(value) {
                    return value[0];
                  },
                },
              },
            ],
          ],
        }

      }else{
        this.barChart = null
      }
    },
    /*gerarGraficoPorTipo(){
      
      if(this.dashboard.quantPorTipo!=null && this.dashboard.quantPorTipo.length){

        let labels = []
        let series = []

        this.dashboard.quantPorTipo.forEach(t => {          
          labels.push(t.dscTipo)
          series.push(t.quantidade)
        })          

        this.pieChart =  {
          data: {
            labels: labels,
            series: series,
          },          
        }

      }else{
        this.pieChart = null
      }
    },*/

    gerarGraficoPorSituacao(){

      this.$refs['coberturaLegend'].innerHTML = '';
                        
      if(this.dashboard!=null){

        let labels = ['Coberto [ '+this.getInfoDashBoard('qtdPlantaoCoberto').toLocaleString()+' ]',
         'Não coberto [ '+this.getInfoDashBoard('qtdPlantaoNaoCoberto').toLocaleString()+' ]']
        let series = [ this.getInfoDashBoard('qtdPlantaoCoberto'), this.getInfoDashBoard('qtdPlantaoNaoCoberto')]

        var sum = function(a, b) { return a + b };

        var total = series.reduce(sum)

        if(total>0){    
          this.pieChartCobertura =  {
            data: {
              labels: labels,
              series: series,
            },
            options: {            
              labelInterpolationFnc: function(value, index) {              
                return series[index]>0 ? (series[index] / series.reduce(sum) * 100).toFixed(2) + '%' : '';              
              },  
              plugins: [
                  Chartist.plugins.legend({
                    //legendNames: ['Coberto','Não coberto'],
                    //className: 'small-legend-dash',
                    position: this.$refs['coberturaLegend']
                  })
              ]
            },          
            responsiveOptions: [],     
          }
        }else{
          this.pieChartCobertura = null  
        }
      }else{
        this.pieChartCobertura = null
      }

    },
    gerarGraficoPorFuro(){

    this.$refs['porFuroLegenda'].innerHTML = '';
    
    if(this.dashboard.quantPorFuro!=null && this.dashboard.quantPorFuro.length>0){

        let labels = []
        let series = []

        this.dashboard.quantPorFuro.forEach(t => {
          labels.push(t.dscGrafico)
          series.push(t.percentual)
        })           

        var sum = function(a, b) { return a + b };

        this.pieChartPorFuro =  {
          data: {
            labels: labels,
            series: series
          },
          options: {
            donut: true,              
            labelInterpolationFnc: function(value, index) {              
              return series[index].toFixed(2) + '%';
            },
            plugins: [
                Chartist.plugins.legend({
                  className: 'small-legend-dash',
                  position: this.$refs['porFuroLegenda']
                })
            ]
          },
          responsiveOptions: [
            
          ],
        }

      }else{
        this.pieChartPorFuro = null
      }
    },
    gerarGraficoBarraPorSituacao(){

      this.$refs['barChartPorSituacaoLegenda'].innerHTML = '';

      if(this.dashboard!=null){

        var confirmado = this.getInfoDashBoard('qtdPlantaoCoberto')
        var previsto = this.getInfoDashBoard('qtdPrevisto')
        var falta = this.getInfoDashBoard('qtdFaltaJustificada')+this.getInfoDashBoard('qtdFaltaInjustificada')
        var semProfissional = this.getInfoDashBoard('qtdSemProfissional')
      
        if(confirmado>0 || previsto>0 || falta>0 || semProfissional>0){

          let labels = ['Confirmado','Previsto','Falta','Sem profissional']      
          let series = [[confirmado,0,0,0],
          [0,previsto,0,0],
          [0,0,falta,0],
          [0,0,0,semProfissional]]
          
          let max = Math.max(confirmado,previsto,falta,semProfissional);      
          
          this.barChartPorSituacao =  {
            data: {
              labels: labels,
              series: series,
            },
            options: {            
              low: 0,
              high: max,            
              seriesBarDistance: 5,
              axisX: {
                showGrid: true,
              },            
              //chartPadding: 60,
              chartPadding: {            
                left: 50
              },
              //labelOffset: 100,
              labelDirection: 'explode',
              horizontalBars: true,            
              plugins: [
                  Chartist.plugins.legend({
                    legendNames: ['Confirmado','Previsto','Falta','Sem profissional'],
                    position: this.$refs['barChartPorSituacaoLegenda']
                  }),                
                  Chartist.plugins.ctPointLabels({                                    
                    textAnchor: 'middle',
                    labelInterpolationFnc: function(value) {                    
                          return value !== undefined && value > 0 ? value : ''
                      },                    
                  }),
                  Chartist.plugins.tooltip({                  
                    valueTransform: function (value) {                    
                        return value;
                    }
                })
              ]
            },
            responsiveOptions: [
              [
                "screen and (max-width: 640px)",
                {           
                  height: '100px',     
                  showPoint: true,
                  seriesBarDistance: 5,
                  axisX: {
                    labelInterpolationFnc(value) {
                      return value[0];
                    },
                  },
                },
              ],
            ],
          }
        }else{
          this.barChartPorSituacao = null;  
        }

      }else{
        this.barChartPorSituacao = null;  
      }

    },
    listarLocais() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarLocais");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa + "}";
      AgendaService.listaLocaisFilter(dadosConsulta).then((resposta) => {        
          this.itensLocal = resposta.data.obj;
          if (this.itensLocal.length > 0) {
            //this.itemLocal = this.itensLocal[0]
          }
          desativarShow("listarLocais");
        })
        .catch((err) => {                
          desativarShow("listarLocais");
          const { isUnauthorized } = useAuthentication()          
          const isLogin = isUnauthorized(err)
          if(isLogin) this.$router.push("/login");
        });
    },    
    listarTipos() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarTipos");
      let idsLocal = [];
            
      if (this.itemLocal != null) {               
        idsLocal.push(this.itemLocal.id);        
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idsLocal": idsLocal
      }

      AgendaService.listaTiposFilter(dadosConsulta).then((resposta) => {        
          this.itensTipo = resposta.data.obj;
          desativarShow("listarTipos");
        }).catch((error) => {
          desativarShow("listarTipos");
        });
    },
    listarSituacoes() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarSituacoes");

      let idsLocal = [];
      if (this.itemLocal != null) {
        this.itemLocal.forEach((item) => {
          idsLocal.push(item.id);
        });
      }

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa +',"idsLocal": [' +idsLocal +'], "acima": true, "abaixo": true }';

      AgendaService.recuperaSituacoes(dadosConsulta).then((resposta) => {
          this.itensSituacao = resposta.data.obj;
          desativarShow("listarSituacoes");
        }).catch((error) => {
          desativarShow("listarSituacoes");
        });
    },
  },
  computed:{
    colorEficiencia: function () {
      var val = this.getInfoDashBoard('eficiencia');
      if(val < 50){                      
          return 'color: #cd7f32'
      }else if(val > 50 && val < 70){                     
          return 'color: #c0c0c0'        
      }else{        
          return 'color: #ffd700'        
      }      
    }
  }
};


function dateToStr(date, format) {  
    if (date !== undefined && date != null) {      
      let strDate = format.replace('yyyy', date.getFullYear())
      .replace('MM',(date.getMonth()+1).toString().padStart(2,'0'))
      .replace('dd',date.getDate().toString().padStart(2,'0'))
      .replace('HH',date.getHours().toString().padStart(2,'0'))
      .replace('mm',date.getMinutes().toString().padStart(2,'0'))
      .replace('ss',date.getSeconds().toString().padStart(2,'0'));
      return strDate;
    }
  }

</script>

<style lang="scss">

  .dashboard .legend{
      ul{
        list-style-type: none;
      }

      li{
        display: inline;
      }
    }

  .dashboard{
  
    .card .ct-chart{
        margin: 0 0;
        height: 150px;
    }

    .hidden_header{
      display: none;
    }

    .tb-qtde-tipo tr{
      height: 10px;
    }

    .small-legend-dash{
      font-size: 10px;
    }

    .small-legend-dash li:before{
      margin-top: 2px;
    }

    .card .table tbody td:last-child,
    .card .table thead th:last-child {
      padding-right: 15px;
      height: 100%;
      display: none;
    }    
  }

</style>
