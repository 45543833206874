<template>
 <div id="menuTodo" >
  <div class="sidebar" :data-color="backgroundColor" v-if="loggedIn">
    <!-- <div class="sidebar" :data-color="backgroundColor" v-if="loggedIn"> -->

    <div class="sidebar-wrapper"  >

      <slot name="content"></slot>

      <ul class="nav nav-main__links">
        
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot >
          <sidebar-link v-for="(link,index) in sidebarLinks"
                        :key="index"
                        :to="link.path"
                        @click="closeNavbar"
                        :link="link"
                        >
            <i :class="link.icon" ></i>
            <!-- <p >{{link.name}}</p> -->
          </sidebar-link>
        </slot>

      </ul>
      <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </div>

 </div>
</template>

<script>
  import SidebarLink from './SidebarLink.vue'

  export default {
    components: {
      SidebarLink
    },
    props: {      
      title: {
        type: String,
        default: ''
      },
      backgroundColor: {
        type: String,
        default: 'blue',
        validator: (value) => {
          let acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      backgroundImage: {
        type: String,
        default: 'img/sidebar-4.png'
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide () {
      return {
        autoClose: this.autoClose
      }
    },
    computed: {
      sidebarStyle () {
        return {
          backgroundImage: `url(${this.backgroundImage})`
        }
      },  
      loggedIn() {
        return this.$store.state.auth.status.loggedIn
      }  
    }, 
    mounted() {      
      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))      
      if(dadosUsuario!=null && dadosUsuario.grupo.empresa.logo !== null){
        this.logoEmpresa = dadosUsuario.grupo.empresa.logo
    }
      
    },
    data () {
      return {
        logoEmpresa: null,
        mostraMenu: false
      }
    },
  methods: {
      
      escondeMenu(){
        this.mostraMenu = !this.mostraMenu
        console.log(this.mostraMenu)
      }

    }
  }

</script>
<style>
  .sidebar .sidebar-wrapper {
    display: flex;
    flex-direction: column;
  }
  
 .sidebar .nav-main__links {
   flex: 1;
   /* display: flex;
   flex-direction: column; */
 }
 .sidebar .sidebar-wrapper .logo .logo__container {
   /*padding-left: 10px;*/
 }

 .sidebar .sidebar-wrapper .logo {
   padding: 0;
 }

</style>
