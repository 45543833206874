<template>
    <div>

        <b-modal ref="modal-edit-item" id="modal-edit-item" size="sm" title="Editar Item" hide-footer no-close-on-backdrop>
            <div class="container-fluid">

                <b-row class="my-1">
                    <div class="col-md-12">

                        <b-card-body class="p-0" style="min-height: 370px;">

                            <b-row>
                                <b-form-group label="Nível de permissão">
                                    <b-form-radio-group v-model="dadosEdicaoItem.nivel" stacked>
                                        <b-form-radio value="E">Total</b-form-radio>
                                        <b-form-radio value="L">Leitura</b-form-radio>
                                        <b-form-radio value="SP">Sem permissão</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                            </b-row>

                        </b-card-body>
                    </div>
                </b-row>

                <b-row class="my-1">
                    <div class="col-md-12">
                        <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right"
                            @click="fecharEditItem">
                            Fechar
                        </button>
                        <button size="sm" class="btn btn-success btn-fill float-right" @click="confirmarEditItem">
                            Confirmar
                        </button>
                    </div>
                </b-row>
            </div>
        </b-modal>

        <div class="tree-component" v-if="treeData">
            <ul id="demo">
                <tree-item class="item" :item="treeData" @make-folder="makeFolder" @add-item="addItem"
                    @select-item="selectItem" @edit-item="editItem"></tree-item>
            </ul>
        </div>
    </div>
</template>
<script>

import TreeItem from './TreeItem.vue'

export default {
    props: ['treeData'],
    name: 'TreeComponent',
    components: {
        TreeItem
    },
    data() {
        return {
            dadosEdicaoItem: {
                nivel: 'E'
            },
            selectedItem: null
        }
    },
    mounted() {

        /*this.treeData = {
            name: "My Tree",
            select: false,
            children: [
                { name: "hello", select: true },
                { name: "wat", select: false },
                {
                    name: "child folder",
                    select: false,
                    children: [
                        {
                            name: "child folder",
                            select: false,
                            children: [{ name: "hello", select: false }, { name: "wat", select: false }]
                        },
                        { name: "hello", select: false },
                        { name: "wat", select: false },
                        {
                            name: "child folder",
                            select: false,
                            children: [{ name: "hello", select: false }, { name: "wat", select: false }]
                        }
                    ]
                }
            ]
        }*/

    },
    methods: {
        makeFolder: function (item) {
            this.$set(item, "children", [])
            this.addItem(item);
        },
        addItem: function (item) {
            item.children.push({
                name: "new stuff"
            });
        },
        selectItem: function (item) {
            this.selectChildren(item)
        },
        selectChildren: function (item) {
            if (item.children) {
                item.children.forEach(child => {
                    child.select = item.select
                    this.selectChildren(child)
                })
            }
        },
        editItem: function (item) {
            this.selectedItem = item
            this.dadosEdicaoItem.nivel = item.nivel
            this.$bvModal.show('modal-edit-item')
        },
        confirmarEditItem() {
            this.selectedItem.nivel = this.dadosEdicaoItem.nivel
            this.selectedItem.select = true
            this.$bvModal.hide('modal-edit-item')
        },
        fecharEditItem() {
            this.$bvModal.hide('modal-edit-item')
        },
        async recarregar(v) {
            console.log(v)
            this.treeData = v

        },
    },
    watch: {
        //treeData() { console.log('mudou') }
    }
}

</script>