<template>
  <b-overlay :show="show" rounded="sm">    
    <card v-if="entity">
    <h4 slot="header" class="card-title"> {{ this.idGrupoUsuario > 0 ? 'Atualizar Grupo de Usuário' : 'Novo Gruop de Usuário' }}  </h4>
    <form>

      <div class="row">        
        <div class="col-md-5">          
          <b-form-group label="descricao" label-for="input-descricao">
            <b-form-input id="input-descricao" type="text" placeholder="Descrição" maxlength = "100" v-model="entity.descricao" />
          </b-form-group>        
        </div>
      </div>            

      <div class="text-center">        
        <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="salvar">
          Salvar
        </button>
        <button type="submit" class="btn btn-danger btn-fill float-right mr-1" @click.prevent="voltar">
          Fechar
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>

</b-overlay>
</template>

<script>

import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'

export default {    
    props: ['idGrupoUsuario'],
    components: {
      Card    
    },
    data() {
        return {
            http: null,
            show: false,
            showFrom: "",
            filaShow: [],            
            entity: null,    
            gruposUsuario: null,
        }
    },
  async mounted() {    

      this.http = axios.create({
        baseURL: BASE_CONSTANTS.urlBase,
        headers: {
            'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
            'Content-Type': 'application/json',
            'Accept': '*/*'
        }
      })          

      if (this.idGrupoUsuario > 0) {        
        this.carregaDados();
      } else {
        this.entity = {
          "descricao": "",          
        }
      }

    },
    methods: {
      carregaDados(){

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("carregarDados");
     
        this.show = true

      AgendaService.editGrupoUsuario(this.http, this.idGrupoUsuario)
          .then((resposta) => {           
           this.entity = resposta.data.obj          
          this.show = false          
          desativarShow("carregarDados");
        })
        .catch((err) => {          
          this.show = false
          desativarShow("carregarDados");
        });
      },  
      voltar() {
        this.$emit('fecharedit')
      },
      salvar() {
        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("salvarUsuario");              
        AgendaService.saveUsuario(this.http, this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {            
              let ret = {
                "status": "success",
                "entity": retorno.obj
              }              
              this.$emit('executou', ret)
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
                var v = this
                setTimeout(function () {
                  v.voltar()
                }, 2000);            
            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }  
            desativarShow("salvarUsuario");
          }).catch((error) => {          
            desativarShow("salvarUsuario");
            toast(error, 'Falha', 'danger')
          });
      },                
  }
    
}
</script>



<style>

</style>