<template>
  <div>

    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') continuarAcao() }">
      <template v-slot:texto>Confirma aprovação?</template>
    </confirma>

    <card>
      <h4 slot="header" class="card-title"> Aprovação </h4>

      <form>

        <b-container class="c-form px-2" v-if="aprovacao">

          <div v-if="aprovacao.agenda">
            <b-row v-if="isVoltar">
              <b-col>
                <b-button class="btn btn-primary btn-fill float-right" @click.prevent="voltar">
                  Voltar
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="7">
                <p class="text-secondary"><span class="font-weight-bold">Local do plantão: </span> {{
                  aprovacao.agenda.dscLocal }} </p>
              </b-col>
              <b-col cols="5">
                <p class="text-secondary"><span class="font-weight-bold">Tipo de serviço: </span> {{
                  aprovacao.agenda.dscTipo }}</p>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="2">
                <p class="text-secondary"><span class="font-weight-bold">Data: </span> {{
                  strDateFormat(aprovacao.agenda.dtAgenda, 'dd/MM/yyyy') }}</p>
              </b-col>
              <b-col cols="2">
                <p class="text-secondary"><span class="font-weight-bold">Horário: </span> {{ aprovacao.agenda.horarioDe }}
                </p>
              </b-col>
              <b-col cols="2">
                <p class="text-secondary"><span class="font-weight-bold">Duração: </span> {{
                  aprovacao.agenda.duracaoHoraMinuto }}</p>
              </b-col>
              <b-col cols="3">
                <p class="text-secondary"><span class="font-weight-bold">Tipo apuração: </span> {{
                  aprovacao.agenda.tipoApuracao }}</p>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="5">
                <p class="text-secondary"><span class="font-weight-bold">Profissional alocado: </span> {{
                  aprovacao.agenda.nomeProfissionalEfetivo }}</p>
              </b-col>
              <b-col cols="2" v-if="aprovacao.agenda.dscMotivoTroca">
                <p class="text-secondary"><span class="font-weight-bold">Motivo troca: </span> {{
                  aprovacao.agenda.dscMotivoTroca }}</p>
              </b-col>
              <b-col cols="5">
                <p class="text-secondary"><span class="font-weight-bold">Profissional do plantão: </span> {{
                  aprovacao.agenda.nomeProfissional }}
                </p>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="3">
                <p class="text-secondary"><span class="font-weight-bold">Adiantado: </span> {{ aprovacao.agenda.avista ==
                  'S' ? 'Sim' : 'Não' }}</p>
              </b-col>
              <b-col cols="3">
                <p class="text-secondary"><span class="font-weight-bold">Vl. unit: </span> {{
                  aprovacao.agenda.valorCompra.toLocaleString('pt-BR', {
                    style: 'currency', currency: 'BRL',
                    maximumFractionDigits: 2
                  }) }}</p>
              </b-col>
              <b-col cols="3">
                <p class="text-secondary"><span class="font-weight-bold">Complemento.: </span> {{
                  aprovacao.agenda.valorCompl.toLocaleString('pt-BR', {
                    style: 'currency', currency: 'BRL',
                    maximumFractionDigits: 2
                  }) }}</p>
              </b-col>
              <b-col cols="3">
                <p class="text-secondary"><span class="font-weight-bold">Vl. total.: </span> {{
                  (aprovacao.agenda.valorTotalCompra + aprovacao.agenda.valorCompl).toLocaleString('pt-BR', {
                    style:
                      'currency', currency: 'BRL', maximumFractionDigits: 2
                  }) }}</p>
              </b-col>
            </b-row>
          </div>

          <b-row class="my-3" v-if="aprovacao.agenda.obs">
            <b-col cols="9">
              <p class="font-weight-bold">Observação do solicitante: </p>
              <p class="text-secondary">{{ aprovacao.agenda.obs }}</p>
            </b-col>
            <b-col cols="3">
              <p class="text-secondary"><span class="font-weight-bold">Situação: </span> {{ aprovacao.dscSituacao }}</p>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col style="min-height: 150px;">
              <base-input label="Motivo" v-show="reprovando">
                <b-form-textarea id="textarea" v-model="obsReprovacao" placeholder="Informe o motivo da reprovação..."
                  rows="3" max-rows="10"></b-form-textarea>
              </base-input>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col class="text-center">

              <b-button v-if="isPodeAprovar && !reprovando" class="btn btn-success btn-fill"
                @click.prevent="confirmaAprovacao('aprovar')">
                Aprovar
              </b-button>

              <b-button v-if="isPodeCancelarAprovar" class="btn btn-success btn-fill ml-1"
                @click.prevent="confirmaAprovacao('cancelarAprovar')">
                Cancelar Aprovação
              </b-button>

              <b-button v-if="isPodeReprovar" class="btn btn-danger btn-fill ml-1" @click.prevent="reprovar">
                {{ !reprovando ? 'Reprovar' : 'Confirmar reprovação' }}
              </b-button>

              <b-button v-if="isPodeCancelarReprovar" class="btn btn-danger btn-fill ml-1"
                @click.prevent="confirmaAprovacao('cancelarReprovar')">
                Cancelar Reprovação
              </b-button>

              <b-button v-if="isPodeAprovarFinanceiro && !reprovando" class="btn btn-success btn-fill ml-1"
                @click.prevent="confirmaAprovacao('aprovarFinanceiro')">
                Aprovar financeiro
              </b-button>

              <b-button v-if="isPodeCancelarAprovarFinanceiro" class="btn btn-success btn-fill ml-1"
                @click.prevent="confirmaAprovacao('cancelarAprovarFinanceiro')">
                Cancelar Aprovação do finanaceiro
              </b-button>

              <b-button v-if="isPodeReprovarFinanceiro" class="btn btn-danger btn-fill ml-1"
                @click.prevent="reprovarFinanceiro">
                {{ !reprovando ? 'Reprovar financeiro' : 'Confirmar reprovação financeiro' }}
              </b-button>

              <b-button v-if="isPodeCancelarReprovarFinanceiro" class="btn btn-danger btn-fill ml-1"
                @click.prevent="confirmaAprovacao('cancelarReprovarFinanceiro')">
                Cancelar Reprovação financeiro
              </b-button>

              <b-button v-if="reprovando" class="btn btn-fill ml-1" @click.prevent="cancelarAcaoReprovar">
                Cancelar
              </b-button>

            </b-col>
          </b-row>

        </b-container>

      </form>

    </card>
  </div>
</template>

<script>

import AgendaService from '../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import appUtils from '@/composables/utils'
import Confirma from '../comuns/Confirma'

export default {
  props: ['idAprovacao', 'isVoltar'],
  components: {
    Card,
    Confirma,
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      aprovacao: null,
      reprovando: false,
      obsReprovacao: null,
      acao: null,
      perfil: null,
      permisaoCancelarFinanceiro: false
    }
  },
  watch: {
    /*idAprovacao(newVal) {
      this.buscarPerfilAprovador()
      this.carregaDados()
    }*/
  },
  async mounted() {

    this.permisaoCancelarFinanceiro = this.hasPermissionWith('LISTA_APROVACAO', 'CANCELAR_APROVACAO_FINANCEIRO', 'E')

    if (this.idAprovacao) {
      this.carregaDados()
    }

  },
  methods: {
    async buscarPerfilAprovador() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarPerfilAprovador");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": dadosUsuario.id,
        "idEmpresa": dadosUsuario.grupo.empresa.id
      }

      await AgendaService.buscarPerfilAprovador(dadosConsulta)
        .then((resposta) => {
          this.perfil = resposta.data.obj
          desativarShow("buscarPerfilAprovador");
        })
        .catch((err) => {
          desativarShow("buscarPerfilAprovador");
        });
    },
    async carregaDados() {

      if (!this.perfil) {
        await this.buscarPerfilAprovador()
      }

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("abrirAprovacao");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        id: this.idAprovacao
      }

      AgendaService.editAprovacao(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.aprovacao = retorno.obj;
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("abrirAprovacao");
        }).catch((error) => {
          desativarShow("abrirAprovacao");
          toast(error, 'Falha', 'danger')
        });


    },
    voltar() {
      this.$emit('fecharedit')
    },
    continuarAcao() {
      if (this.acao === 'aprovar') {
        this.aprovar()
      } else if (this.acao === 'cancelarAprovar') {
        this.cancelarAprovar()
      } else if (this.acao === 'cancelarReprovar') {
        this.cancelarReprovar()
      } else if (this.acao === 'aprovarFinanceiro') {
        this.aprovarFinanceiro()
      } else if (this.acao === 'cancelarAprovarFinanceiro') {
        this.cancelarAprovarFinanceiro()
      } else if (this.acao === 'cancelarReprovarFinanceiro') {
        this.cancelarReprovarFinanceiro()
      }
    },
    aprovar() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("aprovarAprovacao");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.aprovacao.id,
        "idTabela": dadosUsuario.grupo.idTabela,
      }

      AgendaService.aprovarAprovacao(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("aprovarAprovacao");
        }).catch((error) => {
          desativarShow("aprovarAprovacao");
          toast(error, 'Falha', 'danger')
        });
    },
    reprovar() {

      if (!this.reprovando) {
        this.reprovando = true
        return
      }

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("reprovarAprovacao");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.aprovacao.id,
        "obs": this.obsReprovacao
      }

      AgendaService.reprovarAprovacao(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.reprovando = false;
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("reprovarAprovacao");
        }).catch((error) => {
          desativarShow("reprovarAprovacao");
          toast(error, 'Falha', 'danger')
        });
    },
    aprovarFinanceiro() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("aprovarAprovacao");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idPessoaOrigem": dadosUsuario.id,
        "id": this.aprovacao.id,
        "idTabela": dadosUsuario.grupo.idTabela,
      }

      AgendaService.aprovarAprovacaoFinanceiro(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("aprovarAprovacao");
        }).catch((error) => {
          desativarShow("aprovarAprovacao");
          toast(error, 'Falha', 'danger')
        });
    },
    reprovarFinanceiro() {

      if (!this.reprovando) {
        this.reprovando = true
        return
      }

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("reprovarFinanceiro");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.aprovacao.id,
        "idPessoaOrigem": dadosUsuario.id,
        "obs": this.obsReprovacao
      }

      AgendaService.reprovarAprovacaoFinanceiro(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.reprovando = false;
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("reprovarFinanceiro");
        }).catch((error) => {
          desativarShow("reprovarFinanceiro");
          toast(error, 'Falha', 'danger')
        });
    },
    cancelarAprovar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("cancelarAprovarFinanceiro");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.aprovacao.id,
        "idPessoaOrigem": dadosUsuario.id,
        "obs": this.obsReprovacao
      }

      AgendaService.cancelarAprovar(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("cancelarAprovarFinanceiro");
        }).catch((error) => {
          desativarShow("cancelarAprovarFinanceiro");
          toast(error, 'Falha', 'danger')
        });
    },
    cancelarAprovarFinanceiro() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("cancelarAprovarFinanceiro");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.aprovacao.id,
        "idPessoaOrigem": dadosUsuario.id,
        "obs": this.obsReprovacao
      }

      AgendaService.cancelarAprovarFinanceiro(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("cancelarAprovarFinanceiro");
        }).catch((error) => {
          desativarShow("cancelarAprovarFinanceiro");
          toast(error, 'Falha', 'danger')
        });
    },
    cancelarReprovar() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("cancelarReprovarFinanceiro");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.aprovacao.id,
        "idPessoaOrigem": dadosUsuario.id,
        "obs": this.obsReprovacao
      }

      AgendaService.cancelarReprovar(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("cancelarReprovarFinanceiro");
        }).catch((error) => {
          desativarShow("cancelarReprovarFinanceiro");
          toast(error, 'Falha', 'danger')
        });
    },
    cancelarReprovarFinanceiro() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("cancelarReprovarFinanceiro");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.aprovacao.id,
        "idPessoaOrigem": dadosUsuario.id,
        "obs": this.obsReprovacao
      }

      AgendaService.cancelarReprovarFinanceiro(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("cancelarReprovarFinanceiro");
        }).catch((error) => {
          desativarShow("cancelarReprovarFinanceiro");
          toast(error, 'Falha', 'danger')
        });
    },
    cancelarAcaoReprovar() {
      this.reprovando = false
    },
    confirmaAprovacao(acao) {
      this.acao = acao
      this.$refs.conf.show()
    },

    strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
    hasPermissionWith(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
  },
  computed: {
    obsProfissional: function () {
      let candidato = this.agenda && this.agenda.idProfissional ? this.agenda.candidatos.find((f) => f.id === this.agenda.idProfissional) : null
      return candidato !== null ? candidato.obsProfissional : ''
    },
    isPodeAprovar() {
      return this.perfil.diretor === 'S' && !this.aprovacao.dtAprovacao && !this.aprovacao.dtReprovacao;
    },
    isPodeCancelarAprovar() {
      return this.perfil.diretor === 'S' && this.aprovacao.dtAprovacao;
    },
    isPodeReprovar() {
      return this.perfil.diretor === 'S' && !this.aprovacao.dtAprovacao && !this.aprovacao.dtReprovacao;
    },
    isPodeCancelarReprovar() {
      return this.perfil.diretor === 'S' && this.aprovacao.dtReprovacao;
    },
    isPodeAprovarFinanceiro() {
      return this.perfil.financeiro === 'S' && this.aprovacao.dtAprovacao && !this.aprovacao.dtAprovacaoFinanceiro && !this.aprovacao.dtReprovacaoFinanceiro;
    },
    isPodeCancelarAprovarFinanceiro() {
      return this.permisaoCancelarFinanceiro && this.perfil.financeiro === 'S' && this.aprovacao.dtAprovacaoFinanceiro;
    },
    isPodeReprovarFinanceiro() {
      return this.perfil.financeiro === 'S' && this.aprovacao.dtAprovacao && !this.aprovacao.dtAprovacaoFinanceiro && !this.aprovacao.dtReprovacaoFinanceiro;
    },
    isPodeCancelarReprovarFinanceiro() {
      return this.perfil.financeiro === 'S' && this.aprovacao.dtReprovacaoFinanceiro;
    },
  }

}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>

<style></style>