<template>
  <div>        
    <div :id="chartId" class="ct-chart" ></div>
  </div>  
</template>
<script>
  import Card from './Card.vue'
    
  export default {
    name: 'chart-card',
    components: {
      Card,  
    },
    props: {
      chartType: {
        type: String,
        default: 'Line' // Line | Pie | Bar
      },
      chartOptions: {
        type: Object,
        default: () => {
          return {
            
          }
        }
      },
      chartData: {
        type: Object,
        default: () => {
          return {
            labels: [],
            series: []
          }
        },        
      },
      responsiveOptions: [Object, Array],
      isCard: true,      
    },
    data () {
      return {
        chartId: 'no-id',
        $Chartist: null,
        chart: null,        
      }
    },
    /*watch: {
      chartData(newData){      
        deep: true,
        this.chart.update(newData)      
      }
    },*/
    methods: {
      /***
       * Initializes the chart by merging the chart options sent via props and the default chart options
       */
      initChart () {
        var chartIdQuery = `#${this.chartId}`
        this.chart = this.$Chartist[this.chartType](chartIdQuery, this.chartData, this.chartOptions, this.responsiveOptions)
        this.$emit('initialized', this.chart)
        if (this.chartType === 'Line') {
          this.animateLineChart()
        }
        if (this.chartType === 'Bar') {
          this.animateBarChart()
        }
        /*if (this.chartType === 'Pie') {
          this.animatePieChart()
        }*/
      },
      /***
       * Assigns a random id to the chart
       */
      updateChartId () {
        const currentTime = new Date().getTime().toString()
        const randomInt = this.getRandomInt(0, currentTime)
        this.chartId = `div_${randomInt}`
      },
      getRandomInt (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
      },
      animateLineChart () {
        let seq = 0
        let durations = 500
        let delays = 80
        this.chart.on('draw', (data) => {
          if (data.type === 'line' || data.type === 'area') {
            data.element.animate({
              d: {
                begin: 600,
                dur: 700,
                from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                to: data.path.clone().stringify(),
                easing: this.$Chartist.Svg.Easing.easeOutQuint
              }
            })
          } else if (data.type === 'point') {
            seq++
            data.element.animate({
              opacity: {
                begin: seq * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            })
          }
        })
        seq = 0
      },
      animateBarChart () {
        let seq = 0
        let durations = 500
        let delays = 80
        this.chart.on('draw', (data) => {
          if (data.type === 'bar') {
            seq++
            data.element.animate({
              opacity: {
                begin: seq * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            })
          }
        })
      },
      /*animatePieChart () {        
        var count = 45;
        var max = 100;
        this.chart.on('draw', (context) => {
          if(context.type === 'slice') {
            context.element.attr({
              // Now we set the style attribute on our bar to override the default color of the bar. By using a HSL colour we can easily set the hue of the colour dynamically while keeping the same saturation and lightness. From the context we can also get the current value of the bar. We use that value to calculate a hue between 0 and 100 degree. This will make our bars appear green when close to the maximum and red when close to zero.
              style: 'stroke: hsl(' + Math.floor(context.value / max * 100) + ', 50%, 50%);'            
            });
            console.log(context);
            this.$emit('onFinaliza', 'emit:' + context.element.attr['style']);
          }          
        })
      }*/
    },
    async mounted () {      
      this.updateChartId()
      const Chartist = await import('chartist')
      this.$Chartist = Chartist.default || Chartist
      this.initChart()      
    }
  }
</script>
<style>

</style>
