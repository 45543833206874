<template>
  <div>
    <card v-if="entity">
      <h4 slot="header" class="card-title"> {{ this.idEscala > 0 ? 'Detalhes da Escala' : 'Nova Escala' }} </h4>
      <form>

        <b-row>

          <div class="col-md-12">
            <b-form-group label="Local" label-for="input-local">
              <b-form-input :disabled="true" id="input-local" type="text" label="Local" placeholder="Local" maxlength="50"
                v-model="entity.dscLocal" />
            </b-form-group>
          </div>

          <div class="col-md-12">
            <b-form-group label="Tipo" label-for="input-tipo">
              <b-form-input :disabled="true" id="input-tipo" type="text" label="Tipo" placeholder="Tipo" maxlength="200"
                v-model="entity.dscTipo" />
            </b-form-group>
          </div>

        </b-row>

        <b-row>
          <div class="col-md-1">
            <b-form-group label="Horário início" label-for="input-hora-inicio">
              <b-form-input :disabled="true" v-mask="'##:##'" label="Horário Início" placeholder="hh:mm" id="input-hora-inicio"
                v-model="entity.horarioInicio" size="sm">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-1">
            <b-form-group label="Duração" label-for="input-duracao">
              <b-form-input :disabled="true" v-mask="'##:##'" label="Duração" placeholder="hh:mm" id="input-duracao"
                v-model="entity.duracaoStr" size="sm">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-1">
            <b-form-group label="Limite dia" label-for="input-limite-dia">
              <b-form-input :disabled="true" v-mask="'##:##'" label="Limite dia" placeholder="hh:mm" id="input-limite-dia"
                v-model="entity.limiteDiaStr" size="sm">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-1">
            <b-form-group label="Limite mês" label-for="input-limite-mes">
              <b-form-input :disabled="true" v-mask="mascaraLimiteMes" label="Limite mês" placeholder="hh:mm" id="input-limite-mes"
                v-model="entity.limiteMesStr" size="sm">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-1">
            <b-form-group label="Unidade" label-for="input-unidade">
              <b-form-input :disabled="true" v-mask="'##:##'" label="Unidade" placeholder="hh:mm" id="input-unidade"
                v-model="entity.unidadeStr" size="sm">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Valor" label-for="input-valor">
              <b-form-input :disabled="true" v-model="entity.valorCompra" id="input-valor" v-money="money" placeholder="Valor" size="sm">
              </b-form-input>              
            </b-form-group>
          </div>
        </b-row>

        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill float-right" v-if="false" @click.prevent="salvar">
            Salvar
          </button>
          <button type="submit" class="btn btn-danger btn-fill float-right mr-1" @click.prevent="voltar">
            Fechar
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </card>

  </div>
</template>

<script>

import AgendaService from '../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../service/config'
import appUtils from '@/composables/utils'

export default {
  props: ['idEscala'],
  components: {
    Card,
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      entity: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
    }
  },
  mounted() {
    if (this.idEscala > 0) {
      this.carregaDados();
    } else {
      this.entity = {
        "dscLocal": "",
        "dscTipo": "",
      }
    }
  },
  methods: {
    carregaDados() {

      const { ativarShow, desativarShow, formatarValorNumerico } = appUtils(this)

      ativarShow("carregarDados");

      AgendaService.editEscala(this.idEscala)
        .then((resposta) => {

          let obj = resposta.data.obj
          obj.valorCompra = formatarValorNumerico(obj.valorCompra)
          this.entity = obj
          
          desativarShow("carregarDados");
        })
        .catch((err) => {
          desativarShow("carregarDados");
        });
    },
    voltar() {
      this.$emit('fecharedit')
    },
    salvar() {
      /*const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("salvarLocal");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      this.entity.idUsuario = dadosUsuario.idPessoa,

        AgendaService.saveLocal(this.http, this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
              let ret = {
                "status": "success",
                "entity": retorno.obj
              }
              this.$emit('executou', ret)
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
              var v = this
              setTimeout(function () {
                v.voltar()
              }, 2000);
            } else {
              if (retorno.mensagens.length > 0) {
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }
            desativarShow("salvarLocal");
          }).catch((error) => {
            desativarShow("salvarLocal");
            toast(error, 'Falha', 'danger')
          });*/
    },        
  },

  computed: {
    mascaraLimiteMes() {
        return this.entity.limiteMesStr.length <= 5 ? '##:##' : '###:##'
    },
  }

}
</script>

<style>

</style>