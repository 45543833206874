<template>    
  <b-card>

    <template slot="header">
      <h4 class="card-title">{{form ? form.titulo : 'Aguarde...'}}</h4>
    </template>

    <b-container class="c-form" v-if="form !== null">
      <form autocomplete="off">             
        <campos-formulario
        :campos="form.campos" @alterouCnpj="(r) => { this.alterouCnpj(r) }"/>
        <div class="text-center pt-3">
          <loading :show="showEnviando">
          <b-button type="submit" class="btn btn-primary btn-fill" size="lg" @click.prevent="enviarDados">            
            Enviar dados
          </b-button>        
        </loading>
        </div>        
      </form>      
    </b-container>
  </b-card>  
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  import getUF from '@/composables/getUF'
  import { VMoney } from 'v-money'
import CamposFormulario from './CamposFormulario.vue'
import Loading from '../../layout/Loading'
  
export default {
    props: ['form','showEnviando'],
    components: {
      Card,
      CamposFormulario,
      Loading,
    },
    data () {
      return {
        //http: null,
        show: false,
        showFrom: "",
        filaShow: [],                
        URL_PAGE: this.$route.query.page,                
        optionsUF:getUF(),        
        mascara: '(##)#####-####',
        empresa: '',
        idEmpresa: 0,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
      }
    },
    directives: {money: VMoney},
    async mounted() {


    },
  methods: {          
      enviarDados() {        
        let ret = {
                "status": "success",
                "entity": this.form
        }     
        this.$emit('executou', ret)
      },
      alterouCnpj(r) {                   
        this.$emit('alterouCnpj', r)
      },
  },
  computed: {

      
  },
  watch: {
    showEnviando(newValue) {
      this.showEnviando = newValue
    },
  }    
 
  }

  const validar = cpf => checkAll(prepare(cpf))

  const notDig = i => !['.', '-', ' '].includes(i)
  const prepare = cpf => cpf.trim().split('').filter(notDig).map(Number)
  const is11Len = cpf => cpf.length === 11
  const notAllEquals = cpf => !cpf.every(i => cpf[0] === i)
  const onlyNum = cpf => cpf.every(i => !isNaN(i))

  const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx)
  const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0)
  const resto11 = somaDig => 11 - (somaDig % 11)
  const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11

  const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)))
  const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos]

  const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)]
  const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r)

</script>
<style>

.c-form{
  padding-bottom: 60px;
}

.texto-ajuda{
  font-size: 0.7rem;
  color: #9a9a9a;
}

.custom-file label{
  margin-bottom: 0;  
}

</style>