<template>
  <div>
      
      <b-modal header-bg-variant="primary" header-text-variant="light" footer-bg-variant="light"
        ref="modal-edit-situacao-fechamento-local" id="modal-edit-situacao-fechamento-local" size="lg" title="Configuração da situação de fechamento no local"
        no-close-on-backdrop hide-footer>
  
        <loading :show="show">
        <div class="container-fluid" v-if="entity">
          <b-row class="my-1">
            <div class="col-md-12">
  
              <b-card-body class="p-0" style="min-height: 450px;">
  
                <div class="container-fluid">
  
                  <b-row>
                    <div class="col-md-7">
                      <b-form-group label="Local" label-for="input-local">
                        <b-form-input id="input-local" type="text" label="Local" placeholder="Local" readonly
                          v-model="entity.dscLocal" />
                      </b-form-group>
                    </div>
                    <div class="col-md-5">
                      <b-form-group class="mb-0" label="Situação" label-for="input-situacao">
                        <b-form-select v-if="!entity.id" id="input-situacao" v-model="entity.idSituacao" value-field="id"
                          text-field="dscSituacao" :options="itensSituacao" size="sm" @change="alterarSituacao(entity.idSituacao)">
                          <template #first>
                            <b-form-select-option :value="null">-- Selecione a situação --</b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-input v-else id="input-situacao" type="text" label="Situação" placeholder="Situação"
                          readonly v-model="entity.dscSituacao" />
                      </b-form-group>
                    </div>
                  </b-row>
  
                  <b-row>
                    <div class="col-md-12">
                      <h4 class="card-title text-primary m-0 pb-2">Configuração</h4>
                    </div>
                  </b-row>
  
                  <b-row>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.padrao" value="S" unchecked-value="N" switch>Padrão</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.faturar" value="S" unchecked-value="N"
                        switch>Faturar</b-form-checkbox>
                    </div>
                    <div class="col-md-3">
                      <b-form-checkbox v-model="entity.atualizarPreco" value="S" unchecked-value="N"
                        switch>Atualizar preço</b-form-checkbox>
                    </div>                    
                  </b-row>
  
                </div>
              </b-card-body>
            </div>
          </b-row>
        </div>
  
        <b-row class="mt-2 mb-0 mx-0 p-0">
          <div class="col-md-12 m-0 px-0 pt-2 pb-0 footer-js">
              <b-button size="md" variant="danger" class="btn-fill float-right" @click="fechar">Fechar</b-button>
              <b-button size="md" variant="primary" class="btn-fill float-right mr-1" @click="salvar()">Confirmar</b-button>
          </div>
        </b-row>
      </loading>
      </b-modal>
    </div>
  </template>
  
  <script>
  
  import AgendaService from "../../../service/AgendaService";
  import appUtils from '@/composables/utils'
  import Loading from '../../../layout/Loading'
  
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        show: false,
        showFrom: "",
        filaShow: [],
        idsEliminar: [],
        entity: null,
        itensSituacao: []
      }
    },
    mounted() {
  
    },
    methods: {
      novo(idLocal) {  
        const { toast } = appUtils(this)
        this.show = true
        AgendaService.dadosNovaSituacaoFechamentoLocal(idLocal).then(resposta => {
          this.entity = resposta.data.obj.entity
          this.entity.id = null
          this.entity.idSituacao = null          
          this.itensSituacao = resposta.data.obj.situacoes
          this.show = false
        }).catch(error => {
          toast(error, 'Falha', 'danger')
          this.show = false
        })
  
        this.$bvModal.show('modal-edit-situacao-fechamento-local')
      },
      edit(id) {
        const { toast } = appUtils(this)        
        AgendaService.editSituacaoFechamentoLocal(id).then(resposta => {
          this.entity = resposta.data.obj
        }).catch(error => {
          toast(error, 'Falha', 'danger')        
        })
      },      
      salvar() {
  
        const { toast } = appUtils(this)
  
        this.show = true
  
        AgendaService.saveSituacaoFechamentoLocal(this.entity)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
  
              let ret = {
                "status": "success",
                "entity": retorno.obj
              }
              this.$emit('executou', ret)
  
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
              var v = this
              setTimeout(function () {
                v.fechar()
              }, 2000);
  
            } else {
              if (retorno.mensagens.length > 0) {
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }
  
            this.show = false
          }).catch((error) => {
            this.show = false
            toast(error, 'Falha', 'danger')
          });
  
      },
      fechar() {
        this.hide()
      },
      async abrir(v) {
        this.edit(v)
        this.$bvModal.show('modal-edit-situacao-fechamento-local')
      },
      hide() {
        this.$bvModal.hide('modal-edit-situacao-fechamento-local')
      },
      alterarSituacao(idSituacao) {
        /*let obj = this.itensSituacao.find((o) => o.id === idSituacao)
        this.entity.padrao = obj.padrao*/        
      },
    }
  }
  </script>
  
  <style></style>