<template>
  <div>
    <b-modal ref="modal-locais" id="modal-locais" title="Locais" size="xl">

      <div style="height: 500px;">

        <b-tabs content-class="mt-2">

          <b-tab title="Locais">

            <b-table v-if="locais" :items="locais.lista" :fields="fieldsLocal" :fixed="true" sticky-header="400px"
              :tbody-tr-class="rowClass">

              <template v-slot:cell(padrao)="row">
                <b-form-checkbox v-model="row.item.coordenador" value="S" unchecked-value="N" switch></b-form-checkbox>
              </template>
              <template v-slot:cell(coordenador)="row">
                <b-form-checkbox v-model="row.item.coordenador" value="S" unchecked-value="N" switch></b-form-checkbox>
              </template>

              <template #cell(actions)="row">
                <b-button size="sm" @click="row.item.deleted = row.item.deleted == 'S' ? 'N' : 'S'" class="mr-1"
                  variant="danger" title="Excluir">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>
              </template>

              <template #cell()="data">
                {{ data.value }}
              </template>

            </b-table>
            <b-row>
              <div class="col-md-12">
                <div class="w-100">
                  <b-button variant="info" size="sm" class="btn-fill" @click="abrirSelecionaLocal">
                    Adicionar
                  </b-button>
                </div>
              </div>
            </b-row>

          </b-tab>

        </b-tabs>

      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right btn-fill" @click="salvarLocais">
            Salvar
          </b-button>
        </div>
      </template>

    </b-modal>

    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir(0) }">
      <template v-slot:texto>Confirma exclusão do usuário?</template>
    </confirma>

    <select-local @showOverlay="(status) => this.$emit('showOverlay', status)" ref="selectLocal"
      @executou="(r) => { if (r.status === 'success') adicionarLocal(r.ids) }" />

    <select-classificacao @showOverlay="(status) => this.$emit('showOverlay', status)" ref="selectClassificacao"
      @executou="(r) => { if (r.status === 'success') retornoSelecionaClassificacao(r.ids) }" />

    <div v-if="!edit">

      <card>

        <template slot="header">
          <h4 class="card-title">Usuários</h4>
        </template>

        <div class="row">

          <b-col class="col-md-6">
            <b-form-group label="Filtrar: " label-for="filter-input" label-cols-sm="1" label-align-sm="left"
              label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Digite aqui sua pesquisa"
                  v-on:keydown.enter="buscar"></b-form-input>
              </b-input-group>
            </b-form-group>

          </b-col>

          <b-col class="col-md-2">
            <b-form-checkbox v-model="interno" value="S" unchecked-value="N" @change="internoChange" class="custom-checkbox ml-2" switch>
            Interno
            </b-form-checkbox>
          </b-col>

          <b-col class="col-md-4 px-3">
            <b-button class="mr-1" size="sm" variant="primary" @click="novo">
              <b-icon icon="file-earmark"></b-icon>Criar usuário
            </b-button>
            <b-dropdown text="Ação" variant="primary" class="ml-2" size="sm">
              <b-dropdown-item @click="abrirAdicionarClassificacao">Classificar</b-dropdown-item>
              <b-dropdown-item @click="abrirRemoverClassificacao" href="#">Remover classificação</b-dropdown-item>
            </b-dropdown>

          </b-col>

        </div>

        <div class="row">
          <div class="col-md-12 pt-2">

            <b-table empty-text="Nenhum registro" :items="items" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" striped show-empty small hover
              @row-clicked="editarUsuario">

              <template #head(seleciona)>
                <b-form-checkbox v-model="todosSelecionados" value="S" unchecked-value="N"
                  @input="selecionarTodos"></b-form-checkbox>
              </template>

              <template v-slot:cell(seleciona)="row">
                <b-form-checkbox v-model="row.item.seleciona" value="S" unchecked-value="N"></b-form-checkbox>
              </template>

              <template v-slot:cell(grupo)="row">
                {{ row.value.descricao }}
              </template>

              <template v-slot:cell(classificacao)="row">
                <b-badge v-if="row.item.tags" class="ml-1" v-for="t in row.item.tags" v-bind:key="t.id"
                  v-bind:style="'background-color: ' + t.cor + ';'">{{ t.dscClassificacao }}</b-badge>
              </template>

              <template #cell(actions)="row">

                <b-button size="sm" @click="editar(row.item.id)" class="mr-1" variant="success" title="Editar">
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
                <b-button v-if="isUsuarioEmpresa(row.item)" size="sm" @click="confirmaExcluir(row.item.id)" class="mr-1"
                  variant="danger" title="Excluir">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>
                <b-button v-if="isUsuarioEmpresa(row.item)" size="sm" variant="primary" class="mr-1" @click="abrirLocais(row.item)"
                  title="Locais">
                  <b-icon icon="list-ul" aria-hidden="true"></b-icon>
                </b-button>

              </template>

            </b-table>
          </div>

        </div>

        <div class="row">
          <div class="col-md-2 pl-2">
            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
              size="sm"></b-pagination>
          </div>
        </div>

      </card>
    </div>

    <div v-else>
      <edit-usuario @showOverlay="(status) => this.$emit('showOverlay', status)" :idUsuario="idUsuario"
        @fecharedit="() => this.edit = false"
        @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.entity) }" />
    </div>
    <div class="clearfix"></div>

  </div>
</template>

<script>
import AgendaService from '../../../service/AgendaService'
import Card from 'src/components/Cards/Card.vue'
import axios from 'axios'
import { BASE_CONSTANTS } from '../../../service/config'
import appUtils from '@/composables/utils'
import Confirma from '../../comuns/Confirma'
import EditUsuario from './EditUsuario.vue'
import SelectLocal from '../../comuns/SelectLocal.vue'
import SelectClassificacao from '../../comuns/SelectClassificacao.vue'

export default {
  components: {
    Card,
    EditUsuario,
    Confirma,
    SelectLocal,
    SelectClassificacao,
  },
  data() {
    return {
      id: null,
      show: false,
      http: null,
      showFrom: "",
      filaShow: [],
      configuracao: null,
      items: [],
      edit: false,
      idUsuario: null,
      todosSelecionados: 'N',
      addRemoveClassificacao: null,
      usuarioLogado: null,
      interno: 'S',
      fields: [
        { key: 'seleciona', label: '', thStyle: { width: "3%" } },
        { key: 'nome', label: 'Nome', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },
        { key: 'login', label: 'Login', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },
        { key: 'grupo.descricao', label: 'Grupo', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },
        { key: 'classificacao', label: 'Classificações', sortable: true, sortDirection: 'asc', thStyle: { width: "20%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "17%" } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      locais: null,
      fieldsLocal: [
        { key: 'dscLocal', label: 'Local', sortable: true, sortDirection: 'asc', thStyle: { width: "80%" } },
        { key: 'coordenador', label: 'Coordenador', type: "check", thStyle: { width: "10%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "10%" } }
      ],
    }
  },
  computed: {

  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    this.usuarioLogado = JSON.parse(localStorage.getItem("dadosUsuario"));

    this.carregaDados()

  },
  methods: {
    novo() {
      this.edit = true
      this.idUsuario = 0
    },
    editarUsuario(record, index) {
      this.editar(record.id)
    },
    editar(id) {
      this.edit = true
      this.idUsuario = id
    },
    internoChange(checked) {
      this.carregaDados()
    },
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarUsuarios");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "identificadorEmpresa": dadosUsuario.grupo.empresa.identificador,
        "filtro": this.filter,
        "comClassificacao": "S",
        "interno": this.interno,
      }

      AgendaService.listaUsuariosFilter(this.http, dadosConsulta)
        .then((resposta) => {
          this.items = resposta.data.obj
          this.totalRows = this.items.length
          desativarShow("listarUsuarios");
        })
        .catch((err) => {
          desativarShow("listarUsuarios");
        });
    },
    buscar() {
      this.carregaDados();
    },
    abrirLocais(record) {
      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("abrirLocais");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        idUsuario: dadosUsuario.idPessoa,
        idPessoa: record.idPessoa,
        idEmpresa: dadosUsuario.grupo.empresa.id
      }

      AgendaService.buscarPessoaLocal(dadosConsulta).then((resposta) => {
        this.locais = resposta.data.obj
        this.$bvModal.show("modal-locais")
        desativarShow("abrirLocais");
      }).catch((err) => {
        desativarShow("abrirLocais");
      });

    },
    salvarLocais() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("salvarLocais");
      AgendaService.atualizarPessoaLocal(this.http, this.locais)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            this.$bvModal.hide('modal-locais')
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("salvarLocais");
        }).catch((error) => {
          desativarShow("salvarLocais");
          toast(error, 'Falha', 'danger')
        });
    },

    confirmaExcluir(id) {
      this.id = id
      this.$refs.conf.show()
    },
    excluir() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("deleteUsuario");

      AgendaService.deleteUsuario(this.http, this.id)
        .then(resposta => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.items = this.items.filter((i) => { return i.id !== this.id })
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("deleteUsuario");
        }).catch(error => {
          desativarShow("deleteUsuario");
          toast(error, "Falha", "danger")
        })
    },
    atualizarRegistro(entity) {
      let index = this.items.indexOf(this.items.find((i) => i.id === entity.id));
      if (index >= 0) {
        this.items.splice(index, 1, entity);
      } else {
        this.items.push(entity);
      }
    },
    abrirSelecionaLocal() {
      let idsEliminar = this.locais.lista.map(elem => elem.idLocal);
      this.$refs.selectLocal.abrir(idsEliminar)
    },
    adicionarLocal(locais) {
      locais.forEach((item) => {
        let reg = {
          idLocal: item.id,
          dscLocal: item.descricao,
          coordenador: "S",
        }
        this.locais.lista.push(reg);
      });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.deleted === 'S') return 'table-danger'
    },
    selecionarTodos(checked) {
      this.items.forEach((item) => {
        item.seleciona = checked === 'S' ? 'S' : 'N';
      });
    },
    async retornoSelecionaClassificacao(classificacoes) {

      let idsRegistro = this.items.filter(function (elem) {
        return elem.seleciona === 'S'
      }).map(elem => elem.id);

      let idsClassificacao = classificacoes.map(elem => elem.id);

      if (this.addRemoveClassificacao === 'add') {
        await this.$refs.selectClassificacao.adicionarClassificacao(idsRegistro, idsClassificacao, 'usuario')
      } else {
        await this.$refs.selectClassificacao.removerClassificacao(idsRegistro, idsClassificacao)
      }

      this.buscar()

    },
    abrirAdicionarClassificacao() {
      this.addRemoveClassificacao = "add"
      this.$refs.selectClassificacao.abrir(null, ['TGU'])
    },
    abrirRemoverClassificacao() {
      this.addRemoveClassificacao = "remove"
      this.$refs.selectClassificacao.abrir(null, ['TGU'])
    },
    isUsuarioEmpresa(usu) {      
      return this.usuarioLogado.grupo.empresa.id === usu.grupo.empresa.id
    },
  }
}
</script>
<style scoped>
.b-table {
  height: 200px;
  background-color: red;
}
</style>