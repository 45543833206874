<template>
  <ul class="nav nav-mobile-menu"  >

    <base-dropdown v-if="false" >
      <template slot="title">
        <i class="fa fa-globe"></i>
        <b class="caret"></b>
        <span class="notification" v-if="false">5 Notificações</span>
      </template>
      <a class="dropdown-item" href="#" v-if="false">Agenda</a>
      <a class="dropdown-item" href="#" v-if="false">Mensagens</a>
    </base-dropdown>

    <li class="nav-item" v-if="false" >
      <a href="#" class="nav-link">
        <i class="nc-icon nc-zoom-split hidden-lg-up"></i>
        <span class="d-lg-none">Pesquisar</span>
      </a>
    </li>

    <base-dropdown title="Administrativo" v-if="false">
      <a class="dropdown-item" href="#">Empresa</a>
      <a class="dropdown-item" href="#">Profissionais</a>
      <a class="dropdown-item" href="#">Tipo</a>
      <a class="dropdown-item" href="#">Situação</a>
      <a class="dropdown-item" href="#">Grupos de usuário</a>
      <a class="dropdown-item" href="#">Menus</a>
      <a class="dropdown-item" href="#">Situação</a>
    </base-dropdown>

    <base-dropdown title="Relatórios" v-if="false">
      <a class="dropdown-item" href="#/admin/relatorio-financeiro">Financeiro</a>
      <a class="dropdown-item" href="#/admin/relatorio-furo">Furos</a>
      <!-- <a class="dropdown-item" href="#/admin/relatorio-terceiro">Terceiro</a> -->
    </base-dropdown>

    <li>
      <button @click="logout" size="sm"  >
        Sair
      </button>
    </li>
  </ul>
</template>
<script>
import jwt_decode from "jwt-decode";
  export default {
    name: 'mobile-menu',
  mounted() {
         

  },
  data () {
      return {
        
      }
    },
    methods:{
      logout(){
        /*localStorage.setItem('authorization', null)
        localStorage.setItem('eventosArmazenados', null)
        localStorage.setItem('itensSetor', null)
        localStorage.setItem('itensProfissionais', null)
        localStorage.setItem('itensLocal', null)
        localStorage.setItem('dadosUsuario', null)
        this.$router.push('/login/acesso')*/
        this.$store.dispatch('auth/logout')
        this.toggleSidebar()
        this.$router.push('/login/acesso')
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
        this.$sidebar.displaySidebar(false)
      },
    }
  }
</script>
<style>
</style>
