import { BIconNodePlusFill } from "bootstrap-vue";

export const agenda = ({
  id: null,
  idRecorrencia: null,
  horarioDe: null,
  duracao: null,
  dtAgenda: null,
  dtAgendasStr: null,
  dtUltimaAlteracao: null,
  allDay: null,
  idLocal: 0,
  nomeLocal: null,
  idSituacao: 0,
  idProfissional: 0,
  nomeProfissional: '',
  idTipo: 0,
  cor: null,
  agendaDiaSemana: null,
  end: null,
  editRecorrencia: false,
  obs: "",
  valorCompl: 0.0,
  idUsuario: 0,
  replicar: false,
  historico: "",
})
