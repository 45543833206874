<template>
  <b-modal :ref="id" :id="id" size="lg" title="Selecionar cor" hide-footer no-close-on-backdrop>
    <div class="container-fluid">

      <b-row class="my-1">
        <div class="col-md-12">

          <b-card-body class="p-0" style="min-height: 370px;">

            <div class="container-fluid">

              <b-row>
                <b-col>
                  <div :style="{ background: color }">
                    <color-picker theme="light" :color="color" :sucker-hide="false" :sucker-canvas="suckerCanvas"
                      :sucker-area="suckerArea" @changeColor="changeColor" @openSucker="openSucker" />
                  </div>
                </b-col>
              </b-row>

            </div>

          </b-card-body>

        </div>
      </b-row>

      <b-row class="my-1">
        <div class="col-md-12">
          <button size="sm" style="margin-left: 4px" class="btn btn-danger btn-fill float-right" @click="fechar">
            Fechar
          </button>
          <button size="sm" class="btn btn-success btn-fill float-right" @click="confirmar">
            Confirmar
          </button>
        </div>
      </b-row>
    </div>

  </b-modal>
</template>

<script>

import colorPicker from '@caohenghu/vue-colorpicker'

export default {
  components: {
    colorPicker
  },
  data() {
    return {
      http: null,
      filaShow: [],
      color: '#59c7f9',
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      id: null,
    }
  },
  mounted() {
    this.updateId()
  },
  methods: {
    updateId() {
      const currentTime = new Date().getTime().toString()
      const randomInt = this.getRandomInt(0, currentTime)
      this.id = `modal-select-cor_${randomInt}`
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    confirmar() {
      let ret = {
        "status": "success",
        "color": this.color
      }
      this.$emit('executou', ret)
      this.fechar()
    },
    fechar() {
      this.hide()
    },
    async show(v) {
      this.color = v != null && v !== '' ? v : '#59c7f9';
      this.$bvModal.show(this.id)
    },
    hide() {
      this.$bvModal.hide(this.id)
    },
    changeColor(color) {
      this.color = color.hex
      //const { r, g, b, a } = color.rgba
      //this.color = `rgba(${r}, ${g}, ${b}, ${a})`      
    },
    openSucker(isOpen) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    }
  }
}
</script>

<style></style>