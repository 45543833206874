<template>
<div class="row justify-content-center">
    
  <card>

    <form>
      
      <div class="text-center" style="margin: 4%;width: 500px;">
        <h4>Dados enviados com sucesso</h4>
      </div>

      <div class="text-center" style="margin: 4%;width: 500px;">
        <p> Agradecemos o contato.<br/><br />Em breve entraremos em contato.</p>
      </div>

      <div class="clearfix"></div>
    </form>

  </card>
    
</div>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  
  export default {
    components: {
      Card,
    },
    data () {
      return {
      }
    },
    mounted: function () {


    },
    methods: {
      
    
    },
 
  }


</script>
<style>

</style>