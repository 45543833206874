<template>
  <li>
    <div :class="{ bold: isFolder}" @dblclick="makeFolder" >
      <span @click="toggle" v-if="isFolder">[{{ isOpen ? '-' : '+' }}]</span>
      <input class="check" type="checkbox" v-model="item.select" @change="selectItem">{{ item.titulo }}
      <button v-if="item.nivel != null" class="btn-edit" type="button" @click="editItem">Editar</button>
    </div>
    <ul v-show="isOpen" v-if="isFolder">      
      <tree-item class="item" v-for="(child, index) in item.children" :key="index" :item="child" 
        @make-folder="$emit('make-folder', $event)" @add-item="$emit('add-item', $event)"
        @select-item="$emit('select-item', $event)"  @edit-item="$emit('edit-item', $event)"></tree-item>
      <li v-if="false" class="add" @click="$emit('add-item', item)">+</li>
    </ul>
  </li>
</template>
  
<script>
export default {
  name: 'TreeItem',
  props: {
    item: Object
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    isFolder: function () {
      return this.item.children && this.item.children.length;
    },
  },
  methods: {
    toggle: function () {      
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },    
    makeFolder: function () {
      if (!this.isFolder) {
        this.$emit("make-folder", this.item);
        this.isOpen = true;
      }
    },
    selectItem: function () {
      this.$emit("select-item", this.item);
    },
    editItem: function () {
      this.$emit("edit-item", this.item);
    }

  }
}
</script>
  
<style lang="scss">
.tree-component {

  body {
    font-family: Menlo, Consolas, monospace;
    color: #444;
  }

  .item {
    cursor: pointer;
  }

  .bold {
    font-weight: bold;
  }

  ul {
    padding-left: 1em;
    line-height: 1.5em;
    list-style-type: none;
  }

  .check {
    margin-left: 0.5em;
  }

  .btn-edit {
    background-color: #5b8df9;
    border: none;
    color: white;    
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 9px;
  }

}
</style>