<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          
          <card class="col-md-12 caixa-suporte">
          <h3>Suporte</h3>
          E-mail: suporte@jsoftware.com.br<br/><br/>
          Fone: (31) 98872-5101 <br/><br/>
          
          <a href="https://docssapp.s3.sa-east-1.amazonaws.com/termo_uso.pdf" class="link-primary">Termo de uso</a><br/><br/>
          <a href="https://docssapp.s3.sa-east-1.amazonaws.com/politica_privacidade.pdf" class="link-primary">Política de privacidade</a>
                     
          </card>

        </div>        
      </div>
    </div>
  </div>
</template>

<script>

import AgendaService from "../service/AgendaService";
import axios from "axios";
import { BASE_CONSTANTS } from "../service/config";

export default {
  components: {
    
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],      
    };
  },
  async mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        Authorization:
          "bearer " +
          String(localStorage.getItem("authorization")).replaceAll('"', ""),
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });    

  },
  methods: {
    ativarShow(from) {
      if (this.showFrom === "") {
        this.showFrom = from;
        this.show = true;
      } else {
        this.filaShow.push(from);
      }
    },
    desativarShow(from) {
      if (from === this.showFrom) {
        if (this.filaShow.length > 0) {
          this.showFrom = this.filaShow[0];
          this.filaShow.splice(0, 1);
        } else {
          this.show = false;
          this.showFrom = "";
        }
      } else {
        let index = this.filaShow.indexOf(from);
        if (index >= 0) {
          this.filaShow.splice(index, 1);
        }
      }
    },    
  },
  computed:{
    
  }
};

</script>

<style>

.caixa-suporte{  
  width: 500px;
  margin: 200px auto;
  text-align: center;
}

</style>
