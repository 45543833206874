<template>

  <div v-if="perfil" class="box-perfil-profissional">
        
    <b-row>
      <b-col cols="4">
        <img width="100" height="auto" :src="'data:image/png;base64,' + perfil.foto" alt="Foto" />
      </b-col>
      <b-col cols="8">
        <p class="text-secondary"><span class="font-weight-bold">Nome: </span> {{ perfil.nome }} </p> 
        <p class="text-secondary"><span class="font-weight-bold">Crm: </span> {{ perfil.crm }} </p> 
        <p class="text-secondary"><span class="font-weight-bold">Telefone: </span> {{ perfil.celular | VMask( perfil.celular.length === 11 ? '(##) #####-####' : '(##) ####-####') }} </p>         
        <p class="text-secondary"><span class="font-weight-bold">E-mail: </span> {{ perfil.email }} </p> 
      </b-col>
    </b-row>

    <b-row class="box-content-quantidade my-3">

      <div class="mr-3">        
        <p>Realizados</p>
        <div class="box-qtd box-qtd-plantao">
          <p class="my-0" >{{perfil.qtdPlantao}}</p>
        </div>
      </div>      

      <div class="ml-3">                
        <p>Faltas</p>
        <div class="box-qtd box-qtd-furo">
          <p class="my-0">{{perfil.qtdFuro}}</p>
        </div>
      </div>      

    </b-row>

    <b-row class="my-3">
      <div>
        <label class="mr-3" for="rating-avaliacao">Avaliação:</label>
        <b-form-rating id="rating-avaliacao" v-model="perfil.rating" stars="5" variant="primary" inline show-value show-value-max readonly></b-form-rating>
      </div>
    </b-row>

  </div>
  
  </template>
  
  <script>
    import AgendaService from '../../service/AgendaService'
    import Card from 'src/components/Cards/Card.vue'    
    import appUtils from '@/composables/utils'
    
export default {
      props: ['idProfissional'],
      components: {
        Card, 
      },
      data() {           
        return {
          show: false,
          http: null,
          showFrom: "",
          filaShow: [],          
          perfil: null,
        }
      },
      computed: {
        
      },
    mounted() {
        if (this.idProfissional) {
          this.abrir(this.idProfissional)        
        }
      },
    methods: {              
      abrir(idProfissional) {
  
        const { ativarShow, desativarShow } = appUtils(this)

        ativarShow("abrir");

        let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))
        let dadosConsulta = {
          idEmpresa: dadosUsuario.grupo.empresa.id,
          idUsuario: dadosUsuario.idPessoa,
          idProfissional: idProfissional
        }      

        AgendaService.buscarPerfil(dadosConsulta)
          .then(resposta => {
            this.perfil = resposta.data.obj                        
            desativarShow("abrir");
          }).catch(error => {
            desativarShow("abrir");          
        })

      },      
  },  
      
  }
  
  function dateToStr(date, format) {  
    if (date !== undefined && date !== null) {
      let strDate = format
        .replace("yyyy", date.getFullYear())
        .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
        .replace("dd", date.getDate().toString().padStart(2, "0"))
        .replace("HH", date.getHours().toString().padStart(2, "0"))
        .replace("mm", date.getMinutes().toString().padStart(2, "0"))
        .replace("ss", date.getSeconds().toString().padStart(2, "0"));
      return strDate;
    }
    return "";
  }
  
  </script>
  <style>

  .box-perfil-profissional .box-content-quantidade{
    display: flex;    
    justify-content: center;
  }

  .box-perfil-profissional .box-qtd{    
      width: 60px;
      height: 60px;      
      justify-content: center;
      text-align: center;
      display: flex;
      align-items: center;
    }
    
    .box-perfil-profissional .box-qtd-plantao{      
      border: 3px solid #3472f7;                    
    }

    .box-perfil-profissional .box-qtd-furo{      
      border: 3px solid #ff4a55;
    }
  
  </style>