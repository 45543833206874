<template>
    <b-overlay :show="show" rounded="sm">     
      <card>

<template slot="header">
  <h4 class="card-title">Vaga</h4>
</template>

<b-container fluid> 
      <dados-vaga :idAgenda="idAgenda"></dados-vaga>

      </b-container>

      </card>
      </b-overlay>
      
</template>

<script>
  import Card from 'src/components/Cards/Card.vue'
  import DadosVaga from './comuns/DadosVaga'
  import appUtils from '@/composables/utils'  
  import AgendaService from '../service/AgendaService' 
  
  export default {
    components: {
      Card,
      DadosVaga
    },
    data () {
      return {
        show: false,
        showFrom: "",
        filaShow: [],
        empresa: '',
        idAgenda: null,
        agenda: null,        
        optionsUF:[]
      }
    },
  async mounted(){

    try {          

      this.empresa = this.$route.params.empresa
      this.idAgenda = this.$route.params.idAgenda

      const { toast, ativarShow, desativarShow } = appUtils(this)
        
      ativarShow("buscarEmpresa");

      let dadosConsulta = {
        "identificador": this.empresa
      }

        await AgendaService.buscarEmpresaPeloIdentificador(dadosConsulta)
            .then((resposta) => {

              let retorno = resposta.data;
              
              if (retorno.status === "SUCCESS") {            
                this.$store.dispatch('auth/loadEmpresa', retorno.obj);                
              } else {                        
                if (retorno.mensagens.length > 0) {              
                  toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                }
              }

              desativarShow("buscarEmpresa");

          }).catch((error) => {          
              desativarShow("buscarEmpresa");
              toast(error, 'Falha', 'danger')
          });

          //this.buscarVaga()
      }catch(e){
        alert(e)
      }      
      
    },
    methods: {
      buscarVaga() {         

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("BuscarVaga");                     
        
        let dadosConsulta = {
          idAgenda: this.idAgenda
        };
                
        AgendaService.buscarVagaPlantao(dadosConsulta)
          .then((resposta) => {
            this.agenda = resposta.data.obj;            
            
            if (this.agenda !== null) {
            
            } else {
              toast('Agenda não encontrada', 'Falha', 'danger')              
            }
            desativarShow("BuscarVaga");                     
          })
          .catch((error) => {            
            desativarShow("BuscarVaga");                     
          });          
      },         
      login() {
        this.$router.push({ name: 'Acesso', query: { redirect: '/'+this.empresa+'/vaga' } });
      },
      strDateFormat(dataStr, format) {
        let data = new Date(dataStr + " (UTC-3)");
        return dateToStr(data, format);
      },
    },    
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
}

</script>
<style>

  @media (max-width: 600px) {

    .link-vaga .display-3{  
      font-size: 2.0rem;
    }

    .link-vaga .lead{  
      font-size: 1.0rem;
    }

  }


  @media (min-width: 991px) {

    .dados-link-vaga p{  
      font-size: 1.0 rem;
    }

    .dados-link-vaga .valor-plantao{  
      font-size: 2.0rem;
      padding: 5px 15px;
    }

  }

</style>