<template>

  <div>  
  
      <!-- confirma ref="conf" @respondeu="(r) => { if(r === 'S') excluir() }" >
        <template v-slot:texto>Confirma exclusão da especialização</template>
      </confirma !-->
   
      <div>
  
      <card class="h-100 my-0" >
  
        <template slot="header" v-if="showHeader">
          <h4 class="card-title">Especializações</h4>
        </template>      
  
      <b-container :fluid="fluid">         
      <b-row class="lista-especializacao" v-if="especializacoes">       

        <div v-for="espec in especializacoes" :key="espec.id" class="box-especializacao col-12 px-2">
          
          <b-card class="shadow my-0" no-body>

            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button class="text-left text-truncate" block v-b-toggle="'collapse-' + espec.id"  variant="primary">{{espec.dscTipo}} - {{espec.titulo}}</b-button>
            </b-card-header>

            <b-collapse :id="'collapse-'+espec.id" accordion="my-accordion" role="tabpanel" visible>
              <b-card-body >
                <especializacao
                :espec="espec" :showSave="showSave" :showExcluir="showExcluir" :leitura="leitura" :campo="campo"
                @excluir="(e) => { excluir(e) }" @cancelar="(e) => { cancelar(e) }" @editar="(e) => { editar(e) }"></especializacao>
          </b-card-body>

          </b-collapse>       
        </b-card>            

        </div>

      </b-row>

      <b-row class="pt-2">        
        <b-col class="text-center text-md-left" cols="4" v-if="!leitura && !campo.naoQueroInformar">
          <b-button type="submit" class="btn btn-info btn-fill" @click.prevent="incluir">
            Incluir especialização
          </b-button>                  
        </b-col>
        <b-col class="d-flex align-items-center mx-2" style="align-items: center;" v-if="especializacoes.length == 0">
          <b-form-checkbox v-model="campo.naoQueroInformar" :disabled="leitura" 
           >{{ campo.conf && campo.conf.tituloNaoInformar ? campo.conf.tituloNaoInformar : "Não quero informar" }}</b-form-checkbox>
        </b-col>
      </b-row>
          
    </b-container>                    
  
    </card>
  
    </div>
  
    <div class="clearfix"></div>
    
  </div>
  
  </template>
  
  <script>
    import AgendaService from '../../service/AgendaService'
    import Card from 'src/components/Cards/Card.vue'
    import axios from 'axios'
    import { BASE_CONSTANTS } from '../../service/config'
    import appUtils from '@/composables/utils'
import Confirma from './Confirma'
import funcao from "../../components/Funcao"
import getUF from '@/composables/getUF'
import Especializacao from './Especializacao'
    
export default {
      props: ['especializacoes','showHeader','showSave','showExcluir','leitura','fluid','campo'],
      components: {
        Card, 
        Confirma,
        Especializacao,    
      },
      data() {           
        return {
          show: false,
          http: null,
          showFrom: "",
          filaShow: [],
          id: null,          
          fileSelecionado: null,
          /*tiposEspecializacao: [],
          listaEspecialidade: [],
          niveisEspecializacao: [],          
          situacoesEspecializacao: [],*/
          copia: null,
          optionsUF: [],
        }
      },
      computed: {
        
      },
      mounted() {
  
        this.http = axios.create({
          baseURL: BASE_CONSTANTS.urlBase,
          headers: {
              'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
              'Content-Type': 'application/json',
              'Accept': '*/*'
          }
        })      

        this.inicializar()
        this.carregaDados()

      },
    methods: {        
      inicializar() {
        this.optionsUF = getUF()      
      },      
      incluir() {             

        let minId = this.especializacoes.reduce(
          (min, prof) => (prof.id < min ? prof.id : min),
          0
        )

        minId -= 1;

        this.especializacoes.push({
          "id": minId,
            "idPessoa": null,
            "titulo": '',
            "nivel": '',
            "instituicao": '',
            "idEspecialidade": null,
            "dscEspecialidade": '',
            "idSituacao": null,
            "dscSituacao": '',
            "rqe": '',
            "dtObtencao": '',
            "documentos": [],
          "editing": 'N'
        })

        this.editar(minId)
        
        },
      editar(id) {

        if (this.showSave) {
          this.especializacoes.forEach((e) => {
            e.editing = 'N'
          })          
        }

          let item = this.especializacoes.find(e => e.id === id)
          this.copia = {...item}
          item.editing = 'S'          

        },      
      carregaDados(){
  
          const { toast, ativarShow, desativarShow } = appUtils(this)
  
          ativarShow("listarCredenciamentos");
  
        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
  
        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idSituacao": this.idSituacao,
          "tipoData": this.tipoFiltroData,
          "dtInicio": this.dtInicio,
          "dtFim": this.dtFim,
        }      
        
        AgendaService.listaCredenciamento(dadosConsulta)
          .then((resposta) => {           
            this.items = resposta.data.obj  
            this.totalRows = this.items.length                    
            desativarShow("listarCredenciamentos");
          })
          .catch((err) => {          
            desativarShow("listarCredenciamentos");
          });
  
        },      
        salvar(id) {
          
          const { toast, ativarShow, desativarShow } = appUtils(this)

          ativarShow("salvar");         

          let item = this.especializacoes.find(e => e.id === id)
          
          AgendaService.saveEspecializacao(this.http, item)
            .then((resposta) => {
              let retorno = resposta.data;
              if (retorno.status === "SUCCESS") {            
                let index = this.especializacoes.indexOf(item);     
                this.especializacoes.splice(index, 1, retorno.obj);
                toast(retorno.mensagens[0].descricao, "Sucesso", "success")                
              } else {                        
                if (retorno.mensagens.length > 0) {              
                  toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                }
              }              
              desativarShow("salvar");
            }).catch((error) => {          
              desativarShow("salvar");
              toast(error, 'Falha', 'danger')
            });

      },
      /*confirmaExcluir(id) {
          
        this.id = id
        this.$refs.conf.show()        
          
      },*/
      cancelar(id) {                  
        if (id < 0) {
          //this.especializacoes = this.especializacoes.filter((e) => { return e.id !== id })
          this.$emit('remove', id)
        } else {
          let item = this.especializacoes.find(e => e.id === id)        
          item.editing = 'N'
          let index = this.especializacoes.indexOf(item);
          this.especializacoes.splice(index, 1, this.copia);
        }        
      },
      excluir(id) {
        this.especializacoes = this.especializacoes.filter((e) => { return e.id !== id })

        /*if (id < 0) {
          this.cancelar(id)
        } else {

          const { toast, ativarShow, desativarShow } = appUtils(this)
          ativarShow("excluir");

          AgendaService.deleteEspecializacao(this.http, this.id).then(resposta => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
              this.especializacoes = this.especializacoes.filter((e) => { return e.id !== this.id })
              toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            } else {
              if (retorno.mensagens.length > 0) {
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }
            desativarShow("excluir");
          }).catch(error => {
            desativarShow("excluir");
            toast(error, "Falha", "danger")
          })
        }*/

      },
      atualizarRegistro(entity) {
          let index = this.items.indexOf(this.items.find((i) => i.id === entity.id));
          if (index >= 0) {
            this.items.splice(index, 1, entity);
          } else {          
            this.items.push(entity);
          }
      },
      abrirSelecionaProfissional() {    
          const { toast } = appUtils(this)
          let idsEliminar = this.locais.locais.map(elem => elem.idLocal);
          this.$refs.selectLocal.abrir(idsEliminar)      
        },    
      adicionarProfissional(locais) {
          locais.forEach((item) => {              
              
            let reg = {
              idLocal:  item.id,
              dscLocal: item.descricao,            
            }
  
            this.locais.locais.push(reg);

          });          
      },
      rowClass(item, type) {
           if (!item || type !== 'row') return
          if (item.deleted === 'S') return 'table-danger'
        },
        abrirCredenciamento(record, index) {
          this.editar(record.id)
      },
      strDateFormat(dataStr, format) {
        if (dataStr !== null) {
          let data = new Date(dataStr + " (UTC-3)")
          return dateToStr(data, format);
        } else {
          return ""
        }
      },      
      submitFile(event) {

        var espec = this.especializacoes.find(e => e.id.toString() === event.target.id);
        let index = 0;

        let lerArquivo = (file) => {

            var reader = new FileReader();
          reader.onload = (e) => {      
            var minId = espec.documentos.reduce((min, doc) => (doc.id < min ? doc.id : min), 0)            
            minId -= 1;      
              espec.documentos.push({
                "id": minId,
                "nome" : file.name,            
                "arquivo": e.target.result,
                "idTipo": null
              })                      
            };            
            reader.readAsDataURL(file)
            reader.onerror = function (error) {
              console.log('Error: ', error)
            }
        };

        for (index = 0; index < event.target.files.length; index++){
          var file = event.target.files[index];
          lerArquivo(file)
        }
      },
      alterarTipo(id, idTipo) {
        let item = this.especializacoes.find(e => e.id === id)          
        let tipo = this.tiposEspecializacao.find(t => t.id === idTipo)
        item.dscTipo = tipo ? tipo.descricao : ''
      },    
      abrirArquivo(idEspec, idDoc) {
        var espec = this.especializacoes.find(e => e.id = idEspec);        
        var doc = espec.documentos.find(d => d.id === idDoc);
        funcao.methods.downloadData(doc.arquivo, doc.nome);
      },  
      removerDocumento(idEspec, idDoc) {
        var espec = this.especializacoes.find(e => e.id = idEspec);
        console.log(espec)        
        espec.documentos = espec.documentos.filter((d) => { return d.id !== idDoc })
      }

  },  
  /*watch: {
    especializacoes (newValue) {
      this.especializacoesInterna = newValue // Update when the prop changes
    },
    especializacoesInterna(newValue) {
      console.log(newValue)
      this.$emit('passData', this.especializacoesInterna)
    }
  }*/
    
  }
  
  function dateToStr(date, format) {  
    if (date !== undefined && date !== null) {
      let strDate = format
        .replace("yyyy", date.getFullYear())
        .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
        .replace("dd", date.getDate().toString().padStart(2, "0"))
        .replace("HH", date.getHours().toString().padStart(2, "0"))
        .replace("mm", date.getMinutes().toString().padStart(2, "0"))
        .replace("ss", date.getSeconds().toString().padStart(2, "0"));
      return strDate;
    }
    return "";
  }
  
  </script>
  <style>

    /*.card form [class*=col-]:first-child{
      padding-left: 0 !important;
    }

    .card form [class*=col-]:last-child{
      padding-right: 0 !important;
    }*/

    .lista-especializacao p{
      line-height: 1;
    }
  
    .c-form{
      padding-bottom: 60px;
    }

    .lista-especializacao .box-especializacao{
      background-color: rgba(213, 221, 249, 0.327);
    }
  
    </style>