finpu<template>
<div class="row justify-content-center">
    <b-overlay :show="show" rounded="sm">
  <card>

  <div class="row">    
      <div class="logo">
        <img v-if="logoEmpresa !== null" :src="logoEmpresa" alt="Logo" width="200" height="100" >
      </div>
      <div class="col-md-7" style="text-align:center;" >
        <h4 slot="header" style="margin-top: 4%;" class="card-title">:: Ficha de cadastro</h4>
    </div>
  </div>

    <form>
      <div class="row">

        <div class="col-md-7">
          <base-input label="Nome">
          
              <b-form-input
                    ref="nome"
                    type="text"
                    label="Nome"
                    placeholder="Nome"
                    maxlength = "100"
                    v-model="profissional.nome" />
          </base-input>
        </div>
        <div class="col-md-5">
          <base-input label="Nacionalidade">
             <b-form-input 
                    ref="nacionalidade"
                    type="text"
                    placeholder="Nacionalidade"
                    v-model="profissional.nacionalidade" />
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          <base-input label="UF CRM">
            <b-form-select ref="ufCrm" v-model="profissional.ufCrm" :options="optionsUF" value-field="value" text-field="value"></b-form-select>
          </base-input>
         </div> 
        <div class="col-md-3">
          <base-input label="CRM">
              <b-form-input type="text"
                    ref="crm"
                    placeholder="CRM"
                    v-model="profissional.crm" />
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input label="CPF">
              <b-form-input type="text"
                    ref="cpf"
                    maxlength = "14"
                    placeholder="CPF"
                    v-mask="'###.###.###-##'"                    
                    v-model="profissional.cpf" />
          </base-input>
        </div>
         <div class="col-md-3">
          <base-input label="RG">
            <b-form-input
                    type="text"
                    maxlength = "10"
                    ref="rg"
                    v-mask="'##.###.###'"
                    placeholder="RG"
                    v-model="profissional.rg" />
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <base-input label="Estado Cívil">
            <b-form-select ref="estadoCivil" v-model="profissional.estadoCivil" :options="optionsEstadoCivil"></b-form-select>
          </base-input>
        </div>

        <div class="col-md-8">
          <base-input label="E-mail">
            <b-form-input type="email"
                    ref="email"
                    placeholder="E-mail"
                    v-model="profissional.email" />
          </base-input>
        </div>
      </div>

        <div class="row">
          <div class="col-md-4">
            <base-input label="Telefone Fixo">
            <b-form-input
                      type="text"
                      ref="telefone"
                      placeholder="Telefone Fixo"
                      maxlength = "13"
                      v-mask="'(##)####-####'"
                      v-model="profissional.telefone" />
            </base-input>
          </div>
           <div class="col-md-4">
            <base-input label="Telefone Celular">
                  <b-form-input
                      type="text"
                      ref="celular"
                      placeholder="Telefone Celular"
                      maxlength = "14"
                      v-mask="'(##)#####-####'"
                      v-model="profissional.celular" />
            </base-input>
          </div>
        </div>

      Endereço:  
      <hr />
      <div class="row">
        <div class="col-md-4">
          <!-- 
            https://viacep.com.br/ws/32900000/json/

          -->
          <base-input label="CEP">
              <b-form-input
                    type="text"
                    ref="cep"
                    maxlength = "9"
                    placeholder="CEP"
                    v-mask="'#####-###'"
                    v-model="profissional.endereco.cep"
                    v-on:keyup="buscar" />
          </base-input>
        </div>  
      </div>

      <div class="row">
        <div class="col-md-7">
          <base-input label="Endereço">
                <b-form-input
                    id="inputLogradouro"
                    type="text"
                    ref="logradouro"
                    placeholder="Endereço"
                    v-model="profissional.endereco.logradouro" />
          </base-input>
        </div>
        
         <div class="col-md-2">
          <base-input label="Número">

                <b-form-input
                    type="text"
                    ref="numero"
                    maxlength = "6"
                    placeholder="num."
                    v-model="profissional.endereco.numero" />
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input label="Complemento">
                <b-form-input
                    type="text"
                    ref="complemento"
                    placeholder="Complemento"
                    v-model="profissional.endereco.complemento" />
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <base-input label="Bairro">
                <b-form-input
                    id="inputBairro"
                    type="text"
                    ref="bairro"
                    placeholder="Bairro"
                    v-model="profissional.endereco.bairro" />
          </base-input>
        </div>

        <div class="col-md-4">
          <base-input label="UF">

              <b-form-input
                    id="inputUF"
                    type="text"
                    ref="uf"
                    maxlength = "2"
                    placeholder="UF"
                    v-model="profissional.endereco.uf" />
          </base-input>
        </div>
         <div class="col-md-4">
          <base-input label="Cidade">
                <b-form-input
                    id="inputCidade"
                    type="text"
                    maxlength="100"
                    ref="cidade"
                    placeholder="Cidade"
                    v-model="profissional.endereco.cidade" />
          </base-input>
        </div>
      </div>
      
      Dados Bancários:  
      <hr />
       <div class="row">
        <div class="col-md-4">
          <base-input label="Cod. Banco">
                <b-form-input
                  type="text"
                    ref="codBanco"
                    placeholder="Cod. Banco"
                    maxlength="4"
                    v-model="profissional.contaBancaria.codBanco" />
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input label="Agência">
          <b-form-input
                  type="text"
                    ref="agencia"
                    maxlength="6"
                    placeholder="Agencia"
                    v-model="profissional.contaBancaria.agencia" />
          </base-input>
        </div>
        <div class="col-md-1">
          <base-input label="Dígito">
          <b-form-input
                  type="text"
                    ref="dvAgencia"
                    maxlength="1"
                    placeholder="Dígito"
                    v-model="profissional.contaBancaria.dvAgencia" />
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input label="Conta">
                <b-form-input
                type="text"
                    ref="conta"
                    maxlength="20"
                    placeholder="Conta"
                    v-model="profissional.contaBancaria.numero" />
          </base-input>
        </div>
        <div class="col-md-1">
          <base-input label="Dígito">
                <b-form-input
                type="text"
                    ref="dvConta"
                    maxlength="1"
                    placeholder="Dígito"
                    v-model="profissional.contaBancaria.dvNumero" />
          </base-input>
        </div>
      </div>
      Anexos:      
      <hr />
    <div class="row">
      <div class="col-md-9">
        <b-form-file id="file1"
        v-model="files[0].arquivo"
        :state="Boolean(files[0].arquivo)"
        placeholder="Carteira de identidade"
        drop-placeholder="Arraste o arquivo aqui"
        browse-text="Procurar"
        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
        @change="submitFile"
        ></b-form-file>
        <div>{{ files[0].arquivo ? 'Arquivo selecionado: '+ files[0].arquivo.name : 'Formatos aceitos: jpg, jpeg, png, pdf, doc, docx' }}</div>        
      </div>
      <div class="col-md-1">
        <b-button @click="files[0].arquivo = null" size="sm">Limpar</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <b-form-file id="file2"
        v-model="files[1].arquivo"
        :state="Boolean(files[1].arquivo)"
        placeholder="CPF"
        drop-placeholder="Arraste o arquivo aqui"
        browse-text="Procurar"
        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
        @change="submitFile"
        ></b-form-file>
        <div>{{ files[1].arquivo ? 'Arquivo selecionado: '+ files[1].arquivo.name : 'Formatos aceitos: jpg, jpeg, png, pdf, doc, docx' }}</div>        
      </div>
      <div class="col-md-1">
        <b-button @click="files[1].arquivo = null" size="sm" >Limpar</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <b-form-file id="file3"
        v-model="files[2].arquivo"
        :state="Boolean(files[2].arquivo)"
        placeholder="CRM ou Registro de Inscrição do CRM"
        drop-placeholder="Arraste o arquivo aqui"
        browse-text="Procurar"
        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
        @change="submitFile"
        ></b-form-file>
        <div>{{ files[2].arquivo ? 'Arquivo selecionado: '+ files[2].arquivo.name : 'Formatos aceitos: jpg, jpeg, png, pdf, doc, docx' }}</div>        
      </div>
      <div class="col-md-1">
        <b-button @click="files[2].arquivo = null" size="sm" >Limpar</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <b-form-file id="file4"
        v-model="files[3].arquivo"
        :state="Boolean(files[3].arquivo)"
        placeholder="Comprovante de endereço"
        drop-placeholder="Arraste o arquivo aqui"
        browse-text="Procurar"
        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
        @change="submitFile"
        ></b-form-file>
        <div>{{ files[3].arquivo ? 'Arquivo selecionado: '+ files[3].arquivo.name : 'Formatos aceitos: jpg, jpeg, png, pdf, doc, docx' }}</div>        
      </div>
      <div class="col-md-1">
        <b-button @click="files[3].arquivo = null" size="sm" >Limpar</b-button>
      </div>
    </div>

      <div class="row">
        <b-row class="my-1">
         <div class="col-md-12">
           <b-alert
            v-if="msg !== ''" style="width:100%" class="col-md-12" show variant="warning">{{ msg }}</b-alert>
         </div>
       </b-row>
      </div>
      <div>
         <b-button id="exibeMensagem" hidden @click="toast('b-toaster-bottom-right', true)" class="mb-2">b-toaster</b-button>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="enviarDados">
          Enviar
        </button>        
      </div>
      <div class="clearfix"></div>
    </form>
  </card>
    </b-overlay>
</div>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  import axios from 'axios'
  import { BASE_CONSTANTS } from '../../../service/config'
  
  export default {
    components: {
      Card,
    },
    data () {
      return {
        logoEmpresa: null,
        counter: 0,
        cabecalho: '',
        URL_PAGE: this.$route.query.page,
        show: false,
        msg: '',
        cep: '',
        endereco: {},
        naoLocalizado: false,
        files: [
             {id: "file1", arquivo: null, conteudo: null},
             {id: "file2", arquivo: null, conteudo: null},
             {id: "file3", arquivo: null, conteudo: null},
             {id: "file4", arquivo: null, conteudo: null}
        ],
        profissional: {
          cpf: null,
          rg: null,
          estadoCivil: null,
          email: null,
          telefone: null,
          celular: null,
          idEmpresa: null,
          nome: null,
          nacionalidade: null,          
          ufCrm: null,
          crm: null,
          endereco: {
            cep: null,
            logradouro: null,
            numero: null,
            complemento: null,
            bairro: null,
            uf: null,
            cidade: null
          },
          contaBancaria: {
            codBanco: null,
            agencia: null,
            dvAgencia: null,
            numero: null,
            dvNumero: null
          },
          documentos: [{idTipo: 1, nome: 'Carteira de Identidade', arquivo: null},
          {idTipo: 2, nome: 'CPF', arquivo: null},
          {idTipo: 3, nome: 'CRM ou Registro de Inscrição do CRM', arquivo: null},
          {idTipo: 4, nome: 'Comprovante de endereço', arquivo: null}]
        },
        optionsEstadoCivil:[
          { value: null, text: 'Estado Cívil', disabled: true  },
          { value: 'S', text: 'Solteiro(a)' },
          { value: 'C', text: 'Casado(a)' },
          { value: 'D', text: 'Divorciado(a)' },
          { value: 'V', text: 'Viúvo(a)'},
        ],
        optionsUF:[
          { value: '', text: '', disabled: true  },
          { value: 'AC', text: 'Acre' },
          { value: 'AL', text: 'Alagoas' },
          { value: 'AP', text: 'Amapá' },
          { value: 'AM', text: 'Amazonas'},
          { value: 'BA', text: 'Bahia' },
          { value: 'CE', text: 'Ceará' },
          { value: 'ES', text: 'Espírito Santo' },
          { value: 'GO', text: 'Goiás'},
          { value: 'MS', text: 'Maranhão'},
          { value: 'MT', text: 'Mato Grosso' },
          { value: 'MS', text: 'Mato Grosso do Sul' },
          { value: 'MG', text: 'Minas Gerais' },
          { value: 'PA', text: 'Pará'},
          { value: 'PB', text: 'Paraíba'},
          { value: 'PR', text: 'Paraná'},
          { value: 'PE', text: 'Pernambuco'},
          { value: 'PI', text: 'Piauí'},
          { value: 'RJ', text: 'Rio de Janeiro'},
          { value: 'RN', text: 'Rio Grande do Norte'},
          { value: 'RS', text: 'Rio Grande do Sul'},
          { value: 'RO', text: 'Rondônia'},
          { value: 'RR', text: 'Roraima'},
          { value: 'SC', text: 'Santa Catarina'},
          { value: 'SP', text: 'São Paulo'},
          { value: 'SE', text: 'Sergipe'},
          { value: 'TO', text: 'Tocantins'},
          { value: 'DF', text: 'Distrito Federal'},
        ]
      }
    },
    mounted: function () {

      try{          
        
        let dadosConsulta = '{ "codigo": "' + this.$route.params.idForm + '"}';
                
        axios.post(BASE_CONSTANTS.urlBase + 'formulario/buscar', dadosConsulta, {
            headers: {
                'Content-Type':'application/json'
            }
          }).then((response) => {   
                        
            let retorno = response.data;
            if(retorno.status === 'SUCCESS'){
              let formulario = retorno.obj;
              this.profissional.idEmpresa = formulario.empresa.id              
              this.logoEmpresa = formulario.empresa.logo
            }else{
              this.$router.push('/notfound');
            }

          })
          .catch((error) => {
            alert(error)    
          });

      }catch(e){
        alert(e)
      }

      this.dadosTeste()
    },
    methods: {
      verificarCpf(cpf) {      
        return validar(cpf)
      },
      validaCampos(evento){        
        return true
      },
      toast(toaster, append = false, mensagem, cabecalho, tipoMensagem) {
        this.counter++
        this.$bvToast.toast(mensagem, {
            title: cabecalho,
            toaster: toaster,
            solid: true,
            variant: tipoMensagem,
            appendToast: append,
            enableHtml: true,

        })
      },      
      enviarDados () {
         
         this.profissional.documentos[0].arquivo = this.files[0].conteudo
         this.profissional.documentos[1].arquivo = this.files[1].conteudo
         this.profissional.documentos[2].arquivo = this.files[2].conteudo
         this.profissional.documentos[3].arquivo = this.files[3].conteudo
         var registro = this.profissional
         
         this.show = true;
          axios.post(BASE_CONSTANTS.urlBase + 'profissional/captacao', registro, {
            headers: {
                'Content-Type':'application/json'
            }
          }).then(response => {            
            let retorno = response.data;
            if(retorno.status === 'SUCCESS'){
              this.show = false;
              //this.limpar()              
              this.$router.push('/forms/confirmacao');
            }else{
              this.show = false;
              this.toast('b-toaster-top-right', true, retorno.mensagens[0].descricao, 'Falha', 'danger')
            }            
          }).catch(e =>{
            this.show = false;
            this.toast('b-toaster-top-right', true, retorno.mensagens[0].descricao, 'Falha', 'danger')
          });
        
      },
      submitFile(event) {        
        var file = this.files.find(f => f.id === event.target.id);        
        var reader = new FileReader();        
        reader.onloadend = () => {                     
          file.conteudo = reader.result
          //console.log(reader.result);   
        }        
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }                
        reader.readAsDataURL(event.target.files[0])
      },
      /*submitFile1 () {
        var file = document.getElementById('file1').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.profissional.anexo1 = reader.result          
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      }*/      
      registrar: function(){
      // processar dados
    },
    dadosTeste(){      
      this.profissional.cpf = '603.827.430-83'
      this.profissional.rg = 'MG 11526854'
      this.profissional.estadoCivil = 'S'
      this.profissional.email = 'jovitomg@gmail.com.br'
      this.profissional.telefone = '3136254587'
      this.profissional.celular = '3199632545'
      //this.profissional.idEmpresa = null
      this.profissional.nome = 'Profissional Teste 1'
      this.profissional.nacionalidade = 'brasileiro'
      this.profissional.ufCrm = 'MG'
      this.profissional.crm = '25635'
      this.profissional.endereco.cep = '31310070'
      this.profissional.endereco.logradouro = 'Rua teste'
      this.profissional.endereco.numero = '123'
      this.profissional.endereco.complemento = null
      this.profissional.endereco.bairro = 'bairro teste'
      this.profissional.endereco.uf = 'MG'
      this.profissional.endereco.cidade = 'Belo Horizonte'
      this.profissional.contaBancaria.codBanco = '003'
      this.profissional.contaBancaria.agencia = '2563'
      this.profissional.contaBancaria.numero = '456'      
    },
    limpar(){
      this.file1  = null
      this.file2 = null
      this.file3 = null
      this.profissional.cpf = null
      this.profissional.rg = null
      this.profissional.estadoCivil = null
      this.profissional.email = null
      this.profissional.telefone = null
      this.profissional.celular = null
      this.profissional.idEmpresa = null
      this.profissional.nome = null
      this.profissional.nacionalidade = null
      this.profissional.ufCrm = null
      this.profissional.crm = null
      this.profissional.endereco.cep = null
      this.profissional.endereco.logradouro = null
      this.profissional.endereco.numero = null
      this.profissional.endereco.complemento = null
      this.profissional.endereco.bairro = null
      this.profissional.endereco.uf = null
      this.profissional.endereco.cidade = null
      this.profissional.contaBancaria.codBanco = null
      this.profissional.contaBancaria.agencia = null
      this.profissional.contaBancaria.numero = null
      this.$refs.file1.value = null
      this.$refs.file2.value = null
      this.$refs.file3.value = null
    },
    buscar: function(){
      
      var self = this;
      var cep = (this.profissional.endereco.cep).replace('-','')      
      self.naoLocalizado = false;
      let valit = /^[0-9]{5}-[0-9]{3}$/.test(cep)
      let tamanhoString = cep.length
      if(tamanhoString == 8){        
        this.show = true;
        let urlBusca = "https://viacep.com.br/ws/" + cep + "/json/"
         axios.get(urlBusca).then(endereco => {
          this.profissional.endereco.logradouro = endereco.data.logradouro
          this.profissional.endereco.bairro = endereco.data.bairro
          this.profissional.endereco.uf     = endereco.data.uf
          this.profissional.endereco.cidade = endereco.data.localidade
          this.show = false;
        }).catch(e => {
          this.show = false;
          this.profissional.endereco.logradouro = null
          this.profissional.endereco.bairro = null
          this.profissional.endereco.uf     = null
          this.profissional.endereco.cidade = null
        })
      }
    }
    }
 
  }

  const validar = cpf => checkAll(prepare(cpf))

  const notDig = i => !['.', '-', ' '].includes(i)
  const prepare = cpf => cpf.trim().split('').filter(notDig).map(Number)
  const is11Len = cpf => cpf.length === 11
  const notAllEquals = cpf => !cpf.every(i => cpf[0] === i)
  const onlyNum = cpf => cpf.every(i => !isNaN(i))

  const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx)
  const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0)
  const resto11 = somaDig => 11 - (somaDig % 11)
  const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11

  const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)))
  const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos]

  const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)]
  const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r)

</script>
<style>

</style>