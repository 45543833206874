<template>
  <div class="h-100 d-flex align-items-center justify-content-center">            
       <h3 class="no-data-dash text-center text-secondary" >Não existem dados a serem exibidos</h3>            
  </div>
</template>

<script>
export default {

}
</script>

<style>
.no-data-dash{
    font-size: 20px;
}
</style>